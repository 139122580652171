import React, { useState, useEffect, useRef } from 'react';
import { Form, Container, Row, Col, Image, Button } from 'react-bootstrap';
import { Title, Content, Styles } from '../../../style/content';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { userService } from 'src/services/GM/userService';
import { MobilityGroup } from './../Groups/groups.interface';

const Groups: React.FC = () => {
  const [mobilityGroups, setMobilityGroups] = useState([] as MobilityGroup[]);

  useEffect(() => {
    getMobilityGroups();
  }, []);

  const getMobilityGroups = async () => {
    const result = await userService.getMobilityGroups();
    if (result) {
      setMobilityGroups(result);
    } else {
      console.log(result.message);
    }
  };

  return (
    <>
      <Title>Grupos de Mobilidade</Title>

      <Content>
        <Container fluid className='card-no-tabs groups_create'>
          <Row>
            <Col>
              <p className='description_header'></p>
            </Col>
            <Col md={3}>
              <Link to={'/gm/groups/create/'}>
                <Button
                  variant='outline-info'
                  size='lg'
                  className='float-right new-group-button'
                  style={Styles.button}
                  data-testid='newGroupButton'
                >
                  <FontAwesomeIcon icon={faPlus} />
                </Button>
              </Link>
            </Col>
          </Row>
          <Row className='container_group_card d-flex justify-content-around'>
            {mobilityGroups &&
              mobilityGroups.length > 0 &&
              mobilityGroups.map((item, i) => {
                return (
                  <Link
                    to={`/gm/groups/update?groupid=${item.id}`}
                    key={String(item.id)}
                  >
                    <div className='group_card'>
                      <span className='title'>{item.acronym}</span>
                      <span className='value'>
                        {item.plafondValue ? item.plafondValue : '0'}
                        {''}€
                      </span>
                      <span className='sub_value'>saldo mensal atribuído</span>
                      <div className='services_bar social-networks'>
                        {item.mobilityGroupServiceDTO.map((item, i) => {
                          return (
                            <i
                              className={`icon-service-${item.serviceId}`}
                              key={i}
                            ></i>
                          );
                        })}
                        {item.mobilityGroupServiceDTO &&
                          item.mobilityGroupServiceDTO.length == 0 && (
                            <p>Sem serviços</p>
                          )}
                      </div>
                      <span className='users_label'>
                        {item.users.length}{' '}
                        {item.users.length > 1
                          ? 'colaboradores'
                          : 'colaborador'}
                      </span>
                    </div>
                  </Link>
                );
              })}
          </Row>
        </Container>
      </Content>
    </>
  );
};

export default Groups;

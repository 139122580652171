import React, { useState, useEffect, useRef } from 'react';
import { Form, Container, Row, Col, Image, Button } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { Link, useHistory } from 'react-router-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import { CLIENT_CREATE_PAGE_TITLE } from '../../../constants/clientConstants';
import { companyService } from '../../../services/companyService';
import { CLIENT_PAGE } from '../../../constants/clientConstants';
import { Title, Content, Styles } from '../../../style/client';
import { clientService } from 'src/services/clientService';
import { SweetAlertType } from 'react-bootstrap-sweetalert/dist/types';

const ClientCreate: React.FC = () => {
  const [services, setServices] = useState([] as Service[]);
  const [clientServices, setClientServices] = useState([] as Number[]);
  const [clientBalance, setClientBalance] = useState(Number);
  const [alertDialog, setAlertDialog] = useState(false);
  const [alertDialogConfig, setAlertDialogConfig] = useState({} as AlertConfig);
  const history = useHistory();
  const { id } = useParams();

  useEffect(() => {
    getServices();
    if (id) {
      getClient(id).then((result) => {
        if (result) {
          setClientBalance(result.balance)
          getClientServices()
        } else {
          history.push(`/clients`);
        }
      });
    }
  }, []);

  const editClient = async (id) => {
    let client = { balance: clientBalance, clientServices: clientServices }
    const result = await clientService.edit(client, id);
    if (result.status === 200) {
      alert('Sucesso', result.message, 'success', true);
    } else {
      alert('Erro', result.message, 'error');
    }
  };
  const getClientServices = async () => {
    const result = await companyService.getClientServices(id);
    if (result.data) {
      let services = result.data.filter((el) => el.serviceId !== 6);
      let array : Number[] = []
      services.forEach((el) => {array.push(el.serviceId); selectService(el.serviceId)})
      setClientServices(array);
    }
  }
  const getServices = async () => {
    const result = await companyService.getAllActive();
    if (result.data) {
      let services = result.data.filter((el) => el.id !== 6);
      setServices(services);
    }
  };

  const getClient = async (id) => {
    try {
      const result = await clientService.getById(id);
      return result.data as Client;
    } catch (e) {
      console.log(e);
    }
  };
  
  const alert = (
    title: String,
    message: String,
    type: SweetAlertType,
    redirect = false
  ) => {
    setAlertDialog(true);
    setAlertDialogConfig({
      title: title,
      message: message,
      type: type,
      redirect: redirect,
    });
  };

  const selectService = (id) => {
    console.log(id)
    const input = document.getElementById(`checkservice${id}`) as HTMLInputElement | null;
    if (clientServices == undefined) {
      setClientServices(clientServices);
    } else {
      if (!clientServices.includes(id)) {
        let array = clientServices;
        array.push(id);
        setClientServices(array);
        
        if (input !== null) {
          input.checked = true
        }
        
      } else {
        let array = clientServices;
        const index = array.indexOf(id);
        if (index > -1) {
          array.splice(index, 1);
        }
        setClientServices(array);
        if (input !== null) {
          input.checked = false
        }
      }
    }
  };

  const getServiceElement = (item) => {
    return (
      <Row className='pl-4 service_item'>
        <Form.Check
          inline
          type={'checkbox'}
          id={'checkservice'+item.id}
          onChange={() => selectService(item.id)}
          key={item.name + item.id}
          
        />
        <img
          className={'logo'}
          src={require(`../../../assets/logos/${item.name.toLowerCase()}.png`)}
          alt='Profile'
        />
      </Row>
    );
  };

  return (
    <>
      <Title>{CLIENT_CREATE_PAGE_TITLE}</Title>

      <Content>
        <div className='content'>
          <Container fluid className='card-no-tabs client_create'>
            <Row
              style={{
                textAlign: 'center',
                font: 'normal normal normal 18px/22px Roboto',
                letterSpacing: '0px',
                color: '#878787',
                opacity: 1,
                marginLeft: '82.5px',
                marginTop: '51px',
              }}
            >
              <Link className='color-back' to={`/clients/view/${id}`}>
                <p>
                  <u>&#60; {CLIENT_PAGE.BACK}</u>
                </p>
              </Link>
            </Row>

            <Row>
              <Col
                style={{
                  textAlign: 'left',
                  font: 'normal normal bold 26px/34px Roboto',
                  letterSpacing: '0px',
                  color: '#2292CC',
                  opacity: 1,
                  marginLeft: '82.5px',
                }}
              >
                <h3>Editar contrato do cliente</h3>
              </Col>

              <Col></Col>
            </Row>

            <Row className='division' style={Styles.clientPageDivision}>
              <Col>
                <Row style={{ margin: '62px -40px 0 -40px' }}>
                  <Col style={Styles.clientPageDivisionHeader}>
                    <h4>{CLIENT_PAGE.CONTRACT}</h4>
                  </Col>
                </Row>
                <hr />
              </Col>
            </Row>

            <Row>
              <Col md={11}>
                <Row>
                  <Col md={8} style={{ margin: '15px 0 0 125.5px' }}>
                    <h6 className='light'>{CLIENT_PAGE.BALANCE_LIMIT}</h6>
                    <Form.Control
                      type={'number'}
                      onChange={(e) =>
                        setClientBalance(Number(e.target.value))
                      }
                      value={clientBalance || ''}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg={10} style={{ margin: '15px 0 0 125.5px' }}>
                    <h6 className='light'>{CLIENT_PAGE.HIRED_SERVICES}</h6>
                    <Row>
                      {services.map((item) => {
                        return (
                          <Col lg={4} key={item.name + String(item.id)}>
                            {getServiceElement(item)}
                          </Col>
                        );
                      })}
                    </Row>
                  </Col>
                </Row>
                <Row
                  className='division bottom-divider'
                  style={{ margin: '141.36px 0 167.5px 0' }}
                >
                  <Col className='center'>
                    <Button
                      style={Styles.okButton}
                      variant='outline-info'
                      size='lg'
                      onClick={() => editClient(id)}
                    >
                      {CLIENT_PAGE.OK_BUTTON_LABEL}
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
            {alertDialog && (
              <SweetAlert
                type={alertDialogConfig?.type}
                title={alertDialogConfig?.title ?? ''}
                onConfirm={() => {
                  setAlertDialog(false);
                  if (alertDialogConfig.redirect) {
                    history.push(`/clients`);
                  }
                }}
              >
                {alertDialogConfig?.message ?? ''}.
              </SweetAlert>
            )}
          </Container>
        </div>
      </Content>
    </>
  );
};

export default ClientCreate;

export interface Company {
  name: string;
  id: number;
}

export interface Client {
  name: string;
  nif?: string;
  imageFile?: string;
  imageName?: string;
  balance: number;
  userContact: UserContact;
  clientServices?: number[];
  status: string;
  dateCreated: Date;
  dateLastUpdated: Date;
}

export interface UserContact {
  name: string;
  email: string;
  countryCode: number;
  phoneNumber: number;
  profile: string;
  clientId: number;
}

export interface AlertConfig {
  title: String;
  message: String;
  type: SweetAlertType;
  redirect: Boolean;
}

export interface Service {
  active: Boolean;
  dateCreated: Date;
  description: String;
  id: Number;
  name: String;
  pathLocationFiles?: String;
}

import React, { useState, useEffect, useRef } from 'react';
import { Form, Container, Row, Col, Image, Button } from 'react-bootstrap';
import { configuratorService } from '../../services/configuratorService';
import { PackProductDTO } from '../Configurator/packs.interface';
import { toFixed } from '../../utils/utils';
import { storeCart, getCart } from '../../redux/cartReducer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUserEdit,
  faTrashAlt,
  faCar,
  faPhone
} from '@fortawesome/free-solid-svg-icons';

export const ProductCard = ({
  product,
  editProduct,
  deleteProduct,
  editItinerary,
  contact
}) => {
  let _product = product as PackProductDTO;

  const getLogo = (serviceId) => {
    const service = {
      1: require('../../assets/logos/kinto-one-logo-on-2x.png'),
      2: require('../../assets/logos/guerin-xs-logo-on-2x.png'),
      3: require('../../assets/logos/xtracars-xs-logo-on-2x.png'),
      4: require('../../assets/logos/rodinhas-xs-logo-on-2x.png'),
      5: require('../../assets/logos/bedriven-xs-logo-on-2x.png'),
    };

    return service[serviceId];
  };

  const getDescription = (product) => {
    switch (product.serviceId) {
      case 1:
        return `${product.appDetailsInfo} km | ${product.appDetails} meses`;
      case 2:
        return `${product.valuePack} x  ${product.valuePack && product.valuePack > 1 ? 'dias' : 'dia'
          }`;
      case 3:
        return `${product.valuePack} x  ${product.valuePack && product.valuePack > 1 ? 'meses' : 'mês'
          }`;
      case 4:
        return (
          <>
            <span className='mb-0'>{`${product.pickupZone} | ${product.dropoffZone}`}</span>
            <br />
            <span>{`${product.numberChildren && product.numberChildren === 1
                ? product.numberChildren + ' criança'
                : product.numberChildren + ' crianças'
              } | ${product.numberPerWeek ? product.numberPerWeek : 0 + ' x por semana'}`}</span>
          </>
        );
      case 5:
        return 'TVDE';
      default:
        return '';
    }
  };

  const getBrandText = (serviceId) => {
    const brandText = {
      1: 'Renting',
      2: 'Rent-a-car',
      3: 'Aluguer Flexível',
      4: 'Transporte de Crianças',
      5: 'TVDE',
      6: 'Aluguer Flexível',
    };

    return brandText[serviceId];
  };

  const getProductName = () => {
    switch (product.serviceId) {
      case 1:
        return `${product.productName} ou SIMILAR`;
      case 2:
        return `${product.productName} ou SIMILAR`;
      case 3:
        return `${product.productName} ou SIMILAR`;
      case 4:
        return `${product.productName}`;
      case 5:
        return 'TVDE';
      default:
        return '';
    }
  };

  return (
    <>
      <Col sm={12} key={_product.id} className='px-0'>
        <div className='product_card'>
          <Row>
            <Col md={12} lg={3}>
              <img src={getLogo(_product.serviceId)} className='service_logo' />
              <p className='text_service'>{getBrandText(_product.serviceId)}</p>
            </Col>
            <Col>
              <p className='text_product_name'>{getProductName()}</p>
              <p>{getDescription(_product)}</p>
            </Col>
            <Col md={4} lg={3}>
              <Row>
                <Col lg={7} md={12}>
                  <img src={_product.productImage} className='service_logo' />
                </Col>
                <Col>
                  <div className={`circle-service-${product.serviceId} circle`}>
                    <p>{toFixed(product.balance, 2)}€</p>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col sm={12} md={12} lg={1} className='actions_col'>
              <Row>
                <Col xs={1} sm={1} md={1} lg={12}
                  onClick={() => {
                    editProduct(product);
                  }}
                >
                  <FontAwesomeIcon
                    color='#2292CC'
                    icon={faUserEdit}
                    size='1x'
                    className='ml-4 mb-3'
                  />
                </Col>
                {product && product.serviceId === 4 && (
                  <Col xs={1} sm={1} md={1} lg={12}
                    onClick={() => {
                      editItinerary(product);
                    }}
                  >
                    <FontAwesomeIcon
                      color='#2292CC'
                      icon={faCar}
                      size='1x'
                      className='ml-4 mb-3'
                    />
                  </Col>
                )}
                  {product && product.serviceId === 1 && (
                  <Col xs={1} sm={1} md={1} lg={12}
                    onClick={() => {
                      contact(product);
                    }}
                  >
                    <FontAwesomeIcon
                      color='#2292CC'
                      icon={faPhone}
                      size='1x'
                      className='ml-4 mb-3'
                    />
                  </Col>
                )}
                <Col xs={1} sm={1} md={1} lg={12}
                  onClick={() => {
                    deleteProduct(product);
                  }}
                >
                  <FontAwesomeIcon
                    color='#2292CC'
                    icon={faTrashAlt}
                    size='1x'
                    className='ml-4'
                  />
                </Col>
              
              </Row>
            </Col>
          </Row>
        </div>
      </Col>
    </>
  );
};

export default ProductCard;

import api from '../../utils/api';

export const servicesService = {
  getServices,
  updateServices,
};

async function getServices() {
  const response = await api.apiPrivate
    .get(`api/comercial-profile/clients/client-services/`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        console.log(error.request);
        return error.request;
      }
      throw error;
    });
  return response;
}

async function updateServices(data) {
  const response = await api.apiPrivate
    .post(`api/comercial-profile/user-system/user-services/`, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        console.log(error.request);
        return error.request;
      }
      throw error;
    });
  return response;
}

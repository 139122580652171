import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Title, Content } from '../../style/content';
import { subscriptionService } from 'src/services/subscriptionService';
import {
  getCart,
  storeAuthPay,
  storePackToPay,
  deleteCart,
  deletePackPay,
  stopProcess,
} from '../../redux/cartReducer';
import { getUserRoles } from '../../redux/sessionReducer';
import { PackProductDTO } from '../Configurator/packs.interface';
import Radio from '@material-ui/core/Radio';
import { useHistory } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { toFixed } from '../../utils/utils';
import Loading from 'react-fullscreen-loading';

const Authorize: React.FC = () => {
  const [paymentDetails, setPaymentDetails] = useState({
    customerDTO: {
      browserDTO: {
        acceptHeader: '',
        challengeWindow: '',
        javaEnabled: '',
        language: '',
        screenColorDepth: '',
        screenHeight: '',
        screenWidth: '',
        timezone: '',
        userAgent: '',
      },
      ip: '',
    },
    valuePayment: 0,
    paymentAuthorize: 'PAYMENT_TOTALITY',
  });
  const [valueToPayment, setValueToPayment] = useState({
    valueToServicePayment: [
      {
        productId: 0,
        serviceId: 0,
        productName: '',
        valueWithoutDiscount: 0,
        value: 0,
      },
    ],
    valueWithoutDiscount: 0,
    value: 0,
    hasDiscount: false,
  });
  const [userPackDTO, setUserPackDTO] = useState([]);
  const [paymentType, setPaymentType] = useState(Number);
  const [alertDialog, setAlertDialog] = useState(false);
  const [alertConfig, setAlertConfig] = useState({
    title: '',
    message: '',
    redirect: false,
    redirectURL: '',
  });
  const history = useHistory();
  // const [anchorEl, setAnchorEl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const mountPack = async () => {
      let packs = getCart();

      // eslint-disable-next-line
      if (packs != undefined && packs?.length > 0) {
        let array = JSON.parse(packs) as PackProductDTO[];
        const listPacks = [];

        array.forEach((pack) => {
          if (pack.serviceId === 4) {
            let newPack = {
              childrenRodinhasDTO: [],
              packId: pack.pack,
              endService: pack.endService,
              startService: pack.startService,
              packItineraryDTO: pack.packItineraryDTO,
              packProducts: [
                {
                  serviceId: pack.serviceId,
                  rodinhasItinerary: {
                    numberChildren: pack.numberChildren,
                    region: pack.region,
                    pickupZone: pack.pickupZone,
                    pickupSubZone: pack.pickupSubZone,
                    dropoffZone: pack.dropoffZone,
                    dropoffSubZone: pack.dropoffSubZone,
                    numberPerWeek: pack.numberPerWeek,
                    roundTrip: pack.roundTrip,
                  },
                },
              ],
              productServiceType: 'REGULAR',
              remainderValuePack: '',
              paymentAuthorize: 'PAYMENT_TOTALITY',
            };

            listPacks.push(newPack as never);
          } else {
            let newPack = {
              childrenRodinhasDTO: [],
              packId: pack.pack,
              endService: '',
              startService: '',
              packItineraryDTO: [],
              packProducts: [
                {
                  productId: pack.productId,
                  serviceId: pack.serviceId,
                  valueProductPack: pack.valuePack,
                },
              ],
              productServiceType: 'REGULAR',
              remainderValuePack: '',
              paymentAuthorize: 'PAYMENT_TOTALITY',
            };

            listPacks.push(newPack as never);
          }
        });

        setUserPackDTO(listPacks);
      }
    };

    mountPack();
  }, []);

  useEffect(() => {
    if (userPackDTO.length > 0) {
      calculateValueToPayment();
    }
    // eslint-disable-next-line
  }, [userPackDTO]);

  const calculateValueToPayment = async () => {
    const result = await subscriptionService.calculateValue(userPackDTO);
    if (!result.error) {
      setValueToPayment(result);
    } else {
      console.log(result.error);
      alert('Erro', result.message, true, '/cart');
    }
  };

  const totalAuthorize = async () => {
    try {
      setPaymentDetails({
        ...paymentDetails,
        valuePayment: valueToPayment.value,
      });
      const resultPayment = await subscriptionService.pay({
        ...paymentDetails,
        valuePayment: valueToPayment.value,
      });
      storeAuthPay(resultPayment);
      storePackToPay(userPackDTO);
    } catch (error) {
      console.log(error);
    } finally {
      history.push('/cart/payment');
    }
  };

  const payment = async () => {
    if (paymentType === 0) {
      let userRoles = getUserRoles();
      if (userRoles.includes('ROLE_PARTICULAR_USER')) {
        totalAuthorize();
      }
      if (userRoles.includes('ROLE_CORPORATE_USER')) {
        corporatePayment();
      }
    } else {
      history.push('/cart');
    }
  };

  const corporatePayment = async () => {
    console.log('corporatePayment');
    setIsLoading(true);
    try {
      if (userPackDTO) {
        const response = await subscriptionService.createPack(userPackDTO);
        console.log(response);
        if (response.newResourceId) {
          await subscriptionService.paymentToSubscription({
            checkoutId: null,
            listPack: response.newResourceId,
          });
          deletePackPay();
          deleteCart();
          stopProcess();
          setIsLoading(false);
          alert(
            'Sucesso',
            'Pagamento realizado com sucesso',
            true,
            '/usercorporate/subscriptions'
          );
        } else {
          setIsLoading(false);
          alert('Erro', response.message, false, '');
        }
      } else {
        setIsLoading(false);
        console.log('erro no pagamento');
        alert('Erro', 'Erro no pagamento', false, '');
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };
  const alert = (
    title: string,
    message: string,
    redirect: boolean,
    redirectURL?: string
  ) => {
    setAlertConfig({
      title: title,
      message: message,
      redirect: redirect,
      redirectURL: redirectURL ? redirectURL : '',
    });
    setAlertDialog(true);
  };
  const handleClose = () => {
    // setAnchorEl(null);
  };

  return (
    <>
      {isLoading && (
        <Loading
          loading
          background='rgba(255, 255, 255, .4)'
          loaderColor='#3498db'
        />
      )}
      <Title>Autorização</Title>

      <Content>
        <Container fluid className='card-no-tabs cart_authorize'>
          <h1>Método de débito</h1>
          <div>
            {valueToPayment && valueToPayment.hasDiscount && (
              <Row>
                <Col md={5} xs={12} className='discount_card'>
                  <Row className='d-flex align-content-center'>
                    <p>
                      Valor sem Desconto
                      <span className='value'>
                        {valueToPayment.valueWithoutDiscount}€
                      </span>
                    </p>
                  </Row>
                  <Row>
                    <p>
                      Valor com desconto
                      <span className='value discount'>
                        {valueToPayment.value}€
                      </span>
                    </p>
                  </Row>
                </Col>
                <Col className='products_card'>
                  {valueToPayment !== undefined &&
                    valueToPayment.valueToServicePayment !== undefined &&
                    valueToPayment.valueToServicePayment.length > 0 &&
                    valueToPayment.valueToServicePayment.map((el, index) => {
                      return (
                        <Row
                          className='d-flex justify-content-end mr-2'
                          key={el.productId}
                        >
                          <Col className='d-flex justify-content-start'>
                            <p className='product_name mr-4'>
                              {el.productName}
                            </p>
                          </Col>
                          <Col className='d-flex justify-content-end'>
                            <h1>
                              <span className='dashed mr-2'>
                                {el.valueWithoutDiscount}€
                              </span>
                            </h1>
                            <h1>{el.value}€</h1>
                          </Col>
                        </Row>
                      );
                    })}
                </Col>
              </Row>
            )}
          </div>
          <Row className='auth_row'>
            <Col md={2} className='d-flex justify-content-center'>
              <div className='circle'>
                <h2>{toFixed(valueToPayment.value, 2)}€</h2>
              </div>
            </Col>
            <Col>
              <h3>Autorizar Totalidade</h3>
              <p>Pretendo debitar de imediato o valor total da subscrição.</p>
            </Col>
            <Col md={2} className='d-flex justify-content-center'>
              <Radio
                checked={paymentType === 0}
                onChange={() => setPaymentType(0)}
                value='Total'
                name='radio-button-demo'
              />
            </Col>
          </Row>
          <Row className='auth_row'>
            <Col md={2} className='d-flex justify-content-center'>
              <div className='circle'>
                <h2>0€</h2>
              </div>
            </Col>
            <Col>
              <h3>Autorizar Depois</h3>
              <p>
                Prefiro debitar o valor apenas quando todos os serviços
                estiverem aprovados.
              </p>
            </Col>
            <Col md={2} className='d-flex justify-content-center'>
              <Radio
                checked={paymentType === 1}
                onChange={() => setPaymentType(1)}
                value='Partial'
                name='radio-button-demo'
              />
            </Col>
          </Row>
          <Row className='button_row mt-4'>
            <Button className='button' onClick={() => payment()}>
              CONTINUAR
            </Button>
          </Row>
        </Container>
        <Dialog
          open={alertDialog}
          onClose={handleClose}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle id='alert-dialog-title'>{alertConfig.title}</DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-description'>
              {alertConfig.message}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                if (alertConfig.redirect) {
                  history.push(alertConfig.redirectURL);
                }
                setAlertDialog(false);
              }}
              color='primary'
              autoFocus
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </Content>
    </>
  );
};

export default Authorize;

import api from '../utils/api';

export const bookingService = {
  getBookings,
  getStations,
  getStationTimes,
  getPrice,
  getExtras,
  getExtrasSecondstep,
  getVehicles,
  createReservation,
  updateReservation,
  cancelReservation,
  mcfReserve,
  mcfReserveEdit,
  updateRodinhas,
};

async function getBookings(data) {
  const response = await api.apiPrivate
    .get(`api/product/user-reserve/user-reserve-list?${data ?? ''}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        console.log(error.request);
        return error.request;
      }
      throw error;
    });
  return response;
}

async function getStations(code) {
  const response = await api.apiPrivate
    .get(
      `api/guerin-xtracars-integration/booking-guerinxtracars/get-stations?serviceId=${code}&countryID=33`
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        console.log(error.request);
        return error.request;
      }
      throw error;
    });
  return response;
}

async function getStationTimes(stationId, date) {
  const response = await api.apiPrivate
    .get(
      `api/guerin-xtracars-integration/booking-guerinxtracars/get-stations-times?stationId=${stationId}&date=${date}`
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        console.log(error.request);
        return error.request;
      }
      throw error;
    });
  return response;
}

async function getPrice(data) {
  const response = await api.apiPrivate
    .post(
      `api/guerin-xtracars-integration/booking-guerinxtracars/get-price`,
      data
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        console.log(error.request);
        return error.request;
      }
      throw error;
    });
  return response;
}

async function getExtras(data) {
  const response = await api.apiPrivate
    .post(
      `api/guerin-xtracars-integration/booking-guerinxtracars/get-extras-packs`,
      data
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        console.log(error.request);
        return error.request;
      }
      throw error;
    });
  return response;
}

async function getExtrasSecondstep(data) {
  const response = await api.apiPrivate
    .post(
      `api/guerin-xtracars-integration/booking-guerinxtracars/get-extras`,
      data
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        console.log(error.request);
        return error.request;
      }
      throw error;
    });
  return response;
}

async function getVehicles(data) {
  const response = await api.apiPrivate
    .post(
      `api/guerin-xtracars-integration/booking-guerinxtracars/get-vehicles`,
      data
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        console.log(error.request);
        return error.request;
      }
      throw error;
    });
  return response;
}

async function createReservation(data) {
  const response = await api.apiPrivate
    .post(
      `api/guerin-xtracars-integration/booking-guerinxtracars/create-reservation`,
      data
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        console.log(error.request);
        return error.request;
      }
      throw error;
    });
  return response;
}

async function updateReservation(data, reserveId) {
  const response = await api.apiPrivate
    .put(
      `api/guerin-xtracars-integration/booking-guerinxtracars/edit-reservation/${reserveId}`,
      data
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        console.log(error.request);
        return error.request;
      }
      throw error;
    });
  return response;
}

async function cancelReservation(data) {
  const response = await api.apiPrivate
    .post(
      `api/guerin-xtracars-integration/booking-guerinxtracars/cancel-reservation`,
      data
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        console.log(error.request);
        return error.request;
      }
      throw error;
    });
  return response;
}

async function mcfReserve(data) {
  const response = await api.apiPrivate
    .post(`api/product/user-reserve/create-reserve`, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        console.log(error.request);
        return error.request;
      }
      throw error;
    });
  return response;
}

async function mcfReserveEdit(data, reserveId) {
  const response = await api.apiPrivate
    .put(`api/product/user-reserve/update-reserve/${reserveId}`, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        console.log(error.request);
        return error.request;
      }
      throw error;
    });
  return response;
}

async function updateRodinhas(data, reserveId) {
  const response = await api.apiPrivate
    .put(
      `api/product/rodinhas-communication/rodinhas-reserve-update/${reserveId}`,
      data
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        console.log(error.request);
        return error.request;
      }
      throw error;
    });
  return response;
}

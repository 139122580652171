import api from '../utils/api';

export const pricingService = {
  create,
  getAll,
  insert,
  getByCode,
  edit
};

async function create(data) {
  const response = await api.apiPrivate
    .post(`api/comercial-profile/pricing/create-promocodes`, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        console.log(error.request);
        return error.request;
      }
      throw error;
    });
  return response;
}

async function getAll(promocodePerPage, activePage, filter) {
  let pageSizeParam = promocodePerPage ? `?size=${promocodePerPage}` : '';
  let data = { "code": filter };
  const response = await api.apiPrivate
    .post(
      `api/comercial-profile/pricing/all-promocodes${pageSizeParam}&page=${activePage}`, data
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        console.log(error.request);
        return error.request;
      }
      throw error;
    });
  return response;
}

async function insert(data) {
  const response = await api.apiPrivate
    .post(`api/comercial-profile/pricing/insert-promocode`, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        console.log(error.request);
        return error.request;
      }
      throw error;
    });
  return response;
}

async function getByCode(id) {
  const response = await api.apiPrivate
    .get(`api/comercial-profile/pricing/insert-promocode/${id}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        console.log(error.request);
        return error.request;
      }
      throw error;
    });
  return response;
}

async function edit(id) {
  const response = await api.apiPrivate
    .put(`api/comercial-profile/pricing/edit-promocode/${id}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        console.log(error.request);
        return error.request;
      }
      throw error;
    });
  return response;
}

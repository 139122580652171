import styled from 'styled-components';
import kinto from '../../assets/logos/kinto-config.png';
import guerin from '../../assets/logos/guerin-config.png';
import xtracars from '../../assets/logos/xtracars-config.png';
import rodinhas from '../../assets/logos/rodinhas-config.png';
import bedriven from '../../assets/logos/bedriven-config.png';

const Content = styled.div`
  height: auto;
  background: #d2f2fd;
  border-radius: 40px;
  padding: 20px;
  min-height: 27vw;
  margin-left: 30px !important;

  .back {
    color: #878787;
    text-decoration: underline;
    cursor: pointer;
  }

  .back2 {
    color: #878787;
    text-decoration: underline;
    cursor: pointer;
  }

  .back-btn {
    padding: 20px 0;
  }

  .clickable {
    cursor: pointer;
  }

  .configs {
    display: flex;
    max-width: 100%;
    padding-bottom: 100px;
    justify-content: space-around;
    @media screen and (min-width: 1600px) {
      margin: 0 72px;
      justify-content: normal;
    }

    .finlog {
      width: 200px;
      height: 200px;
      background-image: url(${kinto});
      background-size: 200px;
      position: relative;

      .config {
        position: absolute;
        background: #00aeef4d;
        width: 140px;
        height: 166px;
        border-radius: 37px;
        top: 24px;
        left: 30px;
        opacity: 0;
        color: #fff;
        padding: 20px;
        font-size: 12px;
        cursor: pointer;
        text-align: right;

        p {
          margin: 0;
          padding: 0;
        }
      }

      .config:hover {
        opacity: 0.9;
      }
    }

    .guerin {
      width: 200px;
      height: 200px;
      background-image: url(${guerin});
      background-size: 200px;
      position: relative;

      .config {
        position: absolute;
        background: #8dc63f;
        width: 140px;
        height: 166px;
        border-radius: 37px;
        top: 24px;
        left: 30px;
        opacity: 0;
        color: #fff;
        padding: 20px;
        font-size: 12px;
        cursor: pointer;
        text-align: right;
      }

      p {
        margin: 0;
        padding: 0;
      }

      .config:hover {
        opacity: 0.7;
      }
    }

    .xtracars {
      width: 200px;
      height: 200px;
      background-image: url(${xtracars});
      background-size: 200px;
      position: relative;

      .config {
        position: absolute;
        background: #000;
        width: 140px;
        height: 166px;
        border-radius: 37px;
        top: 24px;
        left: 30px;
        opacity: 0;
        color: #fff;
        padding: 20px;
        font-size: 12px;
        cursor: pointer;
        text-align: right;
      }

      p {
        margin: 0;
        padding: 0;
      }

      .config:hover {
        opacity: 0.7;
      }
    }

    .rodinhas {
      width: 200px;
      height: 200px;
      background-image: url(${rodinhas});
      background-size: 200px;
      position: relative;

      .config {
        position: absolute;
        background: #f8bb15;
        width: 140px;
        height: 166px;
        border-radius: 37px;
        top: 24px;
        left: 30px;
        opacity: 0;
        color: #fff;
        padding: 20px;
        font-size: 12px;
        cursor: pointer;
        text-align: right;
      }

      p {
        margin: 0;
        padding: 0;
      }

      .config:hover {
        opacity: 0.8;
      }
    }

    .bedriven {
      width: 200px;
      height: 200px;
      background-image: url(${bedriven});
      background-size: 200px;
      position: relative;

      .config {
        position: absolute;
        background: #005a88b0;
        width: 140px;
        height: 166px;
        border-radius: 37px;
        top: 24px;
        left: 30px;
        opacity: 0;
        color: #fff;
        padding: 20px;
        font-size: 12px;
        cursor: pointer;
        text-align: right;
      }

      p {
        margin: 0;
        padding: 0;
      }

      .config:hover {
        opacity: 0.8;
      }
    }

    img {
      width: 200px;
    }
  }

  .table {
    .font-light {
      font-weight: 300;
    }

    .wrapper-product {
      display: flex;

      .item-data {
        max-width: 350px;
      }

      .item {
        line-height: 0;
        text-indent: 20px;

        img {
          width: 80px;
        }

        .title {
          font-weight: bold;
          padding-top: 10px;
        }

        .description {
          font-weight: 300;
          white-space: normal;
          line-height: 1;
          text-indent: 0;
          margin: 0 0 0 5px;
        }
      }
    }

    .actions {
      color: #00000029;
      display: flex;
      justify-content: center;
      align-items: center;

      .wrapper {
        padding: 0 10px;
        margin: 0;
        text-align: center;
      }

      .change-status {
        color: #000;
      }

      svg {
        cursor: pointer;
      }

      .edit:hover {
        color: #2292cc;
      }

      .text-edit {
        color: #2292cc;
        font-weight: bold;
      }

      .text-remove {
        color: #ff0000;
        font-weight: bold;
      }

      .remove:hover {
        color: #ff0000;
      }
    }

    .edit {
      line-weight: 0;

      .creation {
        font-weight: bold;
      }
    }
  }

  .header-tab {
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
    border: 0;
    padding: 20px;
    width: 50%;
    outline: none;
    box-shadow: 0px -5px 5px 0px rgba(219, 219, 219, 0.1);
    background: white;
    backdrop-filter: blur(20px);
    filter: blur(20px);
    cursor: default;
  }

  .title-pack {
    text-align: right;
    position: absolute;
    font-size: 19px;
    cursor: pointer;
    font-weight: bold;
    right: 234px;
    top: 162px;
    color: #c5c5c5;
  }

  .title-products {
    position: absolute;
    top: 153px;
    left: 256px;
    font-size: 19px;
    font-weight: bold;
    color: #c5c5c5;
    cursor: pointer;
  }

  .active {
    backdrop-filter: none !important;
    filter: none !important;
  }

  .nav-item {
  }

  .nav-tabs .nav-item.nav-link.active {
    text-align: center;
    color: red;
    text-decoration: underline;
    color: #2292cc;
    font-weight: bold;
    font-size: 18px;
    height: 67px;
  }

  .nav-tabs {
    color: red;
  }

  .content {
    background: #fff;
    border: 0;
    height: auto;
    border-bottom-left-radius: 40px;
    border-bottom-right-radius: 40px;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
    padding-bottom: 100px;

    .wrapper-title {
      width: 250px;
      border-bottom: 1px solid #2292cc;
      text-indent: 20px;
      position: relative;
      left: 60px;
      bottom: 30px;

      h2 {
        font-size: 22px;
        font-weight: bold;
        color: #2292cc;
      }
    }

    .wrapper-title-packs {
      width: 250px;
      border-bottom: 1px solid #2292cc;
      text-indent: 172px;
      position: absolute;
      top: 164px;
      right: 15%;

      h2 {
        font-size: 22px;
        font-weight: bold;
        color: #2292cc;
      }
    }

    .opacity {
      opacity: 0.4;
    }

    .wrapper {
      padding: 0 50px;

      .card {
        border: 0;
        border-bottom: 1px solid #000;

        .card-header {
          background: transparent;
          border-bottom: 1px solid #000;
        }

        .card-body {
          padding: 0;
        }
      }

      .pack {
        cursor: pointer;
        background: #000;
        position: relative;
        border-radius: 20px;
        box-shadow: 0 0 2px #000;
        min-height: 200px;
        text-align: center;
        max-height: 265px;
        width: 202px;

        .badge {
          position: relative;
          bottom: 14px;
        }

        .wrapper {
          color: #fff;
          padding: 50px 20px;

          h5 {
            color: #00aeef;
            font-size: 13px;
          }

          h4 {
            color: #fff;
          }

          .big-text {
            font-weight: bold;
            font-size: 30px;
          }

          .social-networks {
            display: flex;
            justify-content: center;
            border-top: 1px solid gray;
            border-bottom: 1px solid gray;

            .icon-service-1 {
              color: #00aeef;
            }

            .icon-service-2 {
              color: #6dab24;
            }

            .icon-service-5 {
              color: #1a84c6;
            }

            .icon-service-3 {
              color: #ffca00;
            }

            .icon-service-4 {
              color: #d8001a;
            }
          }
        }
      }

      .add-pack {
        justify-content: flex-end;
        flex-direction: column;
        align-items: flex-end;

        p {
          font-weight: bold;
          font-size: 12px;
          position: relative;
          left: 20px;
          top: 10px;
          color: #2292cc;
        }
      }
    }
  }

  .left-corner {
    border-top-left-radius: 40px;
  }

  .right-corner {
    border-top-right-radius: 40px;
  }
`;
export default Content;

import React, { useState, useEffect, useRef } from 'react';
import { Form, Container, Row, Col, Image, Button } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SweetAlert from 'react-bootstrap-sweetalert';
import {
  faCalculator,
  faCamera,
  faEdit,
  faTrashAlt,
} from '@fortawesome/free-solid-svg-icons';
import { CLIENT_CREATE_PAGE_TITLE } from '../../../constants/clientConstants';
import { phoneIndicative } from '../../../constants/aux_constants';
import { companyService } from '../../../services/companyService';
import { CLIENT_PAGE } from '../../../constants/clientConstants';
import { Title, Content, Styles } from '../../../style/client';
import { clientService } from 'src/services/clientService';
import { SweetAlertType } from 'react-bootstrap-sweetalert/dist/types';

const ClientCreate: React.FC = () => {
  const [services, setServices] = useState([] as Service[]);
  const [client, setClient] = useState({} as Client);
  const [alertDialog, setAlertDialog] = useState(false);
  const [alertDialogConfig, setAlertDialogConfig] = useState({} as AlertConfig);
  const [hasValidVAT, setHasValidVAT] = useState(false);
  const fileInputField = useRef(null);
  const history = useHistory();

  useEffect(() => {
    getServices();
    setClient({
      ...client,
      userContact: {
        ...client.userContact,
        countryCode: 351,
        profile: 'GM',
      },
    });
  }, []);

  const createClient = async (client) => {
    const result = await clientService.create(client);
    if (result.status === 200) {
      alert('Sucesso', result.message, 'success', true);
    } else {
      alert('Erro', result.message, 'error');
    }
  };

  const validateVAT = async (vat) => {
    if (vat !== '') {
      const result = await clientService.validateVAT({ nif: vat });
      if (result.status === 200) {
        setHasValidVAT(true);
      } else {
        setHasValidVAT(false);
        alert(
          'Erro',
          result.errors[0]?.defaultMessage ?? 'NIF inválido',
          'error'
        );
      }
    }
  };

  const checkFields = () => {
    if (
      client.name !== '' &&
      hasValidVAT &&
      client.userContact?.name !== '' &&
      client.clientServices?.length !== undefined &&
      client.clientServices?.length > 0 &&
      client.userContact.email !== '' &&
      client.userContact.phoneNumber !== undefined &&
      client.balance !== undefined
    ) {
      return true;
    }
    return false;
  };

  const getServices = async () => {
    const result = await companyService.getAllActive();
    if (result.data) {
      let services = result.data.filter((el) => el.id !== 6);
      setServices(services);
    }
  };

  const alert = (
    title: String,
    message: String,
    type: SweetAlertType,
    redirect = false
  ) => {
    setAlertDialog(true);
    setAlertDialogConfig({
      title: title,
      message: message,
      type: type,
      redirect: redirect,
    });
  };

  const selectService = (id) => {
    if (client.clientServices == undefined) {
      setClient({ ...client, clientServices: [id] });
    } else {
      if (!client.clientServices.includes(id)) {
        let array = client.clientServices;
        array.push(id);
        setClient({ ...client, clientServices: array });
      } else {
        let array = client.clientServices;
        const index = array.indexOf(id);
        if (index > -1) {
          array.splice(index, 1);
        }
        setClient({ ...client, clientServices: array });
      }
    }
  };

  const handleNewFileUpload = (e: any, showMessage = false) => {
    if (e.target.files.length) {
      const file = e.target.files[0];
      const fileSize = file.size / 1024 / 1024;

      if (fileSize > 2) {
        alert('Erro', 'Arquivo deve ter menos que 2 MB', 'error');
        return false;
      }

      getBase64(file).then((data) => {
        let base64String = data as string;
        base64String = base64String.split(',')[1];
        setClient({ ...client, imageName: file.name, imageFile: base64String });
      });
    }
  };

  const getBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const resolveBase64 = (file: any) => {
    if (file.split(':')[0] == 'data') {
      return file;
    } else {
      return 'data:image/png;base64,' + file;
    }
  };

  const getServiceElement = (item) => {
    return (
      <Row className='pl-4 service_item'>
        <Form.Check
          inline
          type={'checkbox'}
          id={item.id}
          onChange={() => selectService(item.id)}
          key={item.name + item.id}
        />
        <img
          className={'logo'}
          src={require(`../../../assets/logos/${item.name.toLowerCase()}.png`)}
          alt='Profile'
        />
      </Row>
    );
  };

  return (
    <>
      <Title>{CLIENT_CREATE_PAGE_TITLE}</Title>

      <Content>
        <div className='content'>
          <Container fluid className='card-no-tabs client_create'>
            <Row
              style={{
                textAlign: 'center',
                font: 'normal normal normal 18px/22px Roboto',
                letterSpacing: '0px',
                color: '#878787',
                opacity: 1,
                marginLeft: '82.5px',
                marginTop: '51px',
              }}
            >
              <Link className='color-back' to={'/clients'}>
                <p>
                  <u>&#60; {CLIENT_PAGE.BACK}</u>
                </p>
              </Link>
            </Row>

            <Row>
              <Col
                style={{
                  textAlign: 'left',
                  font: 'normal normal bold 26px/34px Roboto',
                  letterSpacing: '0px',
                  color: '#2292CC',
                  opacity: 1,
                  marginLeft: '82.5px',
                }}
              >
                <h3>Novo Cliente</h3>
              </Col>

              <Col></Col>
            </Row>

            <Row
              style={{
                textAlign: 'left',
                font: 'normal normal normal 16px/19px Roboto',
                letterSpacing: '0px',
                color: '#1E1E1E',
                opacity: 1,
                margin: '15.99px 0 61.64px 82.5px',
              }}
            >
              <p>{CLIENT_PAGE.DESCRIPTION}</p>
            </Row>

            <Row className='division' style={Styles.clientPageDivision}>
              <Col>
                <Row style={{ margin: '0 -40px 0 -40px' }}>
                  <Col style={Styles.clientPageDivisionHeader}>
                    <h4>{CLIENT_PAGE.COMPANY}</h4>
                  </Col>
                  <Col md={3} className='center'>
                    <h4>{CLIENT_PAGE.LOGO}</h4>
                  </Col>
                </Row>
                <hr />
              </Col>
            </Row>

            <Row>
              <Col style={{ margin: '15px 0 0 125.5px' }}>
                <h6 className='light'>{CLIENT_PAGE.NAME}</h6>
                <Form onSubmit={(e) => e.preventDefault()}>
                  <Row>
                    <Col>
                      <Form.Control
                        onChange={(e) =>
                          setClient({ ...client, name: e.target.value })
                        }
                        defaultValue={client.name}
                      />
                    </Col>
                  </Row>
                </Form>

                <h6 className='light mt-4'>{CLIENT_PAGE.NIF}</h6>
                <Row>
                  <Col lg={4}>
                    <Form.Control
                      maxLength={9}
                      onChange={(e) =>
                        setClient({ ...client, nif: e.target.value })
                      }
                      onBlur={() => validateVAT(client.nif)}
                      defaultValue={client.nif}
                    />
                  </Col>
                </Row>
              </Col>

              <Col md={3} className='center'>
                {client.imageFile ? (
                  <div className={'image'}>
                    <input
                      type='file'
                      ref={fileInputField}
                      onChange={handleNewFileUpload}
                      accept='image/*'
                    />
                    <Image
                      src={resolveBase64(client.imageFile)}
                      roundedCircle
                      width='100'
                    />
                  </div>
                ) : (
                  <div className={'upload'}>
                    <input
                      type='file'
                      ref={fileInputField}
                      onChange={handleNewFileUpload}
                      accept='image/*'
                    />
                    <FontAwesomeIcon icon={faCamera} color={'#2292CC'} />
                  </div>
                )}
              </Col>
            </Row>

            <Row className='division' style={Styles.clientPageDivision}>
              <Col>
                <Row style={{ margin: '62px -40px 0 -40px' }}>
                  <Col style={Styles.clientPageDivisionHeader}>
                    <h4>{CLIENT_PAGE.CONTACT_INFO}</h4>
                  </Col>
                </Row>
                <hr />
              </Col>
            </Row>
            <Row>
              <Col md={11} style={{ margin: '15px 0 0 125.5px' }}>
                <h6 className='light'>{CLIENT_PAGE.FULLNAME}</h6>
                <Row>
                  <Col md={8}>
                    <Form.Control
                      onChange={(e) =>
                        setClient({
                          ...client,
                          userContact: {
                            ...client.userContact,
                            name: e.target.value,
                          },
                        })
                      }
                      defaultValue={client.userContact?.name}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={8} style={{ margin: '15px 0 0 0px' }}>
                    <h6 className='light'>{CLIENT_PAGE.EMAIL}</h6>
                    <Form.Control
                      onChange={(e) =>
                        setClient({
                          ...client,
                          userContact: {
                            ...client.userContact,
                            email: e.target.value,
                          },
                        })
                      }
                      defaultValue={client.userContact?.email}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row>
              <Col md={11}>
                <Row>
                  <Col md={8} style={{ margin: '15px 0 0 125.5px' }}>
                    <h6 className='light'>{CLIENT_PAGE.CONTACT}</h6>
                    <Row>
                      <Col md={4}>
                        <Form.Control
                          as='select'
                          onChange={(e) =>
                            setClient({
                              ...client,
                              userContact: {
                                ...client.userContact,
                                countryCode: Number(e.target.value),
                              },
                            })
                          }
                          defaultValue={client.userContact?.countryCode}
                        >
                          {phoneIndicative.map((item) => {
                            return (
                              <option key={item.id} value={item.value}>
                                {item.name}
                              </option>
                            );
                          })}
                        </Form.Control>
                      </Col>
                      <Col>
                        <Form.Control
                          type={'number'}
                          onChange={(e) =>
                            setClient({
                              ...client,
                              userContact: {
                                ...client.userContact,
                                phoneNumber: Number(e.target.value),
                              },
                            })
                          }
                          defaultValue={client.userContact?.phoneNumber}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col md={8} style={{ margin: '15px 0 0 125.5px' }}>
                    <h6 className='light'>{CLIENT_PAGE.PROFILE}</h6>
                    <Form.Control
                      onChange={(e) =>
                        setClient({
                          ...client,
                          userContact: {
                            ...client.userContact,
                            email: e.target.value,
                          },
                        })
                      }
                      defaultValue={client.userContact?.profile}
                      disabled
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row></Row>

            <Row className='division' style={Styles.clientPageDivision}>
              <Col>
                <Row style={{ margin: '62px -40px 0 -40px' }}>
                  <Col style={Styles.clientPageDivisionHeader}>
                    <h4>{CLIENT_PAGE.CONTRACT}</h4>
                  </Col>
                </Row>
                <hr />
              </Col>
            </Row>

            <Row>
              <Col md={11}>
                <Row>
                  <Col md={8} style={{ margin: '15px 0 0 125.5px' }}>
                    <h6 className='light'>{CLIENT_PAGE.BALANCE_LIMIT}</h6>
                    <Form.Control
                      type={'number'}
                      onChange={(e) =>
                        setClient({
                          ...client,
                          balance: Number(e.target.value),
                        })
                      }
                      defaultValue={client.balance}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg={10} style={{ margin: '15px 0 0 125.5px' }}>
                    <h6 className='light'>{CLIENT_PAGE.HIRED_SERVICES}</h6>
                    <Row>
                      {services.map((item) => {
                        return (
                          <Col lg={4} key={item.name + String(item.id)}>
                            {getServiceElement(item)}
                          </Col>
                        );
                      })}
                    </Row>
                  </Col>
                </Row>
                <Row
                  className='division bottom-divider'
                  style={{ margin: '141.36px 0 167.5px 0' }}
                >
                  <Col className='center'>
                    <Button
                      style={Styles.okButton}
                      variant='outline-info'
                      size='lg'
                      onClick={() => createClient(client)}
                      disabled={!checkFields()}
                    >
                      {CLIENT_PAGE.OK_BUTTON_LABEL}
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
            {alertDialog && (
              <SweetAlert
                type={alertDialogConfig?.type}
                title={alertDialogConfig?.title ?? ''}
                onConfirm={() => {
                  setAlertDialog(false);
                  if (alertDialogConfig.redirect) {
                    history.push(`/clients`);
                  }
                }}
              >
                {alertDialogConfig?.message ?? ''}.
              </SweetAlert>
            )}
          </Container>
        </div>
      </Content>
    </>
  );
};

export default ClientCreate;

export interface Company {
  name: string;
  id: number;
}

export interface Client {
  name: string;
  nif?: string;
  imageFile?: string;
  imageName?: string;
  balance: number;
  userContact: UserContact;
  clientServices?: number[];
  status: string;
  dateCreated: Date;
  dateLastUpdated: Date;
}

export interface UserContact {
  name: string;
  email: string;
  countryCode: number;
  phoneNumber: number;
  profile: string;
  clientId: number;
}

export interface AlertConfig {
  title: String;
  message: String;
  type: SweetAlertType;
  redirect: Boolean;
}

export interface Service {
  active: Boolean;
  dateCreated: Date;
  description: String;
  id: Number;
  name: String;
  pathLocationFiles?: String;
}

import React, { useState, useEffect } from 'react';
import { Form, Container, Row, Col, Button } from 'react-bootstrap';
import { Title, Content, Styles } from '../../../style/content';
import SweetAlert from 'react-bootstrap-sweetalert';
import { servicesService } from 'src/services/GM/servicesService';
import { SweetAlertType } from 'react-bootstrap-sweetalert/dist/types';
import { User, AlertConfig } from '../Users/users.interface';
import { useHistory, Link } from 'react-router-dom';
import { ServiceConfig } from './services.interface';
import { MobilityGroup } from '../Groups/groups.interface';
import { groupsService } from 'src/services/GM/groupsService';

const ServicesConfig: React.FC = () => {
  const [services, setServices] = useState([
    { serviceId: Number(), service: String(), rules: String() },
  ]);
  const [alertDialog, setAlertDialog] = useState(false);
  const [alertDialogConfig, setAlertDialogConfig] = useState({} as AlertConfig);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedServices, setSelectedServices] = useState([] as Number[]);
  const [selectedGroup, setSelectedGroup] = useState(Number);
  const [group, setGroup] = useState({} as MobilityGroup);
  const [serviceConfig, setServiceConfig] = useState({} as ServiceConfig);
  const [plafond, setPlafond] = useState(Number());
  const [review, setReview] = useState(false);
  const history = useHistory();

  useEffect(() => {
    getServices();
    getSelectedUsers();
  }, []);

  useEffect(() => {
    if (selectedGroup !== 0) {
      getServiceConfig(selectedGroup);
    }
  }, [selectedGroup]);

  const getServices = async () => {
    const result = await servicesService.getServices();
    if (result) {
      setServices(result);
    }
  };

  const getSelectedUsers = async () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    const usersIds = urlParams.get('usersids');
    if (usersIds) {
      setSelectedUsers(usersIds?.split(',') as never[]);
    }

    const groupId = urlParams.get('groupid');
    if (groupId) {
      setSelectedGroup(groupId as never);
    }
  };

  const getServiceConfig = async (id) => {
    const result = await groupsService.getById(id);
    if (result.plafondValue && result.mobilityGroupServiceDTO) {
      setPlafond(result.plafondValue);
      setGroup(result);
      result.mobilityGroupServiceDTO.map((el) => {
        updateSelectedServices(el.serviceId);
      });
    }
  };

  const updateSelectedServices = (id) => {
    let newServices = selectedServices;
    if (newServices.includes(id)) {
      let index = newServices.indexOf(id);
      if (index !== -1) {
        newServices.splice(index, 1);
        if (document.getElementById(`checkbox-${getCompany(id)}`)) {
          const input = document.getElementById(
            `checkbox-${getCompany(id)}`
          ) as HTMLInputElement;
          input.checked = false;
        }
      }
    } else {
      newServices.push(id);

      if (document.getElementById(`checkbox-${getCompany(id)}`)) {
        const input = document.getElementById(
          `checkbox-${getCompany(id)}`
        ) as HTMLInputElement;
        input.checked = true;
      } else {
        setTimeout(() => {
          if (document.getElementById(`checkbox-${getCompany(id)}`)) {
          const input = document.getElementById(
            `checkbox-${getCompany(id)}`
          ) as HTMLInputElement;
          input.checked = true;}
        }, 500)
      }
    }
    setSelectedServices(newServices);
  };

  const confirmConfig = async () => {
    if (selectedUsers !== undefined && selectedUsers.length > 0) {
      let object = {
        users: {},
        userServicesDTO: {
          plafondValue: 0,
          userServices: [],
        },
      };
      object.users = selectedUsers;
      object.userServicesDTO.plafondValue = plafond;
      object.userServicesDTO.userServices = [];
      selectedServices.map((item) => {
        let index = services.findIndex((s) => s.serviceId == item);
        if (index !== -1) {
          object.userServicesDTO.userServices.push(services[index] as never);
        }
      });

      const result = await servicesService.updateServices(object);
      if (result.status == 200) {
        alert('Sucesso', result.message, 'success');
      } else {
        if(result.errors[0]) {
          alert('Erro', result.errors[0]?.defaultMessage ?? 'Erro', 'error');
        } else {
          alert('Erro', 'Erro', 'error');
        }
       
      }
    } else {
      if (selectedGroup !== undefined) {
        let object = {
          mobilityGroupServiceDTO: [],
          plafondValue: 0,
          id: selectedGroup,
          clientId: group.clientId,
          users: group.users
        };
        object.plafondValue = plafond;
        selectedServices.map((item) => {
          let index = services.findIndex((s) => s.serviceId == item);
          if (index !== -1) {
            object.mobilityGroupServiceDTO.push({
              ...services[index],
              mobilityGroupId: selectedGroup,
            } as never);
          }
        });

        const result = await groupsService.edit(object);

        if(result) {
          if (result.status == 200) {
            alert('Sucesso', result.message, 'success');
          } else {
            if(result.status == 500) {
              alert('Erro', 'Erro ao editar o grupo', 'error');
            } else {
              alert('Erro', result.message ?? 'Error', 'error');
            }
          }
        } else {
          alert('Erro', 'Error', 'error');
        }
        
      }
    }
  };

  const getCompany = (id: any) => {
    return {
      1: 'kinto one',
      2: 'guerin',
      3: 'xtracars',
      4: 'rodinhas',
      42: 'rodinhas',
      5: 'bedriven',
    }[id];
  };

  const alert = (
    title: String,
    message: String,
    type: SweetAlertType,
    redirect: Boolean = false
  ) => {
    setAlertDialog(true);
    setAlertDialogConfig({
      title: title,
      message: message,
      type: type,
      redirect: redirect,
    });
  };

  return (
    <>
      <Title>Configurador</Title>

      <Content>
        <Container
          fluid
          className='card-no-tabs servicesGM_main service_config'
        >
          <Link className='color-back' to={'/gm/services/'}>
            <p>
              <u>&#60; Voltar</u>
            </p>
          </Link>
          <h1 className='title'>Configure os serviços</h1>
          <h3 className='subtitle'>
            Indique o plafond global. Selecione o(s) serviço(s) que deseja
            configurar.
          </h3>
          <div className='plafond'>
            <h6 className='light'>Plafond global</h6>
            <Row>
              <Col lg={4}>
                <Form.Control
                  type={'number'}
                  onChange={(e) => {
                    setPlafond(Number(e.target.value));
                  }}
                  value={plafond}
                  disabled={review}
                />
              </Col>
            </Row>
          </div>
          <Row className='services'>
            {services &&
              services.length > 0 &&
              services.map((item) => {
                return (
                  <Col lg={5} style={{ marginTop: '5px' }} key={item.serviceId}>
                    <Row style={{ marginLeft: '5px', marginBottom: '10px' }}>
                      <Form.Check
                        type={'checkbox'}
                        id={`checkbox-${getCompany(item.serviceId)}`}
                        onClick={(e) => updateSelectedServices(item.serviceId)}
                        disabled={review}
                      />
                      {item.serviceId && (
                        <img
                          src={require(`../../../assets/logos/${getCompany(
                            item.serviceId
                          )}.png`)}
                          alt={`${getCompany(item.serviceId)}`}
                          className='groups_create_logo'
                        />
                      )}
                    </Row>
                  </Col>
                );
              })}
          </Row>
          <Col className='center'>
            <Button
              style={Styles.okButton}
              variant='outline-info'
              size='lg'
              onClick={() => (review ? confirmConfig() : setReview(true))}
            >
              {review ? 'CONFIRMAR' : 'CONTINUAR'}
            </Button>
          </Col>
        </Container>
        {alertDialog && (
          <SweetAlert
            type={alertDialogConfig?.type}
            title={alertDialogConfig?.title ?? ''}
            onConfirm={() => {
              setAlertDialog(false);

              history.push('/gm/services');
            }}
          >
            {alertDialogConfig?.message ?? ''}.
          </SweetAlert>
        )}
      </Content>
    </>
  );
};

export default ServicesConfig;

import React from 'react';
import ReactDOM from 'react-dom';
import { transitions, positions, Provider as AlertProvider } from 'react-alert';
import AlertTemplate from 'react-alert-template-basic';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import './index.css';
import { persistor, store } from './redux/configureStore';
import './style/menu.css';
import './style/package.css';
import './style/screens/index.scss';
import './style/components/index.scss';
import './style/GM/screens/index.scss';
import './style/GP/index.scss';
import './style/Configurator/index.scss';
import './style/User/index.scss';
import './style/Cart/index.scss';
import './style/CMS/index.scss';
import './style/product.css';
import './style/index.scss';
import './style/styles.css';
import './style/checkbox.css';
import './utils/i18n';
import './assets/fonts/fontello.css';
import 'typeface-roboto';
// import { render } from '@testing-library/react';
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App';

const options = {
  position: positions.TOP_RIGHT,
  timeout: 5000,
  offset: '30px',
  // you can also just use 'scale'
  transition: transitions.SCALE,
};

class Principal extends React.Component {
  render() {
    return (
      <Provider store={store}>
        <AlertProvider template={AlertTemplate} {...options}>
          <PersistGate loading={null} persistor={persistor}>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </PersistGate>
        </AlertProvider>
      </Provider>
    );
  }
}
ReactDOM.render(<Principal />, document.getElementById('root'));

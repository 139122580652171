import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Image, Button } from 'react-bootstrap';
import { productService } from 'src/services/productService';
import ModalBase from './ModalBase';
import { Product, ProductFinlog } from './cart.interface';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toFixed } from '../../utils/utils';
import { storeCart, getCart } from '../../redux/cartReducer';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { PackProductDTO } from '../Configurator/packs.interface';

export const ModalFinlog = ({ closeButton, open, onClose, product }) => {
  const [products, setProducts] = useState([] as Product[]);
  const [activeProduct, setActiveProduct] = useState({} as ProductFinlog);
  const [cartIndex, setCartIndex] = useState(-1);
  const [canPrevious, setCanPrevious] = useState(false);
  const [canNext, setCanNext] = useState(false);
  const [isNewProduct, setIsNewProduct] = useState(false);

  useEffect(() => {
    if (product && product.serviceId === 1) {
      storeCartIndex(product.id);
      getFinlogProducts();
    }
  }, [product]);

  useEffect(() => {
    if (open && product === null) {
      setIsNewProduct(true);
      createNewProduct();
    }
    if (!open) {
      setIsNewProduct(false);
      setActiveProduct({} as ProductFinlog);
    }
  }, [open]);

  const getProduct = async (id) => {
    const _product = await productService.getFinlogById(id);
    return _product.data;
  };

  const storeCartIndex = (id) => {
    let cart = getCart();
    if (cart) {
      let array = JSON.parse(cart) as PackProductDTO[];
      if (array) {
        let index = array.findIndex((el) => el.id === id);
        if (index !== -1) {
          setCartIndex(index);
        }
      }
    }
  };

  const createNewProduct = async () => {
    const completeProducts = await productService.getFinlogAll();
    if (completeProducts && completeProducts.data.length > 0) {
      let _products = [] as ProductFinlog[];
      let getProducts = new Promise<void>((resolve, reject) => {
        completeProducts.data.forEach(async (el, index, array) => {
          let p = await getProduct(el.productId);
          _products.push(p);
          if (index === array.length - 1) resolve();
        });
      });
      getProducts.then(() => {
        setCanNext(_products.length > 1);
        setProducts(_products);
        setActiveProduct(_products[0]);
        setIsNewProduct(true);
      });
    }
  };
  const getFinlogProducts = async () => {
    const completeProducts = await productService.getFinlogAll();
    if (completeProducts && completeProducts.data.length > 0) {
      let _products = [] as ProductFinlog[];
      completeProducts.data.forEach(async (el) => {
        let p = await getProduct(el.productId);

        if (el.productId === product.productId) {
          setActiveProduct(p);
          _products.unshift(p);
        } else {
          _products.push(p);
        }
      });
      setCanNext(true);
      setProducts(_products);
    }
  };

  const next = () => {
    let index = products.findIndex(
      (el) => el.productId === activeProduct.productId
    );
    if (index !== -1) {
      if (products[index + 1]) {
        setActiveProduct(products[index + 1]);
        setCanPrevious(true);
        if (!products[index + 2]) {
          setCanNext(false);
        }
      }
    }
  };

  const previous = async () => {
    let index = products.findIndex(
      (el) => el.productId === activeProduct.productId
    );
    if (index !== -1) {
      if (products[index - 1]) {
        setActiveProduct(products[index - 1]);
        setCanNext(true);
        if (!products[index - 2]) {
          setCanPrevious(false);
        }
      }
    }
  };

  const save = () => {
    if (activeProduct.nameProduct) {
      let cart = getCart();
      if (cart) {
        let array = JSON.parse(cart) as PackProductDTO[];
        if (isNewProduct) {
          let newProduct = {
            productImage: activeProduct.urlImage,
            productId: activeProduct.productId,
            productName: activeProduct.nameProduct,
            appDetailsInfo: activeProduct.km,
            balance: activeProduct.monthlyAmountInclVAT,
            appDetails: activeProduct.contractMonth,
            id: activeProduct.productId,
            serviceId: 1,
          };
          array.push(newProduct);
          storeCart(array);
          closeButton(1);
        } else {
          if (array && array[cartIndex]) {
            array[cartIndex] = {
              ...array[cartIndex],
              productImage: activeProduct.urlImage,
              productId: activeProduct.productId,
              productName: activeProduct.nameProduct,
              appDetailsInfo: activeProduct.km,
              balance: activeProduct.monthlyAmountInclVAT,
              appDetails: activeProduct.contractMonth,
            };
            storeCart(array);
            closeButton(1);
          }
        }
      } else {
        let newProduct = [
          {
            productImage: activeProduct.urlImage,
            productId: activeProduct.productId,
            productName: activeProduct.nameProduct,
            appDetailsInfo: activeProduct.km,
            balance: activeProduct.monthlyAmountInclVAT,
            appDetails: activeProduct.contractMonth,
            id: activeProduct.productId,
            serviceId: 1,
          },
        ];

        storeCart(newProduct);
        closeButton(1);
      }
    }
  };
  const component = () => {
    return (
      <div className='cart_modal modal_finlog'>
        <Row className='card'>
          <p>Veículo</p>
          <Row className='row_car'>
            <Col className='d-flex justify-content-center'>
              <FontAwesomeIcon
                color={canPrevious ? '#00A1ED' : '#eeeeee'}
                icon={faChevronLeft}
                size='lg'
                className='mr-4'
                onClick={() => (canPrevious ? previous() : '')}
              />
            </Col>
            <Col md={8}>
              <div className='car_card align-content-center'>
                <Row className='d-flex justify-content-center'>
                  {activeProduct && (
                    <img
                      src={activeProduct.urlImage ? activeProduct.urlImage : require('../../assets/car_placeholder.png')}
                      alt={activeProduct.nameProduct}
                      className='car_image'
                    />
                  )}
                </Row>
                <Row className='d-flex justify-content-center'>
                  {activeProduct && activeProduct.nameProduct && (
                    <p className='car_name'>{activeProduct.nameProduct}</p>
                  )}
                  {products.length === 0 && (
                    <p className='car_name'>Sem resultados</p>
                  )}
                </Row>
              </div>
            </Col>
            <Col className='d-flex justify-content-center'>
              <FontAwesomeIcon
                color={canNext ? '#00A1ED' : '#eeeeee'}
                icon={faChevronRight}
                size='lg'
                className='mr-4'
                onClick={() => (canNext ? next() : '')}
              />
            </Col>
          </Row>
        </Row>
        <Row className='card mt-4'>
          <Row className='row_info'>
            <Col md={6}>
              <p>Duração do contrato</p>
            </Col>
            <Col md={6}>
              <p className='value'>
                {activeProduct.contractMonth}
                {activeProduct.contractMonth && activeProduct.contractMonth > 1
                  ? ' mês'
                  : ' meses'}
              </p>
            </Col>
            <Col md={6}>
              <p>Quilometragem contratada</p>
            </Col>
            <Col md={6}>
              <p className='value'>
                {activeProduct.km}
                {activeProduct.km && activeProduct.km > 1 ? ' Kms' : ' Km'}
              </p>
            </Col>
          </Row>
        </Row>

        <Row className='card mt-4'>
          <Row className='row_extras'>
            <Col md={12} className='mb-2'>
              Vantagens
            </Col>
            {activeProduct.maintenance && (
              <Col md={6} className='mb-1'>
                <Row className='extra_col'>
                  <Col md={2}>
                    <img
                      src={require('../../assets/cart/FinlogExtras/manutencao.png')}
                    />
                  </Col>
                  <Col>
                    <p className='extra_text d-flex align-content-center'>
                      Manutenção
                    </p>
                  </Col>
                </Row>
              </Col>
            )}
            {activeProduct.damageInsurance && (
              <Col md={6} className='mb-1'>
                <Row className='extra_col'>
                  <Col md={2}>
                    <img
                      src={require('../../assets/cart/FinlogExtras/seguro.png')}
                    />
                  </Col>
                  <Col>
                    <p className='extra_text'>Seguro de danos próprios</p>
                  </Col>
                </Row>
              </Col>
            )}
            {activeProduct.hourAssistance && (
              <Col md={6} className='mb-1'>
                <Row className='extra_col'>
                  <Col md={2}>
                    <img
                      src={require('../../assets/cart/FinlogExtras/assistencia.png')}
                    />
                  </Col>
                  <Col>
                    <p className='extra_text'>Assistência 24h</p>
                  </Col>
                </Row>
              </Col>
            )}
            {activeProduct.replacementCar && (
              <Col md={6} className='mb-1'>
                <Row className='extra_col'>
                  <Col md={2}>
                    <img
                      src={require('../../assets/cart/FinlogExtras/viatura.png')}
                    />
                  </Col>
                  <Col>
                    <p className='extra_text'>Viatura de substituição</p>
                  </Col>
                </Row>
              </Col>
            )}
            {activeProduct.unlimitedTires && (
              <Col md={6} className='mb-1'>
                <Row className='extra_col'>
                  <Col md={2}>
                    <img
                      src={require('../../assets/cart/FinlogExtras/pneus.png')}
                    />
                  </Col>
                  <Col>
                    <p className='extra_text'>Pneus ilimitados</p>
                  </Col>
                </Row>
              </Col>
            )}
          </Row>
        </Row>
        <Row className='row_value mt-4'>
          <Col>
            <p className='value_label_text'>Valor estimado</p>
          </Col>
          <Col md={3}>
            <p className='value_text'>
              {toFixed(activeProduct.monthlyAmountInclVAT, 2)}€
            </p>
          </Col>
        </Row>
        <Row className='row_button'>
          <Button className='button' onClick={save}>
            GUARDAR
          </Button>
        </Row>
      </div>
    );
  };

  return (
    <>
      <ModalBase
        serviceId={1}
        Component={component}
        closeButton={closeButton}
        open={open}
        onClose={onClose}
      ></ModalBase>
    </>
  );
};

export default ModalFinlog;

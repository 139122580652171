import {
  KEY_CART,
  SUBS_PROC,
  PACK_TO_PAY,
  PAYMENT_AUTH,
} from '../constants/config';
import { createStore } from 'redux';
import rootReducer from './reducers';

export function getCart() {
  try {
    return localStorage.getItem(KEY_CART);
  } catch (e) {
    throw e;
  }
}

export function storeCart(cart) {
  try {
    return localStorage.setItem(KEY_CART, JSON.stringify(cart));
  } catch (e) {
    throw e;
  }
}

export function deleteCart() {
  try {
    return localStorage.removeItem(KEY_CART);
  } catch (e) {
    throw e;
  }
}

export function startProcess() {
  try {
    return localStorage.setItem(SUBS_PROC, JSON.stringify(true));
  } catch (e) {
    throw e;
  }
}

export function stopProcess() {
  try {
    return localStorage.setItem(SUBS_PROC, JSON.stringify(false));
  } catch (e) {
    throw e;
  }
}

export function getProcess() {
  try {
    return localStorage.getItem(SUBS_PROC);
  } catch (e) {
    throw e;
  }
}

export function storeAuthPay(data) {
  try {
    return sessionStorage.setItem(PAYMENT_AUTH, data);
  } catch (e) {
    throw e;
  }
}

export function getAuthPay() {
  try {
    return sessionStorage.getItem(PAYMENT_AUTH);
  } catch (e) {
    throw e;
  }
}

export function deleteAuthPay() {
  try {
    return sessionStorage.removeItem(PAYMENT_AUTH);
  } catch (e) {
    throw e;
  }
}

export function storePackToPay(data) {
  try {
    return localStorage.setItem(PACK_TO_PAY, JSON.stringify(data));
  } catch (e) {
    throw e;
  }
}

export function getPackToPay() {
  try {
    console.log(PACK_TO_PAY);
    return localStorage.getItem(PACK_TO_PAY);
  } catch (e) {
    console.log(e);
    throw e;
  }
}

export function deletePackPay() {
  try {
    return localStorage.removeItem(PACK_TO_PAY);
  } catch (e) {
    throw e;
  }
}

const store = createStore(
  rootReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export default store;

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { companyService } from '../../../services/companyService';
import {
  CLIENT_PAGE,
} from '../../../constants/clientConstants';
import { Title, Content, Styles } from '../../../style/client';
import { userService } from 'src/services/GM/userService';
import { useHistory } from 'react-router-dom';
import { SweetAlertType } from 'react-bootstrap-sweetalert/dist/types';
import { AlertConfig } from './users.interface';
import SweetAlert from 'react-bootstrap-sweetalert';
import { iCan } from 'src/redux/sessionReducer';
import { User } from './users.interface';
import { USER_PAGE, USER_VIEW_PAGE_TITLE } from 'src/constants/userConstants';

const UsersView: React.FC = () => {
  const [services, setServices] = useState([
    { serviceId: Number(), id: Number(), description: String() },
  ]);
  const [user, setUser] = useState({} as User);
  const { id } = useParams();
  const history = useHistory();
  const [alertDialog, setAlertDialog] = useState(false);
  const [userToDelete, setUserToDelete] = useState('');
  const [alertDialogConfig, setAlertDialogConfig] = useState({} as AlertConfig);

  useEffect(() => {
    const getServices = async () => {
      const result = await companyService.getUserServices(id);
      if (result.data) {
        let services = result.data.filter((el) => el.id !== 6);
        setServices(services);
      }
    };

    getUser(id).then((result) => {
      console.log(result);
      if (result) {
        setUser(result);
      } else {
        history.push(`/gm/users`);
      }
    });
    getServices();
  }, []);

  const getUser = async (id) => {
    try {
      const result = await userService.getById(id);
      console.log(result);
      return result as User;
    } catch (e) {
      console.log(e);
    }
  };

  const deleteUser = () => {
    setUserToDelete(id);
    alert(
      'Apagar utilizador',
      'Tens certeza que deseja apagar este utilizador?',
      'warning'
    );
  };

  const confirmUserDelete = async () => {
    if (userToDelete !== '') {
      const result = await userService.deleteUser(userToDelete);
      if (result) {
        alert('Apagar utilizador', result.message, 'success');
        history.push('gm/users');
      } else {
        alert('Apagar utilizador', result.message, 'error');
      }
    }
  };

  const alert = (
    title: String,
    message: String,
    type: SweetAlertType,
    redirect: Boolean = false
  ) => {
    setAlertDialog(true);
    setAlertDialogConfig({
      title: title,
      message: message,
      type: type,
      redirect: redirect,
    });
  };

  return (
    <>
      <Title>{USER_VIEW_PAGE_TITLE}</Title>

      <Content>
        <div className='content'>
          <Container fluid className='card-no-tabs client_view'>
            <Row
              style={{
                textAlign: 'center',
                font: 'normal normal normal 18px/22px Roboto',
                letterSpacing: '0px',
                color: '#878787',
                opacity: 1,
                marginLeft: '82.5px',
                marginTop: '51px',
              }}
            >
              <Link className='color-back' to={'/gm/users'}>
                <p>
                  <u>&#60; {USER_PAGE.BACK}</u>
                </p>
              </Link>
            </Row>

            <Row>
              <Col
                style={{
                  textAlign: 'left',
                  font: 'normal normal bold 26px/34px Roboto',
                  letterSpacing: '0px',
                  color: '#2292CC',
                  opacity: 1,
                  marginLeft: '82.5px',
                }}
              >
                <h3>{user.name}</h3>
              </Col>

              <Col>
                <Row className={'d-flex justify-content-end mr-5'}>
                  {/* <div className={'edit_button'} onClick={() => console.log('edit')}>
              <FontAwesomeIcon
                color={'#2292cc'}
                icon={faEdit}
                className={'icon'}
              />
            </div> */}
                  {iCan('ROLE_DELETE_USER') && (
                    <div className={'edit_button'} onClick={() => deleteUser()}>
                      <FontAwesomeIcon
                        color={'#2292cc'}
                        icon={faTrashAlt}
                        className={'icon'}
                      />
                    </div>
                  )}
                </Row>
              </Col>
            </Row>

            <Row
              style={{
                textAlign: 'left',
                font: 'normal normal normal 16px/19px Roboto',
                letterSpacing: '0px',
                color: '#1E1E1E',
                opacity: 1,
                margin: '15.99px 0 61.64px 82.5px',
              }}
            >
              <p>{USER_PAGE.DESCRIPTION}</p>
            </Row>

            <Row className='division' style={Styles.clientPageDivision}>
              <Col>
                <Row style={{ margin: '0 -40px 0 -40px' }}>
                  <Col style={Styles.clientPageDivisionHeader}>
                    <h4>Dados pessoais</h4>
                  </Col>
                </Row>
                <hr />
              </Col>
            </Row>
            <div style={{ marginLeft: 125 }}>
              <Row>
                <Col md={4} style={{ marginTop: 15 }}>
                  <h6 className='light'>{USER_PAGE.NAME}</h6>
                  <h6 className='bold'>
                    {user.name !== null ? user.name : '-'}
                  </h6>
                </Col>

                <Col md={4} style={{ marginTop: 15 }}>
                  <h6 className='light'>{USER_PAGE.NIF}</h6>
                  <h6 className='bold'>{user.nif !== null ? user.nif : '-'}</h6>
                </Col>

                <Col md={4} style={{ marginTop: 15 }}>
                  <h6 className='light'>Foto</h6>
                  {user.imageFile ? (
                    <Image src={user.imageFile} roundedCircle width='100' />
                  ) : (
                    ''
                  )}
                </Col>
              </Row>
              <Row>
                <Col md={4} style={{ marginTop: 15 }}>
                  <h6 className='light'>E-mail</h6>
                  <h6 className='bold'>
                    {user.email !== null ? user.email : '-'}
                  </h6>
                </Col>

                <Col md={4} style={{ marginTop: 15 }}>
                  <h6 className='light'>Género</h6>
                  <h6 className='bold'>
                    {user.userGender == null ? '-' : (user.userGender === 'M'
                      ? 'Masculino'
                      : 'Feminino')}
                  </h6>
                </Col>

                <Col md={4} style={{ marginTop: 15 }}>
                  <h6 className='light'>Data de nascimento</h6>
                  <h6 className='bold'>
                    {user.dateBirth !== null ? user.dateBirth : '-'}
                  </h6>
                </Col>
                <Col md={4} style={{ marginTop: 15 }}>
                  <h6 className='light'>Morada</h6>
                  <h6 className='bold'>
                    {user.address !== null ? user.address : '-'}
                  </h6>
                </Col>
                <Col md={4} style={{ marginTop: 15 }}>
                  <h6 className='light'>Código Postal</h6>
                  <h6 className='bold'>
                    {user.postalCode !== null ? user.postalCode : '-'}
                  </h6>
                </Col>
                <Col md={4} style={{ marginTop: 15 }}>
                  <h6 className='light'>Distrito</h6>
                  <h6 className='bold'>
                    {user.district !== null ? user.district : '-'}
                  </h6>
                </Col>
                <Col md={4} style={{ marginTop: 15 }}>
                  <h6 className='light'>Localidade</h6>
                  <h6 className='bold'>
                    {user.region !== null ? user.region : '-'}
                  </h6>
                </Col>
                <Col md={4} style={{ marginTop: 15 }}>
                  <h6 className='light'>Telefone</h6>
                  <h6 className='bold'>
                    {'(+'}
                    {user.countryCode !== null ? user.countryCode : '-'}
                    {') '}
                    {user.phoneNumber !== null ? user.phoneNumber : '-'}
                  </h6>
                </Col>
                <Col md={4} style={{ marginTop: 15 }}>
                  <h6 className='light'>Perfil</h6>
                  <h6 className='bold'>
                    {user.profileDescription !== null
                      ? user.profileDescription
                      : '-'}
                  </h6>
                </Col>
              </Row>
            </div>
            <Row className='division' style={Styles.clientPageDivision}>
              <Col>
                <Row style={{ margin: '62px -40px 0 -40px' }}>
                  <Col style={Styles.clientPageDivisionHeader}>
                    <h4>{USER_PAGE.CONTRACT}</h4>
                  </Col>
                </Row>
                <hr />
              </Col>
            </Row>

            <Row>
              <Col lg={4} style={{ margin: '15px 0 0 125.5px' }}>
                <h6 className='light'>{CLIENT_PAGE.BALANCE_LIMIT}</h6>
              </Col>

              <Col style={{ margin: '15px 0 0 0' }}>
                <h6 className='light'>{CLIENT_PAGE.HIRED_SERVICES}</h6>
                {services.map((item) => {
                  return <h6 key={item.serviceId + String(item.description)}>{item.description}</h6>;
                })}
              </Col>
            </Row>
          </Container>
        </div>
      </Content>
      {alertDialog && (
        <SweetAlert
          type={alertDialogConfig?.type}
          title={alertDialogConfig?.title ?? ''}
          showCancel={true}
          cancelBtnText='Cancelar'
          cancelBtnBsStyle='light'
          confirmBtnText='Remover'
          confirmBtnBsStyle='danger'
          onCancel={() => {
            setAlertDialog(false);
          }}
          onConfirm={() => {
            setAlertDialog(false);
            if (alertDialogConfig?.type === 'warning') {
              confirmUserDelete();
            }
          }}
        >
          {alertDialogConfig?.message ?? ''}.
        </SweetAlert>
      )}
    </>
  );
};

export default UsersView;

export interface Company {
  name: string;
  id: number;
}
export interface Service {
  active: Boolean;
  dateCreated: Date;
  description: String;
  id: Number;
  name: String;
  pathLocationFiles?: String;
}

import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Title, Content } from '../../../style/content';
import {
  Pack,
  MobilityProfileProductDTO,
  BalanceData,
} from './subscriptions.interface';
import ProductCard from './ProductCard';
import { Doughnut } from 'react-chartjs-2';
import { subscriptionService } from '../../../services/subscriptionService';
import { dashboardService } from '../../../services/User/dashboardService';
import dayjs from 'dayjs';
import { toFixed } from '../../../utils/utils';
import Loading from 'react-fullscreen-loading';
import ModalReservationGuerin from '../Reservations/ModalReservationGuerin';
import ModalReservationXtracars from '../Reservations/ModalReservationXtracars';
import ModalTopUp from './ModalTopUp';
import SweetAlert from 'react-bootstrap-sweetalert';
import { AlertConfig } from '../Profile/profile.interface';
import { SweetAlertType } from 'react-bootstrap-sweetalert/dist/types';

const Subscriptions: React.FC = () => {
  const [subscriptionsList, setSubscriptionsList] = useState(
    [] as MobilityProfileProductDTO[]
  );
  const [chartData, setChartData] = useState({} as BalanceData);

  const [balance, setBalance] = useState(0);
  const [totalMonthBalance, setTotalMonthBalance] = useState(-1);
  const [totalMovements, setTotalMovements] = useState(-1);
  const [isLoading, setIsLoading] = useState(true);
  const [booking, setBooking] = useState({});
  const [openModalGuerin, setOpenModalGuerin] = useState(false);
  const [openModalXtracars, setOpenModalXtracars] = useState(false);
  const [openTopUp, setOpenTopUp] = useState(false);
  const [alertDialog, setAlertDialog] = useState(false);
  const [alertDialogConfig, setAlertDialogConfig] = useState({} as AlertConfig);

  const data = {
    datasets: [
      {
        data: [
          chartData.percentageFinlog,
          chartData.percentageGuerin,
          chartData.percentageRodinhas,
          chartData.percentageXtracars,
          chartData.percentageBedriven,
          chartData.percentageAvailableBalance,
        ],
        backgroundColor: [
          '#00AEEF',
          '#8DC63F',
          '#FFCC00',
          '#3B3B3B',
          '#1A84C6',
          '#EEEEEE',
        ],
        borderColor: [
          '#00AEEF',
          '#8DC63F',
          '#FFCC00',
          '#3B3B3B',
          '#1A84C6',
          '#EEEEEE',
        ],
        borderWidth: 4,
      },
    ],
  };
  const nodata = {
    datasets: [
      {
        data: [100],
        backgroundColor: ['#eeeeee'],
        borderColor: ['#eeeeee'],
        borderWidth: 4,
      },
    ],
  };
  const months = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ];

  useEffect(() => {
    setIsLoading(true)
    getSubscriptions();
    getData();
  }, []);

  const alert = (
    title: string,
    message: string,
    type: SweetAlertType,
  ) => {
    setAlertDialogConfig({
      title: title,
      message: message,
      type: type
    });
    setAlertDialog(true);
  };

  const getTopUpResult = (message, type) => {
    setAlertDialog(true);
    setAlertDialogConfig({
      title: "Alteração de Saldo",
      message: message,
      type: type
    }    );
    closeButton();
  }

  const getSubscriptions = async () => {
    const result = await subscriptionService.getProfilePack();
    if (result) {
      let array = [] as MobilityProfileProductDTO[];
      if (result.length) {
        result.map((el) => {
          el.mobilityProfileProductDTO.map((prod) => {
            prod.packId = el.id;
            prod.hasReservation = el.hasReservation
            array.push(prod);
          });
        });
        setSubscriptionsList(array);
      }
      setIsLoading(false);
    } else {
      console.log(result.message);
      setIsLoading(false);
    }
  };

  const getData = async () => {
    let year = dayjs().format('YYYY');
    let month = dayjs().month(dayjs().month()).format('MM');
    let date = `${year}-${month}-01`;
    const result = await dashboardService.pieChart(date);
    if (result) {
      setBalance(result.balance);
      setTotalMovements(result.totalMovements);
      setTotalMonthBalance(result.totalMonthBalance);
      let percentageAvailableBalance = 0
      if(result.percentageAvailableBalance === 0) {
        percentageAvailableBalance = 100
      } else {
        percentageAvailableBalance = result.percentageAvailableBalance
      }
      
      setChartData({
        percentageFinlog: result.percentageFinlog,
        percentageGuerin: result.percentageGuerin,
        percentageXtracars: result.percentageXtracars,
        percentageRodinhas: result.percentageRodinhas,
        percentageBedriven: result.percentageBedriven,
        percentageAvailableBalance: percentageAvailableBalance,
        balance: result.balance,
      });
    } else {
      console.log(result.message);
    }
  };

  const showNoData = () => {
    if (totalMovements === 0) {
      return true;
    }
    return false;
  };

  const editBooking = (product) => {
    setBooking(product);
    if (product.serviceId === 2) {
      setOpenModalGuerin(true);
    }

    if (product.serviceId === 3) {
      setOpenModalXtracars(true);
    }
  };

  const getBooking = () => {
    return booking;
  };

  const closeButton = () => {
    setOpenModalXtracars(false);
    setOpenModalGuerin(false);
    setOpenTopUp(false);
    getSubscriptions();
    getData();
  };

  const onClose = () => {
    console.log('close');
  };

  return (
    <>
     {isLoading && (
        <Loading
          loading
          background='rgba(255, 255, 255, .4)'
          loaderColor='#3498db'
        />
      )}
      <Title>Subscrições</Title>
      <Content>
        <Container fluid className='card-no-tabs user_subscriptions'>
          <Row className='justify-content-center'>
            <div className='chart_container'>
                <div className='circle_info'
                  onClick= { () => setOpenTopUp(true)}
                >
                   <div className='month'>Saldo disponível</div>
                  <div className='total'>{toFixed(totalMovements, 2)}€</div>
                </div>
              {showNoData() && (
                <Doughnut
                  data={nodata}
                  className='nodata'
                  options={{
                    cutout: 85,
                  }}
                />
              )}
              {!showNoData() && (
                <Doughnut
                  data={data}
                  options={{
                    cutout: 85,
                    plugins: {
                      tooltip: {
                        callbacks: {
                          label: function (context) {

                            let label = context.dataset.label || '';
                            let value = context.parsed % Math.floor(context.parsed) == 0 ? context.parsed.toFixed(0) : context.parsed.toFixed(2)

                            if (label) {
                              label += ': ';
                            }
                            if (context.parsed !== null) {
                              label += ': ' + value + ' %';
                            }
                            return label;
                          }
                        }
                      }
                    }
                  }}
                  className='data'
                />
              )}
              {showNoData() && <p style={{textAlign: 'center', paddingTop: 10}}>Sem dados para exibir</p>}
              {!showNoData() && <p className='mt-2' style={{textAlign: 'center'}}>Toque para alterar</p>}
            </div>
          </Row>
          <Row className='mt-4 mb-4'>
            {subscriptionsList &&
              subscriptionsList.length > 0 &&
              subscriptionsList.map((el) => {
                return (
                  <Col
                    md={12}
                    className='d-flex justify-content-center align-middle'
                    key={el.id}
                  >
                    <ProductCard
                      key={el.id}
                      product={el}
                      booking={(e) => editBooking(el)}
                    ></ProductCard>
                  </Col>
                );
              })}
          </Row>
          {alertDialog && (
              <SweetAlert
                type={alertDialogConfig?.type}
                title={alertDialogConfig?.title ?? ''}
                onConfirm={() => {
                  setAlertDialog(false);
                }}
              >
                {alertDialogConfig?.message ?? ''}.
              </SweetAlert>
            )}
        </Container>
      </Content>
      <ModalReservationGuerin
        closeButton={closeButton}
        open={openModalGuerin}
        onClose={onClose}
        booking={getBooking()}
      ></ModalReservationGuerin>
      <ModalReservationXtracars
        closeButton={closeButton}
        open={openModalXtracars}
        onClose={onClose}
        booking={getBooking()}
      ></ModalReservationXtracars>
      <ModalTopUp
        closeButton={closeButton}
        open={openTopUp}
        showResult={getTopUpResult}
      ></ModalTopUp>
    </>
  );
};

export default Subscriptions;

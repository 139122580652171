import React, { useState, useEffect, useRef } from 'react';
import { Form, Container, Row, Col, Image, Button, Tooltip } from 'react-bootstrap';
import { Title, Content} from '../../../style/content';
import '../../../style/GP/movements.scss';
import DatePicker from 'react-datepicker';
import Loading from 'react-fullscreen-loading';
import { movementsService } from '../../../services/movementsService';
import { AlertConfig, MovementsScreen } from './movements.interface';
import dayjs from 'dayjs';
import { faFileDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SweetAlert from 'react-bootstrap-sweetalert';
import { SweetAlertType } from 'react-bootstrap-sweetalert/dist/types';

const Movements: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);

  const [movementsConfig, setMovementsConfig] = useState(
    {
      title: 'Movimentos',
      description: 'Selecione as datas do período e clique no icon para fazer Download do ficheiro de movimentos',
      startDate: dayjs().startOf('M').toDate(),
      endDate: dayjs().endOf('M').toDate()
    } as MovementsScreen);
  const fileInputField = useRef(null);
  const [alertDialog, setAlertDialog] = useState(false);
  const [alertDialogConfig, setAlertDialogConfig] = useState({} as AlertConfig);

  const alert = (
    title: string,
    message: string,
    redirect: boolean,
    type: SweetAlertType,
  ) => {
    setAlertDialogConfig({
      title: title,
      message: message,
      redirect: redirect,
      type: type
    });
    setAlertDialog(true);
  };

  const DownloadFile = async () => {
    setIsLoading(true);
    let config = movementsConfig;
    const result = await movementsService.DownloadFile(dayjs(config.startDate).format(
      'YYYY-MM-DD'
    ),
      dayjs(config.endDate).format(
        'YYYY-MM-DD'
      ));
    console.log(result);
    if (result.error) {
      setIsLoading(false);
      alert('Erro', result.message, false, 'error');
    } else {
      setIsLoading(false);
      window.location = result;
    }
  };


  return (
    <>
      {isLoading && (
        <Loading
          loading
          background='rgba(255, 255, 255, .4)'
          loaderColor='#3498db'
        />
      )}
      <Title>Movimentos</Title>

      <Content>
        <Container fluid className='card-no-tabs movements_main mb-4'>
          <Row>
            <Col>
              <h3>{movementsConfig.title}</h3>
              <p>{movementsConfig.description}</p>
            </Col>
          </Row>
          <Row className='mt-4'>
            <Col md={4} xs={12}>
              Data Início
            </Col>
            <Col md={4} xs={12}>
              Data Fim
            </Col>
          </Row>
          <Row className='mt-2'>
            <Col md={4} xs={12} className='d-flex align-items-center'>
              <DatePicker
                selected={movementsConfig.startDate}
                dateFormat='dd/MM/yyyy'
                onChange={(date) =>
                  date &&
                  setMovementsConfig({
                    ...movementsConfig,
                    startDate: new Date(date.toString()),
                  })}
              />
            </Col>
            <Col md={4} xs={12} className='d-flex align-items-center'>
              <DatePicker
                selected={movementsConfig.endDate}
                dateFormat='dd/MM/yyyy'
                onChange={(date) =>
                  date &&
                  setMovementsConfig({
                    ...movementsConfig,
                    endDate: new Date(date.toString()),
                  })}
              />
            </Col>
            <Col md={3} xs={12}>
              <FontAwesomeIcon
                size="3x"
                icon={faFileDownload}
                className="margin-label"
                onClick={() => {
                  DownloadFile();
                }}
                color='#BADDF1'
                style={{ cursor: "pointer"}}
                title='Download'
                />
            </Col>
          </Row>
        </Container>
        {alertDialog && (
              <SweetAlert
                type={alertDialogConfig?.type}
                title={alertDialogConfig?.title ?? ''}
                onConfirm={() => {
                  setAlertDialog(false);
                }}
              >
                {alertDialogConfig?.message ?? ''}.
              </SweetAlert>
            )}
      </Content>
    </>
  );
};

export default Movements;

import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { autocompleteService } from 'src/services/autocompleteService';


const AutoCompleteRodinhas = ({ text, className, onSelect, onChange }) => {
    const [addresses, setAddresses] = useState([]);
    const [inputAddress, setInputAddress] = useState(text);
    const [pendingSelection, setPendingSelection] = useState({ label: '', value: '' })

    const getAddress = async (value) => {
        if (value.length > 3) {
            let addresses = await autocompleteService.getAddress(value);
            setAddresses(addresses);
        } else {
            setAddresses([]);
        }
    }

    const SelectOption = (label, value) => {
        setAddresses([]);
        onSelect(label, value)
    }

    const ChangeAddresses = (value) => {

        setAddresses([]);
        onChange(value)

    };
    return (
        <div>
            <Form.Control
                type='text'
                minLength={3}
                required
                className={className}
                onChange={(e) => {
                    getAddress(e.target.value);
                    setInputAddress(e.target.value); //this make change work , but clicked dont
                    setPendingSelection({ label: '', value: '' });
                }}
                onBlur={(event) => {

                    if (pendingSelection["label"] !== '') {
                        SelectOption(pendingSelection["label"], pendingSelection["value"]);
                    } else {
                        ChangeAddresses(inputAddress);
                    }
                }}
                value={inputAddress}
                placeholder={'Morada'}
            />

            <div className="autocomplete-adresses" hidden={addresses && addresses.length > 0 ? false : true}>
                {addresses && addresses.map((address, i) =>
                    <div key={address["value"]}
                        onMouseEnter={() => {
                            setPendingSelection({ label: address["label"], value: address["value"] });
                        }}
                    >{address["label"]}</div>
                )}
            </div>
        </div>
    );
};

export default AutoCompleteRodinhas
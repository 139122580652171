import api from '../utils/api';
import {
  deleteUser,
  storeUser,
  storeUserDetails,
  deleteUserDetails,
  setUserProfileManager,
  updateUserRoles
} from '../redux/sessionReducer';
export const AuthService = {
  authenticate,
  logout,
  getMenu,
  getUserFromSubscription,
};

async function getMenu() {
  const response = await api.apiPrivate
    .get('api/backoffice/menu')
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        console.log(error.request);
        return error.request;
      }
      throw error;
    });
  return response;
}

async function authenticate(data) {
  const response = await api.apiPrivate
    .post('api/oauth/auth-api/v1/auth/user', data)
    .then((response) => {
      storeUser(response.data);
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        console.log(error.request);
        return error.request;
      }
      throw error;
    });
  return response;
}

async function getUserFromSubscription(allData = false) {
  const response = await api.apiPrivate
    .get('api/comercial-profile/user-system/get-from-subscription?idgo=true')
    .then((response) => {
      if (allData) {
        return response.data;
      }
      let user = response.data;
      delete user.address;
      delete user.countryCode;
      delete user.dateBirth;
      delete user.documentExpiryDate;
      delete user.driverLicense;
      delete user.driverLicenseExpiryDate;
      delete user.identificationCard;
      delete user.moreInformation;
      delete user.nif;
      delete user.phoneNumber;
      delete user.postalCode;
      delete user.region;
      delete user.screenType;
      delete user.userGender;
      storeUserDetails(response.data);
      setUserProfileManager(user.userProfile);
      updateUserRoles(user.roles)
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        console.log(error.request);
        return error.request;
      }
      throw error;
    });
  return response;
}

async function logout() {
  const response = await api.apiPrivate.put(
    'api/oauth/auth-api/v1/auth/logout'
  );
  deleteUser();
  deleteUserDetails();

  return response;
}

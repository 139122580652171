import api from '../../utils/api';

export const userService = {
  getAll,
  create,
  createMany,
  getById,
  uploadSheet,
  getMobilityGroups,
  getUsersWithoutGroup,
  deleteUser,
  getAllComercial,
  deleteCommercial,
  downloadUserTemplate,
};

async function getAll(pageSize, page, filter) {
  let pageSizeParam = pageSize ? `?size=${pageSize}` : '';
  let filterParam = filter ? `&filter=${filter}` : '';
  const response = await api.apiPrivate
    .get(
      `api/comercial-profile/user-system/get-users-by-client${pageSizeParam}&page=${page}${filterParam}&deleted=false`
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        console.log(error.request);
        return error.request;
      }
      throw error;
    });
  return response;
}

async function getAllComercial(pageSize, page, filter) {
  let pageSizeParam = pageSize ? `?size=${pageSize}` : '';
  let filterParam = filter ? `&filter=${filter}` : '';
  const response = await api.apiPrivate
    .get(
      `api/comercial-profile/user-system/commercial-users${pageSizeParam}&page=${page}${filterParam}&deleted=false`
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        console.log(error.request);
        return error.request;
      }
      throw error;
    });
  return response;
}

async function create(data) {
  const response = await api.apiPrivate
    .post('api/comercial-profile/user-system/create-user', data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        console.log(error.request);
        return error.request;
      }
      throw error;
    });
  return response;
}

async function createMany(data) {
  const response = await api.apiPrivate
    .post('api/comercial-profile/user-system/create-users', data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        console.log(error.request);
        return error.request;
      }
      throw error;
    });
  return response;
}

async function getById(id) {
  const response = api.apiPrivate
    .get(`api/comercial-profile/user-system/${id}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        console.log(error.request);
        return error.request;
      }
      throw error;
    });
  return response;
}

async function deleteUser(id) {
  const response = api.apiPrivate
    .delete(`api/comercial-profile/user-system/${id}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        console.log(error.request);
        return error.request;
      }
      throw error;
    });
  return response;
}

async function deleteCommercial(id) {
  const response = api.apiPrivate
    .delete(`api/comercial-profile/user-system/delete/commercial-manager/${id}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        console.log(error.request);
        return error.request;
      }
      throw error;
    });
  return response;
}

async function uploadSheet(data) {
  const response = await api.apiPrivate
    .post('api/comercial-profile/user-system/upload-user-sheet', data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        console.log(error.request);
        return error.request;
      }
      throw error;
    });
  return response;
}

async function getMobilityGroups() {
  const response = await api.apiPrivate
    .get('api/comercial-profile/mobility-group/all-mobility-groups')
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        console.log(error.request);
        return error.request;
      }
      throw error;
    });
  return response;
}

async function getUsersWithoutGroup(filter = '') {
  const response = await api.apiPrivate
    .get(
      `api/comercial-profile/user-system/all-users-services?filter=${filter}`
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        console.log(error.request);
        return error.request;
      }
      throw error;
    });
  return response;
}

async function downloadUserTemplate() {
  try {
    const response = await api.apiPrivate.get(
      `api/comercial-profile/user-system/download-template`
    );

    return response;
  } catch (error) {
    throw error;
  }
}

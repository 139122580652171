export const TABLE_HEADERS = {
  CLIENT: 'Cliente',
  CONTACT: 'Contacto',
  ACCOUNT_STATUS: 'Estado da Conta',
  ACCOUNT_CREATION: 'Criação',
};

export const BADGE_LABEL = {
  ACTIVE: 'ativo',
  INACTIVE: 'não ativado',
  CANCELED: 'cancelado',
  DELETED: 'apagado',
};

export const NR_OF_CLIENTS_MESSAGE = ' clientes registados';

export const CLIENT_LIST_PAGE_TITLE = 'Os seus clientes';
export const CLIENT_INFO_PAGE_TITLE = 'Consultar';

export const CLIENT_CREATE_PAGE_TITLE = 'Criar um cliente';
export const CLIENT_VIEW_PAGE_TITLE = 'Visualizar um cliente';

export const CLIENT_PAGE = {
  DESCRIPTION:
    'Consulte os dados de conta e de contacto do cliente.',
  COMPANY: 'Empresa',
  LOGO: 'Logótipo',
  NAME: 'Nome',
  NIF: 'NIF',
  CONTACT_INFO: 'Pessoa de contacto',
  FULLNAME: 'Nome completo',
  PROFILE: 'Perfil',
  EMAIL: 'E-mail',
  CONTACT: 'Contacto',
  CONTRACT: 'Contrato',
  BALANCE_LIMIT: 'Limite de saldo',
  HIRED_SERVICES: 'Serviços contratados',
  OK_BUTTON_LABEL: 'OK',
  BACK: 'Voltar',
};

export const NR_OF_CLIENTS_PER_PAGE = 10;

import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Title, Content } from '../../../style/content';
import { Document, AlertConfig } from './documents.interface';
import { SweetAlertType } from 'react-bootstrap-sweetalert/dist/types';
import SweetAlert from 'react-bootstrap-sweetalert';
import { documentService } from '../../../services/documentService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import LinearProgress from '@material-ui/core/LinearProgress';
import Loading from 'react-fullscreen-loading';
import { getProcess } from '../../../redux/cartReducer';
import { useHistory } from 'react-router-dom';
import { URL_FE_PUBLIC } from '../../../constants/config';

const Documents: React.FC = () => {
  const [documentList, setDocumentList] = useState([] as Document[]);
  const fileInputField = useRef(null);
  const [fileInputKey, setFileInputKey] = useState('12345');
  const [progress, setProgress] = useState(0);
  const [alertDialog, setAlertDialog] = useState(false);
  const [alertDialogConfig, setAlertDialogConfig] = useState({} as AlertConfig);
  const [isLoading, setIsLoading] = useState(false);
  const [onSubscriptionProcess, setOnSubscriptionProcess] = useState(false);
  const history = useHistory();

  useEffect(() => {
    getDocuments();
    let onProcess = getProcess();
    if (onProcess) {
      setOnSubscriptionProcess(onProcess === 'true');
    }
  }, []);

  const getDocuments = async () => {
    setIsLoading(true);
    const result = await documentService.getAllDocuments();
    if (result && result.length > 0) {
      const list = result.map((document) => {
        return {
          status: 'upload',
          loading: false,
          fileName: '',
          ...document,
        };
      });

      const responseUploadedDocuments =
        await documentService.getUserDocuments();

      setDocumentList(list);
      setIsLoading(false);
      if (responseUploadedDocuments.length) {
        let handledDocuments = [];

        responseUploadedDocuments.forEach((item) => {
          handledDocuments = list.map((doc) => {
            if (item.documentId === doc.id) {
              doc.documentsUser.push(item);
              return doc;
            }

            return doc;
          });
        });

        setDocumentList(handledDocuments);
      }
    }
  };

  const documentUpload = async (document, index) => {
    setIsLoading(true);
    const documentsData = [...documentList];
    const dataPayload = {
      documentId: documentsData[index].id,
      imageName: document.documentName,
      imageFile: document.documentBase64,
    };

    let documentsUserLength = documentsData[index].documentsUser.length;

    documentsData[index].documentsUser[documentsUserLength] = {
      loading: true,
      urlImage: '',
      imageUUID: `${document.documentName}`,
      extension: '',
    };
    setIsLoading(false);
    setDocumentList([...documentsData]);

    setTimeout(function () {
      startProgressBar();
    }, 3000);

    try {
      const result = await documentService.uploadFile(dataPayload);
      setTimeout(function () {
        documentsData[index].status = 'success';
        documentsData[index].documentsUser[documentsUserLength] = result;
        documentsData[index].fileName = document.documentName;
        setDocumentList([...documentsData]);
        setProgress(0);
      }, 3000);
    } catch (error) {
      console.log(error);
      documentsData[index].status = 'error';
      setDocumentList([...documentsData]);
      alert('Erro', error.response, 'error');
    }
  };

  const handleNewFileUpload = (e: any, index) => {
    if (e.target.files.length) {
      const file = e.target.files[0];
      const fileSize = file.size / 1024 / 1024;

      if (fileSize > 2) {
        alert('Erro', 'Arquivo deve ter menos que 2 MB', 'error');
        return false;
      }

      getBase64(file).then((data) => {
        let base64String = data as string;
        base64String = base64String.split(',')[1];
        documentUpload(
          { documentName: file.name, documentBase64: base64String },
          index
        );
      });
      setFileInputKey(Math.random().toString(36).substring(7));
    }
  };

  const getBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const removeDocument = async (document, index) => {
    setIsLoading(true);
    const { documentsUser } = document;

    const documentsData = [...documentList];

    try {
      const response = await documentService.removeDocument(
        documentsUser[0].id
      );

      documentsData[index].documentsUser = [];
      documentsData[index].status = 'upload';

      setDocumentList(documentsData);
      setIsLoading(false);
      alert('Sucesso', response.message, 'success');
    } catch (error) {
      console.log(error);
      alert('Error', 'Erro ao deletar documento', 'error');
    }
  };

  const alert = (title: String, message: String, type: SweetAlertType) => {
    setAlertDialog(true);
    setAlertDialogConfig({
      title: title,
      message: message,
      type: type,
    });
  };

  const startProgressBar = () => {
    for (var i = 0; i <= 100; i++) {
      let num = i++;
      setProgress(num);
    }
  };

  return (
    <>
      <Title>Documentos</Title>

      <Content>
        <Container fluid className='card-no-tabs content_card user_documents'>
          <h1 className='title'>Confira os documentos em falta</h1>
          <p className='subtitle'>
            De maneira a dar continuidade ao processo, envie por favor os
            seguintes documentos para auferir as condições necessárias e
            favoráveis à aprovação dos serviços.
          </p>

          <p className='subtitle'>
            Os dados pessoais fornecidos serão recolhidos e tratados única e
            exclusivamente para serem utilizados na execução e cumprimento das
            obrigações pré-contratuais e contratuais, nomeadamente para efeitos
            de análise de risco de crédito, em conformidade com a nossa Política
            de Privacidade e Proteção de Dados, a qual poderá consultar
            novamente <a href={`${URL_FE_PUBLIC}/privacy-policy/`}>AQUI</a>
          </p>
          <div className='documents_container'>
            {documentList &&
              documentList.length > 0 &&
              documentList.map((el, index) => {
                return (
                  <div key={el.name}>
                    <Row className='mb-2'>
                      <Col md={5}>{el.name}</Col>
                      <Col>
                        <hr className='line_divisor' />
                      </Col>
                      <Col md={2} className='d-flex justify-content-end'>
                        <input
                          type='file'
                          ref={fileInputField}
                          onChange={(e) => handleNewFileUpload(e, index)}
                          key={`${fileInputKey}${el.name}`}
                          className='input_file'
                        />
                        <img
                          src={require('../../../assets/documents/upload.png')}
                          alt='Upload'
                        />
                      </Col>
                    </Row>
                    {el.documentsUser.length > 0 &&
                      el.documentsUser.map((doc, indexDoc) => (
                        <Row className='document_row_preview' key={indexDoc}>
                          <Col lg={2}>
                            <div className='square_preview'>
                              {doc && doc.urlImage && (
                                <img src={doc.urlImage} alt="" />
                              )}
                            </div>
                          </Col>
                          <Col>
                            {doc && doc.imageUUID && (
                              <p>{`${doc.imageUUID}${doc.extension}`}</p>
                            )}
                          </Col>
                          <Col>
                            {doc.loading && (
                              <LinearProgress
                                variant='determinate'
                                value={progress}
                              />
                            )}
                          </Col>
                          <Col sm={1} lg={1} className='d-flex justify-content-end'>
                            <FontAwesomeIcon
                              icon={faTimesCircle}
                              onClick={() => removeDocument(el, index)}
                              color='#2292cc'
                            />
                          </Col>
                        </Row>
                      ))}
                  </div>
                );
              })}
          </div>
          <Row className='d-flex justify-content-center mt-4'>
            {onSubscriptionProcess && (
              <Button
                className='continue_button'
                onClick={() => history.push('/cart')}
              >
                Continuar
              </Button>
            )}
          </Row>
        </Container>
        {alertDialog && (
          <SweetAlert
            type={alertDialogConfig?.type}
            title={alertDialogConfig?.title ?? ''}
            onConfirm={() => {
              setAlertDialog(false);
            }}
          >
            {alertDialogConfig?.message ?? ''}.
          </SweetAlert>
        )}
        {isLoading && (
          <Loading
            loading
            background='rgba(255, 255, 255, .4)'
            loaderColor='#3498db'
          />
        )}
      </Content>
    </>
  );
};

export default Documents;

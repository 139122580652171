export const labels = {
  en: {
    translation: {
      "change lg": "INGLES"
    }
  },
  pt: {
    translation: {
      "change lg": "PORTUGES"
    }
  }
};

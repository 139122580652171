import React, { useState, useEffect } from 'react';
import { Container, Row } from 'react-bootstrap';
import { configuratorService } from '../../services/configuratorService';
import { Pack } from './packs.interface';
import PackCard from './Pack';
import { SERVER_BACKOFFICE } from '../../constants/config';
import { setGoToCart } from '../../redux/sessionReducer';

const Packs: React.FC = () => {
  const [packList, setPackList] = useState([] as any);

  useEffect(() => {
    async function getPacks() {
      try {
        const result = await configuratorService.getAllPacks();
        setPackList(result);
      } catch (error) {
        console.log(error);
      }
    }

    getPacks();
  }, []);

  const clickEdit = (pack: Pack) => {
    window.top.location.replace(`${SERVER_BACKOFFICE}/cart`);
    setGoToCart(true);
  };

  return (
    <>
      <Container fluid className='configurator_packs'>
        <Row className='d-flex justify-content-center container_cards'>
          {packList &&
            packList.length > 0 &&
            packList.map((pack: Pack) => {
              return (
                <PackCard
                  pack={pack}
                  key={pack.id}
                  clickEdit={clickEdit}
                ></PackCard>
              );
            })}
        </Row>
      </Container>
    </>
  );
};

export default Packs;

import api from '../utils/api';

export const packService = {
  create,
  getAll,
  update,
  deletePack,
  getPackById,
  getAllSubPack,
  getIdSubPack,
  getPackDetails,
  deleteSubPack,
  removePack,
  activePack,
  calculateRodinhasValue,
  calculateGuerinXtracarsValue,
};

let prefix = '/api/product';

if (process.env.REACT_APP_ENV === 'local') {
  prefix = '';
}

async function create(body) {
  try {
    const response = await api.apiPrivate.post(
      `${prefix}/pack/create-pack`,
      body
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

async function getAll() {
  try {
    const response = await api.apiPrivate.get(`${prefix}/pack/all-packs`);
    return response;
  } catch (error) {
    throw error;
  }
}

async function deletePack(packId) {
  try {
    const response = await api.apiPrivate.delete(`api/product/pack/${packId}`);
    return response;
  } catch (error) {
    throw error;
  }
}

async function deleteSubPack(body) {
  try {
    const response = await api.apiPrivate.delete(`/pack`, { data: body });
  } catch (error) {
    throw error;
  }
}

async function getPackById(idPack) {
  try {
    const response = await api.apiPrivate.get(`api/product/pack/${idPack}`);
    return response;
  } catch (error) {
    throw error;
  }
}

async function getIdSubPack(idSubPack) {
  try {
    const response = await api.apiPrivate.get(`/pack/sub-pack/${idSubPack}`);
    return response.data;
  } catch (error) {
    throw error;
  }
}

async function getAllSubPack() {
  try {
    const response = await api.apiPrivate.get('/pack/sub-pack');
    return response.data;
  } catch (error) {
    throw error;
  }
}

async function getPackDetails(idSubPack) {
  try {
    const response = await api.apiPrivate.get(`/pack/details/${idSubPack}`);
    return response.data;
  } catch (error) {
    throw error;
  }
}

async function removePack(idPack) {
  try {
    const response = await api.apiPrivate.delete(`/pack/${idPack}`);
    return response.data;
  } catch (error) {
    throw error;
  }
}

async function activePack(idPack, data) {
  try {
    const response = await api.apiPrivate.put(
      `api/product/pack/active-pack/${idPack}`,
      { active: data }
    );
    return response;
  } catch (error) {
    throw error;
  }
}

async function update(pack) {
  try {
    const response = await api.apiPrivate.put(
      `api/product/pack/${pack.id}`,
      pack
    );
    return response;
  } catch (error) {
    throw error;
  }
}

async function calculateRodinhasValue(pack) {
  try {
    const response = await api.apiPrivate.post(
      `${prefix}/pack/calculation-rodinhas-value`,
      pack
    );
    return response;
  } catch (error) {
    throw error;
  }
}

async function calculateGuerinXtracarsValue(pack) {
  try {
    const response = await api.apiPrivate.post(
      `${prefix}/pack/calculation-guerinxtracars-value`,
      pack
    );
    return response;
  } catch (error) {
    throw error;
  }
}

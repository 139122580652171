import { KEY_TOKEN, KEY_DETAILS, KEY_PROFILE, KEY_PROFILE_MANAGER, GO_TO_CART } from '../constants/config';
import { createStore } from 'redux';
import rootReducer from './reducers';

export async function getUser() {
  try {
    return sessionStorage.getItem(KEY_TOKEN);
  } catch (e) {
    throw e;
  }
}

export async function getUserDetails() {
  try {
    return sessionStorage.getItem(KEY_DETAILS);
  } catch (e) {
    throw e;
  }
}

export async function storeUser(userToken) {
  try {
    return sessionStorage.setItem(KEY_TOKEN, JSON.stringify(userToken));
  } catch (e) {
    throw e;
  }
}

export async function updateUserRoles(roles) {
  let user = await getUser()
  let userOBj = JSON.parse(user)
  userOBj.roles = roles
  try {
    return sessionStorage.setItem(KEY_TOKEN, JSON.stringify(userOBj));
  } catch (e) {
    throw e;
  }
}

export async function storeUserDetails(user) {
  try {
    return sessionStorage.setItem(KEY_DETAILS, JSON.stringify(user));
  } catch (e) {
    throw e;
  }
}

export async function deleteUser() {
  try {
    return await sessionStorage.removeItem(KEY_TOKEN);
  } catch (e) {
    throw e;
  }
}

export async function deleteUserDetails() {
  try {
    return await sessionStorage.removeItem(KEY_DETAILS);
  } catch (e) {
    throw e;
  }
}

export async function removeUserProfile() {
  try {
    return await sessionStorage.removeItem(KEY_PROFILE);
  } catch (e) {
    throw e;
  }
}

export async function setUserProfile(profile) {
  try {
    return await sessionStorage.setItem(KEY_PROFILE, JSON.stringify(profile));
  } catch (e) {
    throw e;
  }
}

export function getUserProfile() {
  try {
    return sessionStorage.getItem(KEY_PROFILE);
  } catch (e) {
    throw e;
  }
}

export async function setUserProfileManager(profile) {
  try {
    return await sessionStorage.setItem(KEY_PROFILE_MANAGER, JSON.stringify(profile));
  } catch (e) {
    throw e;
  }
}

export function getUserProfileManager() {
  try {
    return sessionStorage.getItem(KEY_PROFILE_MANAGER);
  } catch (e) {
    throw e;
  }
}

export function setGoToCart(bool) {
  try {
    return sessionStorage.setItem(GO_TO_CART, JSON.stringify(bool));
  } catch (e) {
    throw e;
  }
}

export function getGoToCart() {
  try {
    return sessionStorage.getItem(GO_TO_CART);
  } catch (e) {
    throw e;
  }
}

export function defaultRoute() {
  let user;
  try {
    let result = sessionStorage.getItem(KEY_TOKEN);
    user = JSON.parse(result);
  } catch (e) {
    throw e;
  }

  if (user && user.roles) {
    if (user.roles.includes('ROLE_CORPORATE_USER')) {
      return '/usercorporate/dashboard';
    }

    if (user.roles.includes('ROLE_PARTICULAR_USER')) {
      return '/user/dashboard';
    }

    if (user.roles.includes('ROLE_OAUTH_ADMIN')) {
      //return '/admin';
      return '/gm/dashboard';
    }

    if (user.roles.includes('ROLE_PRODUCT_MANAGER')) {
      return '/gp/dashboard';
    }

    if (user.roles.includes('ROLE_COMMERCIAL_MANAGER')) {
      return '/gc/dashboard';
    }

    if (user.roles.includes('ROLE_MOBILITY_MANAGER')) {
      return '/gm/dashboard';
    }
  }
  return '';
}

export function getUserRoles() {
  let user;
  try {
    let result = sessionStorage.getItem(KEY_TOKEN);
    user = JSON.parse(result);
  } catch (e) {
    throw e;
  }

  if (user) {
    if (user.roles) {
      return user.roles;
    }
  }
}

export function iCan(role) {
  let roles = getUserRoles();
  if (roles) {
    if (roles.includes(role)) {
      return true;
    } else {
      return false;
    }
  }
}

export function isManager() {
  let role = getUserProfileManager();
  if (role) {
    if (role == '"MOBILITY_MANAGER"' || role == '"COMMERCIAL_MANAGER"' || role == '"PRODUCT_MANAGER"') {
      return true;
    } else {
      return false;
    }
  }
}

const store = createStore(
  rootReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export default store;

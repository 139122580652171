import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Title, Content } from '../../style/content';
import ProductCard from './ProductCard';
import { PackProductDTO, Service } from '../Configurator/packs.interface';
import {
  storeCart,
  getCart,
  deleteCart,
  startProcess,
  deletePackPay,
  stopProcess,
  storeAuthPay,
  storePackToPay,
} from '../../redux/cartReducer';
import { getUserRoles } from '../../redux/sessionReducer';
import { toFixed } from '../../utils/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ModalGuerin from './ModalGuerin';
import ModalFinlog from './ModalFinlog';
import ModalXtracars from './ModalXtracars';
import ModalRodinhas from './ModalRodinhas';
import ModalContact from './ModalContact';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { AuthService } from 'src/services/auth.service';
import { useHistory } from 'react-router-dom';
import { documentService } from '../../services/documentService';
import { subscriptionService } from 'src/services/subscriptionService';
import ModalRodinhasItinerary from './ModalRodinhasItinerary';
import { getUserDetails } from '../../redux/sessionReducer';

const Cart: React.FC = () => {
  const [productList, setProductList] = useState([] as PackProductDTO[]);
  const [availableServices, setAvailableServices] = useState([] as Service[]);
  // const [totalValue, setTotalValue] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [modalShow, setModalShow] = useState({
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
    6: false,
    7: false,
    8: false,
  });
  const [activeProduct, setActiveProduct] = useState(null);
  const [alertDialog, setAlertDialog] = useState(false);
  const [alertConfig, setAlertConfig] = useState({
    title: '',
    message: '',
    redirect: false,
    redirectURL: '',
  });
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [deleteConfig, setDeleteConfig] = useState(-1);
  const history = useHistory();
  const [userPackDTO, setUserPackDTO] = useState([]);
  // const [isLoading, setIsLoading] = useState(false);

  const [paymentDetails, setPaymentDetails] = useState({
    customerDTO: {
      browserDTO: {
        acceptHeader: '',
        challengeWindow: '',
        javaEnabled: '',
        language: '',
        screenColorDepth: '',
        screenHeight: '',
        screenWidth: '',
        timezone: '',
        userAgent: '',
      },
      ip: '',
    },
    valuePayment: 0,
    paymentAuthorize: 'PAYMENT_TOTALITY',
  });

  const showLimitedOffer = (prodlist) => prodlist.filter(prod => prod.serviceId !== 4).length > 0 ? true : false;

  useEffect(() => {
    getPacks();
    getServices();
    mountPack();
  }, []);

    const mountPack = async () => {
      let packs = getCart();
      const listPacks = [];

      // eslint-disable-next-line
      if (packs != undefined && packs?.length > 0) {
        let array = JSON.parse(packs) as PackProductDTO[];

        array.forEach((pack) => {
          if (pack.serviceId === 4) {
            let newPack = {
              childrenRodinhasDTO: [],
              packId: pack.pack,
              endService: pack.endService,
              startService: pack.startService,
              packItineraryDTO: pack.packItineraryDTO,
              packProducts: [
                {
                  serviceId: pack.serviceId,
                  rodinhasItinerary: {
                    numberChildren: pack.numberChildren,
                    region: pack.region,
                    pickupZone: pack.pickupZone,
                    pickupSubZone: pack.pickupSubZone,
                    dropoffZone: pack.dropoffZone,
                    dropoffSubZone: pack.dropoffSubZone,
                    numberPerWeek: pack.numberPerWeek,
                    roundTrip: pack.roundTrip,
                  },
                },
              ],
              productServiceType: 'REGULAR',
              remainderValuePack: '',
              paymentAuthorize: 'PAYMENT_TOTALITY',
            };

            listPacks.push(newPack as never);
          } else {
            let newPack = {
              childrenRodinhasDTO: [],
              packId: pack.pack,
              endService: '',
              startService: '',
              packItineraryDTO: [],
              packProducts: [
                {
                  productId: pack.productId,
                  serviceId: pack.serviceId,
                  valueProductPack: pack.valuePack,
                },
              ],
              productServiceType: 'REGULAR',
              remainderValuePack: '',
              paymentAuthorize: 'PAYMENT_TOTALITY',
            };

            listPacks.push(newPack as never);
          }
        });

        setUserPackDTO(listPacks);
      }
    };


  useEffect(() => {
    calculateTotalValue();
    // eslint-disable-next-line
  }, [productList]);

  const getPacks = () => {
    const result = getCart();
    if (result) {
      let array = JSON.parse(result) as PackProductDTO[];
      if (array) {
        setProductList(array);
      }
    }
  };

  const getServices = async () => {
    let result = await getUserDetails();
    if (result) {
      let resultJson = JSON.parse(result as unknown as string);
      if (resultJson.availableServices) {
        setAvailableServices(resultJson.availableServices as Service[]);
      }
    }
  };

  const calculateTotalValue = () => {
    // eslint-disable-next-line
    let total = 0;
    // eslint-disable-next-line
    productList.map((el) => {
      if (el.balance) {
        total += el.balance;
      }
    });
    // setTotalValue(total);
  };

  const clearCart = () => {
    setProductList([]);
    deleteCart();
    setUserPackDTO([]);
  };

  const confirmDeleteProduct = (product) => {
    setDeleteDialog(true);
    setDeleteConfig(product.id);
  };

  const deleteProduct = () => {
    const result = getCart();
    if (result) {
      let array = JSON.parse(result) as PackProductDTO[];
      let index = array.findIndex((el) => el.id === deleteConfig);
      if (index !== -1) {
        array.splice(index, 1);
        setProductList(array);
        storeCart(array);
      }
      mountPack();
    }
    setDeleteDialog(false);
  };

  const editProduct = (product) => {
    setActiveProduct(product);
    modalHandler(product.serviceId, true);
    mountPack();
  };

  const editItinerary = (product) => {
    setActiveProduct(product);
    modalHandler(7, true);
  };

  const contact = (product) => {
    setActiveProduct(product);
    modalHandler(8, true);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    getPacks();
    mountPack();
  };

  const handleSaveRodinhas = (product) => {
    editItinerary(product);
  }

  const modalHandler = (serviceId: number, show: boolean) => {
    
    modalShow[serviceId] = show;
    setModalShow({ ...modalShow });
  };

  const closeButton = (serviceId) => {
    modalHandler(serviceId, false);
    setActiveProduct(null);
    if (serviceId !== 4){

      handleClose();
    }
  };

  const payment = async () => {
      let userRoles = getUserRoles();
      if (userRoles.includes('ROLE_PARTICULAR_USER')) {
        totalAuthorize();
      }
      if (userRoles.includes('ROLE_CORPORATE_USER')) {
        corporatePayment();
      }
    }

  const corporatePayment = async () => {
    // setIsLoading(true);
    try {
      if (userPackDTO) {
        const response = await subscriptionService.createPack(userPackDTO);
        if (response.newResourceId) {
          await subscriptionService.paymentToSubscription({
            checkoutId: null,
            listPack: response.newResourceId,
          });
          deletePackPay();
          deleteCart();
          stopProcess();
          // setIsLoading(false);
          alert(
            'Sucesso',
            'A sua compra foi adicionada com sucesso',
            true,
            '/usercorporate/subscriptions'
          );
        } else {
          // setIsLoading(false);
          alert('Erro', response.message, false, '');
        }
      } else {
        // setIsLoading(false);
        alert('Erro', 'Erro no pagamento', false, '');
      }
    } catch (error) {
      // setIsLoading(false);
    }
  };

  const totalAuthorize = async () => {
    try {
      setPaymentDetails({
        ...paymentDetails,
        valuePayment: valueToPayment.value,
      });
      const resultPayment = await subscriptionService.pay({
        ...paymentDetails,
        valuePayment: valueToPayment.value,
      });
      storeAuthPay(resultPayment);
      storePackToPay(userPackDTO);
    } catch (error) {
    } finally {
      history.push('/cart/payment');
    }
  };

  const subscribe = async () => {
    let services = verifyServices();
    let userRoles = getUserRoles();

    //PARTICULAR USER FLOW
    if (userRoles.includes('ROLE_PARTICULAR_USER')) {
      if (
        services.includes(1) ||
        services.includes(2) ||
        services.includes(3)
      ) {
        let isValid = await validateProfile(true);
        if (isValid) {
          if (services.includes(1)) {
            let validDocs = await validateDocuments();

            if (!validDocs) {
              alert(
                'Documentos',
                'Faltam documentos no seu perfil.',
                true,
                '/user/documents'
              );
              return;
            }
          }
          if (services.includes(4)) {
            let validItinerary = validateRodinhasItinerary();
            if (!validItinerary) {
              alert(
                'Rodinhas',
                'Faltam informações de itinerários de Rodinhas.',
                false,
                ''
              );
              return;
            }
          }

          if (services.includes(1) || services.includes(4)) {
            approvalMessage(services);
            return;
          } else {
            payment();
          }
        } else {
          alert(
            'Informações',
            'Faltam informações no seu perfil.',
            true,
            '/profile'
          );
        }
      } else {
        let isValid = await validateProfile(false);
        if (isValid) {
          if (services.includes(4)) {
            let validItinerary = validateRodinhasItinerary();
            if (!validItinerary) {
              alert(
                'Rodinhas',
                'Faltam informações de itinerários de Rodinhas.',
                false,
                ''
              );
              return;
            }
          }
          payment();
        } else {
          alert(
            'Informações',
            'Faltam informações no seu perfil.',
            true,
            '/profile'
          );
        }
      }
    }

    if (userRoles.includes('ROLE_CORPORATE_USER')) {
      if (
        services.includes(1) ||
        services.includes(2) ||
        services.includes(3)
      ) {
        let isValid = await validateProfile(true);
        if (!isValid) {
          alert(
            'Informações',
            'Faltam informações no seu perfil.',
            true,
            '/profile'
          );
          return;
        }
      } else {
        let isValid = await validateProfile(false);
        if (!isValid) {
          alert(
            'Informações',
            'Faltam informações no seu perfil.',
            true,
            '/profile'
          );
          return;
        }
      }

      if (services.includes(4)) {
        let validItinerary = validateRodinhasItinerary();
        if (!validItinerary) {
          alert(
            'Rodinhas',
            'Faltam informações de itinerários de Rodinhas.',
            false,
            ''
          );
          return;
        }
      }
      payment();
    }
  };

  const verifyServices = () => {
    startProcess();
    const result = getCart();
    if (result) {
      let services = [] as number[];
      let array = JSON.parse(result) as PackProductDTO[];
      array.map((el) => {
        if (el.serviceId && !services.includes(el.serviceId)) {
          services.push(el.serviceId);
        }
      });
      return services;
    } else {
      return [];
    }
  };

  const validateProfile = async (complete: boolean) => {
    const result = await AuthService.getUserFromSubscription(true);
    if (result) {
      if (
        result.address !== '' &&
        result.address !== undefined &&
        result.address !== null &&
        result.country !== '' &&
        result.country !== undefined &&
        result.country !== null &&
        result.countryCode !== '' &&
        result.countryCode !== undefined &&
        result.countryCode !== null &&
        result.dateBirth !== '' &&
        result.dateBirth !== undefined &&
        result.dateBirth !== null &&
        result.district !== '' &&
        result.district !== undefined &&
        result.district !== null &&
        result.documentExpiryDate !== '' &&
        result.documentExpiryDate !== undefined &&
        result.documentExpiryDate !== null &&
        result.email !== '' &&
        result.email !== undefined &&
        result.email !== null &&
        result.identificationCard !== '' &&
        result.identificationCard !== undefined &&
        result.identificationCard !== null &&
        result.name !== '' &&
        result.name !== undefined &&
        result.name !== null &&
        result.nif !== '' &&
        result.nif !== undefined &&
        result.nif !== null &&
        result.phoneNumber !== '' &&
        result.phoneNumber !== undefined &&
        result.phoneNumber !== null &&
        result.postalCode !== '' &&
        result.postalCode !== undefined &&
        result.postalCode !== null &&
        result.region !== '' &&
        result.region !== undefined &&
        result.region !== null
      ) {
        if (!complete) {
          return true;
        } else {
          if (
            result.driverLicense !== '' &&
            result.driverLicense !== undefined &&
            result.driverLicense !== null &&
            result.driverLicenseExpiryDate !== '' &&
            result.driverLicenseExpiryDate !== undefined &&
            result.driverLicenseExpiryDate !== null &&
            result.driverLicenseIssuingCountry !== '' &&
            result.driverLicenseIssuingCountry !== undefined &&
            result.driverLicenseIssuingCountry !== null
          ) {
            return true;
          } else {
            return false;
          }
        }
      } else {
        return false;
      }
    }
    return false;
  };

  const validateDocuments = async () => {
    const documents = await documentService.getAllDocuments();
    const userDocuments = await documentService.getUserDocuments();
    if (documents && userDocuments) {
      return documents.length === userDocuments.length;
    }

    return false;
  };

  const validateRodinhasItinerary = () => {
    const result = getCart();
    if (result) {
      let ready = true;
      let array = JSON.parse(result) as PackProductDTO[];
      array.map((el) => {
        if (el.serviceId === 4) {
          if (
            el.packItineraryDTO === undefined ||
            el.packItineraryDTO.length === 0
          ) {
            ready = false;
          }
        }
      });
      return ready;
    } else {
      return true;
    }
  };

  const [valueToPayment, setValueToPayment] = useState({
    valueToServicePayment: [
      {
        productId: 0,
        serviceId: 0,
        productName: '',
        valueWithoutDiscount: 0,
        value: 0,
      },
    ],
    valueWithoutDiscount: 0,
    value: 0,
    hasDiscount: false,
  });
  
  useEffect(() => {
    if (userPackDTO.length > 0) {
      calculateValueToPayment();
    } else {
      setValueToPayment({
        valueToServicePayment: [
          {
            productId: 0,
            serviceId: 0,
            productName: '',
            valueWithoutDiscount: 0,
            value: 0,
          },
        ],
        valueWithoutDiscount: 0,
        value: 0,
        hasDiscount: false,
      }
      );
    }
  }, [userPackDTO]);


  const calculateValueToPayment = async () => {
    const result = await subscriptionService.calculateValue(userPackDTO);
    if (!result.error) {
      setValueToPayment(result);
    } else {
      alert('Erro', result.message, true, '/cart');
    }
  };

  const approvalMessage = async (services) => {
    const result = await subscriptionService.getMessages({
      serviceList: services,
      messageType: ['APPROVAL_NOTICE'],
    });
    if (result && result[0]) {
      alert(result[0].title, result[0].message, true, '/cart/authorize');
    }
  };

  const alert = (
    title: string,
    message: string,
    redirect: boolean,
    redirectURL?: string
  ) => {
    setAlertConfig({
      title: title,
      message: message,
      redirect: redirect,
      redirectURL: redirectURL ? redirectURL : '',
    });
    setAlertDialog(true);
  };

  return (
    <>
      <Title>Carrinho</Title>

      <Content>
        <Container fluid className='card-no-tabs content_card user_cart'>
          <Row>
            <Col md={8}>
              <h1>Resumo</h1>
              <p>
                Escolha os produtos e adicione ao seu carrinho.
              </p>
            </Col>
            <Col>
              <Button
                variant='outline-info'
                size='lg'
                className='float-right button_add'
                data-testid='newClientButton'
                onClick={handleClick}
              >
                <FontAwesomeIcon icon={faPlus} />
              </Button>
              <Menu
                id='simple-menu'
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                {availableServices.map((el) => {
                  //Remove this validation when Bedriven is replaced by Bolt
                  if (el.serviceDescription !== 'Bedriven') {
                    return (
                      <MenuItem
                        onClick={() => modalHandler(el.serviceID, true)}
                        key={el.serviceID}
                      >
                        {el.serviceDescription}
                      </MenuItem>
                    );
                  }
                })}

                {/*  <MenuItem onClick={() => modalHandler('guerin', true)}>
                  Guerin
                </MenuItem>
                <MenuItem onClick={() => modalHandler('xtracars', true)}>
                  Xtracars
                </MenuItem>
                <MenuItem onClick={() => modalHandler('rodinhas', true)}>
                  Rodinhas
                </MenuItem> */}
              </Menu>
            </Col>
          </Row>
          <Row className='mt-4'>
            {productList &&
              productList.length > 0 &&
              productList.map((el, index) => {
                return (
                  <Col
                    md={12}
                    className='d-flex justify-content-center align-middle'
                    key={index}
                  >
                    <ProductCard
                      key={el.id}
                      product={el}
                      deleteProduct={(p) => confirmDeleteProduct(p)}
                      editProduct={(p) => editProduct(p)}
                      editItinerary={(p) => editItinerary(p)}
                      contact={(p) => contact(p)}
                    ></ProductCard>
                  </Col>
                );
              })}
          </Row>
          {productList && productList.length === 0 && (
            <Row className='d-flex justify-content-center'>
              <p className='text_empty_cart'>Sem produtos no carrinho</p>
            </Row>
          )}
          <Container fluid className='cart_authorize'>
          <div>
            {valueToPayment && valueToPayment.hasDiscount && (
              <Row>
                <Col md={5} xs={12} className='discount_card'>
                  <Row className='d-flex align-content-center'>
                    <p>
                      Valor sem Desconto
                      <span className='value'>
                      {toFixed(valueToPayment.valueWithoutDiscount, 2)}€
                      </span>
                    </p>
                  </Row>
                  <Row>
                    <p>
                      Valor com desconto
                      <span className='value discount'>
                        {toFixed(valueToPayment.value, 2)}€
                      </span>
                    </p>
                  </Row>
                </Col>
                {<Col className='products_card'>
                  {valueToPayment !== undefined &&
                    valueToPayment.valueToServicePayment !== undefined &&
                    valueToPayment.valueToServicePayment.length > 0 &&
                    valueToPayment.valueToServicePayment.map((el, index) => {
                      return (
                        <Row
                          className='d-flex justify-content-end mr-2'
                          key={el.productId}
                        >
                          <Col className='d-flex justify-content-start'>
                            <p className='product_name mr-4'>
                              {el.productName}
                            </p>
                          </Col>
                          <Col className='d-flex justify-content-end'>
                            <h1>
                              <span className='dashed mr-2'>
                                {toFixed(el.valueWithoutDiscount, 2)}€
                              </span>
                            </h1>
                            <h1>{toFixed(el.value, 2)}€</h1>
                          </Col>
                        </Row>
                      );
                    })}
                </Col>}
              </Row>
            )}
          </div>
          </Container>
          <Row className='justify-content-center'>
            <Col md={8} className='pack_value'>
              <Row>
                <Col md={3}>Valor total da subscrição</Col>
                <Col></Col>
                <Col md={3}>
                  <Row className='justify-content-end mr-1'>
                    {toFixed(valueToPayment.value, 2)}€ 
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          {showLimitedOffer(productList) && (
            <Row >
            <Col className='d-flex justify-content-center mt-2 mb-2 footNote'>
              Oferta limitada ao stock existente*
            </Col>
          </Row>
          )}

          <Row className='justify-content-center mt-4'>
            <Button
              className='subscription_button'
              disabled={productList.length === 0}
              onClick={() => subscribe()}
            >
              SUBSCREVER
            </Button>
          </Row>
          <Row className='justify-content-center mt-4'>
            <p className='text_clean_cart' onClick={() => clearCart()}>
              limpar carrinho
            </p>
          </Row>
        </Container>
      </Content>

      <ModalFinlog
        open={modalShow[1]}
        onClose={handleClose}
        closeButton={closeButton}
        product={activeProduct}
      />
      <ModalGuerin
        open={modalShow[2]}
        onClose={handleClose}
        closeButton={closeButton}
        product={activeProduct}
      />
      <ModalXtracars
        open={modalShow[3]}
        onClose={handleClose}
        closeButton={closeButton}
        product={activeProduct}
      />
      <ModalRodinhas
        open={modalShow[4]}
        onClose={handleClose}
        closeButton={closeButton}
        product={activeProduct}
        onSave={handleSaveRodinhas}
      />
      <ModalContact
        open={modalShow[8]}
        onClose={handleClose}
        closeButton={closeButton}
        product={activeProduct}
      />

      <ModalRodinhasItinerary
        open={modalShow[7]}
        onClose={handleClose}
        closeButton={closeButton}
        product={activeProduct}
      />

      <Dialog
        open={alertDialog}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>{alertConfig.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            {alertConfig.message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAlertDialog(false)} color='primary'>
            Cancelar
          </Button>
          <Button
            onClick={() => {
              if (alertConfig.redirect) {
                history.push(alertConfig.redirectURL);
              }
              setAlertDialog(false);
            }}
            color='primary'
            autoFocus
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={deleteDialog}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>Eliminar produto</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Deseja remover este produto do seu carrinho?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialog(false)} color='primary'>
            Cancelar
          </Button>
          <Button onClick={deleteProduct} color='primary' autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Cart;

import React, { useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHome,
  faBox,
  faUser,
  faUsers,
  faFileInvoiceDollar,
  faFile,
  faComments,
  faBoxOpen,
  faShoppingCart,
  faPercentage,
  faTools,
} from '@fortawesome/free-solid-svg-icons';
import { AuthService } from '../../services/auth.service';
import { getUserDetails } from '../../redux/sessionReducer';
import { KEY_DETAILS } from '../../constants/config';

const Navbar: React.FC = () => {
  const location = useLocation();
  const [menu, setMenu] = useState({
    menus: [{ name: '', iconClass: '', route: '' }],
  });
  const [user, setUser] = useState({} as UserDetails);

  useEffect(() => {
    populateMenu();
    getProfile();
  }, []);

  const populateMenu = async () => {
    const result = await AuthService.getMenu();
    setMenu(result);
  };

  const getProfile = async () => {
    let result = await getUserDetails();
    if (result) {
      result = JSON.parse(result as unknown as string);
      setUser(result as unknown as UserDetails);
    }
  };

  const getIcon = (icon) => {
    switch (icon) {
      case 'faHome':
        return faHome;
      case 'faBox':
        return faBox;
      case 'faUser':
        return faUser;
      case 'faUsers':
        return faUsers;
      case 'faFileInvoiceDollar':
        return faFileInvoiceDollar;
      case 'faFile':
        return faFile;
      case 'faComments':
        return faComments;
      case 'faBoxOpen':
        return faBoxOpen;
      case 'faShoppingCart':
        return faShoppingCart;
      case 'faTools':
        return faTools;
      case 'faPercentage':
        return faPercentage;
      default:
        return faUser;
    }
  };

  return (
    <div className='navbar_component'>
      <Wrapper>
        <div className='avatar'>
          {user && user.imageUrl && (
            <div className='image-cropper'>
              <img src={user.imageUrl} alt='Profile' className='' />
            </div>
          )}
          {!user.imageUrl && (
            <img
              src={require('../../assets/avatar/profile.png')}
              alt='Profile'
            />
          )}
        </div>
        <div className='data-user'>
          <h2>{user && user.name ? user.name : 'Nome'}</h2>
          <h3>{user && user.email ? user.email : 'email@email.com'}</h3>
        </div>
        <div className='menu'>
          <ul>
            {menu.menus &&
              menu.menus.map((item) => {
                return (
                  <li
                    className={
                      location.pathname.includes(item.route) ? 'actv-menu' : ''
                    }
                    key={item.name}
                  >
                    <NavLink to={item.route ?? '/gm'} activeClassName='active'>
                      <Row className='menu-info'>
                        <Col lg={1}>
                          <FontAwesomeIcon
                            size='1x'
                            icon={getIcon(item.iconClass)}
                            color='#000'
                          />
                        </Col>
                        <Col className='menu-label'>{item.name}</Col>
                      </Row>
                    </NavLink>
                  </li>
                );
              })}
          </ul>
        </div>
      </Wrapper>
    </div>
  );
};
interface UserDetails {
  country: string;
  district: string;
  email: string;
  imageUrl: string;
  name: string;
}

const Wrapper = styled.nav`
  display: flex;
  flex-direction: column;
  padding: 0 8%;

  .avatar {
    text-align: center;
    padding: 20px 0;
  }

  .data-user {
    text-align: center;
    padding-bottom: 20px;

    h2 {
      font-size: 18px;
      font-weight: bold;
    }

    h3 {
      font-size: 12px;
      color: #878787;
    }
  }

  .menu {
    .active {
      font-weight: bold;
      color: #000;
    }

    .actv-menu {
      background: rgb(255, 255, 255);
      background: radial-gradient(
        circle,
        rgba(255, 255, 255, 1) 0%,
        rgba(210, 243, 253, 1) 61%
      );
      border-top-left-radius: 25px;
      border-bottom-left-radius: 25px;

      &:before,
      &:after {
        content: '';
        opacity: 1 !important;
        position: absolute;
        width: 30px;
        height: 36px;
        right: 14px;
        bottom: -36px;
        border-top-right-radius: 20px;
        opacity: 0;
        box-shadow: 0 -20px 0 0 rgb(210, 242, 253);
        z-index: -1;
      }
      &:after {
        top: -36px;
        bottom: initial;
        box-shadow: 0 20px 0 0 rgb(210, 242, 253);
        border-top-right-radius: initial;
        border-bottom-right-radius: 20px;
      }
    }

    a {
      color: #000;
      text-decoration: none;
    }

    ul {
      list-style-type: none;
      width: 110%;
      margin: 0;
      padding: 0;
      position: relative;
      left: 30px;
    }

    li {
      padding: 15px 0;
      height: 100%;
      text-indent: 20px;
      position: relative;
      z-index: 0;

      &:before,
      &:after {
        content: '';
        opacity: 0;
        position: absolute;
        width: 30px;
        height: 36px;
        right: 14px;
        bottom: -36px;
        border-top-right-radius: 20px;
        opacity: 0;
        box-shadow: 0 -20px 0 0 rgb(210, 242, 253);
        z-index: -1;
      }
      &:after {
        top: -36px;
        bottom: initial;
        box-shadow: 0 20px 0 0 rgb(210, 242, 253);
        border-top-right-radius: initial;
        border-bottom-right-radius: 20px;
      }
    }
  }

  @media (max-width: 575px) {
    padding: 0 16px;

    .avatar{
      display: none;
    }

    .menu {
      overflow-x: auto;
      overflow-y: hidden;

      ul {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        left: 0;
        width: 100%;
        justify-content: space-between;
        li {
          text-indent: 0;
        }
        .actv-menu {
          &::before,
          &:after {
            content: unset;
          }

          border-radius: 25px;
        }
        .menu-info {
          display: flex;
          justify-content: center;
          text-align: center;
          margin: 0;

          div {
            padding: 0;
          }
        }
        .menu-label {
          display: none;
          word-break: break-word;
          width: 75px;
          font-size: 0.75rem;
        }

        .active {
          .menu-label {
            display: inline-block;
          }
        }
      }
    }
  }
`;

export default Navbar;

import styled from 'styled-components';

const Styles = {
  button: {
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow: '0px 5px 20px #1A84C64C',
    borderRadius: '25px',
    opacity: '1',
    width: '83px',
    height: '78px',
    marginRight: '20%',
    marginTop: '15px',
  },

  searchRow: {
    margin: '47px 0 60px 0',
  },

  searchCol: {
    width: '337px',
    height: '44px',
  },

  searchIcon: {
    width: '21px',
    height: '21px',
    background: 'transparent 0% 0% no-repeat padding-box',
    opacity: 1,
    cursor: 'pointer',
  },

  clientTable: {
    margin: '0 5% 0 5%',
  },

  clientTableRow: {
    display: 'flex',
    alignItems: 'center',
  },

  clientRowBold: {
    textAlign: 'left',
    font: 'normal normal bold 16px/21px Roboto',
    letterSpacing: '0px',
    color: '#000000',
    opacity: '1',
    paddingLeft: '0px',
  },

  clientRowGray: {
    textAlign: 'left',
    font: 'normal normal 300 14px/19px Roboto',
    letterSpacing: '0px',
    color: '#878787',
    opacity: '1',
    paddingLeft: '0px',
  },

  inactiveBadge: {
    background: '#FFCC00 0% 0% no-repeat padding-box',
    border: '1px solid #FFE277',
    borderRadius: '15px',
    opacity: 1,
    width: '95px',
    height: '29px',

    textAlign: 'center',
    font: 'normal normal bold 14px/19px Roboto',
    letterSpacing: '0px',
    color: '#FFFFFF',
    textShadow: '0px 3px 6px #00000029',
  },

  activeBadge: {
    background: '#22CC98 0% 0% no-repeat padding-box',
    border: '1px solid #06FFB3',
    borderRadius: '15px',
    opacity: 1,
    width: '95px',
    height: '29px',

    textAlign: 'center',
    font: 'normal normal bold 14px/19px Roboto',
    letterSpacing: '0px',
    color: '#FFFFFF',
    textShadow: '0px 3px 6px #00000029',
  },

  canceledBadge: {
    background: '#DF0000 0% 0% no-repeat padding-box',
    border: '1px solid #FFBCBC',
    borderRadius: '15px',
    opacity: 1,
    width: '95px',
    height: '29px',

    textAlign: 'center',
    font: 'normal normal bold 14px/19px Roboto',
    letterSpacing: '0px',
    color: '#FFFFFF',
    textShadow: '0px 3px 6px #00000029',
  },

  pageItem: {
    font: 'normal normal normal 18px/22px Roboto',
    letterSpacing: '0px',
    color: '#2292CC',
    opacity: 1,
  },

  clientPageBack: {
    textAlign: 'center',
    font: 'normal normal normal 18px/22px Roboto',
    letterSpacing: '0px',
    color: '#878787',
    opacity: 1,
    marginLeft: '82.5px',
    marginTop: '51px',
  },

  clientPageNameRow: {
    textAlign: 'left',
    font: 'normal normal bold 26px/34px Roboto',
    letterSpacing: '0px',
    color: '#2292CC',
    opacity: 1,
    marginLeft: '82.5px',
  },

  clientPageDescription: {
    textAlign: 'left',
    font: 'normal normal normal 16px/19px Roboto',
    letterSpacing: '0px',
    color: '#1E1E1E',
    opacity: 1,
    margin: '15.99px 0 61.64px 82.5px',
  },

  clientPageDivision: {
    margin: '0 40px 0 40px',
  },

  clientPageDivisionHeader: {
    margin: '0 0 0 95.5px',
  },

  okButton: {
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow: '0px 5px 20px #1A84C64C',
    borderRadius: '33px',
    opacity: 1,
    width: '293px',
    height: '66px',
    color: '#2292CC',

    '&:hover': {
      background: '#2292CC 0% 0% no-repeat padding-box',
      boxShadow: '0px 5px 20px #1A84C64C',
      borderRadius: '33px',
      opacity: 1,
    },
  },
};

const Title = styled.h1`
  font: normal normal bold 30px/39px Roboto;
  text-align: left;
  letter-spacing: 0px;
  color: #3b3b3b;
  opacity: 1;
  padding: 20px 0 50px 16px;
`;

const Content = styled.div`
  height: auto;
  background: #d2f2fd;
  border-radius: 40px;
  padding: 20px;
  min-height: 27vw;

  .page-item.active .page-link {
    background-color: #2292cc;
  }

  .back {
    color: #878787;
    text-decoration: underline;
    cursor: pointer;
  }

  .back2 {
    color: #878787;
    text-decoration: underline;
    cursor: pointer;
  }

  .back-btn {
    padding: 20px 0;
  }

  .clickable {
    cursor: pointer;
  }

  .table {
    .font-light {
      font-weight: 300;
    }

    .wrapper-product {
      display: flex;

      .item-data {
        max-width: 350px;
      }

      .item {
        line-height: 0;
        text-indent: 20px;

        img {
          width: 80px;
        }

        .title {
          font-weight: bold;
          padding-top: 10px;
        }

        .description {
          font-weight: 300;
          white-space: normal;
          line-height: 1;
          text-indent: 0;
          margin: 0 0 0 5px;
        }
      }
    }

    .actions {
      color: #00000029;
      display: flex;
      justify-content: center;
      align-items: center;

      .wrapper {
        padding: 0 10px;
        margin: 0;
        text-align: center;
      }

      .change-status {
        color: #000;
      }

      svg {
        cursor: pointer;
      }

      .edit:hover {
        color: #2292cc;
      }

      .text-edit {
        color: #2292cc;
        font-weight: bold;
      }

      .text-remove {
        color: #ff0000;
        font-weight: bold;
      }

      .remove:hover {
        color: #ff0000;
      }
    }

    .edit {
      line-weight: 0;

      .creation {
        font-weight: bold;
      }
    }
  }

  .header-tab {
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
    border: 0;
    padding: 20px;
    width: 50%;
    outline: none;
    box-shadow: 0px -5px 5px 0px rgba(219, 219, 219, 0.1);
    background: white;
    backdrop-filter: blur(20px);
    filter: blur(20px);
    cursor: default;
  }

  .title-pack {
    text-align: right;
    position: absolute;
    font-size: 19px;
    cursor: pointer;
    font-weight: bold;
    right: 234px;
    top: 162px;
    color: #c5c5c5;
  }

  .title-products {
    position: absolute;
    top: 153px;
    left: 256px;
    font-size: 19px;
    font-weight: bold;
    color: #c5c5c5;
    cursor: pointer;
  }

  .active {
    backdrop-filter: none !important;
    filter: none !important;
  }

  .nav-tabs .nav-item.nav-link.active {
    text-align: center;
    color: red;
    text-decoration: underline;
    color: #2292cc;
    font-weight: bold;
    font-size: 18px;
    height: 67px;
  }

  .nav-tabs {
    color: red;
  }

  .content {
    background: #fff;
    border: 0;
    height: auto;
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
    border-bottom-left-radius: 40px;
    border-bottom-right-radius: 40px;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);

    .title {
      padding-left: 81px;

      text-align: left;
      font: normal normal bold 26px/34px Roboto;
      letter-spacing: 0px;
      color: #000000;
      opacity: 1;
    }

    .wrapper-title {
      width: 250px;
      border-bottom: 1px solid #2292cc;
      text-indent: 20px;
      position: relative;
      left: 60px;
      bottom: 30px;

      h2 {
        font-size: 22px;
        font-weight: bold;
        color: #2292cc;
      }
    }

    .wrapper-title-packs {
      width: 250px;
      border-bottom: 1px solid #2292cc;
      text-indent: 172px;
      position: absolute;
      top: 164px;
      right: 15%;

      h2 {
        font-size: 22px;
        font-weight: bold;
        color: #2292cc;
      }
    }

    .opacity {
      opacity: 0.4;
    }

    .wrapper {
      padding: 0 50px;

      .card {
        border: 0;
        border-bottom: 1px solid #000;

        .card-header {
          background: transparent;
          border-bottom: 1px solid #000;
        }

        .card-body {
          padding: 0;
        }
      }

      .pack {
        cursor: pointer;
        background: #000;
        position: relative;
        border-radius: 20px;
        box-shadow: 0 0 2px #000;
        min-height: 200px;
        text-align: center;
        max-height: 265px;
        width: 202px;

        .badge {
          position: relative;
          bottom: 14px;
        }

        .wrapper {
          color: #fff;
          padding: 50px 20px;

          h5 {
            color: #00aeef;
          }

          h4 {
            color: #fff;
          }

          .big-text {
            font-weight: bold;
            font-size: 30px;
          }

          .social-networks {
            display: flex;
            justify-content: center;
            border-top: 1px solid gray;
            border-bottom: 1px solid gray;

            .icon-service-1 {
              color: #00aeef;
            }

            .icon-icon-service-2 {
              color: #6dab24;
            }

            .icon-service-5 {
              color: #1a84c6;
            }

            .icon-service-3 {
              color: #ffca00;
            }

            .icon-service-4 {
              color: #d8001a;
            }
          }
        }
      }

      .add-pack {
        justify-content: flex-end;
        flex-direction: column;
        align-items: flex-end;

        p {
          font-weight: bold;
          font-size: 12px;
          position: relative;
          left: 20px;
          top: 10px;
          color: #2292cc;
        }
      }
    }
  }

  .left-corner {
    border-top-left-radius: 40px;
  }

  .right-corner {
    border-top-right-radius: 40px;
  }

  @media screen and (max-width: 575px){
    .card-no-tabs{
      display: grid;
      gap: 16px;

      .row{
        margin: 0;
        .new-group-button{
          margin: 0 !important;
        }
      }
      .container_group_card{
        margin: 0;
      }
    }
  }
`;

export { Styles, Title, Content };

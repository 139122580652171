//USER
import Cart from '../screens/Cart/Cart';
import Authorize from '../screens/Cart/Authorize';
import Payment from '../screens/Cart/Payment';

const routes: MyRoute[] = [
  {
    path: '/cart',
    exact: true,
    component: Cart,
    role: 'ROLE_USER_CART',
    auth: true,
  },
  {
    path: '/cart/authorize',
    exact: true,
    component: Authorize,
    role: 'ROLE_USER_CART',
    auth: true,
  },
  {
    path: '/cart/payment',
    exact: true,
    component: Payment,
    role: 'ROLE_USER_CART',
    auth: true,
  },
];

interface MyRoute {
  path: string;
  exact: boolean;
  component: React.FC<{}>;
  role: string;
  auth: boolean;
}

export default routes;

import React, { useState, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';

import Content from '../../../style/GP/Content';
import Title from '../../../style/GP/Title';
import PackBadge from '../../../components/PackBadge';
import { packService } from '../../../services/packService';
import { toFixed } from '../../../utils/utils';
import { useHistory } from 'react-router-dom';
import { iCan } from '../../../redux/sessionReducer';
import { motion } from 'framer-motion';
import {
  animationInvisible,
  animationVariants,
  animationVisible,
} from 'src/components/Animations/animations';

const Packs: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [key, setKey] = useState('packs');
  const [packList, setPackList] = useState([] as any);
  const [packId, setPackId] = useState('');
  const [showAddPack, setShowAddPack] = useState(false);
  const history = useHistory();

  useEffect(() => {
    async function getPacks() {
      setIsLoading(true);

      try {
        const result = await packService.getAll();
        const removedPacks = handleDeleted(result.data);
        const handledVariants = handleVariantes(removedPacks);
        setPackList(handledVariants);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    }

    getPacks();
  }, []);

  const handleDeleted = (productList) => {
    return productList.filter((product) => !product.deleted);
  };

  const handleVariantes = (removedPacks: any) => {
    const handledPacks = [...removedPacks];

    handledPacks.forEach((pack) => {
      if (pack.packProductDTO) {
        const ids = pack.packProductDTO.map((dto) => dto.serviceId);

        pack['services'] = Array.from(new Set(ids));
      } else {
        const ids = [] as any;

        pack?.listPackChildren?.forEach((child) => {
          child?.packProductDTO?.forEach((dto) => ids.push(dto.serviceId));
        });

        pack['services'] = Array.from(new Set(ids));
      }
    });

    return handledPacks;
  };

  const openPack = (packId: string) => {
    history.push(`/packs/view/${packId}`);
  };

  return (
    <>
      <Title>Pacotes</Title>
      <Content>
        <div className='content left-corner right-corner'>
          <div className='wrapper'>
            <Col>
              <Row className='add-pack'>
                {iCan('ROLE_CREATE_PACK') && (
                  <>
                    <button
                      className='small-btn mt-5'
                      onClick={() => {
                        history.push('packs/create');
                      }}
                      onMouseEnter={() => setShowAddPack(true)}
                      onMouseLeave={() => setShowAddPack(false)}
                    >
                      +
                    </button>
                    <motion.div
                      initial={animationVisible}
                      animate={showAddPack ? 'visible' : 'invisible'}
                      exit={animationInvisible}
                      transition={{ duration: 0.3 }}
                      variants={animationVariants}
                    >
                      <p>CONFIGURAR PACOTE</p>
                    </motion.div>
                  </>
                )}
                {!iCan('ROLE_CREATE_PACK') && <div className='mt-5'></div>}
              </Row>

              <Row>
                <Col>
                  <h3>Lista de pacotes</h3>
                  <p>
                    Consulte e edite os pacotes de conjunto de serviços
                    configurados.
                  </p>
                </Col>
              </Row>
              <Row className='pt-5 hidden-xs'>
                {packList.map((pack: any) => {
                  return (
                    <Col
                      sm={12}
                      md={12}
                      lg={6}
                      xl={4}
                      onClick={() => openPack(pack.id)}
                      key={pack.id}
                      className='d-flex justify-content-center'
                    >
                      <div className='pack mb-5'>
                        <PackBadge badge={pack.active} />
                        <div
                          className={`wrapper ${!pack.active ? 'opacity' : ''}`}
                        >
                          <h5 className='text-center'>{pack.name}</h5>
                          <h4 className='text-center'>
                            <span className='big-text'>
                              {toFixed(pack.monthlyValue, 2)}€
                            </span>
                            /mês
                          </h4>
                          <div className='social-networks mt-4'>
                            {pack?.services?.length > 0 &&
                              pack.services.map((number, i) => {
                                return (
                                  <i
                                    className={`icon-service-${number}`}
                                    key={i}
                                  ></i>
                                );
                              })}
                          </div>
                          <p className='text-center mt-4'>
                            Pacote de {pack?.services?.length || '1'}
                            {pack?.services?.length == '1'
                              ? ' serviço'
                              : ' serviços'}
                          </p>
                        </div>
                      </div>
                    </Col>
                  );
                })}
              </Row>
            </Col>
          </div>
        </div>
      </Content>
    </>
  );
};
export default Packs;

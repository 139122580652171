import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
class RodinhasUploadSheet extends Component {
  constructor(props) {
    super(props);
  }

  handleNewFileUpload() {
    console.log("Click happened");
  }
  render() {
    return (
      <div>
        <Row className="description">
          <Col>
            <p>
              Faça upload do template e os dados de produto serão
              automaticamente inseridos. Caso ainda não tenha o ficheiro
              necessário, utilize o botão disponível para download.
            </p>
          </Col>
        </Row>
        <Row className="upload_container">
          <div className="upload_container__content">
            {this.props.file.name == "" && (
              <div>
                <img
                  src={require("../../../assets/icons/rodinhas/upload.svg")}
                />
                <p>Largue aqui o seu template preenchido ou</p>
                <a>carregue a partir do seu computador</a>
              </div>
            )}
            {this.props.file.name !== "" && (
              <div>
                <img
                  src={require("../../../assets/icons/rodinhas/uploaded.svg")}
                />
                <p>{this.props.file.name}</p>
              </div>
            )}
          </div>
          {this.props.file.name == "" && (
            <input
              type="file"
              ref={this.props.fileInputField}
              onChange={this.props.handleNewFileUpload}
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            />
          )}
        </Row>
      </div>
    );
  }
}

export default RodinhasUploadSheet;

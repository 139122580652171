export const userConstants = {
  REGISTER_REQUEST: "USERS_REGISTER_REQUEST",
  REGISTER_SUCCESS: "USERS_REGISTER_SUCCESS",
  REGISTER_FAILURE: "USERS_REGISTER_FAILURE",

  LOGIN: "LOGIN",
  LOGIN_SUCCESS: "USERS_LOGIN_SUCCESS",
  LOGIN_FAILURE: "USERS_LOGIN_FAILURE",

  LOGOUT: "LOGOUT"
};

export const USER_VIEW_PAGE_TITLE = 'Visualizar um utilizador';
export const COMMERCIAL_VIEW_PAGE_TITLE = 'Visualizar um comercial';

export const USER_PAGE = {
  DESCRIPTION:
    'Consulte e edite os dados do utilizador e informação do contrato.',
  COMPANY: 'Empresa',
  LOGO: 'Logótipo',
  NAME: 'Nome',
  NIF: 'NIF',
  CONTACT_INFO: 'Pessoa de contacto',
  FULLNAME: 'Nome completo',
  PROFILE: 'Perfil',
  EMAIL: 'E-mail',
  CONTACT: 'Contacto',
  CONTRACT: 'Contrato',
  OK_BUTTON_LABEL: 'OK',
  BACK: 'Voltar',
};

export const COMMERCIAL_PAGE = {
  DESCRIPTION:
    'Consulte os dados do comercial e informação do contrato.',
  COMPANY: 'Empresa',
  LOGO: 'Logótipo',
  NAME: 'Nome',
  NIF: 'NIF',
  CONTACT_INFO: 'Pessoa de contacto',
  FULLNAME: 'Nome completo',
  PROFILE: 'Perfil',
  EMAIL: 'E-mail',
  CONTACT: 'Contacto',
  CONTRACT: 'Contrato',
  OK_BUTTON_LABEL: 'OK',
  BACK: 'Voltar',
};

export const handleErrors = {
  getMessage,
};

function getMessage(result) {
  if (result.errors) {
    return result.errors[0]?.defaultMessage;
  }
  return result.message;
}

//USER
import Dashboard from '../screens/UserCorporate/Dashboard/Dashboard';
import Subscriptions from '../screens/UserCorporate/Subscriptions/Subscriptions';
import Help from '../screens/UserCorporate/Help/Help';
import Reservations from '../screens/UserCorporate/Reservations/Reservations';

const routes: MyRoute[] = [
  {
    path: '/usercorporate/dashboard',
    exact: true,
    component: Dashboard,
    role: 'ROLE_CORPORATE_USER',
    auth: true,
  },
  {
    path: '/usercorporate/subscriptions',
    exact: true,
    component: Subscriptions,
    role: 'ROLE_CORPORATE_USER',
    auth: true,
  },
  {
    path: '/usercorporate/reservations',
    exact: true,
    component: Reservations,
    role: 'ROLE_CORPORATE_USER',
    auth: true,
  },
  {
    path: '/usercorporate/help',
    exact: true,
    component: Help,
    role: 'ROLE_CORPORATE_USER',
    auth: true,
  },
];

interface MyRoute {
  path: string;
  exact: boolean;
  component: React.FC<{}>;
  role: string;
  auth: boolean;
}

export default routes;

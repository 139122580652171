import React, { useState, useEffect } from 'react';
import {
  Form,
  Container,
  Row,
  Col,
  Table,
  InputGroup,
  Button,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import { Title, Content, Styles } from '../../../style/content';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSearch,
  faUserCircle,
  faCheckCircle,
} from '@fortawesome/free-solid-svg-icons';
import { userService } from 'src/services/GM/userService';
import { SweetAlertType } from 'react-bootstrap-sweetalert/dist/types';
import { User, AlertConfig } from '../Users/users.interface';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';

const ServicesUser: React.FC = () => {
  const [searchFilter, setSearchFilter] = useState('');
  const [activePage, setActivePage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [filter, setFilter] = useState('');
  const [clientPage, setClientPage] = useState(null);
  const [users, setUsers] = useState({} as User[]);
  const [alertDialog, setAlertDialog] = useState(false);
  const [alertDialogConfig, setAlertDialogConfig] = useState({} as AlertConfig);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedUsersLenght, setSelectedUsersLenght] = useState(0);
  const history = useHistory();

  useEffect(() => {
    getUsersWithoutGroup();
  }, []);

  useEffect(() => {
    getUsersWithoutGroup(filter);
  }, [filter]);

  const continueAction = () => {
    let userIds = selectedUsers.join();
    history.push(`/gm/services/user/config?usersids=${userIds}`);
  };

  const checkFields = () => {
    if (selectedUsers.length > 0) {
      return true;
    }
    return false;
  };

  const initialName = (words, maxLenght) => {
    'use strict';

    return words.slice(0, maxLenght) + "...";
  };

  const filterClientsEnterClick = (event) => {
    //Enter key up
    if (event.keyCode === 13) {
      event.preventDefault();

      setActivePage(0);
      setFilter(searchFilter);
    } else {
      setSearchFilter(event.target.value);
    }
  };

  const filterClientsIconClick = () => {
    setActivePage(0);
    setFilter(searchFilter);
  };

  const getUsersWithoutGroup = async (filter = '') => {
    const result = await userService.getUsersWithoutGroup(filter);
    if (result) {
      setUsers(result);
    }
  };

  const updateSelectedUsers = (id: never) => {
    let newUsers = selectedUsers;
    if (newUsers.includes(id)) {
      let index = newUsers.indexOf(id);
      if (index !== -1) {
        newUsers.splice(index, 1);
      }
    } else {
      newUsers.push(id);
    }
    setSelectedUsers(newUsers);
    setSelectedUsersLenght(selectedUsers.length);
  };

  const selectAll = () => {
    console.log('select all');
    if (selectedUsers.length == users.length) {
      setSelectedUsers([]);
    } else {
      let array = [];
      users.forEach((item) => {
        array.push(item.id as never);
      });
      setSelectedUsers(array);
    }
  };

  const getUsersStatus = (status: any, statusDescription: any) => {
    switch (status) {
      case 'INACTIVE':
        return (
          <span style={{ backgroundColor: '#FFCC00' }}>
            {statusDescription}
          </span>
        );
      case 'ACTIVE':
        return (
          <span style={{ backgroundColor: '#22CC98' }}>
            {statusDescription}
          </span>
        );
      case 'CANCELED':
        return (
          <span style={{ backgroundColor: '#22CC98' }}>
            {statusDescription}
          </span>
        );
      default:
        return (
          <span style={{ backgroundColor: '#DF0000' }}>
            {statusDescription}
          </span>
        );
    }
  };

  const alert = (title: String, message: String, type: SweetAlertType, redirect: Boolean = false) => {
    setAlertDialog(true);
    setAlertDialogConfig({
      title: title,
      message: message,
      type: type,
      redirect: redirect,
    });
  };

  return (
    <>
      <Title>Gerir serviços</Title>

      <Content>
        <Container fluid className='card-no-tabs servicesGM_main service_user'>
          <Link className='color-back' to={'/gm/services/'}>
            <p>
              <u>&#60; Voltar</u>
            </p>
          </Link>
          <h1 className='title'>Escolha o utilizador</h1>
          <h3 className='subtitle'>
            Selecione o(s) utilizador(es) para os quais deseje configurar os
            serviços.
          </h3>
          <Row className='d-flex justify-content-center'>
            <div className='input-search'>
              <Col>
                <InputGroup>
                  <Form.Control
                    data-testid='searchBar'
                    type='text'
                    onKeyUp={(e) => filterClientsEnterClick(e)}
                    placeholder='Pesquise por email'
                  />
                  <div
                    className='search-icon'
                    data-testid='searchIcon'
                    onClick={(e) => filterClientsIconClick()}
                  >
                    <FontAwesomeIcon
                      style={Styles.searchIcon}
                      color='white'
                      icon={faSearch}
                    />
                  </div>
                </InputGroup>
              </Col>
            </div>
          </Row>
          <Table hover>
            <thead>
              <tr>
                <th>
                  <Form.Check
                    type={'checkbox'}
                    id={`checkbox-selectall`}
                    onChange={(e) => selectAll()}
                    checked={selectedUsers.length == users.length}
                  />
                </th>
                <th>Nome</th>
                <th>Serviços configurados</th>
                <th>Estado</th>
                <th>Adicionado</th>
              </tr>
            </thead>
            <tbody>
              {users &&
                users.length > 0 &&
                users.map((item) => {
                  return (
                    <tr key={item.email}>
                      <td
                        onClick={(e) => updateSelectedUsers(item.id as never)}
                      >
                        {selectedUsers.includes(item.id as never) && (
                          <FontAwesomeIcon
                            size='2x'
                            icon={faCheckCircle}
                            color='#2292CC'
                          />
                        )}
                        {!selectedUsers.includes(item.id as never) && (
                          <FontAwesomeIcon
                            size='2x'
                            icon={faUserCircle}
                            color='#878787'
                          />
                        )}
                      </td>
                      <td>
                        <Row style={{ fontSize: 14 }} >
                          <OverlayTrigger
                            key={"name-tooltiptop"}
                            placement="right"
                            overlay={
                              <Tooltip id={`tooltipName-top`}>
                                {item.name}
                              </Tooltip>
                            }>
                            <span style={{color: '#212529'}}>
                              {item.name.length > 30
                                ? initialName(item.name, 30)
                                : item.name}
                            </span>
                          </OverlayTrigger>
                        </Row>
                        <p className='email'>{item.email}</p>
                      </td>
                      <td>
                        {' '}
                        <div className='services_bar social-networks'>
                          {item.services?.map((item, i) => {
                            return (
                              <i
                                className={`icon-service-${item.serviceId}`}
                                key={i}
                              ></i>
                            );
                          })}
                          {item.services && item.services.length == 0 && (
                            <p>Sem serviços</p>
                          )}
                        </div>
                      </td>
                      <td>
                        {item.status
                          ? getUsersStatus(item.status, item.statusDescription)
                          : ''}
                      </td>
                      <td></td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
          <Col className='center'>
            <Button
              style={Styles.okButton}
              variant='outline-info'
              size='lg'
              onClick={() => continueAction()}
              disabled={!checkFields()}
            >
              CONTINUAR
            </Button>
          </Col>
        </Container>
      </Content>
    </>
  );
};

export default ServicesUser;

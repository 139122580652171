import React, { useState, useEffect } from 'react';
import { Form, Container, Row, Col, Image, Button } from 'react-bootstrap';
import PackCard from './Pack';
import SliderConfigurator from './slider_style';
import { configuratorService } from '../../services/configuratorService';
import { Pack } from './packs.interface';
import { SERVER_BACKOFFICE } from '../../constants/config';
import { setGoToCart } from '../../redux/sessionReducer';

const Configurator: React.FC = () => {
  //Guerin
  const [guerinState, setGuerinState] = useState(false);
  const [guerinSlider, setGuerinSlider] = useState(0);
  const [guerinSegment, setGuerinSegment] = useState(Number);

  //Rodinhas
  const [rodinhasState, setRodinhasState] = useState(false);
  const [rodinhasProduct, setRodinhasProduct] = useState(
    [] as RodinhasProduct[]
  );
  const [rodinhasRegions, setRodinhasRegions] = useState([] as string[]);
  const [rodinhasZones, setRodinhasZones] = useState([] as string[]);
  const [rodinhasSubZones, setRodinhasSubZones] = useState([] as string[]);
  const [rodinhasSubZonesDrop, setRodinhasSubZonesDrop] = useState(
    [] as string[]
  );
  const [rodinhasMaxChildrens, setRodinhasMaxChildrens] = useState(9);
  const [rodinhasMaxNumberPerWeek, setRodinhasMaxNumberPerWeek] = useState(10);
  const [rodinhasData, setRodinhasData] = useState({
    numberChildren: 0,
    region: '',
    pickupZone: '',
    pickupSubZone: '',
    dropoffZone: '',
    dropoffSubZone: '',
    numberPerWeek: 0,
    roundTrip: 'false',
  });

  //Finlog and Xtracars
  const [finlogXtracarsState, setFinlogXtracarsState] = useState(false);
  const [finlogXtracarsSlider, setFinlogXtracarsSlider] = useState(0);
  const [finlogXtracarsSegment, setFinlogXtracarsSegment] = useState(Number);

  //Bedriven
  const [bedrivenState, setBedrivenState] = useState(false);
  const [bedrivenSlider, setBedrivenSlider] = useState(1);

  const [segments, setSegments] = useState([] as Segment[]);
  const [packList, setPackList] = useState([]);
  const [showPacks, setShowPacks] = useState(false);

  useEffect(() => {
    getSegments();
    getRodinhasProduct();
  }, []);

  useEffect(() => {
    getRegions();
  }, [rodinhasProduct]);

  const getSegments = async () => {
    try {
      const resultSegments =
        await configuratorService.getGuerinXtracarsSegments();
      if (resultSegments) {
        setSegments(resultSegments);
      }
    } catch (error) {
      console.log(error);
    } finally {
    }
  };

  const getRodinhasProduct = async () => {
    try {
      const product = await configuratorService.getRodinhasProduct();
      if (product) {
        setRodinhasProduct(product.zones);
        setRodinhasMaxNumberPerWeek(product.maxNumberTime);
        setRodinhasMaxChildrens(product.maxChild);
      }
    } catch (error) {
      console.log(error);
    } finally {
    }
  };

  function getRegions() {
    if (rodinhasProduct) {
      if (rodinhasProduct.length > 0) {
        let regions = [] as string[];
        rodinhasProduct.map((item) => {
          if (!regions.includes(item.region)) {
            regions.push(item.region);
          }
        });
        setRodinhasRegions(regions);
      }
    }
  }

  function getZones(region) {
    if (rodinhasProduct) {
      if (rodinhasProduct.length > 0) {
        let zones = [] as string[];
        rodinhasProduct.map((item) => {
          if (item.region === region) {
            if (!zones.includes(item.zone)) {
              zones.push(item.zone);
            }
          }
        });
        setRodinhasZones(zones);
      }
    }
  }

  function getSubZones(zone, type) {
    if (rodinhasProduct.length > 0) {
      let subZones = [] as string[];
      rodinhasProduct.map((item) => {
        if (item.zone === zone) {
          if (!subZones.includes(item.subZone)) {
            subZones.push(item.subZone);
          }
        }
      });
      if (type == 'pickup') {
        setRodinhasSubZones(subZones);
      }

      if (type == 'dropoff') {
        setRodinhasSubZonesDrop(subZones);
      }
    }
  }

  const searchPacks = async () => {
    try {
      let filter = `active=true&deleted=false&finlog=${finlogXtracarsState}&guerin=${guerinState}&xtracars=${finlogXtracarsState}&rodinhas=${rodinhasState}&bedriven=${bedrivenState}`;

      if (finlogXtracarsState) {
        filter += `${
          finlogXtracarsSegment ? `&carCategory=${finlogXtracarsSegment}` : ''
        }${
          finlogXtracarsSlider > 0
            ? `&monthlyPeriod=${finlogXtracarsSlider}`
            : ''
        }`;
      }

      if (rodinhasState) {
        filter += `${
          rodinhasData.numberChildren > 0
            ? `&numberChildren=${rodinhasData.numberChildren}`
            : ''
        }${
          rodinhasData.numberPerWeek > 0
            ? `&numberPerWeek=${rodinhasData.numberPerWeek}`
            : ''
        }${rodinhasData.region !== '' ? `&region=${rodinhasData.region}` : ''}${
          rodinhasData.pickupZone !== ''
            ? `&pickupZone=${rodinhasData.pickupZone}`
            : ''
        }${
          rodinhasData.pickupSubZone !== ''
            ? `&pickupSubZone=${rodinhasData.pickupSubZone}`
            : ''
        }${
          rodinhasData.dropoffZone !== ''
            ? `&pickupZone=${rodinhasData.dropoffZone}`
            : ''
        }${
          rodinhasData.dropoffSubZone !== ''
            ? `&dropoffSubZone=${rodinhasData.dropoffSubZone}`
            : ''
        }${
          rodinhasData.roundTrip !== ''
            ? `&roundTrip=${rodinhasData.roundTrip}`
            : ''
        }`;
      }

      if (guerinState) {
        filter += `${guerinSlider ? `&dailyRentPerMonth=${guerinSlider}` : ''}${
          guerinSegment ? `&dailyRentCarCategory=${guerinSegment}` : ''
        }`;
      }

      const result = await configuratorService.getPacks(filter);
      setPackList(result);
      setShowPacks(true);
    } catch (error) {
      setShowPacks(false);
      console.log(error);
    }
  };

  const clickEdit = (pack: Pack) => {
    window.top.location.replace(`${SERVER_BACKOFFICE}/cart`);
    setGoToCart(true);
  };

  return (
    <>
      <div className='configurator_configurator'>
        <Row>
          <Col md={3} className='mb-4'>
            <p className='title'>Deseja fazer aluguer de viaturas mensais?</p>
          </Col>
          <Col md={9} className='mb-4'>
            <label className='switch'>
              <input
                type='checkbox'
                checked={finlogXtracarsState}
                onChange={() => setFinlogXtracarsState(!finlogXtracarsState)}
              ></input>
              <span className='slider round'></span>
            </label>
          </Col>
          <Col md={3} className='mb-4'>
            <p className='subtitle'>Se sim! Quantos meses?</p>
          </Col>
          <Col md={9} className='align-middle mb-4'>
            <Row className='align-middle'>
              <SliderConfigurator
                value={finlogXtracarsSlider}
                min={0}
                max={24}
                className='ml-2'
                onChangeCommitted={(e, value) =>
                  setFinlogXtracarsSlider(value as number)
                }
                disabled={!finlogXtracarsState}
              />
              <p className='text_value'>
                {finlogXtracarsSlider}{' '}
                {finlogXtracarsSlider && finlogXtracarsSlider === 1
                  ? 'mês'
                  : 'meses'}
              </p>
            </Row>
          </Col>
          <Col md={3} className='mb-4'>
            <p className='subtitle'>Qual a tipologia de carro preferida?</p>
          </Col>
          <Col md={9} className='align-middle mb-4'>
            <Row className='ml-2'>
              {segments &&
                segments.length > 0 &&
                segments.map((seg) => {
                  return (
                    <Col
                      md={2}
                      xs={4}
                      key={seg.id}
                      className='d-flex justify-content-center py-0 px-0'
                    >
                      <Button
                        className={`segment_button py-0 px-0 ${
                          finlogXtracarsSegment === seg.id ? 'selected' : ''
                        }`}
                        onClick={() => setFinlogXtracarsSegment(seg.id)}
                        disabled={!finlogXtracarsState}
                      >
                        <img
                          src={seg.imageUrl}
                          alt={seg.segmentDescription}
                          className={`segment_image ${
                            finlogXtracarsSegment === seg.id ? 'selected' : ''
                          }`}
                        />
                        <p
                          className={`segment_text ${
                            finlogXtracarsSegment === seg.id ? 'selected' : ''
                          }`}
                        >
                          {seg.segmentDescription}
                        </p>
                      </Button>
                    </Col>
                  );
                })}
            </Row>
          </Col>
          <Col md={3} className='mb-4'>
            <p className='title'>
              Tem necessidade de transporte para crianças?
            </p>
          </Col>
          <Col md={9} className='mb-4'>
            <label className='switch'>
              <input
                type='checkbox'
                checked={rodinhasState}
                onChange={() => setRodinhasState(!rodinhasState)}
              ></input>
              <span className='slider round'></span>
            </label>
          </Col>
          <Col md={3} className='mb-4'>
            <p className='subtitle'>Se Sim! Quantas Crianças?</p>
          </Col>
          <Col md={9} className='align-middle mb-4'>
            <Row className='align-middle'>
              <SliderConfigurator
                value={rodinhasData.numberChildren}
                min={0}
                max={rodinhasMaxChildrens}
                className='ml-2'
                onChangeCommitted={(e, value) =>
                  setRodinhasData({
                    ...rodinhasData,
                    numberChildren: value as number,
                  })
                }
                disabled={!rodinhasState}
              />
              <p className='text_value'>
                {rodinhasData.numberChildren}{' '}
                {rodinhasData.numberChildren &&
                rodinhasData.numberChildren === 1
                  ? 'criança'
                  : 'crianças'}
              </p>
            </Row>
          </Col>
          <Col md={3} className='mb-4'>
            <p className='subtitle'>Quantas vezes por semana?</p>
          </Col>
          <Col md={9} className='align-middle mb-4'>
            <Row className='align-middle'>
              <SliderConfigurator
                value={rodinhasData.numberPerWeek}
                min={1}
                max={rodinhasMaxNumberPerWeek}
                className='ml-2'
                onChangeCommitted={(e, value) =>
                  setRodinhasData({
                    ...rodinhasData,
                    numberPerWeek: value as number,
                  })
                }
                disabled={!rodinhasState}
              />
              <p className='text_value'>
                {rodinhasData.numberPerWeek}{' '}
                {rodinhasData.numberPerWeek && rodinhasData.numberPerWeek === 1
                  ? 'vez'
                  : 'vezes'}
              </p>
            </Row>
          </Col>
          <Col md={3} className='mb-4'>
            <p className='subtitle'>Regime de ida e volta?</p>
          </Col>
          <Col md={9} className='align-middle mb-4'>
            <Row className='align-middle ml-1'>
              <Form.Control
                as='select'
                custom
                onChange={(e) => {
                  setRodinhasData({
                    ...rodinhasData,
                    roundTrip: e.target.value,
                  });
                }}
                value={rodinhasData.roundTrip}
                disabled={!rodinhasState}
              >
                <option value={''}>Escolha</option>
                <option value={'true'}>Sim</option>
                <option value={'false'}>Não</option>
              </Form.Control>
            </Row>
          </Col>
          <Col md={3} className='mb-4'>
            <p className='subtitle'>Em que região procura?</p>
          </Col>
          <Col md={9} className='align-middle mb-4'>
            <Row className='align-middle ml-1'>
              <Form.Control
                as='select'
                custom
                onChange={async (e) => {
                  setRodinhasData({
                    ...rodinhasData,
                    region: e.target.value,
                    pickupZone: '',
                    pickupSubZone: '',
                    dropoffZone: '',
                    dropoffSubZone: '',
                  });
                  getZones(e.target.value);
                }}
                value={rodinhasData.region}
                disabled={!rodinhasState}
              >
                <option value={''}>Escolha</option>
                {rodinhasRegions &&
                  rodinhasRegions.length > 0 &&
                  rodinhasRegions.map((el) => {
                    if (el !== '') {
                      return (
                        <option key={el} value={el}>
                          {el}
                        </option>
                      );
                    }
                  })}
              </Form.Control>
            </Row>
          </Col>
          <Col md={3} className='mb-4'>
            <p className='subtitle'>Qual a zona de recolha?</p>
          </Col>
          <Col md={9} className='align-middle mb-4'>
            <Row className='align-middle ml-1'>
              <Form.Control
                as='select'
                custom
                onChange={(e) => {
                  setRodinhasData({
                    ...rodinhasData,
                    pickupZone: e.target.value,
                    pickupSubZone: '',
                  });
                  getSubZones(e.target.value, 'pickup');
                }}
                value={rodinhasData.pickupZone}
                disabled={!rodinhasState || rodinhasData.region === ''}
              >
                <option value={''}>Escolha</option>
                {rodinhasZones &&
                  rodinhasZones.length > 0 &&
                  rodinhasZones.map((el) => {
                    if (el !== '') {
                      return (
                        <option key={el} value={el}>
                          {el}
                        </option>
                      );
                    }
                  })}
              </Form.Control>
            </Row>
          </Col>
          <Col md={3} className='mb-4'>
            <p className='subtitle'>E a sub-zona?</p>
          </Col>
          <Col md={9} className='align-middle mb-4'>
            <Row className='align-middle ml-1'>
              <Form.Control
                as='select'
                custom
                onChange={(e) => {
                  setRodinhasData({
                    ...rodinhasData,
                    pickupSubZone: e.target.value,
                  });
                }}
                value={rodinhasData.pickupSubZone}
                disabled={!rodinhasState || rodinhasData.pickupZone === ''}
              >
                <option value={''}>Escolha</option>
                {rodinhasSubZones &&
                  rodinhasSubZones.length > 0 &&
                  rodinhasSubZones.map((el) => {
                    return (
                      <option key={el} value={el}>
                        {el}
                      </option>
                    );
                  })}
              </Form.Control>
            </Row>
          </Col>
          <Col md={3} className='mb-4'>
            <p className='subtitle'>Qual a zona de entrega?</p>
          </Col>
          <Col md={9} className='align-middle mb-4'>
            <Row className='align-middle ml-1'>
              <Form.Control
                as='select'
                custom
                onChange={(e) => {
                  setRodinhasData({
                    ...rodinhasData,
                    dropoffZone: e.target.value,
                    dropoffSubZone: '',
                  });
                  getSubZones(e.target.value, 'dropoff');
                }}
                value={rodinhasData.dropoffZone}
                disabled={!rodinhasState || rodinhasData.pickupSubZone === ''}
              >
                <option value={''}>Escolha</option>
                {rodinhasZones &&
                  rodinhasZones.length > 0 &&
                  rodinhasZones.map((el) => {
                    return (
                      <option key={el} value={el}>
                        {el}
                      </option>
                    );
                  })}
              </Form.Control>
            </Row>
          </Col>
          <Col md={3} className='mb-4'>
            <p className='subtitle'>E a sub-zona?</p>
          </Col>
          <Col md={9} className='align-middle mb-4'>
            <Row className='align-middle ml-1'>
              <Form.Control
                as='select'
                custom
                onChange={(e) => {
                  setRodinhasData({
                    ...rodinhasData,
                    dropoffSubZone: e.target.value,
                  });
                }}
                value={rodinhasData.dropoffSubZone}
                disabled={!rodinhasState || rodinhasData.dropoffZone === ''}
              >
                <option value={''}>Escolha</option>
                {rodinhasSubZonesDrop &&
                  rodinhasSubZonesDrop.length > 0 &&
                  rodinhasSubZonesDrop.map((el) => {
                    return (
                      <option key={el} value={el}>
                        {el}
                      </option>
                    );
                  })}
              </Form.Control>
            </Row>
          </Col>
          <Col md={3} className='mb-4'>
            <p className='title'>
              Faz frequentemente aluguer de carros diários?
            </p>
          </Col>
          <Col md={9} className='mb-4'>
            <label className='switch'>
              <input
                type='checkbox'
                checked={guerinState}
                onChange={() => setGuerinState(!guerinState)}
              ></input>
              <span className='slider round'></span>
            </label>
          </Col>
          <Col md={3} className='mb-4'>
            <p className='subtitle'>Em média, quantos por mês?</p>
          </Col>
          <Col md={9} className='align-middle mb-4'>
            <Row className='align-middle'>
              <SliderConfigurator
                value={guerinSlider}
                min={0}
                max={30}
                className='ml-2'
                onChangeCommitted={(e, value) =>
                  setGuerinSlider(value as number)
                }
                disabled={!guerinState}
              />
              <p className='text_value'>
                {guerinSlider}{' '}
                {guerinSlider && guerinSlider === 1 ? 'dia' : 'dias'}
              </p>
            </Row>
          </Col>
          <Col md={3} className='mb-4'>
            <p className='subtitle'>Qual a tipologia de carro preferida?</p>
          </Col>
          <Col md={9} className='align-middle mb-4'>
            <Row className='ml-2'>
              {segments &&
                segments.length > 0 &&
                segments.map((seg) => {
                  return (
                    <Col
                      md={2}
                      xs={4}
                      key={seg.id}
                      className='d-flex justify-content-center'
                    >
                      <Button
                        className={`segment_button ${
                          guerinSegment === seg.id ? 'selected' : ''
                        }`}
                        onClick={() => setGuerinSegment(seg.id)}
                        disabled={!guerinState}
                      >
                        <img
                          src={seg.imageUrl}
                          alt={seg.segmentDescription}
                          className={`segment_image ${
                            guerinSegment === seg.id ? 'selected' : ''
                          }`}
                        />
                        <p
                          className={`segment_text ${
                            guerinSegment === seg.id ? 'selected' : ''
                          }`}
                        >
                          {seg.segmentDescription}
                        </p>
                      </Button>
                    </Col>
                  );
                })}
            </Row>
          </Col>
          {/* To be uncomment when there is new TVDE product */}
          {/* <Col md={3} className='mb-4'>
            <p className='title'>Tem necessidade de transporte privado?</p>
          </Col>
          <Col md={9} className='mb-4'>
            <label className='switch'>
              <input
                type='checkbox'
                checked={bedrivenState}
                onChange={() => setBedrivenState(!bedrivenState)}
              ></input>
              <span className='slider round'></span>
            </label>
          </Col>
          <Col md={3} className='mb-4'>
            <p className='subtitle'>
              Em média, qual o seu gasto mensal em viagens TVDE?
            </p>
          </Col>
          <Col md={9} className='align-middle mb-4'>
            <Row className='align-middle'>
              <SliderConfigurator
                value={bedrivenSlider}
                min={1}
                max={24}
                className='ml-2'
                onChangeCommitted={(e, value) =>
                  setBedrivenSlider(value as number)
                }
              />
              <p className='text_value'>{bedrivenSlider}€</p>
            </Row>
          </Col> */}
          <Col md={12}>
            <Row className='mt-4'>
              <Col className='d-flex justify-content-center'>
                {finlogXtracarsState && (
                  <img
                    src={require('../../assets/configurator/Kinto_one.png')}
                    alt='Finlog'
                    style={{ width: 110, height: 17 }}
                  />
                )}
                {!finlogXtracarsState && (
                  <img
                    src={require('../../assets/configurator/Kinto_one_out.png')}
                    alt='Finlog'
                    style={{ width: 110, height: 17 }}
                  />
                )}
              </Col>
              <Col className='d-flex justify-content-center'>
                {finlogXtracarsState && (
                  <img
                    src={require('../../assets/configurator/Xtracars.png')}
                    alt='Xtracars'
                    style={{ width: 109.65, height: 18.8 }}
                  />
                )}
                {!finlogXtracarsState && (
                  <img
                    src={require('../../assets/configurator/Xtracars_out.png')}
                    alt='Xtracars'
                    style={{ width: 109.65, height: 18.8 }}
                  />
                )}
              </Col>
              <Col className='d-flex justify-content-center'>
                {guerinState && (
                  <img
                    src={require('../../assets/configurator/Guerin.png')}
                    alt='Guerin'
                    style={{ width: 90.84, height: 30.41 }}
                  />
                )}
                {!guerinState && (
                  <img
                    src={require('../../assets/configurator/Guerin_out.png')}
                    alt='Guerin'
                    style={{ width: 90.84, height: 30.41 }}
                  />
                )}
              </Col>
              <Col className='d-flex justify-content-center'>
                {rodinhasState && (
                  <img
                    src={require('../../assets/configurator/Rodinhas.png')}
                    alt='Rodinhas'
                    style={{ width: 109.66, height: 28.92 }}
                  />
                )}
                {!rodinhasState && (
                  <img
                    src={require('../../assets/configurator/Rodinhas_out.png')}
                    alt='Rodinhas'
                    style={{ width: 109.66, height: 28.92 }}
                  />
                )}
              </Col>
              {/* To be replaced by new TVDE product */}
              {/* <Col className='d-flex justify-content-center'>
                {bedrivenState && (
                  <img
                    src={require('../../assets/configurator/Bedriven.png')}
                    alt='Bedriven'
                    style={{ width: 94.2, height: 17.21 }}
                  />
                )}
                {!bedrivenState && (
                  <img
                    src={require('../../assets/configurator/Bedriven_out.png')}
                    alt='Bedriven'
                    style={{ width: 94.2, height: 17.21 }}
                  />
                )}
              </Col> */}
            </Row>
            <Row className='d-flex justify-content-center mt-5'>
              <Button className='simulate_button' onClick={() => searchPacks()}>
                SIMULAR
              </Button>
            </Row>
          </Col>
        </Row>
        <Container fluid className='configurator_packs'>
          {showPacks && packList &&
            <Row className='d-flex justify-content-center' style={{ fontWeight: "bold", fontSize: 23, margin: 30 }}>
              {packList.length} Resultado(s)
            </Row>
          }
          {showPacks && packList && packList.length == 0 &&
            <Row className='d-flex justify-content-center' style={{ textAlign: "center", margin: 10 }}>
              Sem pacotes para exibir
            </Row>
          }
          <Row className='d-flex justify-content-center container_cards'>
            {packList &&
              packList.length > 0 &&
              packList.map((pack: Pack) => {
                return (
                  <PackCard
                    pack={pack}
                    key={pack.id}
                    clickEdit={clickEdit}
                  ></PackCard>
                );
              })}
          </Row>
        </Container>
      </div>
    </>
  );
};

export interface RodinhasConfig {
  region: string;
  pickupZone: string;
  pickupSubZone: string;
  dropoffZone: string;
  dropoffSubZone: string;
  numberPerWeek: string;
  roundTrip: string;
  numberChildren: number;
  productId: number;
}

export interface Segment {
  id: number;
  imageUrl: string;
  segmentDescription: string;
  segmentId: number;
}

export interface RodinhasProduct {
  id: number;
  productId: number;
  region: string;
  subZone: string;
  zone: string;
}

export default Configurator;

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useParams, Link, useHistory } from 'react-router-dom';
import { Form, Col, Row, Modal, Button, Container } from 'react-bootstrap';
import {
  faTrashAlt,
  faPowerOff,
  faEdit,
} from '@fortawesome/free-solid-svg-icons';
import dayjs from 'dayjs';
import Loading from 'react-fullscreen-loading';
import SweetAlert from 'react-bootstrap-sweetalert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toFixed } from '../../../utils/utils';
import { packService } from '../../../services/packService';
import { productService } from '../../../services/productService';
import { companyService } from '../../../services/companyService';
import { Title, Content, Styles } from '../../../style/content';
import PackHeader from '../../../components/Packs/PackHeader';
import { iCan } from '../../../redux/sessionReducer';
import {
  animationInvisible,
  animationVariants,
  animationVisible,
} from 'src/components/Animations/animations';
import { motion } from 'framer-motion';

interface Props {
  productId: any;
  reloadPacks: () => void;
}

const Pack: React.FC = () => {
  const [pack, setPack] = useState({
    active: true,
    deleted: false,
    monthlyValue: Number(),
    id: null,
    name: '',
    packValidate: null as any,
    packProductDTO: [
      {
        productId: '',
        productName: '',
        serviceId: '1',
        valuePack: '',
        pack: '',
        balance: 0,
        monthlyAmountInclVAT: 0,
      },
      {
        pack: '',
        productId: 0,
        productName: '',
        serviceId: '2',
        valuePack: '',
        groupId: '',
        segmentId: 0,
        segmentDescription: '',
        days: 0,
        balance: 0,
        segment: '',
      },
      {
        pack: '',
        productId: 0,
        productName: '',
        serviceId: '3',
        valuePack: '',
        groupId: '',
        segmentId: 0,
        segmentDescription: '',
        months: 0,
        balance: 0,
        segment: '',
      },
      {
        pack: '',
        productName: '',
        serviceId: '4',
        valuePack: '',
        region: '',
        pickupZone: '',
        pickupSubZone: '',
        dropoffZone: '',
        dropoffSubZone: '',
        numberPerWeek: '',
        roundTrip: '',
        numberChildren: 0,
        balance: 0,
      },
      {
        pack: '',
        productId: '',
        productName: '',
        serviceId: '5',
        valuePack: '',
        balance: 0,
      },
    ],
    listPackChildren: [] as any,
  });

  const [isResume, setIsResume] = useState(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showDeactivate, setShowDeactivate] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [modalActivePack, setModalActivePack] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');
  const [showCreatedPack, setShowCreatedPack] = useState(false);
  const [packSize, setPackSize] = useState(0);
  const [services, setServices] = useState([] as any);
  const { productId } = useParams();
  const [finlogList, setFinlogList] = useState([
    {
      name: '',
      id: Number(),
      productId: '',
      monthlyAmountInclVAT: Number(),
    },
  ]);
  const [guerinList, setGuerinList] = useState([
    {
      segment: '',
      name: '',
      id: Number(),
      groupID: '',
      productId: '',
      segmentDescription: '',
    },
  ]);
  const [xtracarsList, setXtracarsList] = useState([
    {
      segment: '',
      name: '',
      id: Number(),
      groupID: '',
      productId: '',
      segmentDescription: '',
    },
  ]);
  const [bedrivenList, setBedrivenList] = useState([]);

  //Finlog
  const [finlogPackValue, setFinlogPackValue] = useState(0.0);

  //Rodinhas
  const [rodinhasProduct, setRodinhasProduct] = useState();
  const [rodinhasPackValue, setRodinhasPackValue] = useState(0.0);
  const [rodinhasProductRegions, setRodinhasProductRegions] = useState([]);
  const [rodinhasZonesPickup, setRodinhasZonesPickup] = useState([]);
  const [rodinhasZonesDropoff, setRodinhasZonesDropoff] = useState([]);
  const [rodinhasSubZonesPickup, setRodinhasSubZonesPickup] = useState([]);
  const [rodinhasSubZonesDropoff, setRodinhasSubZonesDropoff] = useState([]);
  const [rodinhasSelectedConfig, setRodinhasSelectedConfig] = useState(
    {} as RodinhasConfig
  );
  const [rodinhasMaxNumberTime, setRodinhasMaxNumberTime] = useState(5);
  const [rodinhasMaxNumberChildrens, setRodinhasMaxNumberChildrens] =
    useState(5);
  const [rodinhasUpdated, setRodinhasUpdated] = useState(false);

  //Guerin
  const [guerinGroups, setGuerinGroups] = useState(['']);
  const [guerinPackValue, setGuerinPackValue] = useState(0.0);

  //Xtracars
  const [xtracarsGroups, setXtracarsGroups] = useState(['']);
  const [xtracarsPackValue, setXtracarsPackValue] = useState(0.0);

  const [totalPackValue, setTotalPackValue] = useState(0.0);
  const [segments, setSegments] = useState([
    {
      id: Number(),
      imageUrl: '',
      segmentDescription: '',
      segmentId: Number(),
    },
  ]);

  const [companies, setCompanies] = useState([]);
  const [dtoList, setDtoList] = useState([]);
  const [successDialog, setSuccessDialog] = useState(false);
  const [errorDialog, setErrorDialog] = useState(false);
  const history = useHistory();

  useEffect(() => {
    const getPack = async (packId: string) => {
      setIsLoading(true);
      try {
        const companies = await companyService.getAll();
        setCompanies(companies.data);
        createDTOSkeleton(companies.data);

        const result = await packService.getPackById(packId);
        await handleDTO(result.data, companies.data);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };

    const getSegments = async () => {
      try {
        const resultSegments = await productService.getGuerinXtracarsSegments();
        setSegments(resultSegments.data);
      } catch (error) {
        console.log(error);
      } finally {
      }
    };
    getSegments();

    if (productId) {
      setIsResume(true);
      getPack(productId);
    }
  }, []);

  const handleDTO = async (newPack: any, newCompanies: any) => {
    if (newPack?.listPackChildren?.length) {
      newPack.listPackChildren.forEach((child) => {
        const ids = child.packProductDTO.map((item) => item.serviceId);
        const dtos = createDTOSkeleton(newCompanies);
        const filtered = dtos.filter(
          (item: any) => !ids.includes(item.serviceId)
        );
        const result = [...filtered, ...child.packProductDTO];
        const sortedResult = result.sort((a, b) => a.serviceId - b.serviceId);
        child.packProductDTO = sortedResult;
      });

      setPack({ ...pack, ...newPack });
    } else {
      const ids = newPack.packProductDTO.map((item) => item.serviceId);
      const dtos = createDTOSkeleton(newCompanies);
      const filtered = dtos.filter(
        (item: any) => !ids.includes(item.serviceId)
      );
      const result = [...filtered, ...newPack.packProductDTO];
      const sortedResult = result.sort((a, b) => a.serviceId - b.serviceId);
      newPack.packProductDTO = sortedResult;

      setPack({ ...pack, ...newPack });
      setServices([...Array.from(new Set(ids))]);
    }
  };

  const createDTOSkeleton = (companiesList: any) => {
    const result = companiesList.reduce((map, item) => {
      map.push({
        productId: '',
        productName: '',
        serviceId: item.id,
        valuePack: '',
        region: '',
        pickupZone: '',
        pickupSubZone: '',
        dropoffZone: '',
        dropoffSubZone: '',
        numberPerWeek: '',
        roundTrip: '',
        numberChildren: 0,
        balance: '',
        months: '',
        days: '',
      });

      return map;
    }, []);

    setDtoList(result);

    return result;
  };

  const getCompany = (id: any) => {
    return {
      1: 'kinto-one',
      2: 'guerin',
      3: 'xtracars',
      4: 'rodinhas',
      42: 'rodinhas',
      5: 'bedriven',
    }[id];
  };

  const updatePack = (event: any, index: any, serviceId: any) => {
    const { options, selectedIndex, value } = event.target;

    const newPack = pack;

    newPack.packProductDTO[index].productId = value;
    newPack.packProductDTO[index].productName =
      options[selectedIndex].innerHTML;
    newPack.packProductDTO[index].serviceId = serviceId;

    setPack({ ...pack, ...newPack });

    if (index === 0) {
      let index = finlogList.findIndex((el) => el.id == value);
      if (index !== -1) {
        setFinlogPackValue(toFixed(finlogList[index].monthlyAmountInclVAT, 2));
      } else {
        setFinlogPackValue(0.0);
      }
    }
  };

  const updateGuerinXtracars = async (
    event: any,
    index: any,
    serviceId: any,
    key: any
  ) => {
    const { value } = event.target;
    const newPack = pack;
    newPack.packProductDTO[index][key] = value;

    //segment
    if (key === 'segmentId') {
      if (serviceId === 2) {
        const products = guerinList?.filter((el) => el.segment === value);
        let groups: string[] = [];
        products.map((item) => {
          if (!groups.includes(item.groupID)) {
            groups.push(item.groupID);
          }
        });
        setGuerinGroups(groups);
        setGuerinPackValue(0.0);
      } else if (serviceId === 3) {
        const products = xtracarsList?.filter((el) => el.segment === value);
        let groups: string[] = [];
        products.map((item) => {
          if (!groups.includes(item.groupID)) {
            groups.push(item.groupID);
          }
        });
        setXtracarsGroups(groups);
        setXtracarsPackValue(0.0);
      }
      newPack.packProductDTO[index].productId = '';
      newPack.packProductDTO[index].productName = '';
      newPack.packProductDTO[index].valuePack = '';
      newPack.packProductDTO[index].groupId = '';
    }

    //group
    if (key === 'groupId') {
      if (serviceId === 2) {
        const guerinIndex = guerinList?.findIndex(
          (el) => el.groupID == value.toString()
        );
        if (guerinIndex >= 0) {
          newPack.packProductDTO[index].productId = guerinList[guerinIndex].id;
        }
        setGuerinPackValue(0.0);
        newPack.packProductDTO[index].productName = '';
        newPack.packProductDTO[index].valuePack = '';
      } else if (serviceId === 3) {
        const xtracarsIndex = xtracarsList?.findIndex(
          (el) => el.groupID == value.toString()
        );
        if (xtracarsIndex >= 0) {
          newPack.packProductDTO[index].productId =
            xtracarsList[xtracarsIndex].id;
        }
        setXtracarsPackValue(0.0);
        newPack.packProductDTO[index].productName = '';
        newPack.packProductDTO[index].valuePack = '';
      }
    }

    //days or months
    if (key === 'days' || key === 'months') {
      newPack.packProductDTO[index].valuePack = value;
      const result = await packService.calculateGuerinXtracarsValue(
        pack.packProductDTO[index]
      );
      if (result.data) {
        if (key === 'days') {
          newPack.packProductDTO[index].productName = 'Guerin';
          setGuerinPackValue(result.data);
        } else if (key === 'months') {
          newPack.packProductDTO[index].productName = 'Xtracars';
          let total = toFixed(result.data, 2);
          setXtracarsPackValue(total);
        }
      }
    }

    setPack({ ...pack, ...newPack });
  };

  const updatePackChildren = (
    event: any,
    indexChildren: any,
    indexDTO: any,
    serviceId: any
  ) => {
    const { options, selectedIndex, value } = event.target;

    const newPack = pack;

    newPack.listPackChildren[indexChildren].packProductDTO[indexDTO].productId =
      value;
    newPack.listPackChildren[indexChildren].packProductDTO[
      indexDTO
    ].productName = options[selectedIndex].innerHTML;

    setPack({ ...pack, ...newPack });
  };

  const updateValuePack = (index: any, value) => {
    const newPack = pack;

    newPack.packProductDTO[index].valuePack = value;

    setPack({ ...pack, ...newPack });
  };

  const removePack = async (productId: any) => {
    setIsLoading(true);
    setShowRemoveModal(false);

    try {
      const result = await packService.deletePack(productId);
      setSuccessDialog(true);
      setPack({ ...pack, deleted: true });
      setAlertMsg(result.data.message);
    } catch (error) {
      setErrorDialog(true);
      setAlertMsg(error.response.data.message);
    } finally {
      setIsLoading(false);
    }
  };

  const activePack = async (productId: string, newPack: any) => {
    setModalActivePack(false);
    setIsLoading(true);

    try {
      const result = await packService.activePack(productId, !newPack.active);
      newPack.active = !newPack.active;

      setPack({ ...pack, ...newPack });
      setSuccessDialog(true);
      setAlertMsg(result.data.message);
    } catch (error) {
      setAlertMsg(error.response.data.message);
      setErrorDialog(true);
    } finally {
      setIsLoading(false);
    }
  };

  const getPackHeaderValue = (product: any) => {
    if (product.balance) {
      if (product.serviceId == '3') {
        return toFixed(product.balance / product.valuePack, 2);
      }
      return toFixed(product.balance);
    } else {
      if (product.serviceId == '1') {
        return toFixed(finlogPackValue, 2) ?? 0.0;
      }
      if (product.serviceId == '2') {
        return toFixed(guerinPackValue, 2) ?? 0.0;
      }
      if (product.serviceId == '3') {
        return toFixed(xtracarsPackValue, 2) ?? 0.0;
      }
      if (product.serviceId == '4') {
        return toFixed(rodinhasPackValue, 2) ?? 0.0;
      }
    }
  };

  const resume = () => (
    <div>
      <Modal backdrop='static' show={modalActivePack} size='lg'>
        <Modal.Header></Modal.Header>
        <Modal.Body>
          <FontAwesomeIcon icon={faPowerOff} />
          <h2 className='pt-5'>
            Tem certeza que pretende {pack.active ? 'desativar' : 'ativar'}?
          </h2>
          <p className='disclaimer'>Poderá reverter a sua ação mais tarde.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='remove'
            onClick={() => {
              activePack(productId, pack);
              setModalActivePack(false);
            }}
          >
            SIM, {pack.active ? 'DESATIVAR' : 'ATIVAR'}
          </Button>
          <Button variant='back' onClick={() => setModalActivePack(false)}>
            NÃO, VOLTAR
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal backdrop='static' show={showRemoveModal} size='lg'>
        <Modal.Header></Modal.Header>
        <Modal.Body>
          <FontAwesomeIcon icon={faTrashAlt} className='remove' />
          <h2 className='pt-5'>Tem certeza que pretende eliminar?</h2>
          <p className='disclaimer'>Esta ação não pode ser revertida.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='remove' onClick={() => removePack(productId)}>
            SIM, ELIMINAR
          </Button>
          <Button variant='back' onClick={() => setShowRemoveModal(false)}>
            NÃO, VOLTAR
          </Button>
        </Modal.Footer>
      </Modal>

      <div className='form'>
        <Row>
          <Col>
            <h3>{pack.name}</h3>
          </Col>

          <Col md={{ offset: 4 }} className='d-flex justify-content-end'>
            {iCan('ROLE_DELETE_PACK') && (
              <>
                <div
                  className='wrapper-actions'
                  onMouseEnter={() => setShowDeactivate(true)}
                  onMouseLeave={() => setShowDeactivate(false)}
                >
                  <FontAwesomeIcon
                    icon={faPowerOff}
                    onClick={() => setModalActivePack(true)}
                  />
                  <motion.div
                    initial={animationVisible}
                    animate={showDeactivate ? 'visible' : 'invisible'}
                    exit={animationInvisible}
                    transition={{ duration: 0.3 }}
                    variants={animationVariants}
                  >
                    <p className='text-deactivate font-weight-bold'>
                      {pack.active ? 'desativar' : 'ativar'}
                    </p>
                  </motion.div>
                </div>
              </>
            )}
            {iCan('ROLE_UPDATE_PACK') && (
              <>
                <div
                  className='wrapper-actions edit'
                  onMouseEnter={() => setShowEdit(true)}
                  onMouseLeave={() => setShowEdit(false)}
                  onClick={() => {
                    history.replace(`/packs/edit/${productId}`);
                  }}
                >
                  <FontAwesomeIcon
                    icon={faEdit}
                    className='edit'
                  />
                  <motion.div
                    initial={animationVisible}
                    animate={showEdit ? 'visible' : 'invisible'}
                    exit={animationInvisible}
                    transition={{ duration: 0.3 }}
                    variants={animationVariants}
                  >
                    <p className='text-edit font-weight-bold'>editar</p>
                  </motion.div>
                </div>
              </>
            )}
          </Col>
        </Row>

        <div className={'pack_resume'}>
          <div className={!pack.active ? 'opacity' : ''}>
            <p>Valor total do Pacote*</p>
            <h2 className='value my-4'>
              {toFixed(pack.monthlyValue, 2)}
              {'€'}
            </h2>
            <p>Serviços incluídos e respetiva oferta</p>

            <div className='mt-5'>
              {pack.packProductDTO && (
                <>
                  {pack.packProductDTO.map((product) => {
                    return (
                      <div key={product.productId}>
                        {product.productName && (
                          <>
                            <PackHeader
                              img={require(`../../../assets/logos/${getCompany(
                                product.serviceId
                              )}-xs-logo-on-2x.png`)}
                              value={getPackHeaderValue(product)}
                            />

                            <div className='font-weight-bold mt-3'>
                              {product.serviceId == '1' && product.productName}
                              {product.serviceId == '2' &&
                                'Grupo ' +
                                  product.groupId +
                                  ' | ' +
                                  product.segmentDescription}
                              {product.serviceId == '3' &&
                                'Grupo ' +
                                  product.groupId +
                                  ' | ' +
                                  product.segmentDescription}

                              {product.serviceId == '4' && (
                                <div>
                                  <Row>
                                    <Col>
                                      <p>Região</p>
                                      <h1>{product.region}</h1>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col>
                                      <p>Zona de Pick-up</p>
                                      <h1>{product.pickupZone}</h1>
                                    </Col>
                                    <Col>
                                      <p>Sub-zona de Pick-up</p>
                                      <h1>{product.pickupSubZone}</h1>
                                    </Col>
                                    <Col>
                                      <p>Zona de Drop-off</p>
                                      <h1>{product.dropoffZone}</h1>
                                    </Col>
                                    <Col>
                                      <p>Sub-zona de Drop-off</p>
                                      <h1>{product.dropoffSubZone}</h1>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col>
                                      <p>Vezes por semana</p>
                                      <h1>{product.numberPerWeek}</h1>
                                    </Col>
                                    <Col>
                                      <p>Ida e Volta</p>
                                      <h1>
                                        {product.roundTrip ? 'Sim' : 'Não'}
                                      </h1>
                                    </Col>
                                    <Col>
                                      <p>Nº de Crianças</p>
                                      <h1>{product.numberChildren}</h1>
                                    </Col>
                                  </Row>
                                </div>
                              )}
                            </div>
                          </>
                        )}
                      </div>
                    );
                  })}
                </>
              )}
              {pack?.listPackChildren?.length > 0 &&
                pack.listPackChildren.map((pack: any, i) => {
                  return (
                    pack.name && (
                      <ul key={i}>
                        <li className='name pt-5'>{pack.name}</li>
                        {pack.packProductDTO?.length &&
                          pack.packProductDTO.map((product) => {
                            return (
                              <div>
                                {product.productName && (
                                  <li>
                                    <Form.Group as={Row} xs={10}>
                                      <Form.Label column xs={2}>
                                        {product.serviceId && (
                                          <img
                                            src={require(`../../../assets/logos/${getCompany(
                                              product.serviceId
                                            )}-xs-logo-on-2x.png`)}
                                            alt=''
                                          />
                                        )}
                                      </Form.Label>

                                      <Col xs={8} className='mt-4'>
                                        <p className='font-weight-bold mt-3 value-name'>
                                          {product.productName} -{' '}
                                          {product.valuePack}
                                        </p>
                                      </Col>
                                    </Form.Group>
                                  </li>
                                )}
                              </div>
                            );
                          })}
                      </ul>
                    )
                  );
                })}
            </div>
          </div>
          <Row className='footer'>
            <Col>
              <p>Validade do pacote</p>
              <h1>{dayjs(pack.packValidate).format('DD/MM/YYYY')}</h1>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <Title>Pacote</Title>

      <Content>
        <Container fluid className='card-no-tabs' style={{ paddingLeft: 80 }}>
          {isLoading && (
            <Loading
              loading
              background='rgba(255, 255, 255, .4)'
              loaderColor='#3498db'
            />
          )}

          {errorDialog && (
            <SweetAlert
              error
              title='Erro!'
              onConfirm={() => {
                setErrorDialog(false);
                setAlertMsg('');
              }}
            >
              {alertMsg}
            </SweetAlert>
          )}

          {successDialog && (
            <SweetAlert
              success
              title='Sucesso!'
              onConfirm={() => {
                setSuccessDialog(false);
                setAlertMsg('');
              }}
            >
              {alertMsg}
            </SweetAlert>
          )}

          <Modal
            backdrop='static'
            show={showCreatedPack}
            size='lg'
            dialogClassName='custom-dialog'
          >
            <Modal.Header>
              <div>
                <h2>Pacote criado</h2>
                <p className='font-weight-normal'>
                  Sua configuração de pacote foi criada com sucesso!
                </p>
              </div>
            </Modal.Header>
            <Modal.Body>
              <div className='pack mb-5'>
                <div className={`wrapper ${!pack.active ? 'opacity' : ''}`}>
                  <h5 className='text-center'>Pacote {pack.name}</h5>
                  <h4 className='text-center'>
                    <span className='big-text'>
                      {toFixed(pack.monthlyValue, 2)}€
                    </span>
                    /mês
                  </h4>
                  <div className='social-networks mt-4'>
                    {services?.length > 0 &&
                      services.map((number, i) => {
                        return (
                          <i className={`icon-service-${number}`} key={i}></i>
                        );
                      })}
                  </div>
                  <p className='text-center mt-4'>
                    Pacote de {packSize}
                    {packSize == 1 ? ' serviço' : ' serviços'}
                  </p>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button
                className='big-btn ml-4'
                onClick={() => window.location.reload()}
              >
                OK
              </button>
            </Modal.Footer>
          </Modal>
          <Row
            style={{
              textAlign: 'center',
              font: 'normal normal normal 18px/22px Roboto',
              letterSpacing: '0px',
              color: '#878787',
              opacity: 1,
              marginLeft: '8px',
              marginTop: '51px',
            }}
          >
            <Link className='color-back' to={'/packs'}>
              <p>
                <u>&#60; Voltar</u>
              </p>
            </Link>
          </Row>
          {resume()}
        </Container>
      </Content>
    </>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  .header_guerin {
    width: 100%;
  }

  .no-border {
    border: 0 !important;
  }

  .wrapper-actions {
    text-align: center;
    cursor: pointer;

    .text-edit {
      color: #2292cc;
    }

    .text-remove {
      color: #ff0000;
    }
  }

  .product-definition {
    width: 100%;
    .header {
      display: flex;
      justify-content: space-evenly;

      .title {
        background-color: #2292cc;
        color: #fff;
        padding: 30px;
        border-radius: 30px;
        font-weight: bold;
      }
    }

    .container {
      width: 70%;
      border-bottom: 1px solid #ccc;
      padding: 10px;
      display: flex;
      justify-content: space-between;
    }
  }

  .form {
    padding: 50px 0px;

    .opacity {
      opacity: 0.4;
    }

    .price {
      width: 300px;
      height: 90px;
      font-size: 30px;
    }

    .price::placeholder {
      font-size: 30px;
      color: #000;
    }

    .react-date-picker__wrapper {
      border: 0;
    }

    .back-edit {
      font-weight: bold;
      text-decoration: underline;
      cursor: pointer;
    }

    .resume2 {
      color: #2292cc;
    }

    .value-name {
      text-indent: 20px;
    }

    .resume {
      border-bottom: 1px solid #eee;
      display: flex;
      justify-content: space-between;
      text-indent: 48px;
      padding: 0;
      margin: 0;

      p {
        margin: 0;
        padding: 0;
      }

      p.services {
        color: #2292cc;
      }

      span.remove a {
        padding: 0 10px;
        color: #00000069;
        cursor: pointer;
      }

      span.remove a:hover {
        color: #df0000;
      }
    }

    .btn-hover:hover {
      color: #2292cc;
    }

    h6 {
      border-bottom: 1px solid #eee;
      text-indent: 50px;
      color: #2292cc;
    }

    .section-upload {
      display: flex;
      justify-content: center;
    }

    ul {
      list-style-type: none;

      li {
        border-bottom: 1px solid #ccc;
      }

      li.name {
        text-transform: uppercase;
        font-weight: bold;
      }
    }

    .inputs {
      padding: 20px 0;

      .upload {
        width: 200px;
        height: 100px;
        border-radius: 20px;
        box-shadow: 3px 15px 5px -6px rgba(158, 195, 240, 0.62);
      }
    }

    .validity {
      padding: 50px;

      .title {
        color: #808080;
      }
    }

    .resume-value {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #ccc;
      padding-left: 50px;
      margin: 0;

      p {
        font-weight: bold;
        font-size: 20px;
        margin: 0;
      }

      h4 {
        font-size: 16px;
        font-weight: bold;
      }
    }
  }
`;

export interface RodinhasConfig {
  region: string;
  pickupZone: string;
  pickupSubZone: string;
  dropoffZone: string;
  dropoffSubZone: string;
  numberPerWeek: string;
  roundTrip: string;
  numberChildren: number;
  productId: number;
}

export default Pack;

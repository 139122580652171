import api from '../utils/api';

export const clientService = {
  getById,
  getAll,
  getByUk,
  create,
  update,
  edit,
  deleteClient,
  createUpload,
  validateVAT,
};

async function getById(id) {
  try {
    const response = await api.apiPrivate.get(
      `api/comercial-profile/clients/${id}`
    );

    return response;
  } catch (error) {
    throw error;
  }
}

async function getAll(pageSize, page, filter) {
  try {
    let pageSizeParam = pageSize ? `?size=${pageSize}` : '';
    let filterParam = filter ? `&filter=${filter}` : '';

    const response = await api.apiPrivate.get(
      `/api/comercial-profile/clients/list-clients${pageSizeParam}&page=${page}${filterParam}`
    );

    return response;
  } catch (error) {
    throw error;
  }
}

async function getByUk(idClient) {
  try {
    const response = await api.apiPublic.get(`/client/${idClient}`);
    return response.data;
  } catch (error) {
    throw error;
  }
}

async function create(data) {
  const response = await api.apiPrivate
    .post('api/comercial-profile/clients/create-client', data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        console.log(error.request);
        return error.request;
      }
      throw error;
    });
  return response;
}

async function edit(data, id) {
  const response = await api.apiPrivate
    .post(`api/comercial-profile/clients/edit-client/${id}`, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        console.log(error.request);
        return error.request;
      }
      throw error;
    });
  return response;
}

async function update(data) {
  try {
    const response = api.apiPublic.put('/client', data);
    return 200;
  } catch (error) {
    throw error;
  }
}

async function deleteClient(body) {
  try {
    const response = await api.apiPublic.delete(`/client`, {
      data: body,
    });
  } catch (error) {
    throw error;
  }
}

async function createUpload(data) {
  try {
    const response = await api.apiPublic.post(
      `/client/profile-image`,
      sendPhoto(data)
    );
  } catch (error) {
    throw error;
  }
}

const sendPhoto = (data) => {
  const body = new FormData();
  body.append('file', data.file);
  body.append('client_uk', data.uk);
  return body;
};

async function validateVAT(data) {
  const response = await api.apiPrivate
    .post('/api/product/document/nif-validation', data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        return error.request;
      }
      throw error;
    });
  return response;
}

import React, { useState, useEffect } from 'react';
import { Card, Accordion, Button, Table, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTrashAlt,
  faEdit,
  faArrowRight,
  faArrowDown,
  faPowerOff,
} from '@fortawesome/free-solid-svg-icons';
import Loading from 'react-fullscreen-loading';
import dayjs from 'dayjs';
import SweetAlert from 'react-bootstrap-sweetalert';

import { motion } from 'framer-motion';
import { productService } from '../../../services/productService';
import BadgeComponent from '../../../components/Badge';
import Content from '../../../style/GP/Content';
import Title from '../../../style/GP/Title';
import { useHistory } from 'react-router-dom';
import { iCan } from '../../../redux/sessionReducer';
import {
  animationInvisible,
  animationVariants,
  animationVisible,
} from 'src/components/Animations/animations';

const Configuration: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [successRemove, setSuccessRemove] = useState(false);
  const [errorRemove, setErrorRemove] = useState(false);

  // const [section, setSection] = useState('config');
  // const [isView, setIsView] = useState(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState('');
  const [selectedId, setSelectedId] = useState('');

  // const [finlogProductId, setFinlogProducId] = useState('');
  // const [guerinProductId, setGuerinProductId] = useState('');
  // const [xtracarsProductId, setXtracarsProductId] = useState('');
  // const [rodinhasProductId, setRodinhasProductId] = useState('');
  // const [bedrivenProductId, setBedrivenProductId] = useState('');
  // const [showDeactivate, setShowDeactivate] = useState(false);
  const [showDeactivateModal, setShowDeactivateModal] = useState(false);
  // const [showAddPack, setShowAddPack] = useState(false);
  const [activeProduct, setActiveProduct] = useState({} as any);

  const [finlogList, setFinlogList] = useState([]);
  const [rodinhasList, setRodinhasList] = useState([]);
  const [bedrivenList, setBedrivenList] = useState([]);
  const [guerinList, setGuerinList] = useState([]);
  const [xtracarsList, setXtracarsList] = useState([]);
  const [activeList, setActiveList] = useState([]);
  // const [packList, setPackList] = useState([] as any);
  // const [packId, setPackId] = useState('');
  const [alertMsg, setAlertMsg] = useState('');
  // const [key, setKey] = useState('products');
  const [tabs, setTabs] = useState({
    finlog: false,
    guerin: false,
    rodinhas: false,
    xtracars: false,
    bedriven: false,
  });
  const [selectedProduct, setSelectedProduct] = useState('');
  const history = useHistory();

  useEffect(() => {
    async function getProducts() {
      try {
        setIsLoading(true);

        const products = await Promise.allSettled([
          productService.getProductsByCompany(
            'finlog',
            'deleted=false&serviceId=1'
          ),
          productService.getProductsByGuerinXtracars(
            'guerin',
            'serviceId=2&deleted=false'
          ),
          productService.getProductsByGuerinXtracars(
            'xtracars',
            'serviceId=3&deleted=false'
          ),
          productService.getProductsByCompany(
            'rodinhas',
            'deleted=false&serviceId=4'
          ),
          /*
          productService.getProductsByCompany(
            'bedriven',
            'deleted=false&serviceId=5'
          ),
          */
        ]);

        products[0].status === 'fulfilled'
          ? setFinlogList(products[0]['value'].data)
          : setFinlogList([]);
        products[1].status === 'fulfilled'
          ? setGuerinList(products[1]['value'].data)
          : setGuerinList([]);
        products[2].status === 'fulfilled'
          ? setXtracarsList(products[2]['value'].data)
          : setXtracarsList([]);
        products[3].status === 'fulfilled'
          ? setRodinhasList(products[3]['value'].data)
          : setRodinhasList([]);
        /*
        products[4].status === 'fulfilled'
          ? setBedrivenList(products[4]['value'].data)
          : setBedrivenList([]); */
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    }

    getProducts();
  }, []);
  // eslint-disable-next-line
  const handleDeleted = (productList) => {
    return productList.filter((product) => !product.deleted);
  };

  const openFinlog = (isEditing: boolean, productId: string) => {
    isEditing
      ? history.push(`/products/finlog/view/${productId}`)
      : history.push(`/products/finlog/create`);
  };

  const openGuerin = (isEditing: boolean, productId: string) => {
    isEditing
      ? history.push(`/products/guerin/view/${productId}`)
      : history.push(`/products/guerin/create`);
  };

  const openXtracars = (isEditing: boolean, productId: string) => {
    isEditing
      ? history.push(`/products/xtracars/view/${productId}`)
      : history.push(`/products/xtracars/create`);
  };

  const openRodinhas = (isEditing: boolean, productId: string) => {
    isEditing
      ? history.push(`/products/rodinhas/view/${productId}`)
      : history.push(`/products/rodinhas/create`);
  };

  // const openBedriven = (
  //   isEditing: boolean,
  //   productId: string,
  //   isView: boolean
  // ) => {
  //   isEditing ? setBedrivenProductId(productId) : setBedrivenProductId('');
  //   setSection('bedriven');
  //   setIsView(isView);
  // };

  const openModalRemoveProduct = (companyName: string, product: any, list) => {
    setSelectedId(product.id);
    setShowRemoveModal(true);
    setSelectedCompany(companyName);
    setActiveProduct(product);
    setActiveList(list);
    setSelectedProduct(
      `${companyName.charAt(0).toUpperCase() + companyName.slice(1)} - ${
        product.id
      }`
    );
  };

  const removeByCompany = async (
    companyName: string,
    productId: string,
    list
  ) => {
    setIsLoading(true);
    setShowRemoveModal(false);

    try {
      let response;
      if (companyName === 'guerin' || companyName === 'xtracars') {
        response = await productService.removeGuerinXtracars(
          companyName,
          productId
        );
      } else {
        response = await productService.removeProductByCompany(
          companyName,
          productId
        );
      }
      removeProductFromState(companyName, productId, list);
      setSuccessRemove(true);
      setAlertMsg(response.data.message);
    } catch (error) {
      setErrorRemove(true);
      setAlertMsg(error.response.data.message);
    } finally {
      setIsLoading(false);
    }
  };

  const changeStatusProduct = async (product: any) => {
    setShowDeactivateModal(false);
    setIsLoading(true);

    try {
      let response;

      if (selectedProduct === 'guerin' || selectedProduct === 'xtracars') {
        response = await productService.activeGuerinXtracars(
          selectedProduct,
          product.id,
          !product.active
        );
      } else {
        response = await productService.changeProductByCompany(
          selectedProduct,
          product.id,
          !product.active
        );
      }

      let list;

      switch (selectedProduct) {
        case 'finlog':
          list = finlogList;
          break;
        case 'guerin':
          list = guerinList;
          break;
        case 'xtracars':
          list = xtracarsList;
          break;
        case 'rodinhas':
          list = rodinhasList;
          break;
        case 'bedriven':
          list = bedrivenList;
          break;
        default:
          break;
      }

      list = list.map((item) => {
        if (item.id === product.id) {
          item.productState = item.active ? 'INACTIVE' : 'PUBLISHED';
          item.active = !item.active;
          item.dateLastUpdated = new Date();
        }

        return item;
      });

      switch (selectedProduct) {
        case 'finlog':
          console.log(list);
          setFinlogList(list);
          break;
        case 'guerin':
          setGuerinList(list);
          break;
        case 'xtracars':
          setXtracarsList(list);
          break;
        case 'rodinhas':
          setRodinhasList(list);
          break;
        case 'bedriven':
          setBedrivenList(list);
          break;
        default:
          break;
      }

      setSuccessRemove(true);
      setAlertMsg(response.data.message);
    } catch (error) {
      setErrorRemove(true);
      setAlertMsg(error.response.data.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleStatus = (companyName: string, product: any) => {
    setShowDeactivateModal(true);
    setActiveProduct(product);
    setSelectedProduct(companyName);
  };

  const removeProductFromState = (companyName, productId, list) => {
    let newList = [];
    switch (companyName) {
      case 'bedriven':
        newList = list.filter((product) => product.id !== productId);
        setBedrivenList(newList);
        break;
      case 'finlog':
        newList = list.filter((product) => product.id !== productId);
        setFinlogList(newList);
        break;
      case 'rodinhas':
        newList = list.filter((product) => product.id !== productId);
        setRodinhasList(newList);
        break;
      case 'guerin':
        newList = list.filter((product) => product.id !== productId);
        setGuerinList(newList);
        break;
      case 'xtracars':
        newList = list.filter((product) => product.id !== productId);
        setXtracarsList(newList);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <Title>Produtos</Title>
      <Content>
        <div className='content left-corner right-corner'>
          <div className='wrapper'>
            <div className='configs'>
              <div className='finlog'></div>
              <div className='guerin'></div>
              <div className='xtracars'></div>
              <div
                className='rodinhas'
                onClick={() =>
                  iCan('ROLE_CREATE_PRODUCT') ? openRodinhas(false, '') : ''
                }
              >
                <div className='config'>
                  <p>
                    <FontAwesomeIcon icon={faArrowRight} />
                  </p>
                  <p>configurar</p>
                </div>
              </div>
              {/* <div
                className='bedriven'
                onClick={() => openBedriven(false, '', false)}
              >
                <div className='config'>
                  <p>
                    <FontAwesomeIcon icon={faArrowRight} />
                  </p>
                  <p>configurar</p>
                </div>
              </div> */}
            </div>
            <h3>Lista de Produtos</h3>
            <p>
              Consulte e edite os produtos configurados relativamente de cada
              serviço.
            </p>

            <Accordion>
              <Card>
                <Card.Header
                  onClick={() =>
                    setTabs({
                      guerin: false,
                      rodinhas: false,
                      xtracars: false,
                      bedriven: false,
                      finlog: !tabs.finlog,
                    })
                  }
                >
                  <Accordion.Toggle as={Button} variant='link' eventKey='0'>
                    <img
                      src={require('../../../assets/logos/kinto-one-logo-on-2x.png')}
                      alt=''
                      style={{ width: 110 }}
                    />
                    <FontAwesomeIcon
                      icon={tabs.finlog ? faArrowDown : faArrowRight}
                      color='#40C2F3'
                    />
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey='0'>
                  <Card.Body>
                    {finlogList.length === 0 && (
                      <p>Não há produtos cadastrados.</p>
                    )}
                    {finlogList.length > 0 && (
                      <Table responsive>
                        <thead>
                          <tr>
                            <th>Produto</th>
                            <th>
                              Criação
                              <br />
                              <span className='font-light'>
                                Última alteração
                              </span>
                            </th>
                            <th>Estado</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {finlogList.map((product: any) => {
                            return (
                              <tr key={product.id}>
                                <td
                                  onClick={() => openFinlog(true, product.id)}
                                  className='clickable'
                                >
                                  <div className='wrapper-product'>
                                    <div className='item'>
                                      <img
                                        src={
                                          product.uuidImageFile
                                            ? product.uuidImageFile
                                            : require('../../../assets/car_placeholder.png')
                                        }
                                        alt=''
                                      />
                                    </div>
                                    <div className='item item-data'>
                                      <p className='title'>{product.make}</p>
                                      <p className='description'>
                                        {product.name}
                                      </p>
                                      <p className='description mt-2'>
                                        {product.no.replace('CA', 'CA ')}
                                      </p>
                                    </div>
                                  </div>
                                </td>
                                <td
                                  className='edit clickable'
                                  onClick={() => openFinlog(true, product.id)}
                                >
                                  <p className='creation'>
                                    {product.dateCreated &&
                                      dayjs(product.dateCreated).format(
                                        'DD/MM/YYYY HH:mm'
                                      )}
                                  </p>
                                  <p>
                                    {product.dateLastUpdated &&
                                      dayjs(product.dateLastUpdated).format(
                                        'DD/MM/YYYY HH:mm'
                                      )}
                                  </p>
                                </td>
                                <td
                                  onClick={() => openFinlog(true, product.id)}
                                  className='clickable'
                                >
                                  <BadgeComponent
                                    badge={product.productState}
                                  />
                                </td>
                                <td className='actions'>
                                  {(product.productState === 'PUBLISHED' ||
                                    product.productState === 'INACTIVE') && (
                                    <div className='wrapper change-status'>
                                      <FontAwesomeIcon
                                        icon={faPowerOff}
                                        onClick={() =>
                                          handleStatus('finlog', product)
                                        }
                                      />

                                      <motion.div
                                        initial={animationVisible}
                                        animate={'visible'}
                                        exit={animationInvisible}
                                        transition={{ duration: 0.3 }}
                                        variants={animationVariants}
                                      >
                                        <p className='text-deactivate'>
                                          {product.active
                                            ? 'desativar produto'
                                            : 'ativar produto'}
                                        </p>
                                      </motion.div>
                                    </div>
                                  )}
                                  {(product.productState === 'DRAFT' ||
                                    product.productState === 'PUBLISHED') && (
                                    <>
                                      <div className='wrapper'>
                                        <FontAwesomeIcon
                                          icon={faEdit}
                                          className='mr-0 edit text-edit'
                                          onClick={() =>
                                            openFinlog(true, product.id)
                                          }
                                        />
                                        <motion.div
                                          initial={animationVisible}
                                          animate={'visible'}
                                          exit={animationInvisible}
                                          transition={{ duration: 0.3 }}
                                          variants={animationVariants}
                                        >
                                          <p className='text-edit'>editar</p>
                                        </motion.div>
                                      </div>
                                      <div className='wrapper'>
                                        <FontAwesomeIcon
                                          icon={faTrashAlt}
                                          className='remove text-remove'
                                          onClick={() =>
                                            openModalRemoveProduct(
                                              'finlog',
                                              product,
                                              finlogList
                                            )
                                          }
                                        />
                                        <motion.div
                                          initial={animationVisible}
                                          animate={'visible'}
                                          exit={animationInvisible}
                                          transition={{ duration: 0.3 }}
                                          variants={animationVariants}
                                        >
                                          <p className='text-remove'>
                                            eliminar
                                          </p>
                                        </motion.div>
                                      </div>
                                    </>
                                  )}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    )}
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header
                  onClick={() =>
                    setTabs({
                      guerin: !tabs.guerin,
                      rodinhas: false,
                      xtracars: false,
                      bedriven: false,
                      finlog: false,
                    })
                  }
                >
                  <Accordion.Toggle as={Button} variant='link' eventKey='1'>
                    <img
                      src={require('../../../assets/logos/guerin-xs-logo-on-2x.png')}
                      alt=''
                    />
                    <FontAwesomeIcon
                      icon={tabs.guerin ? faArrowDown : faArrowRight}
                      color='#92C05B'
                    />
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey='1'>
                  <Card.Body>
                    {guerinList.length === 0 && (
                      <p>Não há produtos cadastrados.</p>
                    )}
                    {guerinList.length > 0 && (
                      <Table responsive>
                        <thead>
                          <tr>
                            <th>Produto</th>
                            <th>
                              Criação
                              <br />
                              <span className='font-light'>
                                Última alteração
                              </span>
                            </th>
                            <th>Estado</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {guerinList.map((product: any) => {
                            return (
                              <tr key={product.id}>
                                <td
                                  onClick={() => openGuerin(true, product.id)}
                                  className='clickable'
                                >
                                  <div className='wrapper-product'>
                                    <div className='item'>
                                      <img
                                        src={
                                          product.uuidImageFile
                                            ? product.uuidImageFile
                                            : require('../../../assets/car_placeholder.png')
                                        }
                                        alt=''
                                      />
                                    </div>
                                    <div className='item item-data'>
                                      <p className='title'>{product.make}</p>
                                      <p className='description'>
                                        {product.name}
                                      </p>
                                    </div>
                                  </div>
                                </td>
                                <td
                                  className='edit clickable'
                                  onClick={() => openGuerin(true, product.id)}
                                >
                                  <p className='creation'>
                                    {product.dateCreated &&
                                      dayjs(product.dateCreated).format(
                                        'DD/MM/YYYY HH:mm'
                                      )}
                                  </p>
                                  <p>
                                    {product.dateLastUpdated &&
                                      dayjs(product.dateLastUpdated).format(
                                        'DD/MM/YYYY HH:mm'
                                      )}
                                  </p>
                                </td>
                                <td className='clickable'>
                                  <BadgeComponent
                                    badge={product.productState}
                                  />
                                </td>
                                <td className='actions'>
                                  {(product.productState === 'PUBLISHED' ||
                                    product.productState === 'INACTIVE') && (
                                    <div className='wrapper change-status'>
                                      <FontAwesomeIcon
                                        icon={faPowerOff}
                                        onClick={() =>
                                          handleStatus('guerin', product)
                                        }
                                      />
                                      <motion.div
                                        initial={animationVisible}
                                        animate={'visible'}
                                        exit={animationInvisible}
                                        transition={{ duration: 0.3 }}
                                        variants={animationVariants}
                                      >
                                        <p className='text-deactivate'>
                                          {product.active
                                            ? 'desativar produto'
                                            : 'ativar produto'}
                                        </p>
                                      </motion.div>
                                    </div>
                                  )}
                                  {(product.productState === 'DRAFT' ||
                                    product.productState === 'PUBLISHED') && (
                                    <>
                                      <div className='wrapper'>
                                        <FontAwesomeIcon
                                          icon={faEdit}
                                          className='edit text-edit'
                                          onClick={() =>
                                            openGuerin(true, product.id)
                                          }
                                        />
                                        <motion.div
                                          initial={animationVisible}
                                          animate={'visible'}
                                          exit={animationInvisible}
                                          transition={{ duration: 0.3 }}
                                          variants={animationVariants}
                                        >
                                          <p className='text-edit'>editar</p>
                                        </motion.div>
                                      </div>
                                      <div className='wrapper'>
                                        <FontAwesomeIcon
                                          icon={faTrashAlt}
                                          className='remove text-remove'
                                          onClick={(e) =>
                                            openModalRemoveProduct(
                                              'guerin',
                                              product,
                                              guerinList
                                            )
                                          }
                                        />
                                        <motion.div
                                          initial={animationVisible}
                                          animate={'visible'}
                                          exit={animationInvisible}
                                          transition={{ duration: 0.3 }}
                                          variants={animationVariants}
                                        >
                                          <p className='text-remove'>
                                            eliminar
                                          </p>
                                        </motion.div>
                                      </div>
                                    </>
                                  )}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    )}
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header
                  onClick={() =>
                    setTabs({
                      guerin: false,
                      rodinhas: false,
                      xtracars: !tabs.xtracars,
                      bedriven: false,
                      finlog: false,
                    })
                  }
                >
                  <Accordion.Toggle as={Button} variant='link' eventKey='2'>
                    <img
                      src={require('../../../assets/logos/xtracars-xs-logo-on-2x.png')}
                      alt=''
                    />
                    <FontAwesomeIcon
                      icon={tabs.xtracars ? faArrowDown : faArrowRight}
                      color='#6C6C6C'
                    />
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey='2'>
                  <Card.Body>
                    {xtracarsList.length === 0 && (
                      <p>Não há produtos cadastrados.</p>
                    )}
                    {xtracarsList.length > 0 && (
                      <Table responsive>
                        <thead>
                          <tr>
                            <th>Produto</th>
                            <th>
                              Criação
                              <br />
                              <span className='font-light'>
                                Última alteração
                              </span>
                            </th>
                            <th>Estado</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {xtracarsList.map((product: any) => {
                            return (
                              <tr key={product.id}>
                                <td
                                  onClick={() => openXtracars(true, product.id)}
                                  className='clickable'
                                >
                                  <div className='wrapper-product'>
                                    <div className='item'>
                                      <img
                                        src={
                                          product.uuidImageFile
                                            ? product.uuidImageFile
                                            : require('../../../assets/car_placeholder.png')
                                        }
                                        alt=''
                                      />
                                    </div>
                                    <div className='item item-data'>
                                      <p className='title'>{product.make}</p>
                                      <p className='description'>
                                        {product.name}
                                      </p>
                                    </div>
                                  </div>
                                </td>
                                <td
                                  className='edit clickable'
                                  onClick={() => openXtracars(true, product.id)}
                                >
                                  <p className='creation'>
                                    {product.dateCreated &&
                                      dayjs(product.dateCreated).format(
                                        'DD/MM/YYYY HH:mm'
                                      )}
                                  </p>
                                  <p>
                                    {product.dateLastUpdated &&
                                      dayjs(product.dateLastUpdated).format(
                                        'DD/MM/YYYY HH:mm'
                                      )}
                                  </p>
                                </td>
                                <td
                                  onClick={() => openXtracars(true, product.id)}
                                  className='clickable'
                                >
                                  <BadgeComponent
                                    badge={product.productState}
                                  />
                                </td>
                                <td className='actions'>
                                  {(product.productState === 'PUBLISHED' ||
                                    product.productState === 'INACTIVE') && (
                                    <div className='wrapper change-status'>
                                      <FontAwesomeIcon
                                        icon={faPowerOff}
                                        onClick={() =>
                                          handleStatus('xtracars', product)
                                        }
                                      />
                                      <motion.div
                                        initial={animationVisible}
                                        animate={'visible'}
                                        exit={animationInvisible}
                                        transition={{ duration: 0.3 }}
                                        variants={animationVariants}
                                      >
                                        <p className='text-deactivate'>
                                          {product.active
                                            ? 'desativar produto'
                                            : 'ativar produto'}
                                        </p>
                                      </motion.div>
                                    </div>
                                  )}
                                  {(product.productState === 'DRAFT' ||
                                    product.productState === 'PUBLISHED') && (
                                    <>
                                      <div className='wrapper'>
                                        <FontAwesomeIcon
                                          icon={faEdit}
                                          className='edit text-edit'
                                          onClick={() =>
                                            openXtracars(true, product.id)
                                          }
                                        />
                                        <motion.div
                                          initial={animationVisible}
                                          animate={'visible'}
                                          exit={animationInvisible}
                                          transition={{ duration: 0.3 }}
                                          variants={animationVariants}
                                        >
                                          <p className='text-edit'>editar</p>
                                        </motion.div>
                                      </div>
                                      <div className='wrapper'>
                                        <FontAwesomeIcon
                                          icon={faTrashAlt}
                                          className='remove text-remove'
                                          onClick={() =>
                                            openModalRemoveProduct(
                                              'xtracars',
                                              product,
                                              xtracarsList
                                            )
                                          }
                                        />
                                        <motion.div
                                          initial={animationVisible}
                                          animate={'visible'}
                                          exit={animationInvisible}
                                          transition={{ duration: 0.3 }}
                                          variants={animationVariants}
                                        >
                                          <p className='text-remove'>
                                            eliminar
                                          </p>
                                        </motion.div>
                                      </div>
                                    </>
                                  )}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    )}
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header
                  onClick={() =>
                    setTabs({
                      guerin: false,
                      rodinhas: !tabs.rodinhas,
                      xtracars: false,
                      bedriven: false,
                      finlog: false,
                    })
                  }
                >
                  <Accordion.Toggle as={Button} variant='link' eventKey='3'>
                    <img
                      src={require('../../../assets/logos/rodinhas-xs-logo-on-2x.png')}
                      alt=''
                    />
                    <FontAwesomeIcon
                      icon={tabs.rodinhas ? faArrowDown : faArrowRight}
                      color='#FFD940'
                    />
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey='3'>
                  <Card.Body>
                    {rodinhasList.length === 0 && (
                      <p>Não há produtos cadastrados.</p>
                    )}
                    {rodinhasList.length > 0 && (
                      <Table responsive>
                        <thead>
                          <tr>
                            <th>Produto</th>
                            <th>
                              Criação
                              <br />
                              <span className='font-light'>
                                Última alteração
                              </span>
                            </th>
                            <th>Estado</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {rodinhasList.map((product: any) => {
                            return (
                              <tr key={product.id}>
                                <td
                                  onClick={() => openRodinhas(true, product.id)}
                                  className='clickable'
                                >
                                  <div className='wrapper-product'>
                                    <div className='item'>
                                      <img src={product.uuidImageFile} alt='' />
                                    </div>
                                    <div className='item item-data'>
                                      <p className='title'>{product.make}</p>
                                      <p className='description'>
                                        {product.name}
                                      </p>
                                    </div>
                                  </div>
                                </td>
                                <td
                                  className='edit clickable'
                                  onClick={() => openRodinhas(true, product.id)}
                                >
                                  <p className='creation'>
                                    {product.dateCreated &&
                                      dayjs(product.dateCreated).format(
                                        'DD/MM/YYYY HH:mm'
                                      )}
                                  </p>
                                  <p>
                                    {product.dateLastUpdated &&
                                      dayjs(product.dateLastUpdated).format(
                                        'DD/MM/YYYY HH:mm'
                                      )}
                                  </p>
                                </td>
                                <td
                                  onClick={() => openRodinhas(true, product.id)}
                                  className='clickable'
                                >
                                  <BadgeComponent
                                    badge={product.productState}
                                  />
                                </td>
                                <td className='actions'>
                                  {product.productState === 'DRAFT' && (
                                    <>
                                      <div className='wrapper'>
                                        <FontAwesomeIcon
                                          icon={faEdit}
                                          className='edit text-edit'
                                          onClick={() =>
                                            openRodinhas(true, product.id)
                                          }
                                        />
                                        <motion.div
                                          initial={animationVisible}
                                          animate={'visible'}
                                          exit={animationInvisible}
                                          transition={{ duration: 0.3 }}
                                          variants={animationVariants}
                                        >
                                          <p className='text-edit'>editar</p>
                                        </motion.div>
                                      </div>
                                      <div className='wrapper'>
                                        <FontAwesomeIcon
                                          icon={faTrashAlt}
                                          className='remove text-remove'
                                          onClick={() =>
                                            openModalRemoveProduct(
                                              'rodinhas',
                                              product,
                                              rodinhasList
                                            )
                                          }
                                        />
                                        <motion.div
                                          initial={animationVisible}
                                          animate={'visible'}
                                          exit={animationInvisible}
                                          transition={{ duration: 0.3 }}
                                          variants={animationVariants}
                                        >
                                          <p className='text-remove'>
                                            eliminar
                                          </p>
                                        </motion.div>
                                      </div>
                                    </>
                                  )}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    )}
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              {/* <Card>
                 <Card.Header
                  onClick={() =>
                    setTabs({
                      guerin: false,
                      rodinhas: false,
                      xtracars: false,
                      bedriven: !tabs.bedriven,
                      finlog: false,
                    })
                  }
                >
                 <Accordion.Toggle as={Button} variant='link' eventKey='4'>
                    <img
                      src={require('../../../assets/logos/bedriven-xs-logo-on-2x.png')}
                      alt=''
                    />
                    <FontAwesomeIcon
                      icon={tabs.bedriven ? faArrowDown : faArrowRight}
                      color='#53A4D4'
                    />
                </Accordion.Toggle> 
                </Card.Header>
                <Accordion.Collapse eventKey='4'>
                  <Card.Body>
                    {bedrivenList.length === 0 && (
                      <p>Não há produtos cadastrados.</p>
                    )}
                    {bedrivenList.length > 0 && (
                      <Table responsive>
                        <thead>
                          <tr>
                            <th>Produto</th>
                            <th>
                              Criação
                              <br />
                              <span className='font-light'>
                                Última alteração
                              </span>
                            </th>
                            <th>Estado</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {bedrivenList.map((product: any) => {
                            return (
                              <tr key={product.id}>
                                <td
                                  onClick={() =>
                                    openBedriven(true, product.id, false)
                                  }
                                  className='clickable'
                                >
                                  <div className='wrapper-product'>
                                    <div className='item'>
                                      <img src={product.uuidImageFile} alt='' />
                                    </div>
                                    <div className='item item-data'>
                                      <p className='title'>{product.make}</p>
                                      <p className='description'>
                                        {product.name}
                                      </p>
                                    </div>
                                  </div>
                                </td>
                                <td
                                  className='edit clickable'
                                  onClick={() =>
                                    openBedriven(true, product.id, false)
                                  }
                                >
                                  <p className='creation'>
                                    {product.dateCreated &&
                                      dayjs(product.dateCreated).format(
                                        'DD/MM/YYYY HH:mm'
                                      )}
                                  </p>
                                  <p>
                                    {product.dateLastUpdated &&
                                      dayjs(product.dateLastUpdated).format(
                                        'DD/MM/YYYY HH:mm'
                                      )}
                                  </p>
                                </td>
                                <td
                                  onClick={() =>
                                    openBedriven(true, product.id, false)
                                  }
                                  className='clickable'
                                >
                                  <BadgeComponent
                                    badge={product.productState}
                                  />
                                </td>
                                <td className='actions'>
                                  {(product.productState === 'PUBLISHED' ||
                                    product.productState === 'INACTIVE') && (
                                    <div className='wrapper change-status'>
                                      <FontAwesomeIcon
                                        icon={faPowerOff}
                                        onClick={() =>
                                          handleStatus('bedriven', product)
                                        }
                                      />
                                      <motion.div
                                          initial={animationVisible}
                                          animate={'visible'}
                                          exit={animationInvisible}
                                          transition={{ duration: 0.3 }}
                                          variants={animationVariants}
                                        >
                                        <p className='text-deactivate'>
                                          {product.active
                                            ? 'desativar produto'
                                            : 'ativar produto'}
                                        </p>
                                      </motion.div>
                                    </div>
                                  )}
                                  {product.productState === 'DRAFT' && (
                                    <>
                                      <div className='wrapper'>
                                        <FontAwesomeIcon
                                          icon={faEdit}
                                          className='edit text-edit'
                                          onClick={() =>
                                            openBedriven(
                                              true,
                                              product.id,
                                              false
                                            )
                                          }
                                        />
                                        <motion.div
                                          initial={animationVisible}
                                          animate={'visible'}
                                          exit={animationInvisible}
                                          transition={{ duration: 0.3 }}
                                          variants={animationVariants}
                                        >
                                          <p className='text-edit'>editar</p>
                                        </motion.div>
                                      </div>
                                      <div className='wrapper'>
                                        <FontAwesomeIcon
                                          icon={faTrashAlt}
                                          className='remove text-remove'
                                          onClick={() =>
                                            openModalRemoveProduct(
                                              'bedriven',
                                              product,
                                              bedrivenList
                                            )
                                          }
                                        />
                                        <motion.div
                                          initial={animationVisible}
                                          animate={'visible'}
                                          exit={animationInvisible}
                                          transition={{ duration: 0.3 }}
                                          variants={animationVariants}
                                        >
                                          <p className='text-remove'>
                                            eliminar
                                          </p>
                                        </motion.div>
                                      </div>
                                    </>
                                  )}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    )}
                  </Card.Body>
                </Accordion.Collapse>
              </Card> */}
            </Accordion>
          </div>
        </div>
      </Content>
      {isLoading && (
        <Loading
          loading
          background='rgba(255, 255, 255, .4)'
          loaderColor='#3498db'
        />
      )}

      {successRemove && (
        <SweetAlert
          success
          title='Sucesso!'
          onConfirm={() => {
            setSuccessRemove(false);
            setAlertMsg('');
          }}
        >
          {alertMsg}.
        </SweetAlert>
      )}

      {errorRemove && (
        <SweetAlert
          danger
          title='Erro!'
          onConfirm={() => {
            setErrorRemove(false);
            setAlertMsg('');
          }}
        >
          {alertMsg}.
        </SweetAlert>
      )}

      <Modal backdrop='static' show={showRemoveModal} size='lg'>
        <Modal.Header>
          <h2>
            Produto <span className='product'>{selectedProduct}</span>
          </h2>
        </Modal.Header>
        <Modal.Body>
          {activeProduct.uuidImageFile && (
            <img
              src={activeProduct.uuidImageFile}
              alt=''
              className='modal-image'
            />
          )}
          <FontAwesomeIcon icon={faTrashAlt} className='remove pt-3' />
          <h2 className='pt-5'>Tem certeza que pretende eliminar?</h2>
          <p className='disclaimer'>Esta ação não pode ser revertida.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='remove'
            onClick={() =>
              removeByCompany(selectedCompany, selectedId, activeList)
            }
          >
            SIM, ELIMINAR
          </Button>
          <Button variant='back' onClick={() => setShowRemoveModal(false)}>
            NÃO, VOLTAR
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal backdrop='static' show={showDeactivateModal} size='lg'>
        <Modal.Header>
          <h2>
            Produto <span className='product'>{selectedProduct}</span> -{' '}
            {activeProduct.name}
          </h2>
        </Modal.Header>
        <Modal.Body>
          {activeProduct.uuidImageFile && (
            <img
              src={activeProduct.uuidImageFile}
              alt=''
              className='modal-image'
            />
          )}
          <FontAwesomeIcon icon={faPowerOff} className='pt-3' />
          <h2 className='pt-5'>
            Tem certeza que pretende{' '}
            {activeProduct.active ? 'desativar' : 'ativar'}?
          </h2>
          <p className='disclaimer'>Poderá reverter a sua ação mais tarde.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='remove'
            onClick={() => changeStatusProduct(activeProduct)}
          >
            SIM, {activeProduct.active ? 'DESATIVAR' : 'ATIVAR'}
          </Button>
          <Button
            variant='back'
            onClick={() => {
              setShowDeactivateModal(false);
              setSelectedProduct('');
            }}
          >
            NÃO, VOLTAR
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Configuration;

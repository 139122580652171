import { PROMO_CODE } from '../constants/config';
import { createStore } from 'redux';
import rootReducer from './reducers';

export function getPromocode() {
  try {
    return localStorage.getItem(PROMO_CODE);
  } catch (e) {
    throw e;
  }
}

export function storePromocode(promocode) {
  try {
    return localStorage.setItem(PROMO_CODE, promocode);
  } catch (e) {
    throw e;
  }
}

export function deletePromocode() {
  try {
    return localStorage.removeItem(PROMO_CODE);
  } catch (e) {
    throw e;
  }
}

const store = createStore(
  rootReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export default store;

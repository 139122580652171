import React, { useState, useEffect } from 'react';
import {
  Container,
  Col,
  Row,
  Form,
  InputGroup,
  Button,
  Pagination,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faPlus } from '@fortawesome/free-solid-svg-icons';
import { Title, Content, Styles } from '../../../style/client';
import { clientService } from '../../../services/clientService';
import Loading from 'react-fullscreen-loading';
import { ClientTable } from '../../../components/Clients/ClientTable';
import { useHistory } from 'react-router-dom';
import { iCan } from '../../../redux/sessionReducer';

import {
  CLIENT_LIST_PAGE_TITLE,
  CLIENT_INFO_PAGE_TITLE,
  NR_OF_CLIENTS_MESSAGE,
  NR_OF_CLIENTS_PER_PAGE,
} from '../../../constants/clientConstants';
import { Link } from 'react-router-dom';

const Clients: React.FC = () => {
  const [clientList, setClientList] = useState([] as any);
  const [isLoading, setIsLoading] = useState(true);
  // const [userData, setUserData] = useState({})
  const [searchFilter, setSearchFilter] = useState('');
  const [activePage, setActivePage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [filter, setFilter] = useState('');
  const [clientPage, setClientPage] = useState(null);
  const history = useHistory();

  useEffect(() => {
    let mounted = true;
    setIsLoading(true);

    getClients(activePage, filter).then((result) => {
      if (result) {
        setClientList(result.data.content);

        setTotalPages(result.data.totalPages);
        setTotalElements(result.data.totalElements);
      } else {
        setIsLoading(false);
      }

      if (mounted) {
        setIsLoading(false);
      }
    }).catch((e) =>{setIsLoading(false);} )

    return function cleanup() {
      mounted = false;
    };
  }, [activePage, filter]);

  const getClients = async (activePage, filter) => {
    try {
      // We will need to send this via props to the table in order
      // to show who created the client
      // setUserData({
      //     userVerified: result.data.userVerified,
      //     dateCreated: result.data.dateCreated,
      //     dateLastUpdated: result.data.dateLastUpdated,
      //     userPublished: result.data.userPublished,
      //     userCreated: result.data.userCreated
      // })

      return clientService.getAll(NR_OF_CLIENTS_PER_PAGE, activePage, filter);
    } catch (error) {
      console.log(error);
    }
  };

  const getNumberOfClientsMessage = () => {
    return totalElements + NR_OF_CLIENTS_MESSAGE;
  };

  const filterClientsIconClick = () => {
    setActivePage(0);
    setFilter(searchFilter);
  };

  const filterClientsEnterClick = (event) => {
    //Enter key up
    if (event.keyCode === 13) {
      event.preventDefault();

      setActivePage(0);
      setFilter(searchFilter);
    } else {
      setSearchFilter(event.target.value);
    }
  };

  const previousActivePage = () => {
    if (activePage - 1 >= 0) {
      setActivePage(activePage - 1);
    }
  };

  const nextActivePage = () => {
    if (activePage + 1 < totalPages) {
      setActivePage(activePage + 1);
    }
  };

  const createPaginationItem = (pageIndex, pageNumber, activePage) => {
    return (
      <Pagination.Item
        className='pagination'
        onClick={(e) => setActivePage(pageIndex)}
        key={pageIndex}
        active={pageIndex === activePage}
      >
        {pageNumber}
      </Pagination.Item>
    );
  };

  const getPaginationPages = () => {
    const delta = 1;

    let items = [] as any;
    let active = activePage;

    for (
      let i = Math.max(2, activePage - delta);
      i <= Math.min(totalPages - 1, activePage + delta);
      i++
    ) {
      items.push(createPaginationItem(i - 1, i, active));
    }

    if (activePage - delta > 2) {
      if (items.length === totalPages - 2) {
        items.unshift(createPaginationItem(1, 2, active));
      } else {
        items.unshift(<Pagination.Ellipsis className='pagination' />);
      }
    }

    if (activePage + delta < totalPages - 1) {
      if (items.length === totalPages - 3) {
        items.push(
          createPaginationItem(totalPages - 2, totalPages - 1, active)
        );
      } else {
        items.push(<Pagination.Ellipsis className='pagination' key={`pagination${Math.random()}`} />);
      }
    }

    // first page
    items.unshift(createPaginationItem(0, 1, active));

    if (totalPages <= 1) return items;

    // last page
    items.push(createPaginationItem(totalPages - 1, totalPages, active));

    return items;
  };

  const handleClientPageChange = (client) => {
    history.push(`/clients/view/${client.id}`);
  };

  return (
    <>
      <Title>{CLIENT_LIST_PAGE_TITLE}</Title>
      <Content>
        <div className='content left-corner right-corner'>
          <Container fluid className='card-no-tabs'>
            <Row className='division'>
              <Col md={{ offset: 10 }}>
                {iCan('ROLE_CREATE_CLIENT') && (
                  <Link to={'/clients/create/'}>
                    <Button
                      variant='outline-info'
                      size='lg'
                      className='float-right'
                      style={Styles.button}
                      data-testid='newClientButton'
                    >
                      <FontAwesomeIcon icon={faPlus} />
                    </Button>
                  </Link>
                )}
              </Col>
            </Row>

            <Row>
              <Col className='title'>
                <Title data-testid='numberOfClients'>
                  {getNumberOfClientsMessage()}
                </Title>
              </Col>
            </Row>

            <Row
              style={Styles.searchRow}
              xs={1}
              sm={1}
              md={1}
              lg={2}
              className='justify-content-center'
            >
              <Col style={Styles.searchCol}>
                <InputGroup>
                  <Form.Control
                    data-testid='searchBar'
                    className='input'
                    type='text'
                    onKeyUp={(e) => filterClientsEnterClick(e)}
                    placeholder='Pesquise por cliente ou contacto'
                  />
                  <div
                    className='search-icon'
                    data-testid='searchIcon'
                    onClick={(e) => filterClientsIconClick()}
                  >
                    <FontAwesomeIcon
                      style={Styles.searchIcon}
                      color='white'
                      icon={faSearch}
                    />
                  </div>
                </InputGroup>
              </Col>
            </Row>

            <Row style={Styles.clientTable}>
              <ClientTable
                clientList={clientList}
                setClientPage={handleClientPageChange}
              />
            </Row>

            <Row className='justify-content-center'>
              <Pagination>
                <Pagination.Prev
                  style={Styles.pageItem}
                  onClick={(e) => previousActivePage()}
                />
                {getPaginationPages()}
                <Pagination.Next
                  style={Styles.pageItem}
                  onClick={(e) => nextActivePage()}
                />
              </Pagination>
            </Row>
          </Container>
        </div>
      </Content>
      {isLoading && (
        <Loading
          loading
          background='rgba(255, 255, 255, .4)'
          loaderColor='#3498db'
        />
      )}
    </>
  );
};

export default Clients;

import React, { useEffect } from 'react';

function Payment() {
  useEffect(() => {
    const script = document.createElement('script');

    // script.src = `https://test.oppwa.com/v1/paymentWidgets.js?checkoutId=97860E92FC1994ADB62BCA7076BD60CB.uat01-vm-tx01`
    script.async = true;
    function addErrorText() {
      let card = document.querySelector('.wpwl-control-cardHolder');
      if (card) {
        card.classList.add('wpwl-has-error');
        document.querySelectorAll('.wpwl-has-error').forEach((headline) => {
          headline.insertAdjacentHTML(
            'afterend',
            '<div class="wpwl-hint wpwl-hint-cardHolderError">Invalid card holder</div>'
          );
        });
      }
    }

    function validateHolder(e) {
      let holder = document.getElementsByName('card.holder');
      if (holder) {
        let input = holder as unknown as HTMLInputElement;
        let inputValue = input[0].value;
        if (inputValue) {
          if (inputValue.trim().length < 2) {
            addErrorText();
            return false;
          }
          return true;
        }

        addErrorText();
        return false;
      }

      addErrorText();
      return false;
    }
    window.wpwlOptions = {
      style: 'card',
      brandDetection: 'true',
      paymentTarget: '_top',
      onReady: function (e) {
        let card = document.querySelector('.wpwl-form-card');
        if (card) {
          let button = card.querySelector('.wpwl-button-pay');
          if (button) {
            button.addEventListener('click', (e) => {
              validateHolder(e);
            });
          }
        }
      },
      onBeforeSubmitCard: function (e) {
        return validateHolder(e);
      },
    };

    document.body.appendChild(script);
  });

  return (
    <>
      <div id='payment-number'></div>
      <form
        action='http://172.16.102.28/waiting'
        className='paymentWidgets'
        data-brands='VISA MASTER AMEX'
      ></form>
    </>
  );
}

export default Payment;

import api from '../utils/api';

export const autocompleteService = {
    getAddress,
    getAddressDetails
};

async function getAddress(data) {
    const response = await api.apiPrivate
        .get(
            `api/product/rodinhas-communication/get-addresses/${data}`
        )
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            //do nothing
        });
    return response;
};

async function getAddressDetails(data) {
    const response = await api.apiPrivate
        .get(
            `api/product/rodinhas-communication/get-address-details/${data}`
        )
        .then((response) => {
            console.log(response.data)
            return response.data;
        })
        .catch((error) => {
            //Do nothing
        });
    return response;
}





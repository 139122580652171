import React, { useState, useEffect, useRef } from 'react';
import { Form, Container, Row, Col, Image, Button } from 'react-bootstrap';
import { Title, Content, Styles } from '../../../style/content';
import { bookingService } from '../../../services/bookingService';
import ReserveCard from './ReserveCard';
import { ReservationProduct } from './reservation.interface';
import { ModalRodinhas } from './ModalReservationRodinhas';
import ModalReservationGuerin from '../Reservations/ModalReservationGuerin';
import ModalReservationXtracars from '../Reservations/ModalReservationXtracars';
import Loading from 'react-fullscreen-loading';
import SweetAlert from 'react-bootstrap-sweetalert';
import { SweetAlertType } from 'react-bootstrap-sweetalert/dist/types';

const Reservations: React.FC = () => {
  const [reservationsList, setReservationsList] = useState(
    [] as ReservationProduct[]
  );
  const [contract, setContract] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [booking, setBooking] = useState({});
  const [openModalGuerin, setOpenModalGuerin] = useState(false);
  const [openModalXtracars, setOpenModalXtracars] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [alertDialog, setAlertDialog] = useState(false);
  const [confirmCancelDialog, setConfirmCancelDialog] = useState(false);
  const [reserveToCancel, setReserveToCancel] = useState({
    serviceId: '',
    reserveId: '',
  });
  const [alertConfig, setAlertConfig] = useState({} as AlertConfig);

  useEffect(() => {
    getReservationsList();
  }, []);

  const getReservations = async (id) => {
    const result = await bookingService.getBookings(`&serviceId=${id}`);
    if (result.list) {
      return result.list;
    }
  };

  const getReservationsList = async () => {
    const result = await Promise.all([
      getReservations(1),
      getReservations(2),
      getReservations(3),
      getReservations(4),
    ]);
    if (result) {
      setReservationsList(result.flat());
      setIsLoading(false);
    }
  };

  const editProduct = async (product) => {
    if (product.serviceId === 2) {
      setBooking(product)
      setOpenModalGuerin(true);
    }

    if (product.serviceId === 3) {
      setBooking(product)
      setOpenModalXtracars(true);
    }
  };

  const confirmCancelProduct = (product) => {
    setReserveToCancel(product)
    setConfirmCancelDialog(true)
  };

  const cancelProduct = async () => {
    let product = reserveToCancel as any
    setIsLoading(true);
    if (product.serviceId === 2 || product.serviceId === 3) {
      const result = await bookingService.cancelReservation({
        serviceId: product.serviceId,
        reservationNr: product.reserveId,
      });
      if (result.status === 200) {
        getReservationsList();
        setAlertConfig({
          title: "Sucesso",
          message: "Reserva cancelada com sucesso",
          type: "success",
          redirect: false,
        });
        setAlertDialog(true);
        setIsLoading(false);
      } else {
        setAlertConfig({
          title: "Error",
          message: "Erro ao cancelar a reserva.",
          type: "error",
          redirect: false,
        });
        setAlertDialog(true);
        setIsLoading(false);
      }
      getReservationsList();
    }
  };

  const getBooking = () => {
    return booking;
  };

  const acceptContract = async (e) => {
    setContract(e);
    setOpenModal(true);
  };

  const closeButton = () => {
    setOpenModal(false);
    setOpenModalGuerin(false);
    setOpenModalXtracars(false);
    getReservationsList();
  };

  const onClose = () => {
    getReservationsList();
  };

  return (
    <>
      {isLoading && (
        <Loading
          loading
          background='rgba(255, 255, 255, .4)'
          loaderColor='#3498db'
        />
      )}
      <Title>Reservas</Title>

      <Content>
        <Container fluid className='card-no-tabs content_card'>
          <h1>Reservas</h1>
          {reservationsList &&
            reservationsList.length > 0 &&
            reservationsList.map((el, index) => {
              return (
                <section id={el.packId?.toString()} key={index}>
                  <Row className='mt-4 mb-4' key={index}>
                    <Col
                      md={12}
                      className='d-flex justify-content-center align-middle'
                    >
                      <ReserveCard
                        key={el.model}
                        product={el}
                        editProduct={(p) => editProduct(p)}
                        acceptContract={(p) => acceptContract(p)}
                        cancelProduct={(p) => confirmCancelProduct(p)}
                      ></ReserveCard>
                    </Col>
                  </Row>
                </section>
              );
            })}
          {reservationsList && reservationsList.length === 0 && (
           <Container fluid className='card-no-tabs mt-4 movements p-4'>
                <h4>Sem reservas</h4>
           </Container>
          )}
        </Container>
      </Content>
      <ModalRodinhas
        closeButton={closeButton}
        open={openModal}
        onClose={onClose}
        contract={contract}
      />
      <ModalReservationGuerin
        closeButton={closeButton}
        open={openModalGuerin}
        onClose={onClose}
        booking={getBooking()}
      ></ModalReservationGuerin>
      <ModalReservationXtracars
        closeButton={closeButton}
        open={openModalXtracars}
        onClose={onClose}
        booking={getBooking()}
      ></ModalReservationXtracars>
      {alertDialog && (
        <SweetAlert
          type={alertConfig?.type}
          title={alertConfig?.title ?? ''}
          onConfirm={() => {
            setAlertDialog(false);
          }}
        >
          {alertConfig?.message ?? ''}.
        </SweetAlert>
      )}
      {confirmCancelDialog && (
        <SweetAlert
          type='warning'
          title={'Cancelamento'}
          showCancel={true}
          cancelBtnText={'Cancelar'}
          cancelBtnBsStyle='primary'
          confirmBtnBsStyle='light'
          onCancel={() => {
            setConfirmCancelDialog(false); setReserveToCancel({
              serviceId: '',
              reserveId: '',
            });
          }}
          onConfirm={() => {
            setConfirmCancelDialog(false);
            cancelProduct();
          }}
        >
          {`Confirma o cancelamento da reserva  de nº ${reserveToCancel.reserveId}?`}
        </SweetAlert>
      )}
    </>
  );
};

export default Reservations;

export interface AlertConfig {
  title: String;
  message: String;
  type: SweetAlertType;
  redirect: Boolean;
}

import CMS from '../screens/CMS';

const routes: MyRoute[] = [
  {
    path: '/cms',
    exact: true,
    component: CMS,
    role: 'ROLE_PRODUCT_MANAGER',
    auth: true,
  },
];

interface MyRoute {
  path: string;
  exact: boolean;
  component: React.FC<{}>;
  role: string;
  auth: boolean;
}

export default routes;

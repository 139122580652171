import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CircularProgress, Switch } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import React, { useState } from "react";
import { Container, Row, Col, Image, Button } from 'react-bootstrap';
import { Doughnut } from 'react-chartjs-2';
import { subscriptionService } from '../../../services/subscriptionService';
import '../../../style/User/subscriptions.scss'



export const ModalTopUp = ({ closeButton, open, showResult}) => {
    
    const [topUpValue, SetTopUpValue] = useState(0);
    const [isAddition, setIsAddition] = useState(false);
    const [isRequesting, setIsRequesting] = useState(false);
    
    const balanceChange =  async () =>{
        console.log("wait for the payment to be done");
        let data = {
            valuePayment: topUpValue,
            categoryPayment : isAddition ? "ADDITION" : "SUBTRACTION",
        }
        //disable button to avoid multiple requests
        setIsRequesting(true);
        try {
            const result = await subscriptionService.paymentChange(data);
            if (result.status === 200) {
                showResult(result.message, "success");
            } else {
                showResult(result.message, "error");
            }
        } catch (error) {
        }
        //enable button
        setIsAddition(false);
        setIsRequesting(false);
    }


    const nodata = {
        datasets: [
          {
            data: [100],
            backgroundColor: ['#eeeeee'],
            borderColor: ['#eeeeee'],
            borderWidth: 4,
          },
        ],
      };


    return (
        <Modal open={open} className='container_modal_base'>
            <Container
                className='modal_base topup_base'
            >
                <Row className='justify-content-end'>
                    <button
                        onClick={() => {closeButton();
                        SetTopUpValue(0);
                        setIsAddition(false);
                        }}
                        className='button_close'
                    >
                        <FontAwesomeIcon icon={faTimes} />
                    </button>
                </Row>
                <Row className='d-flex justify-content-center mt-2'>
                    <h2>Editar Plafond</h2>
                </Row>
                <Row className='d-flex justify-content-center mt-4 mb-4'>
                    <Col className='d-flex justify-content-center brand_col'>
                        <h3>Defina valor mensal</h3>
                    </Col>
                </Row>
                <Row>
                    <div className="topup_outer_circle"> 
                        <div className="topup_inner_circle">
                            <div  className="topup_inner_circle_content" >

                            <input    
                            type="number"
                            maxLength={4}
                            placeholder={'0'}
                            onChange={(e) => {
                                SetTopUpValue(parseInt(e.target.value))
                                e.target.value = parseInt(e.target.value).toString();
                            }} 
                            value={topUpValue}></input>
                            €
                            </div>
                        </div>
                    </div>
                </Row>
                <Row className='d-flex justify-content-center mt-4 mb-4'>
                    <span style={{ marginTop: 6 }}>Subtrair</span>
                    <Switch
                        color='default'
                        value={isAddition}
                        onChange={() => setIsAddition(!isAddition)}
                    />
                    <span  style={{ marginTop: 6 }}>Adicionar</span>

                </Row>
                <Row className='d-flex justify-content-center mt-4 mb-4'>
                <span style={{paddingBottom:3}} >Atenção! O valor minimo de adição é 5€</span>

                </Row>
                <Row className='d-flex justify-content-center mt-4 mb-4 row_button'>
                    <Col className='d-flex justify-content-center brand_col'>
                        <Button className="button" disabled={!topUpValue || (isAddition && topUpValue < 5 ) || isRequesting}
                            onClick={() => {
                                if (isAddition || topUpValue >= 5) {
                                    balanceChange()
                                }}
                            }>
                            ALTERAR
                        </Button>
                    </Col>
                </Row>
            </Container>
        </Modal>
    )
}

export default ModalTopUp;

import api from '../../utils/api';

export const dashboardService = {
  pieChart,
};

async function pieChart(date) {
  //date format : 2021-08-01
  const response = await api.apiPrivate
    .get(`api/product/user-reserve/user-reserve-by-date/${date}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        console.log(error.request);
        return error.request;
      }
      throw error;
    });
  return response;
}

import React, { useState, useEffect, useRef } from 'react';
import { Form, Container, Row, Col, Image, Button } from 'react-bootstrap';
import { Title, Content, Styles } from '../../../style/content';

const Documents: React.FC = () => {
  return (
    <>
      <Title>Documentos</Title>

      <Content>
        <Container fluid className='card-no-tabs'>
          <h1>Documentos</h1>
        </Container>
      </Content>
    </>
  );
};

export default Documents;

import React, { useState, useEffect } from 'react';
import { Form, Container, Row, Col, Button, Table } from 'react-bootstrap';
import { Title, Content } from '../../../style/content';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faLongArrowAltLeft,
  faLongArrowAltRight,
  // faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { Doughnut } from 'react-chartjs-2';
import { dashboardService } from 'src/services/User/dashboardService';
import { toFixed } from '../../../utils/utils';
import dayjs from 'dayjs';
import { BalanceData } from './dashboard.interface';

const Dashboard: React.FC = () => {
  const [chartData, setChartData] = useState({} as BalanceData);
  // const [movements, setMovements] = useState({} as Movements);
  const months = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ];
  const [selectedMonth, setSelectedMonth] = useState(-1);
  const [currentMonth, setCurrentMonth] = useState(-1);
  const [monthBalance, setMonthBalance] = useState(0);

  useEffect(() => {
    setSelectedMonth(dayjs().month());
    setCurrentMonth(dayjs().month());
  }, []);

  useEffect(() => {
    if (selectedMonth !== -1) {
      let year = dayjs().format('YYYY');
      let month = dayjs().month(selectedMonth).format('MM');
      let date = `${year}-${month}-01`;
      getData(date);
    }
  }, [selectedMonth]);

  const getData = async (date) => {
    const result = await dashboardService.pieChart(date);
    if (result) {
      if(result as BalanceData) {
        setChartData(result);
        setMonthBalance(result.totalMovements);
      }
    } else {
      console.log(result.message);
    }
  };

  const data = {
    datasets: [
      {
        data: [
          chartData.percentageFinlog,
          chartData.percentageGuerin,
          chartData.percentageRodinhas,
          chartData.percentageXtracars,
          chartData.percentageBedriven,
          chartData.percentageAvailableBalance,
        ],
        backgroundColor: [
          '#00AEEF',
          '#8DC63F',
          '#FFCC00',
          '#3B3B3B',
          '#1A84C6',
          '#EEEEEE',
        ],
        borderColor: [
          '#00AEEF',
          '#8DC63F',
          '#FFCC00',
          '#3B3B3B',
          '#1A84C6',
          '#EEEEEE',
        ],
        borderWidth: 4,
      },
    ],
  };

  const nodata = {
    datasets: [
      {
        data: [100],
        backgroundColor: ['#eeeeee'],
        borderColor: ['#eeeeee'],
        borderWidth: 4,
      },
    ],
  };

  const getPreviusMonth = () => {
    let index = selectedMonth >= 1 ? selectedMonth - 1 : -1;
    return index !== -1 ? months[index] : null;
  };

  const getNextMonth = () => {
    let index = selectedMonth <= 10 ? selectedMonth + 1 : -1;
    if (index <= currentMonth) {
      return index !== -1 ? months[index] : null;
    } else {
      return null;
    }
  };

  const getBrandText = (serviceId) => {
    const brandText = {
      1: 'Renting',
      2: 'Rent-a-car',
      3: 'Aluguer Flexível',
      4: 'Transporte de Crianças',
      5: 'TVDE',
      6: 'Aluguer Flexível',
    };

    return brandText[serviceId];
  };

  const getLogo = (serviceId) => {
    const service = {
      1: require('../../../assets/logos/kinto-one-logo-on-2x.png'),
      2: require('../../../assets/logos/guerin-xs-logo-on-2x.png'),
      3: require('../../../assets/logos/xtracars-xs-logo-on-2x.png'),
      4: require('../../../assets/logos/rodinhas-xs-logo-on-2x.png'),
      5: require('../../../assets/logos/bedriven-xs-logo-on-2x.png'),
    };

    return service[serviceId];
  };

  return (
    <>
      <Title>Bem-vindo!</Title>

      <Content className='user__dashboard'>
        <Row className='d-flex justify-content-center mb-3'>
          <Col className='d-flex justify-content-center'>
            <img
              src={require('../../../assets/dashboard/GPGuerin.png')}
              className='services_logo'
              alt=''
            />
          </Col>
          <Col className='d-flex justify-content-center'>
            <img
              src={require('../../../assets/dashboard/GPKinto.png')}
              className='services_logo'
              alt=''
            />
          </Col>
          <Col className='d-flex justify-content-center'>
            <img
              src={require('../../../assets/dashboard/GPRodinhas.png')}
              className='services_logo'
              alt=''
            />
          </Col>
          <Col className='d-flex justify-content-center'>
            <img
              src={require('../../../assets/dashboard/GPXtracars.png')}
              className='services_logo'
              alt=''
            />
          </Col>
          {/* To Be replaced by other service */}
          {/*<Col hidden className='d-none justify-content-center'>
            <img
              src={require('../../../assets/dashboard/GPBeDriven.png')}
              className='services_logo'
            />
         </Col>*/}
        </Row>
        <Container fluid className='card-no-tabs'>
          <Row
            className='d-flex justify-content-center'
            style={{ marginTop: 50 }}
          >
            <Col md={2}>
              <Form.Control
                as='select'
                custom
                onChange={(e) => setSelectedMonth(Number(e.target.value))}
                value={selectedMonth}
              >
                {
                  // eslint-disable-next-line
                months.map((month, index) => {
                  if (index <= currentMonth) {
                    return (
                      <option value={index} key={month}>
                        {month}
                      </option>
                    );
                  }
                })}
              </Form.Control>
            </Col>
          </Row>
          <Row
            className='d-flex justify-content-center chart'
            style={{ marginTop: 30 }}
          >
            <Row>
              <Col style={{ paddingTop: 140 }}>
                <Button
                  onClick={() =>
                    getPreviusMonth() !== null
                      ? setSelectedMonth(selectedMonth - 1)
                      : ''
                  }
                  className='month_button'
                >
                  <FontAwesomeIcon
                    size='2x'
                    icon={faLongArrowAltLeft}
                    color={getPreviusMonth() !== null ? '#2292CC' : '#eeeeee'}
                  />
                </Button>
                <p>{getPreviusMonth()}</p>
              </Col>
              <Col>
                <div className='chart_container'>
                  <Row className='d-flex justify-content-center'>
                    <p className='month'>Gasto mensal</p>
                  </Row>
                  <Row className='d-flex justify-content-center'>
                    <p className='balance'>
                    
                    {toFixed(chartData.totalMonthBalance, 2)}€</p>
                  </Row>
                  <Row className='d-flex justify-content-center'>
                    <p className='avaible_label'>Saldo disponível</p>
                  </Row>
                  <Row className='d-flex justify-content-center'>
                    <p className='avaible'>
                    {toFixed(monthBalance, 2)}€
                    </p>
                  </Row>
                  <Doughnut
                    data={nodata}
                    options={{
                      cutout: 85,
                    }}
                    className='nodata'
                  />
                  {data.datasets.length > 0 && (
                    <Doughnut
                      data={data}
                      options={{
                        cutout: 85,
                        plugins: {
                          tooltip: {
                            callbacks: {
                              label: function (context) {

                                let label = context.dataset.label || '';
                                let value = context.parsed % Math.floor(context.parsed) === 0 ? context.parsed.toFixed(0) : context.parsed.toFixed(2)

                                if (label) {
                                  label += ': ';
                                }
                                if (context.parsed !== null) {
                                  label += ': ' + value + ' %';
                                }
                                return label;
                              }
                            }
                          }
                        }
                      }}
                      className='data'
                    />)}

                </div>
              </Col>
              <Col style={{ paddingTop: 140 }}>
                <Button
                  onClick={() =>
                    getNextMonth() !== null
                      ? setSelectedMonth(selectedMonth + 1)
                      : ''
                  }
                  className='month_button'
                >
                  <FontAwesomeIcon
                    size='2x'
                    icon={faLongArrowAltRight}
                    color={getNextMonth() !== null ? '#2292CC' : '#eeeeee'}
                  />
                </Button>
                <p>{getNextMonth()}</p>
              </Col>
            </Row>
          </Row>
          <Row className='d-flex justify-content-center chart_labels'>
            <Col md={7} xs={12}>
              <Row
                style={{ marginTop: 50 }}
                className='d-flex justify-content-center'
              >
                <Row>
                  <Col md={2}>
                    <div className='service_label finlog'></div>
                  </Col>
                  <Col>
                    <h3 className='mr-4 mb-0'>Renting</h3>
                    <p>{toFixed(chartData.percentageFinlog, 2)}%</p>
                  </Col>
                </Row>
                <Row>
                  <Col md={2}>
                    <div className='service_label guerin'></div>
                  </Col>
                  <Col>
                    <h3 className='mr-4 mb-0'>Alugar Carro</h3>
                    <p>{toFixed(chartData.percentageGuerin, 2)}%</p>
                  </Col>
                </Row>
                <Row>
                  <Col md={2}>
                    <div className='service_label rodinhas'></div>
                  </Col>
                  <Col>
                    <h3 className='mr-4 mb-0'>Transporte de crianças</h3>
                    <p>{toFixed(chartData.percentageRodinhas, 2)}%</p>
                  </Col>
                </Row>
                <Row>
                  <Col md={2}>
                    <div className='service_label xtracars'></div>
                  </Col>
                  <Col>
                    <h3 className='mr-4 mb-0'>Aluguer Flexível</h3>
                    <p>{toFixed(chartData.percentageXtracars, 2)}%</p>
                  </Col>
                </Row>
                {/* To Be replaced by other service */}
                {/*
                <Row>
                  <Col hidden md={2}>
                    <div className='service_label bedriven'></div>
                  </Col>

                  <Col>
                    <h3 className='mr-4 mb-0'>Bedriven</h3>
                    <p>{toFixed(chartData.percentageBedriven, 2)}%</p>
                  </Col>
                </Row>
                */}
              </Row>
            </Col>
          </Row>
        </Container>
        <Container fluid className='card-no-tabs mt-2 movements'>
          <h4>Últimos movimentos</h4>
          <Table responsive>
            <tbody>

              {chartData.movements?.map((item, i) => {
                return (
                  <tr key={i}>
                    <td>
                      <img
                        src={getLogo(item.serviceId)}
                        className='logo_table'
                        alt=''
                      />
                    </td>
                    <td>
                      <h3>{getBrandText(item.serviceId)}</h3>
                      <p>{item.serviceName}</p>
                    </td>
                    <td>
                      <img src={require('../../../assets/icons/watch.png')} alt="" />
                      {dayjs(item.pickUpDate).format('DD-MM-YYYY')}
                    </td>
                    <td>{item.value}€</td>
                  </tr>
                );
              })}

              {(chartData.movements === undefined || chartData.movements.length === 0)  && (
                <tr>
                  <td>
                    <p>Sem movimentos para exibir</p>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </Container>
      </Content>
    </>
  );
};

export default Dashboard;

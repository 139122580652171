import React, { useState, useEffect } from 'react';
import { Form, Container, Row, Col, Button } from 'react-bootstrap';
// import { Link } from 'react-router-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
// import { CLIENT_PAGE } from '../../constants/clientConstants';
import { Title, Content, Styles } from '../../style/client';
import { pricingService } from 'src/services/pricingService';
import { SweetAlertType } from 'react-bootstrap-sweetalert/dist/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import Loading from 'react-fullscreen-loading';
import { useHistory } from 'react-router-dom';
import {
  storePromocode,
  getPromocode,
  deletePromocode,
} from '../../redux/promocodeReducer';
const PricingPromocode: React.FC = () => {
  const [promoCode, setPromoCode] = useState('');
  const [disableInput, setDisableInput] = useState(false);
  const [alertDialog, setAlertDialog] = useState(false);
  const [alertDialogConfig, setAlertDialogConfig] = useState({} as AlertConfig);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    getPromo();
  }, []);

  const getPromo = () => {
    const result = getPromocode();
    if (result) {
      setPromoCode(result);
      setDisableInput(true);
    }
  };

  const _deletePromocode = () => {
    alert('Sucesso', 'Promocode apagado', 'success');
    deletePromocode();
    setDisableInput(false);
    setPromoCode('');
  };

  const insertPromoCode = async () => {
    setIsLoading(true);
    if (promoCode !== '') {
      const result = await pricingService.insert(promoCode);
      if (result.status === 200) {
        setDisableInput(true);
        storePromocode(promoCode);
        alert('Sucesso', result.message, 'success');
      } else {
        alert('Erro', result.message, 'error');
      }
    } else {
      alert('Erro', 'Preencha o cliente', 'error');
    }
    setIsLoading(false);
  };

  const alert = (
    title: String,
    message: String,
    type: SweetAlertType,
    redirect: string = ''
  ) => {
    setAlertDialog(true);
    setAlertDialogConfig({
      title: title,
      message: message,
      type: type,
      redirect: redirect,
    });
  };

  return (
    <>
      {isLoading && (
        <Loading
          loading
          background='rgba(255, 255, 255, .4)'
          loaderColor='#3498db'
        />
      )}
      <Title>Promoções</Title>

      <Content className='pricing_margin'>
        <Container fluid className='card-no-tabs pricing_insert'>
          <Row
            style={{
              textAlign: 'center',
              font: 'normal normal normal 18px/22px Roboto',
              letterSpacing: '0px',
              color: '#878787',
              opacity: 1,
              marginLeft: '82.5px',
              marginTop: '51px',
            }}
          >
          </Row>

          <Row>
            <Col
              style={{
                textAlign: 'left',
                font: 'normal normal bold 26px/34px Roboto',
                letterSpacing: '0px',
                color: '#2292CC',
                opacity: 1,
                marginLeft: '82.5px',
              }}
            >
              <h3>Inserir Código</h3>
            </Col>
          </Row>

          <Row
            style={{
              textAlign: 'left',
              font: 'normal normal normal 16px/19px Roboto',
              letterSpacing: '0px',
              color: '#1E1E1E',
              opacity: 1,
              margin: '16px 0 0px 65px',
            }}
          >
            <Col lg={12} className='mt-4'>
              <h6 className='light'>Código</h6>
              <Form onSubmit={(e) => e.preventDefault()}>
                <Row>
                  <Col sm={9} md={10} lg={10} xl={11}>
                    <Form.Control
                      onChange={(e) => setPromoCode(e.target.value)}
                      defaultValue={promoCode}
                      disabled={disableInput}
                    />
                  </Col>
                  <Col className='pt-2'>
                    <FontAwesomeIcon
                      color='#696969'
                      icon={faTrashAlt}
                      size='lg'
                      onClick={() => _deletePromocode()}
                    />
                  </Col>
                </Row>
              </Form>
              {disableInput && (
                <p className='promocode_message'>
                  Código inserido com sucesso
                </p>
              )}
            </Col>
          </Row>
          <Row className='mt-4'>
            <Col className='center'>
              <Button
                style={Styles.okButton}
                variant='outline-info'
                size='lg'
                onClick={() => insertPromoCode()}
                disabled={disableInput}
              >
                Registar
              </Button>
            </Col>
          </Row>

          {alertDialog && (
            <SweetAlert
              type={alertDialogConfig?.type}
              title={alertDialogConfig?.title ?? ''}
              onConfirm={() => {
                setAlertDialog(false);
                if (
                  alertDialogConfig.redirect !== '' &&
                  alertDialogConfig.redirect !== undefined
                ) {
                  history.push(alertDialogConfig.redirect);
                }
              }}
            >
              {alertDialogConfig?.message ?? ''}.
            </SweetAlert>
          )}
        </Container>
      </Content>
    </>
  );
};

export default PricingPromocode;

export interface AlertConfig {
  title: String;
  message: String;
  type: SweetAlertType;
  redirect?: string;
}

export interface Service {
  active: Boolean;
  dateCreated: Date;
  description: String;
  id: Number;
  name: String;
  pathLocationFiles?: String;
}

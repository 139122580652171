import React, { useState, useEffect, useRef } from 'react';
import { Col, Row, Modal, Button, Container } from 'react-bootstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import Loading from 'react-fullscreen-loading';
import 'react-datepicker/dist/react-datepicker.css';
import { useParams, Link, useHistory } from 'react-router-dom';
import { productService } from '../../../services/productService';
import UploadSheet from '../../../components/Products/Rodinhas/UploadSheet';
import { Title, Content } from '../../../style/content';
import Download from 'src/assets/icons/rodinhas/Download';

// eslint-disable-next-line
interface Props {
  productId: any;
  getRodinhasProducts: () => void;
}

const Rodinhas: React.FC = () => {
  const [product, setProduct] = useState({
    zones: '' as any,
    prices: '' as any,
    discounts: '' as any,
  });
  const [productReady, setProductReady] = useState(false);
  const [file, setFile] = useState({
    base64: '' as any,
    name: '',
  });
  const [upload, setUpload] = useState(false);
  const [publish, setPublish] = useState(false);

  const fileInputField = useRef(null);
  const [activeTab, setActiveTab] = useState(1);
  const [oldProductId, setOldProductId] = useState('');

  const [errorDialog, setErrorDialog] = useState(false);
  const [successDialog, setSuccessDialog] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { productId } = useParams();
  const history = useHistory();

  const zoneRow = (zone: any, i: number) => {
    return (
      <tr key={'zoneRow' + i.toString()}>
        <td className='number'>{i + 2}</td>
        <td>
          <div className='cell '>{zone.fee ? zone.fee : '-'}</div>
        </td>
        <td>
          <div className='cell'>{zone.region ? zone.region : '-'}</div>
        </td>
        <td>
          <div className='cell'>{zone.zone ? zone.zone : '-'}</div>
        </td>
        <td>
          <div className='cell'>{zone.subZone ? zone.subZone : '-'}</div>
        </td>
      </tr>
    );
  };
  const priceRow = (price: any, i: number) => {
    return (
      <tr key={'priceRow' + i.toString()}>
        <td className='number'>{i + 2}</td>
        <td>
          <div className='cell'>{price.fee ? price.fee : '-'}</div>
        </td>
        <td>
          <div className='cell'>
            {price.numberTime ? price.numberTime : '-'}
          </div>
        </td>
        <td>
          <div className='cell'>{price.toGo ? price.toGo : '-'}</div>
        </td>
        <td>
          <div className='cell'>{price.goBack ? price.goBack : '-'}</div>
        </td>
      </tr>
    );
  };
  const discountRow = (discount: any, i: number) => {
    return (
      <tr key={'discountRow' + i.toString()}>
        <td className='number'>{i + 2}</td>
        <td>
          <div className='cell '>
            {discount.children ? discount.children : '-'}
          </div>
        </td>
        <td>
          <div className='cell'>{discount.toGo ? discount.toGo : '-'}</div>
        </td>
        <td>
          <div className='cell'>{discount.goBack ? discount.goBack : '-'}</div>
        </td>
      </tr>
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (productId) getProduct(productId);
  }, [productId]);

  const getProduct = async (productId: string) => {
    try {
      const result = await productService.getRodinhasProduct(productId);
      if (result) {
        setProduct(result);
        setProductReady(true);
        setUpload(false);
        setPublish(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const createProduct = async (product: any) => {
    try {
      const result = await productService.createRodinhasProduct(product);

      if (result.data.oldProductId !== null) {
        setOldProductId(result.data.oldProductId);
        setModalShow(true);
      } else {
        setModalShow(false);
        setAlertMsg(result.data.message);
        setSuccessDialog(true);
      }
    } catch (error) {
      setAlertMsg(error.response.data.message);
      setErrorDialog(true);
      console.log(error);
    }
  };

  const handleNewFileUpload = (e: any, showMessage = false) => {
    if (e.target.files.length) {
      const file = e.target.files[0];
      const fileSize = file.size / 1024 / 1024;

      if (fileSize > 2) {
        setErrorDialog(true);
        setAlertMsg('Arquivo deve ter menos que 2 MB');
        return false;
      }

      if (
        file.type !==
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      ) {
        setErrorDialog(true);
        setAlertMsg('Arquivo deve ter a extensão XLSX');
        return false;
      }

      getBase64(file).then((data) => {
        let base64String = data as string;
        base64String = base64String.split(',')[1];
        setFile({ name: file.name, base64: base64String });
        uploadSheet({ fileName: file.name, file: base64String });
        if (showMessage) {
          setSuccessDialog(true);
          setAlertMsg('O upload do novo ficheiro foi efetuado com sucesso');
        }
      });
    }
  };

  const downloadTemplate = async () => {
    const response = await productService.downloadTemplateRodinhas();
    if (response.data) {
      const link = document.createElement('a');
      link.href = response.data;
      link.setAttribute('download', 'template.xlsx');
      document.body.appendChild(link);
      link.click();
    }
  };
  const uploadSheet = async (sheet: any) => {
    setIsLoading(true);
    const response = await productService.uploadSheetRodinhas(sheet);
    if (response.status === 200) {
      setProduct(response.data);
      setProductReady(true);
      setPublish(false);
      setUpload(true);
      setActiveTab(1);
      setIsLoading(false);
    } else {
      setErrorDialog(true);
      setAlertMsg(response.message);
      setFile({
        base64: '' as any,
        name: '',
      });
      setIsLoading(false);
    }
  };

  const getBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  return (
    <>
      <Title>Rodinhas</Title>

      <Content>
        <Container
          fluid
          className='card-no-tabs'
          style={{ paddingLeft: 80, paddingBottom: 50 }}
        >
          <Row
            style={{
              textAlign: 'center',
              font: 'normal normal normal 18px/22px Roboto',
              letterSpacing: '0px',
              color: '#878787',
              opacity: 1,
              marginLeft: '8px',
              marginTop: '51px',
            }}
          >
            <Link className='color-back' to={'/products'}>
              <p>
                <u>&#60; Voltar</u>
              </p>
            </Link>
          </Row>
          <div className='rodinhas_screen'>
            {isLoading && (
              <Loading
                loading
                background='rgba(255, 255, 255, .4)'
                loaderColor='#3498db'
              />
            )}
            <Modal show={modalShow}>
              <Modal.Body>
                <Row>
                  <Col className='center'>
                    <h3>Atenção</h3>
                  </Col>
                </Row>
                <Row>
                  <Col className='center'></Col>
                </Row>
                <Row>
                  <Col className='center'>
                    <h5>
                      Se prosseguir, este ficheiro irá substituir o anterior já
                      publicado. Apenas um produto Rodinhas pode estar em vigor.
                    </h5>
                  </Col>
                </Row>
                <Row>
                  <Col className='center'>
                    <p className='light'>Esta ação não pode ser revertida</p>
                  </Col>
                </Row>
                <Row>
                  <Button
                    variant='outline-info'
                    style={{
                      marginBottom: 6,
                      borderRadius: 33,
                      width: 293,
                      height: 66,
                    }}
                    onClick={() =>
                      createProduct({
                        active: true,
                        productState: 'PUBLISHED',
                        name: 'rodinhas',
                        zones: product.zones,
                        prices: product.prices,
                        discounts: product.discounts,
                        oldProductId: oldProductId,
                      })
                    }
                  >
                    CONTINUAR
                  </Button>
                </Row>
                <Row>
                  <Button
                    variant='outline-danger'
                    style={{ borderRadius: 33, width: 293, height: 66 }}
                    onClick={() => setModalShow(false)}
                  >
                    CANCELAR
                  </Button>
                </Row>
              </Modal.Body>
            </Modal>
            {successDialog && (
              <SweetAlert
                success
                title='Sucesso!'
                onConfirm={() => {
                  history.push('/products');
                }}
              >
                {alertMsg}.
              </SweetAlert>
            )}

            {errorDialog && (
              <SweetAlert
                danger
                title='Erro!'
                onConfirm={() => {
                  setErrorDialog(false);
                  setAlertMsg('');
                }}
              >
                {alertMsg}.
              </SweetAlert>
            )}

            <div className='form'>
              <Row>
                <Col xs={12} sm={7} md={7} lg={9}>
                  <img
                    src={require('../../../assets/logos/rodinhas-2x-logo.png')}
                    className='logo'
                    alt='Rodinhas'
                  />
                </Col>
                <Col>
                  <div
                    className='dowload_template'
                    onClick={() => downloadTemplate()}
                  >
                   <Download />
                    <span onClick={() => downloadTemplate()}>
                      Template Rodinhas.xlsx
                    </span>
                  </div>
                </Col>
              </Row>
              {!productReady && (
                <UploadSheet
                  file={file}
                  fileInputField={fileInputField}
                  handleNewFileUpload={handleNewFileUpload}
                ></UploadSheet>
              )}

              {productReady && (
                <div className='product_ready'>
                  <Row className='description'>
                    <Col>
                      <p>
                        Edite ou verifique os valores definidos para a atual
                        oferta Rodinhas. Se pretender adicionar novas zonas,
                        preços ou descontos deverá carregar um novo ficheiro.
                      </p>
                    </Col>
                  </Row>
                  <Row className='uploaded_container_row'>
                    <input
                      type='file'
                      accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                      ref={fileInputField}
                      onChange={(e) => {
                        handleNewFileUpload(e, true);
                      }}
                    />
                    <div className='uploaded_container'>
                      <Row className='uploaded_container__content'>
                        <Col md={3} className='pr-0 ml-2'>
                          <img
                            src={require('../../../assets/icons/rodinhas/upload.svg')}
                            alt='Upload'
                          />
                        </Col>
                        <Col className='pl-0'>
                          <p>
                            {file.name !== ''
                              ? file.name
                              : 'Upload de nova tabela'}
                          </p>
                        </Col>
                      </Row>
                    </div>
                  </Row>
                  <Row className='tabs'>
                    <Col
                      className={activeTab === 1 ? 'tab--active' : 'tab'}
                      onClick={() => setActiveTab(1)}
                    >
                      <p>ZONAS</p>{' '}
                    </Col>
                    <Col
                      className={activeTab === 2 ? 'tab--active' : 'tab'}
                      onClick={() => setActiveTab(2)}
                    >
                      <p>PREÇOS</p>{' '}
                    </Col>
                    <Col
                      className={activeTab === 3 ? 'tab--active' : 'tab'}
                      onClick={() => setActiveTab(3)}
                    >
                      <p>DESCONTO</p>{' '}
                    </Col>
                  </Row>
                  <div className='table'>
                    {activeTab === 1 && (
                      <table>
                        <tbody>
                          <tr>
                            <td className='number'>1</td>
                            <td className='fee'>Tarifa</td>
                            <td>Região</td>
                            <td>Zona</td>
                            <td>Sub-zona</td>
                          </tr>

                          {product.zones.map(function (zone, i) {
                            return zoneRow(zone, i);
                          })}
                        </tbody>
                      </table>
                    )}
                    {activeTab === 2 && (
                      <table>
                        <tbody>
                          <tr>
                            <td className='number'>1</td>
                            <td className='fee'>Tarifa</td>
                            <td>Nº de vezes/semana</td>
                            <td>ida</td>
                            <td>ida e volta</td>
                          </tr>
                          {product.prices.map(function (price, i) {
                            return priceRow(price, i);
                          })}
                        </tbody>
                      </table>
                    )}
                    {activeTab === 3 && (
                      <table>
                        <tbody>
                          <tr>
                            <td className='number'>1</td>
                            <td className='fee'>Crianças</td>
                            <td>ida</td>
                            <td>ida e volta</td>
                          </tr>

                          {product.discounts.map(function (discount, i) {
                            return discountRow(discount, i);
                          })}
                        </tbody>
                      </table>
                    )}
                  </div>
                </div>
              )}
            </div>
            {upload && (
              <div className='justify-content-center'>
                <Row className='justify-content-center'>
                  <div
                    className='button'
                    onClick={() =>
                      createProduct({
                        active: true,
                        productState: 'DRAFT',
                        name: 'rodinhas',
                        zones: product.zones,
                        prices: product.prices,
                        discounts: product.discounts,
                      })
                    }
                  >
                    <p>Guardar alterações</p>
                  </div>
                </Row>
              </div>
            )}
            {publish && (
              <div className='justify-content-center'>
                <Row className='justify-content-center'>
                  <div
                    className='button'
                    onClick={() =>
                      createProduct({
                        active: true,
                        productState: 'PUBLISHED',
                        name: 'rodinhas',
                        zones: product.zones,
                        prices: product.prices,
                        discounts: product.discounts,
                      })
                    }
                  >
                    <p>Publicar</p>
                  </div>
                </Row>
              </div>
            )}
          </div>
        </Container>
      </Content>
    </>
  );
};

export default Rodinhas;

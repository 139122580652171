import api from '../utils/api';

export const configuratorService = {
  getPacks,
  getAllPacks,
  getGuerinXtracarsSegments,
  getRodinhasProduct,
};

async function getAllPacks() {
  const response = await api.apiSitePublic
    .get('api/product/pack/all-packs-app?active=true')
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        console.log(error.request);
        return error.request;
      }
      throw error;
    });
  return response;
}

async function getGuerinXtracarsSegments() {
  const response = await api.apiSitePublic
    .get('api/product/product-guerin-xtracars/all-segments')
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        console.log(error.request);
        return error.request;
      }
      throw error;
    });
  return response;
}

async function getRodinhasProduct() {
  const response = await api.apiSitePublic
    .get('api/product/product-rodinhas/rodinhas-published')
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        console.log(error.request);
        return error.request;
      }
      throw error;
    });
  return response;
}

async function getPacks(query) {
  const response = await api.apiSitePublic
    .get(`api/product/pack/filter-packs?${query}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        console.log(error.request);
        return error.request;
      }
      throw error;
    });
  return response;
}

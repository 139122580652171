import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { NavigationBar } from './components/Navigation/NavigationBar';
import styled from 'styled-components';
import { Redirect, Switch, Route } from 'react-router';
import {
  useLocation,
  BrowserRouter as Router,
  useHistory,
} from 'react-router-dom';
import { AuthService } from './services/auth.service';
import {
  getUser,
  defaultRoute,
  setGoToCart,
  getGoToCart,
  getUserRoles
} from './redux/sessionReducer';
import { URL_DEV, URL_QA, SERVER_ENV, URL_FE_PUBLIC } from './constants/config';

//MAIN VIEWS
import Waiting from './screens/Login/Waiting';
import Logout from './screens/Login/Logout';
import Payment from './screens/Payment/Payment';
import Profile from './screens/Profile/Profile';
import Configurator from './screens/Configurator/Main';

//COMPONENTS
import Navbar from './components/Navigation/Navbar';

import GPRoutes from './routes/gp.routes';
import GMRoutes from './routes/gm.routes';
import GCRoutes from './routes/gc.routes';
import UserRoutes from './routes/user.routes';
import UserCorporateRoutes from './routes/usercorporate.routes';
import CartRoutes from './routes/cart.routes';
import CMSRoutes from './routes/cms.routes';
import PricingRoutes from './routes/pricing.routes';

const App: React.FC = () => {
  const [userRoles, setUserRoles] = useState([] as string[]);
  const [user, setUser] = useState({} as UserAuth);
  const [filteredRoutes, setFilteredRoutes] = useState([] as MyRoute[]);
  const [defaultRote, setDefaultRote] = useState('/');
  const history = useHistory();

  useEffect(() => {
    update();
    if ('localNoLogin' === (SERVER_ENV as string)) {
      setFilteredRoutes(routes);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (userRoles.length > 0) {
      if ('localNoLogin' !== (SERVER_ENV as string)) {
        filterRoutes();
      }
    }
    // eslint-disable-next-line
  }, [userRoles]);

  const routes: MyRoute[] = [
    ...GPRoutes,
    ...GMRoutes,
    ...GCRoutes,
    ...UserRoutes,
    ...UserCorporateRoutes,
    ...CartRoutes,
    ...CMSRoutes,
    ...PricingRoutes,
    {
      path: '/profile',
      exact: true,
      component: Profile,
      role: 'ROLE_USER_SYSTEM',
      auth: true,
    },
    {
      path: '/logout',
      exact: true,
      component: Logout,
      role: 'ROLE_USER_SYSTEM',
      auth: true,
    },
  ];

  const getCode = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const code = urlParams.get('code');
    return code;
  };

  const getState = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const state = urlParams.get('state');
    return state;
  };

  const getError = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const error = urlParams.get('error');
    return error;
  };

  const update = async () => {
    const state = getState();
    const code = getCode();
    const error = getError();
    if (state === 'app') {
      return;
    }

    if (error) {
      window.location.replace(`${URL_FE_PUBLIC}`);
    }

    if (code) {
      if (state === 'dev') {
        window.location.replace(`${URL_DEV}/waiting?code=${code}`);
        return;
      }

      if (state === 'qa') {
        window.location.replace(`${URL_QA}/waiting?code=${code}`);
        return;
      }

      if (state === 'preprod') {
        window.location.replace(`${URL_QA}/waiting?code=${code}`);
        return;
      }

      if (state === 'local') {
        window.location.replace(`http://localhost:3000/waiting?code=${code}`);
        return;
      }
// eslint-disable-next-line
      const result = await AuthService.authenticate({ sessionId: code }).then(
        async (result) => {
          let resultUserDetails;
          if (result) {
            // eslint-disable-next-line
            const _user = await getUser().then(async (user) => {
              user = JSON.parse(user as string);
              let userAuth = user as unknown as UserAuth;
              if (userAuth) {
                setUserRoles(userAuth.roles);
              }
              setUser(userAuth);
              resultUserDetails = await AuthService.getUserFromSubscription();
            });
            let goCart = getGoToCart();
            if (goCart === 'true') {
              setDefaultRote('/cart');
              setTimeout(function () {
                history.push('/cart');
                setGoToCart(false);
              }, 500);
            } else {
              if (resultUserDetails.name) {
                if (state === 'publicpage') {
                  let name = window.btoa(
                    unescape(encodeURIComponent(resultUserDetails.name))
                  );
                  if (name) {
                    window.location.replace(`${URL_FE_PUBLIC}/?user=${name}`);
                  }

                  return;
                }
              }
              let route = await defaultRoute();
              setDefaultRote(route);
              if (route) {
                setTimeout(function () {
                  history.push(route);
                }, 500);
              }
            }
          }
        }
      );
    } else {
      // eslint-disable-next-line
      const _user = await getUser().then(async (user) => {
        user = JSON.parse(user as string);
        let userAuth = user as unknown as UserAuth;
        if (userAuth) {
          setUserRoles(userAuth.roles);
          setUser(userAuth);
          const resultUserDetails = await AuthService.getUserFromSubscription();
          if (resultUserDetails.name) {
            let route = await defaultRoute();
            setDefaultRote(route);
          } else {
            window.location.replace(`${URL_FE_PUBLIC}`);
            return;
          }
        }
      });
    }
  };

  const filterRoutes = () => {
    let authenticated =
      user.token && user.token !== '' && user.token !== undefined ? true : false;
    let filtered = routes
    if (!authenticated) {
      filtered.filter((item) => item.auth !== true);
    }
    setFilteredRoutes(filtered);
  };

  const renderMain = () => {
    return (
      <Router>
        <NavigationBar />
        <Space>
          <Container fluid className='pb-3'>
            <Card>
              <Row>
                <Col sm={4} md={3} lg={2} className='pr-0 pl-0'>
                  <Navbar />
                </Col>
                <Col sm={8} md={9} lg={10} className=''>
                  <Wrapper>
                    <Switch>
                      
                      {
                      // eslint-disable-next-line
                      filteredRoutes.map((route, index) => {
                        if (validateRoleAndRoute(route)) {
                          return (
                            <Route
                              key={index}
                              path={route.path}
                              exact={route.exact}
                              component={route.component}
                            />
                          )
                        }
                      })}
                      <Route path={'/'} exact={true}>
                        <Redirect
                          to={{
                            pathname: defaultRote,
                          }}
                        />
                      </Route>
                    </Switch>
                  </Wrapper>
                </Col>
              </Row>
            </Card>
          </Container>
        </Space>
      </Router>
    );
  };

  return (
    <>
      <Switch>
        <Route path='/waiting'>
          <Waiting />
        </Route>
        <Route path='/payment'>
          <Payment />
        </Route>
        <Route path='/denied'>
          <NoHasRole />
        </Route>
        <Route path='/configurator'>
          <Configurator />
        </Route>
        <Route>{renderMain()}</Route>
        <Route path='*'>
          <NoMatch />
        </Route>
      </Switch>
    </>
  );
};
function validateRoleAndRoute(route) {
  let roles = getUserRoles()
  return roles.includes(route.role as string)
}
function NoMatch() {
  let location = useLocation();

  return (
    <div>
      <h3>
        Error 404 - Page not found <code>{location.pathname}</code>
      </h3>
    </div>
  );
}

function NoHasRole() {
  let location = useLocation();

  return (
    <div>
      <h3>
        Error 401 - Acess Denied <code>{location.pathname}</code>
      </h3>
    </div>
  );
}

interface MyRoute {
  path: string;
  exact: boolean;
  component: React.FC<{}>;
  role: string;
  auth: boolean;
}

interface UserAuth {
  token: string;
  expiration: number;
  roles: [];
}

const Card = styled.main`
  padding: 20px;
  box-shadow: 0px 0px 21px 1px rgba(0, 175, 239, 0.3);
  border-radius: 40px;
  margin-top: 20px;
`;
const Space = styled.div`
  padding-left: 6%;
  padding-right: 6%;
  padding-top: 0px;

  @media (max-width: 575px) {
    padding: 0;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export default App;

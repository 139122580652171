import api from '../../utils/api';

export const profileService = {
  listCountries,
  updateUser,
  vatValidation,
};

async function listCountries() {
  const response = await api.apiPrivate
    .get(`api/backoffice/country`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        console.log(error.request);
        return error.request;
      }
      throw error;
    });
  return response;
}

async function updateUser(data) {
  const response = await api.apiPrivate
    .post(`/api/comercial-profile/user-system/edit-from-subscription`, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        console.log(error.request);
        return error.request;
      }
      throw error;
    });
  return response;
}

async function vatValidation(data) {
  const response = await api.apiPrivate
    .post(`/api/product/document/nif-validation`, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        console.log(error.request);
        return error.request;
      }
      throw error;
    });
  return response;
}

import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';

import { Coverage } from './reservation.interface';

export const CoverageCard = ({ coverage, selectCoverage, selected }) => {
  let _covarage = coverage as Coverage;

  return (
    <div className={selected ? 'coverage_card selected' : 'coverage_card'}>
      <Row>
        <Col>
          <h3 className='title'>{_covarage.packDTO?.extra}</h3>
        </Col>
        <Col md={3}>
          <h2 className='value'>{_covarage.packDTO?.value ?? '0'}€</h2>
        </Col>
      </Row>
      <Row>
        <Col>
          <p>Franquia {_covarage.packDTO?.excess ?? 0}€</p>
        </Col>
      </Row>
      <Row>
        <Col>
          <Accordion>
            <AccordionSummary>
              <Typography>Extras</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ol className='list'>
                {_covarage.extrasIncluded?.map((el, index) => {
                  return (
                    <li className='list_item' key={'extraID' + index}>
                      {el.extra} - {el.description}
                    </li>
                  );
                })}
              </ol>
            </AccordionDetails>
          </Accordion>
        </Col>
      </Row>
      <Row className='d-flex justify-content-end mt-2 mr-2'>
        <Button
          variant='outline-secondary'
          onClick={() => selectCoverage(_covarage)}
        >
          Selecionar
        </Button>
      </Row>
    </div>
  );
};

export default CoverageCard;

import React, { useState, useEffect } from 'react';
import {
  Container,
  Col,
  Row,
  Form,
  InputGroup,
  Button,
  Pagination,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faPlus } from '@fortawesome/free-solid-svg-icons';
import { Title, Content, Styles } from '../../../style/client';
import { userService } from '../../../services/GM/userService';
import Loading from 'react-fullscreen-loading';
import { UsersTable } from '../../../components/GM/Users/UsersTable';
import { useHistory } from 'react-router-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import { NR_OF_CLIENTS_PER_PAGE } from '../../../constants/clientConstants';
import { Link } from 'react-router-dom';
import { iCan } from 'src/redux/sessionReducer';
import { SweetAlertType } from 'react-bootstrap-sweetalert/dist/types';
import { AlertConfig } from './users.interface';

const Users: React.FC = () => {
  const [userList, setUserList] = useState([] as any);
  const [isLoading, setIsLoading] = useState(true);
  const [searchFilter, setSearchFilter] = useState('');
  const [activePage, setActivePage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [filter, setFilter] = useState('');
  // const [userPage, setUserPage] = useState(null);
  const history = useHistory();
  const [alertDialog, setAlertDialog] = useState(false);
  const [userToDelete, setUserToDelete] = useState('');
  const [alertDialogConfig, setAlertDialogConfig] = useState({} as AlertConfig);

  useEffect(() => {
    let mounted = true;
    setIsLoading(true);

    getUsers(activePage, filter).then((result) => {
      if (result) {
        setUserList(result.content);

        setTotalPages(result.totalPages);
        setTotalElements(result.totalElements);
      }

      if (mounted) {
        setIsLoading(false);
      }
    });

    return function cleanup() {
      mounted = false;
    };
  }, [activePage, filter]);

  const getUsers = async (activePage, filter) => {
    try {
      return userService.getAllComercial(
        NR_OF_CLIENTS_PER_PAGE,
        activePage,
        filter
      );
    } catch (error) {
      console.log(error);
    }
  };

  const getNumberOfUsersMessage = () => {
    return (
      totalElements +
      (totalElements > 1 ? ' comerciais registados' : ' comercial registado')
    );
  };

  const filterUsersIconClick = () => {
    setActivePage(0);
    setFilter(searchFilter);
  };

  const filterUsersEnterClick = (event) => {
    //Enter key up
    if (event.keyCode === 13) {
      event.preventDefault();

      setActivePage(0);
      setFilter(searchFilter);
    } else {
      setSearchFilter(event.target.value);
    }
  };

  const previousActivePage = () => {
    if (activePage - 1 >= 0) {
      setActivePage(activePage - 1);
    }
  };

  const nextActivePage = () => {
    if (activePage + 1 < totalPages) {
      setActivePage(activePage + 1);
    }
  };

  const createPaginationItem = (pageIndex, pageNumber, activePage) => {
    return (
      <Pagination.Item
        className='pagination'
        onClick={(e) => setActivePage(pageIndex)}
        key={pageIndex}
        active={pageIndex === activePage}
      >
        {pageNumber}
      </Pagination.Item>
    );
  };

  const getPaginationPages = () => {
    const delta = 1;

    let items = [] as any;
    let active = activePage;

    for (
      let i = Math.max(2, activePage - delta);
      i <= Math.min(totalPages - 1, activePage + delta);
      i++
    ) {
      items.push(createPaginationItem(i - 1, i, active));
    }

    if (activePage - delta > 2) {
      if (items.length === totalPages - 2) {
        items.unshift(createPaginationItem(1, 2, active));
      } else {
        items.unshift(<Pagination.Ellipsis className='pagination' />);
      }
    }

    if (activePage + delta < totalPages - 1) {
      if (items.length === totalPages - 3) {
        items.push(
          createPaginationItem(totalPages - 2, totalPages - 1, active)
        );
      } else {
        items.push(<Pagination.Ellipsis className='pagination' />);
      }
    }

    // first page
    items.unshift(createPaginationItem(0, 1, active));

    if (totalPages <= 1) return items;

    // last page
    items.push(createPaginationItem(totalPages - 1, totalPages, active));

    return items;
  };

  const handleUsersPageChange = (user) => {
    history.push(`/gp/users/view/${user.id}`);
  };

  const deleteUser = (user) => {
    setUserToDelete(user.id);
    alert(
      'Apagar comercial',
      'Tem a certeza que deseja apagar este comercial?',
      'warning'
    );
  };

  const confirmUserDelete = async () => {
    if (userToDelete !== '') {
      const result = await userService.deleteUser(userToDelete);
      if (result) {
        alert('Apagar comercial', result.message, 'success');
      } else {
        alert('Apagar comercial', result.message, 'error');
      }
    }
  };

  const alert = (
    title: String,
    message: String,
    type: SweetAlertType,
    redirect: Boolean = false
  ) => {
    setAlertDialog(true);
    setAlertDialogConfig({
      title: title,
      message: message,
      type: type,
      redirect: redirect,
    });
  };

  return (
    <>
      {isLoading && (
        <Loading
          loading
          background='rgba(255, 255, 255, .4)'
          loaderColor='#3498db'
        />
      )}

      <>
        <Title>Comerciais</Title>
        <Content>
          <div className='content left-corner right-corner'>
            <Container fluid className='card-no-tabs'>
              <Row className='division'>
                <Col md={{ offset: 10 }}>
                  {iCan('ROLE_CREATE_COMMERCIAL_MANAGER') && (
                    <Link to={'/gp/users/create/'}>
                      <Button
                        variant='outline-info'
                        size='lg'
                        className='float-right'
                        style={Styles.button}
                        data-testid='newClientButton'
                      >
                        <FontAwesomeIcon icon={faPlus} />
                      </Button>
                    </Link>
                  )}
                </Col>
              </Row>

              <Row>
                <Col className='title'>
                  <Title data-testid='numberOfClients'>
                    {getNumberOfUsersMessage()}
                  </Title>
                </Col>
              </Row>

              <Row
                style={Styles.searchRow}
                xs={1}
                sm={1}
                md={1}
                lg={2}
                className='justify-content-center'
              >
                <Col style={Styles.searchCol}>
                  <InputGroup>
                    <Form.Control
                      data-testid='searchBar'
                      className='input'
                      type='text'
                      onKeyUp={(e) => filterUsersEnterClick(e)}
                      placeholder='Pesquise por email'
                    />
                    <div
                      className='search-icon'
                      data-testid='searchIcon'
                      onClick={(e) => filterUsersIconClick()}
                    >
                      <FontAwesomeIcon
                        style={Styles.searchIcon}
                        color='white'
                        icon={faSearch}
                      />
                    </div>
                  </InputGroup>
                </Col>
              </Row>

              <Row style={Styles.clientTable}>
                <UsersTable
                  clientList={userList}
                  setClientPage={handleUsersPageChange}
                  deleteClient={deleteUser}
                />
              </Row>

              <Row className='justify-content-center'>
                <Pagination>
                  <Pagination.Prev
                    style={Styles.pageItem}
                    onClick={(e) => previousActivePage()}
                  />
                  {getPaginationPages()}
                  <Pagination.Next
                    style={Styles.pageItem}
                    onClick={(e) => nextActivePage()}
                  />
                </Pagination>
              </Row>
            </Container>
          </div>
        </Content>
        {alertDialog && (
          <SweetAlert
            type={alertDialogConfig?.type}
            title={alertDialogConfig?.title ?? ''}
            showCancel={true}
            onCancel={() => {
              setAlertDialog(false);
            }}
            onConfirm={() => {
              setAlertDialog(false);
              if (alertDialogConfig?.type === 'warning') {
                confirmUserDelete();
              }
            }}
          >
            {alertDialogConfig?.message ?? ''}.
          </SweetAlert>
        )}
      </>
    </>
  );
};

export default Users;

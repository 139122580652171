import * as React from 'react';

export default function Download(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='22.022'
      height='28.976'
      viewBox='0 0 22.022 28.976'
      {...props}
    >
      <g transform='translate(-1066.07 -882.756)'>
        <g transform='translate(1066.07 882.756)'>
          <path
            fill='#baddf0'
            d='M21.978,11.109h6.179c.619,0,.775-.159.775-.362h0a2.144,2.144,0,0,0-.775-1.659L22.63,4.481a2.327,2.327,0,0,0-1.492-.536h0c-.3,0-.536.082-.536.854V9.733A1.376,1.376,0,0,0,21.978,11.109Z'
            transform='translate(-6.91 -3.937)'
          />
          <path
            fill='#baddf0'
            d='M19.12,9.733v-5.8H9.631A2.325,2.325,0,0,0,7.312,6.256V30.6a2.325,2.325,0,0,0,2.318,2.318H27.016A2.325,2.325,0,0,0,29.334,30.6v-17.6H22.38A3.265,3.265,0,0,1,19.12,9.733Z'
            transform='translate(-7.312 -3.938)'
          />
        </g>
        <path
          fill='#2292cc'
          d='M8.782,4.291,4.535,0,.289,4.291a1,1,0,0,0,0,1.409.979.979,0,0,0,1.394,0L3.549,3.815V8.83a.986.986,0,1,0,1.972,0V3.815L7.387,5.7a.979.979,0,0,0,1.394,0A1,1,0,0,0,8.782,4.291Z'
          transform='translate(1081.616 905.958) rotate(180)'
        />
      </g>
    </svg>
  );
}

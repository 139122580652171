import api from '../utils/api';

export const documentService = {
  getAllDocuments,
  getUserDocuments,
  uploadFile,
  removeDocument,
};

async function getAllDocuments() {
  const response = await api.apiPrivate
    .get('api/product/document/all-documents')
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        console.log(error.request);
        return error.request;
      }
      throw error;
    });
  return response;
}

async function uploadFile(data) {
  const response = await api.apiPrivate
    .post('api/product/document/create-document-user', data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        console.log(error.request);
        return error.request;
      }
      throw error;
    });
  return response;
}

async function getUserDocuments() {
  const response = await api.apiPrivate
    .get('api/product/document/all-user-documents')
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        console.log(error.request);
        return error.request;
      }
      throw error;
    });
  return response;
}

async function removeDocument(documentId) {
  const response = await api.apiPrivate
    .delete(`api/product/document/${documentId}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        console.log(error.request);
        return error.request;
      }
      throw error;
    });
  return response;
}

import React, { useState, useEffect, useRef } from 'react';
import { Form, Container, Row, Col, Image, Button } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SweetAlert from 'react-bootstrap-sweetalert';
import { faCamera } from '@fortawesome/free-solid-svg-icons';
import {
  phoneIndicative,
  clientProfile,
} from '../../../constants/aux_constants';
import { CLIENT_PAGE } from '../../../constants/clientConstants';
import { Title, Content, Styles } from '../../../style/client';
import { clientService } from 'src/services/clientService';
import { userService } from 'src/services/GM/userService';
import { SweetAlertType } from 'react-bootstrap-sweetalert/dist/types';
import { User, AlertConfig } from './users.interface';
import { MobilityGroup } from './../Groups/groups.interface';
import DatePicker from 'react-datepicker';
import dayjs from 'dayjs';
import { generateArrayOfYears } from '../../../utils/utils';
import { handleErrors } from 'src/utils/handleErrors';

const UserCreate: React.FC = () => {
  const [user, setUser] = useState({} as User);
  const [alertDialog, setAlertDialog] = useState(false);
  const [mobilityGroups, setMobilityGroups] = useState([] as MobilityGroup[]);
  const [alertDialogConfig, setAlertDialogConfig] = useState({} as AlertConfig);
  const [hasValidVAT, setHasValidVAT] = useState(false);
  const fileInputField = useRef(null);
  const history = useHistory();
  const [years, setYears] = useState([] as Number[]);
  const months = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ];
  useEffect(() => {
    getMobilityGroups();
    setUser({ ...user, countryCode: 351 });
    let _years = generateArrayOfYears('past', 0) as Number[];
    setYears(_years);
  }, []);

  const createUser = async (user) => {
    const result = await userService.create(user);
    if (result.status === 200) {
      alert('Sucesso', result.message, 'success', true);
    } else {
      alert('Erro', handleErrors.getMessage(result), 'error');
    }
  };

  const validateVAT = async (vat) => {
    if (vat !== '') {
      const result = await clientService.validateVAT({ nif: vat });
      if (result.status === 200) {
        setHasValidVAT(true);
      } else {
        setHasValidVAT(false);
        alert(
          'Erro',
          result.errors[0]?.defaultMessage ?? 'NIF inválido',
          'error'
        );
      }
    }
  };

  const getMobilityGroups = async () => {
    const result = await userService.getMobilityGroups();
    if (result) {
      setMobilityGroups(result);
    } else {
      console.log(result.message);
    }
  };

  const checkFields = () => {
    if (
      user.name !== undefined &&
      user.name !== '' &&
      user.email !== undefined &&
      user.email !== ''
    ) {
      return true;
    }
    return false;
  };

  const alert = (
    title: String,
    message: String,
    type: SweetAlertType,
    redirect: Boolean = false
  ) => {
    setAlertDialog(true);
    setAlertDialogConfig({
      title: title,
      message: message,
      type: type,
      redirect: redirect,
    });
  };

  const handleNewFileUpload = (e: any, showMessage = false) => {
    if (e.target.files.length) {
      const file = e.target.files[0];
      const fileSize = file.size / 1024 / 1024;

      if (fileSize > 2) {
        alert('Erro', 'Arquivo deve ter menos que 2 MB', 'error');
        return false;
      }

      getBase64(file).then((data) => {
        let base64String = data as string;
        base64String = base64String.split(',')[1];
        setUser({ ...user, imageName: file.name, imageFile: base64String });
      });
    }
  };

  const getBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const resolveBase64 = (file: any) => {
    if (file.split(':')[0] == 'data') {
      return file;
    } else {
      return 'data:image/png;base64,' + file;
    }
  };

  return (
    <>
      <Title>Utilizadores</Title>

      <Content>
        <div className='content'>
          <Container fluid className='card-no-tabs client_create'>
            <Row
              style={{
                textAlign: 'center',
                font: 'normal normal normal 18px/22px Roboto',
                letterSpacing: '0px',
                color: '#878787',
                opacity: 1,
                marginLeft: '82.5px',
                marginTop: '51px',
              }}
            >
              <Link className='color-back' to={'/gm/users/create'}>
                <p>
                  <u>&#60; Voltar</u>
                </p>
              </Link>
            </Row>

            <Row>
              <Col
                style={{
                  textAlign: 'left',
                  font: 'normal normal bold 26px/34px Roboto',
                  letterSpacing: '0px',
                  color: '#2292CC',
                  opacity: 1,
                  marginLeft: '82.5px',
                }}
              >
                <h3>Novo utilizador</h3>
              </Col>

              <Col></Col>
            </Row>

            <Row
              style={{
                textAlign: 'left',
                font: 'normal normal normal 16px/19px Roboto',
                letterSpacing: '0px',
                color: '#1E1E1E',
                opacity: 1,
                margin: '15.99px 0 61.64px 82.5px',
              }}
            >
              <p>Por favor, preencha as seguintes informações.</p>
            </Row>

            <Row className='division' style={Styles.clientPageDivision}>
              <Col>
                <Row style={{ margin: '0 -40px 0 -40px' }}>
                  <Col style={Styles.clientPageDivisionHeader}>
                    <h4>Dados pessoais</h4>
                  </Col>
                  <Col md={3} className='center'>
                    <h4>Foto</h4>
                  </Col>
                </Row>
                <hr />
              </Col>
            </Row>

            <Row>
              <Col style={{ margin: '15px 0 0 125.5px' }}>
                <h6 className='light'>Nome completo</h6>
                <Form onSubmit={(e) => e.preventDefault()}>
                  <Row>
                    <Col>
                      <Form.Control
                        onChange={(e) =>
                          setUser({ ...user, name: e.target.value })
                        }
                        defaultValue={user.name}
                      />
                    </Col>
                  </Row>
                </Form>

                <Row>
                  <Col style={{ margin: '15px 0 0 0px' }}>
                    <h6 className='light'>E-mail</h6>
                    <Form.Control
                      onChange={(e) =>
                        setUser({
                          ...user,
                          email: e.target.value,
                        })
                      }
                      defaultValue={user.email}
                    />
                  </Col>
                </Row>
              </Col>

              <Col md={3} className='center'>
                {user.imageFile ? (
                  <div className={'image'}>
                    <input
                      type='file'
                      ref={fileInputField}
                      onChange={handleNewFileUpload}
                      accept='image/*'
                    />
                    <Image
                      src={resolveBase64(user.imageFile)}
                      roundedCircle
                      width='100'
                    />
                  </div>
                ) : (
                  <div className={'upload'}>
                    <input
                      type='file'
                      ref={fileInputField}
                      onChange={handleNewFileUpload}
                      accept='image/*'
                    />
                    <FontAwesomeIcon icon={faCamera} color={'#2292CC'} />
                  </div>
                )}
              </Col>
            </Row>

            <Row>
              <Col md={11}>
                <Row>
                  <Col md={8} style={{ margin: '15px 0 0 125.5px' }}>
                    <h6 className='light'>{CLIENT_PAGE.CONTACT}</h6>
                    <Row>
                      <Col md={4}>
                        <Form.Control
                          as='select'
                          onChange={(e) =>
                            setUser({
                              ...user,

                              countryCode: Number(e.target.value),
                            })
                          }
                          defaultValue={user.countryCode}
                        >
                          {phoneIndicative.map((item) => {
                            return (
                              <option key={item.id} value={item.value}>
                                {item.name}
                              </option>
                            );
                          })}
                        </Form.Control>
                      </Col>
                      <Col>
                        <Form.Control
                          type={'number'}
                          onChange={(e) =>
                            setUser({
                              ...user,

                              phoneNumber: Number(e.target.value),
                            })
                          }
                          defaultValue={user.phoneNumber}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col style={{ margin: '15px 0 0 125.5px' }}>
                <h6 className='light'>Data de nascimento</h6>
                <Row>
                  <Col>
                    <DatePicker
                      selected={user.dateBirth}
                      dateFormat='dd/MM/yyyy'
                      maxDate={new Date()}
                      onChange={(date) =>
                        date &&
                        setUser({
                          ...user,
                          dateBirth: new Date(date.toString()),
                        })
                      }
                      renderCustomHeader={({
                        date,
                        changeYear,
                        changeMonth,
                        decreaseMonth,
                        increaseMonth,
                        prevMonthButtonDisabled,
                        nextMonthButtonDisabled,
                      }) => (
                        <div
                          style={{
                            margin: 10,
                            display: 'flex',
                            justifyContent: 'center',
                          }}
                        >
                          <button
                            onClick={decreaseMonth}
                            disabled={prevMonthButtonDisabled}
                          >
                            {'<'}
                          </button>
                          <select
                            value={dayjs(date).year()}
                            onChange={({ target: { value } }) =>
                              changeYear(Number(value))
                            }
                          >
                            {years.map((option) => (
                              <option
                                key={option.toString()}
                                value={option.toString()}
                              >
                                {option}
                              </option>
                            ))}
                          </select>
                          <select
                            value={months[dayjs(date).month()]}
                            onChange={({ target: { value } }) =>
                              changeMonth(months.indexOf(value))
                            }
                          >
                            {months.map((option) => (
                              <option key={option} value={option}>
                                {option}
                              </option>
                            ))}
                          </select>
                          <button
                            onClick={increaseMonth}
                            disabled={nextMonthButtonDisabled}
                          >
                            {'>'}
                          </button>
                        </div>
                      )}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col style={{ margin: '15px 0 0 125.5px' }}>
                <h6 className='light'>Género <span className='small'>(Opcional)</span></h6>
                <Row>
                  <Form.Check
                    custom
                    inline
                    name='group1'
                    type={'radio'}
                    id={`checkbox-female`}
                    style={{ marginLeft: '15px' }}
                    onChange={(e) => setUser({ ...user, userGender: 'F' })}
                  />
                  <p>Feminino</p>
                  <Form.Check
                    custom
                    inline
                    name='group1'
                    type={'radio'}
                    id={`checkbox-male`}
                    style={{ marginLeft: '15px' }}
                    onChange={(e) => setUser({ ...user, userGender: 'M' })}
                  />
                  <p>Masculino</p>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col md={11} style={{ margin: '15px 0 0 125.5px' }}>
                <h6 className='light'>NIF</h6>
                <Row>
                  <Col lg={4}>
                    <Form.Control
                      maxLength={9}
                      onChange={(e) =>
                        setUser({ ...user, nif: e.target.value })
                      }
                      onBlur={() => validateVAT(user.nif)}
                      defaultValue={user.nif}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className='division' style={Styles.clientPageDivision}>
              <Col>
                <Row style={{ margin: '62px -40px 0 -40px' }}>
                  <Col style={Styles.clientPageDivisionHeader}>
                    <h4>Morada principal</h4>
                  </Col>
                </Row>
                <hr />
              </Col>
            </Row>
            <Row>
              <Col md={11} style={{ margin: '15px 0 0 125.5px' }}>
                <h6 className='light'>Endereço</h6>
                <Row>
                  <Col lg={8}>
                    <Form.Control
                      onChange={(e) =>
                        setUser({ ...user, address: e.target.value })
                      }
                      defaultValue={user.address}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col lg={9}>
                <Row>
                  <Col style={{ margin: '15px 0 0 125.5px' }}>
                    <h6 className='light'>País</h6>
                    <Row>
                      <Col>
                        <Form.Control
                          onChange={(e) =>
                            setUser({ ...user, country: e.target.value })
                          }
                          defaultValue={user.country}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col style={{ margin: '15px 0 0 0px' }}>
                    <h6 className='light'>Distrito</h6>
                    <Row>
                      <Col>
                        <Form.Control
                          onChange={(e) =>
                            setUser({ ...user, district: e.target.value })
                          }
                          defaultValue={user.district}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col lg={9}>
                <Row>
                  <Col style={{ margin: '15px 0 0 125.5px' }}>
                    <h6 className='light'>Código Postal</h6>
                    <Row>
                      <Col>
                        <Form.Control
                          onChange={(e) =>
                            setUser({ ...user, postalCode: e.target.value })
                          }
                          defaultValue={user.postalCode}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col style={{ margin: '15px 0 0 0px' }}>
                    <h6 className='light'>Localidade</h6>
                    <Row>
                      <Col>
                        <Form.Control
                          maxLength={9}
                          onChange={(e) =>
                            setUser({ ...user, region: e.target.value })
                          }
                          defaultValue={user.region}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className='division' style={Styles.clientPageDivision}>
              <Col>
                <Row style={{ margin: '62px -40px 0 -40px' }}>
                  <Col style={Styles.clientPageDivisionHeader}>
                    <h4>Grupo mobilidade</h4>
                  </Col>
                </Row>
                <hr />
                <p style={{ margin: '0px 0 0 70px' }}>
                  Clique sobre o grupo para adicionar. Pode também adicionar
                  mais tarde.
                </p>
              </Col>
            </Row>
            <Row style={{ margin: '30px 0 0 110px' }}>
              {mobilityGroups &&
                mobilityGroups.length > 0 &&
                mobilityGroups.map((item, i) => {
                  return (
                    <Col lg={4} key={'col' + String(i)}>
                      <Row>
                        <Form.Check
                          custom
                          inline
                          name='group1'
                          type={'radio'}
                          id={`checkbox-group${item.id}`}
                          style={{ marginLeft: '15px' }}
                          onChange={(e) =>
                            setUser({ ...user, groupId: item.id })
                          }
                        />
                        <p>{item.acronym}</p>
                      </Row>
                    </Col>
                  );
                })}
            </Row>

            <Row>
              <Col md={11}>
                <Row
                  className='division bottom-divider'
                  style={{ margin: '141.36px 0 167.5px 0' }}
                >
                  <Col className='center'>
                    <Button
                      style={Styles.okButton}
                      variant='outline-info'
                      size='lg'
                      onClick={() => createUser(user)}
                      disabled={!checkFields()}
                    >
                      Registar Utilizador
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
            {alertDialog && (
              <SweetAlert
                type={alertDialogConfig?.type}
                title={alertDialogConfig?.title ?? ''}
                onConfirm={() => {
                  setAlertDialog(false);
                  if (alertDialogConfig.redirect) {
                    history.push(`/gm/users`);
                  }
                }}
              >
                {alertDialogConfig?.message ?? ''}.
              </SweetAlert>
            )}
          </Container>
        </div>
      </Content>
    </>
  );
};

export default UserCreate;

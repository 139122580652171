import React from 'react'
import { Badge } from 'react-bootstrap'

const PackBadge = ({ badge }) => {
    return (
        <Badge pill variant={badge ? 'success' : 'secondary'}>
            {badge ? 'ativo' : 'inativo'}
        </Badge>
    )
}

export default PackBadge
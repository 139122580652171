import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Alert } from 'react-bootstrap';
import ModalBase from './ModalBase';
import dayjs from 'dayjs';
import { makeStyles } from '@material-ui/core/styles';
import DatePicker from 'react-datepicker';
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import Loading from 'react-fullscreen-loading';
import VehicleCard from './VehicleCard';
import {
  StationLocation,
  Vehicle,
  Coverage,
  Extra,
} from './reservation.interface';
import { User } from '../../Profile/profile.interface';
import { bookingService } from '../../../services/bookingService';
import CoverageCard from './CovarageCard';
import { ProfileData } from './ProfileData';

export const ModalGuerin = ({ closeButton, open, onClose, booking }) => {
  const [step, setStep] = useState(1);

  const [user, setUser] = useState({} as User);
  const [product, setProduct] = useState({
    productId: 0,
    serviceId: 2,
    packId: 0,
  });
  const [reserveId, setReserveId] = useState(null);
  const [selectedPickup, setSelectedPickup] = useState({} as StationLocation);
  const [selectedDropOff, setSelectedDropOff] = useState({} as StationLocation);

  const [datePickup, setDatePickup] = useState(null);
  const [dateDropOff, setDateDropOff] = useState(null);
  const [minDateDropOff, setMinDateDropOff] = useState<Date | null>(null);
  const [maxDateDropOff, setMaxDateDropOff] = useState<Date | null>(null);

  const [showNoResults, setShowNoResults] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [locations, setLocations] = useState([] as StationLocation[]);

  const [vehicles, setVehicles] = useState([] as Vehicle[]);
  const [selectedVehicle, setSelectedVehicle] = useState({} as Vehicle);

  const [coverages, setCoverages] = useState([] as Coverage[]);
  const [selectedCoverage, setSelectedCoverage] = useState({} as Coverage);

  const [extras, setExtras] = useState([] as Extra[]);
  const [selectedExtras, setSelectedExtras] = useState([] as Extra[]);

  const [showOtherData, setShowOtherData] = useState(false);
  const [showMyData, setShowMyData] = useState(true);

  const [price, setPrice] = useState(0.0);

  const guerinReserveLimit = 30

  const [alertDialog, setAlertDialog] = useState(false);
  const [alertConfig, setAlertConfig] = useState({
    title: '',
    message: '',
    redirect: false,
    redirectURL: '',
  });

  const useStyles = makeStyles({
    root: {
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    icon: {
      borderRadius: '50%',
      width: 16,
      height: 16,

      backgroundColor: 'rgba(109, 171, 36, 0.2)',
      'input:hover ~ &': {
        backgroundColor: '#6dab24',
      },
      'input:disabled ~ &': {
        boxShadow: 'none',
        background: 'rgba(109,171,36,.5)',
      },
    },
    checkedIcon: {
      backgroundColor: '#6dab24',
      backgroundImage:
        'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
      '&:before': {
        display: 'block',
        width: 16,
        height: 16,
        backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
        content: '""',
      },
      'input:hover ~ &': {
        backgroundColor: '#6dab24',
      },
    },
  });
  const classes = useStyles();

  useEffect(() => {
    setProduct(booking)
    if (booking.reserveId) {
      setReserveId(booking.reserveId);
    }
  }, [booking]);



  useEffect(() => {
    if (selectedExtras.length > 0) {
      calculateExtras(selectedExtras);
    }
  }, [selectedExtras]);

  useEffect(() => {
    if (!open) {
      setDatePickup(null);
      setDateDropOff(null);
      setMaxDateDropOff(null);
      setMinDateDropOff(null);
      setVehicles([] as Vehicle[]);
      setSelectedVehicle({} as Vehicle);
      setSelectedCoverage({} as Coverage);
      setSelectedExtras([] as Extra[]);
      setStep(1);
    } else {
      getLocations();
    }
  }, [open]);

  const getLocations = async () => {
    setIsLoading(true);

    try {
      const result = await bookingService.getStations(2);
      setLocations(result ?? []);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDate = (date, type) => {
    const selectedDay = dayjs(date).locale('en').format('dddd').toUpperCase();

    if (type === 'pickup') {
      if (selectedPickup.daysClosed?.daysOfWeek?.includes(selectedDay)) {
        alert('Informações', 'Estação fechada na data selecionada', false, '');
        return;
      }

      let newDate = date as any as Date
      newDate.setHours(10)
      newDate.setMinutes(0);
      setMinDateDropOff(newDate as any)
      let max = dayjs(newDate).add(guerinReserveLimit, 'day').toDate()
      setMaxDateDropOff(max)
      setDatePickup(newDate as any);
      setDateDropOff(null)

    } else if (type === 'pickup_time') {
      let hour = date.getHours()
      let minutes = date.getMinutes()
      if (hour !== undefined && minutes !== null && datePickup != undefined) {
        let newDate = datePickup as any as Date
        newDate.setHours(hour)
        newDate.setMinutes(minutes);
        setDatePickup(newDate as any);
      }
    } else if (type === 'dropoff_time') {
      let hour = date.getHours()
      let minutes = date.getMinutes()
      if (hour !== undefined && minutes !== null && dateDropOff != undefined) {
        let newDate = dateDropOff as any as Date
        newDate.setHours(hour)
        newDate.setMinutes(minutes);
        setDateDropOff(newDate as any);
      }
    } else if (type === 'dropoff') {
      if (selectedDropOff.daysClosed?.daysOfWeek?.includes(selectedDay)) {
        alert('Informações', 'Estação fechada na data selecionada', false, '');
        return;
      }
      const date1 = dayjs(datePickup);
      const date2 = dayjs(date);
      if (date2.diff(date1) < 0) {
        alert(
          'Informações',
          'A data de devolução tem de ser maior que a de levantamento',
          false,
          ''
        );
        return;
      }
      if (
        selectedDropOff &&
        Object.keys(selectedDropOff).length === 0 &&
        Object.getPrototypeOf(selectedDropOff) === Object.prototype
      ) {
        alert('Informações', 'Selecione a estação de devolução', false, '');
      } else {
        let newDate = date as any as Date
        newDate.setHours(10)
        newDate.setMinutes(0);
        setDateDropOff(newDate as any);
      }
    }
  };

  const handleChange = (event, type) => {
    if (!event.nativeEvent.inputType) {
      let stationName = event.target.value;
      let obj = locations.find((e) => {
        return e.station?.toUpperCase() === stationName.toUpperCase();
      });
      if (obj) {
        event.target.blur();
        if (type === 'pickup') {
          setSelectedPickup(obj as unknown as StationLocation);
          setSelectedDropOff(obj as unknown as StationLocation);
        } else if (type === 'dropoff') {
          setSelectedDropOff(obj as unknown as StationLocation);
        }
      }
    }
  };

  const clear = (event) => {
    event.target.value = '';
  };

  const handleClose = () => {
    setStep(1);
  };

  const getVehicles = async () => {
    setIsLoading(true);
    setShowNoResults(false);
    try {
      const result = await bookingService.getVehicles({
        serviceId: 2,
        countryID: '33',
        pickUpDTO: {
          date: datePickup,
          rentalStation: selectedPickup.stationID,
        },
        dropOffDTO: {
          date: dateDropOff,
          rentalStation: selectedDropOff.stationID,
        },
      });

      if (result.length === 0) {
        setShowNoResults(true);
      } else {
        setVehicles(result);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const selectVehicle = (vehicle) => {
    setSelectedVehicle(vehicle);
    setStep(2);
    getCoverages(vehicle);
  };

  const getCoverages = async (vehicle) => {
    setIsLoading(true);
    const search = {
      serviceId: 2,
      countryID: '33',
      groupID: vehicle.group,
      pickUpDTO: {
        date: dayjs(datePickup).format('YYYY-MM-DD HH:mm'),
        rentalStation: selectedPickup.stationID,
      },
      dropOffDTO: {
        date: dayjs(dateDropOff).format('YYYY-MM-DD HH:mm'),
        rentalStation: selectedDropOff.stationID,
      },
      extras: [0],
    };
    const result = await bookingService.getExtras(search);
    setIsLoading(false);
    if (result.packExtrasList) {
      setCoverages(result.packExtrasList);

      result.packExtrasList.map(async (item) => {
        if (item.packDTO.extra === 'Pack Base') {
          setSelectedCoverage(item);
          let id = item.packDTO.extraID as any;
          search.extras = [id];
          const result = await bookingService.getPrice(search);
          if (result.priceDTO) {
            setPrice(result.priceDTO.totalPrice);
          }
        }
        return;
      });
    }
  };

  const selectCoverage = async (coverage: Coverage) => {
    setIsLoading(true);
    setSelectedCoverage(coverage);
    const search = {
      serviceId: 2,
      countryID: '33',
      groupID: selectedVehicle.group,
      pickUpDTO: {
        date: dayjs(datePickup).format('YYYY-MM-DD HH:mm'),
        rentalStation: selectedPickup.stationID,
      },
      dropOffDTO: {
        date: dayjs(dateDropOff).format('YYYY-MM-DD HH:mm'),
        rentalStation: selectedDropOff.stationID,
      },
      extras: [coverage.packDTO?.extraID],
    };
    setSelectedExtras([coverage.packDTO as Extra]);
    const result = await bookingService.getPrice(search);
    setIsLoading(false);
    if (result.priceDTO) {
      setPrice(result.priceDTO.totalPrice);
    }
  };

  const getExtras = async () => {
    setIsLoading(true);
    const search = {
      selectedPack: {
        extraID: selectedCoverage.packDTO?.extraID ?? '',
        extrasIncluded: selectedCoverage.packDTO?.extrasIncluded ?? [],
      },
      reservation: {
        serviceId: 2,
        countryID: '33',
        groupID: selectedVehicle.group,
        pickUpDTO: {
          date: dayjs(datePickup).format('YYYY-MM-DD HH:mm'),
          rentalStation: selectedPickup.stationID,
        },
        dropOffDTO: {
          date: dayjs(dateDropOff).format('YYYY-MM-DD HH:mm'),
          rentalStation: selectedDropOff.stationID,
        },
      },
    };
    const result = await bookingService.getExtrasSecondstep(search);
    setIsLoading(false);
    setExtras(result);
    setStep(3);
  };

  const handleExtras = (extra) => {
    if (selectedExtras.includes(extra)) {
      let array = selectedExtras.filter((item) => item !== extra);
      setSelectedExtras(array);
    } else {
      setSelectedExtras([...selectedExtras, extra]);
    }
  };

  const calculateExtras = async (extras) => {
    setIsLoading(true);
    let extrasId = [] as string[];
    extras.map((el: Extra) => extrasId.push(el.extraID));

    const search = {
      serviceId: 2,
      countryID: '33',
      groupID: selectedVehicle.group,
      pickUpDTO: {
        date: dayjs(datePickup).format('YYYY-MM-DD HH:mm'),
        rentalStation: selectedPickup.stationID,
      },
      dropOffDTO: {
        date: dayjs(dateDropOff).format('YYYY-MM-DD HH:mm'),
        rentalStation: selectedDropOff.stationID,
      },
      extras: extrasId,
    };

    const result = await bookingService.getPrice(search);
    setIsLoading(false);
    if (result.priceDTO) {
      setPrice(result.priceDTO.totalPrice);
    }
  };

  const handleChangePersonalData = (event) => {
    if (event.target.value === 'personal') {
      setShowMyData(true);
      setShowOtherData(false);
    }
    if (event.target.value === 'other') {
      setShowOtherData(true);
      setShowMyData(false);
    }
  };

  const goToPersonalData = () => {
    setStep(4);
  };

  const saveProfile = (user) => {
    setUser(user);
    setStep(5);
  };

  const back = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };

  const alert = (
    title: string,
    message: string,
    redirect: boolean,
    redirectURL?: string
  ) => {
    setAlertConfig({
      title: title,
      message: message,
      redirect: redirect,
      redirectURL: redirectURL ? redirectURL : '',
    });
    setAlertDialog(true);
  };

  const submit = async () => {
    setIsLoading(true);
    let totalExtras = [] as string[];
    selectedExtras.map((el: Extra) => totalExtras.push(el.extraID));
    try {
      const _booking = {
        serviceId: 2,
        groupID: selectedVehicle.group,
        countryID: '33',
        pickUpDTO: {
          date: dayjs(datePickup).format('YYYY-MM-DD HH:mm'),
          rentalStation: selectedPickup.stationID,
        },
        dropOffDTO: {
          date: dayjs(dateDropOff).format('YYYY-MM-DD HH:mm'),
          rentalStation: selectedDropOff.stationID,
        },
        ...user,
        driverName: user.name,
      };

      let data = {
        extras: totalExtras,
        totalValue: price,
        serviceId: 2,
        packId: product.packId,
        productId: product.productId,
        groupID: _booking.groupID,
        driver: {
          name: _booking.name,
          address: _booking.address,
          email: _booking.email,
          region: _booking.region,
          country: _booking.country,
          postalCode: _booking.postalCode,
          dateBirth: _booking.dateBirth,
          driverLicense: _booking.driverLicense,
          moreInformation: _booking.moreInformation,
          countryCode: _booking.countryCode,
          phoneNumber: _booking.phoneNumber,
          userGender: _booking.userGender,
          documentExpiryDate: _booking.documentExpiryDate,
          driverLicenseExpiryDate: _booking.driverLicenseExpiryDate,
          identificationCard: _booking.identificationCard,
          nif: _booking.nif,
          driverLicenseIssuingCountry: _booking.driverLicenseIssuingCountry,
        },
        pickUpDTO: {
          date: _booking.pickUpDTO.date,
          rentalStation: _booking.pickUpDTO.rentalStation,
        },
        dropOffDTO: {
          date: _booking.dropOffDTO.date,
          rentalStation: _booking.dropOffDTO.rentalStation,
        },
      }
      if (reserveId) {
        const result = await bookingService.mcfReserveEdit({ ...data }, reserveId);

        if (result.reservationNr) {
          setIsLoading(false);
          alert('Sucesso', result.message, true, '');
        } else {
          setIsLoading(false);
          alert(
            'Informações',
            result.message ?? 'Erro ao fazer a reserva',
            false,
            ''
          );
        }
      } else {
        const result = await bookingService.mcfReserve({ ...data });

        if (result.reservationNr) {
          setIsLoading(false);
          alert('Sucesso', result.message, true, '');
        } else {
          setIsLoading(false);
          alert(
            'Informações',
            result.message ?? 'Erro ao fazer a reserva',
            false,
            ''
          );
        }
      }
    } catch (e) {
      setIsLoading(false);
      alert('Informações', 'Erro ao fazer a reserva', false, '');
    }
  };

  const component = () => {
    return (
      <div className='reserve_modal'>
        {isLoading && (
          <Loading
            loading
            background='rgba(255, 255, 255, .4)'
            loaderColor='#3498db'
          />
        )}

        {step === 1 && (
          <div className='card'>
            <Row>
              <Col xs={12} md={12}>
                <p>Local de levantamento</p>
                <input
                  type='text'
                  list='stations'
                  className='station_input'
                  onChange={(e) => {handleChange(e, 'pickup')}}
                  onClick={clear}
                  onFocus={clear}
                  placeholder='Selecionar uma estação'
                  value={selectedPickup.station}
                />
                <datalist id='stations'>
                  {locations.map((el) => {
                    return <option key={el.stationID} value={el.station}></option>;
                  })}
                </datalist>
              </Col>
              <Col xs={6} md={4}>
                <p>Data</p>
                <DatePicker
                  selected={datePickup}
                  dateFormat='dd/MM/yyyy'
                  onChange={(date) => handleDate(date, 'pickup')}
                  minDate={new Date()}
                  disabled={Object.keys(selectedPickup).length === 0}
                />
              </Col>
              <Col xs={6} md={3}>
                <p>Hora</p>
                <DatePicker
                  selected={datePickup}
                  dateFormat='HH:mm'
                  onChange={(date) => handleDate(date, 'pickup_time')}
                  showTimeSelect
                  showTimeSelectOnly
                  timeFormat='HH:mm'
                  timeIntervals={30}
                  disabled={Object.keys(selectedPickup).length === 0}
                  minTime={setHours(setMinutes(new Date(), 0), 7)}
                  maxTime={setHours(setMinutes(new Date(), 30), 23)}
                />
              </Col>
            </Row>
            <Row className='mt-2'>
              <Col xs={12} md={12}>
                <p>Local de devolução</p>
                <input
                  type='text'
                  list='stations'
                  className='station_input'
                  onChange={(e) => handleChange(e, 'dropoff')}
                  onClick={clear}
                  onFocus={clear}
                  placeholder='Selecionar uma estação'
                  value={selectedDropOff.station}
                />
                <datalist id='stations'>
                  {locations.map((el) => {
                    return <option key={el.stationID} value={el.station}>{el.station}</option>;
                  })}
                </datalist>
              </Col>
              <Col xs={12} md={4}>
                <p>Data</p>
                <DatePicker
                  selected={dateDropOff}
                  dateFormat='dd/MM/yyyy'
                  onChange={(date) => handleDate(date, 'dropoff')}
                  minDate={minDateDropOff}
                  maxDate={maxDateDropOff}
                  disabled={
                    Object.keys(selectedDropOff).length === 0 &&
                    datePickup === null
                  }
                />
              </Col>
              <Col xs={6} md={3}>
                <p>Hora</p>
                <DatePicker
                  selected={dateDropOff}
                  dateFormat='HH:mm'
                  onChange={(date) => handleDate(date, 'dropoff_time')}
                  showTimeSelect
                  showTimeSelectOnly
                  timeFormat='HH:mm'
                  timeIntervals={30}
                  minTime={setHours(setMinutes(new Date(), 0), 7)}
                  maxTime={setHours(setMinutes(new Date(), 30), 23)}
                  disabled={
                    Object.keys(selectedDropOff).length === 0 &&
                    datePickup === null
                  }
                />
              </Col>
            </Row>
            <Row className='d-flex justify-content-center mt-5 mb-5'>
              <Button variant='outline-secondary' onClick={() => getVehicles()}>
                Procurar
              </Button>
            </Row>
            <Row>
              {vehicles.map((el) => {
                return (
                  <Col md={12} onClick={() => selectVehicle(el)} key={el.model}>
                    <VehicleCard vehicle={el} />
                  </Col>
                );
              })}
            </Row>
            {showNoResults && (
              <Row className='d-flex justify-content-center'>
                <Alert variant={'dark'} dismissible>
                  <Alert.Heading>Aviso</Alert.Heading>
                  <p>
                    Nenhum veículo encontrado. Altere os parâmetros de pesquisa
                    e tente novamente.
                  </p>
                </Alert>
              </Row>
            )}
          </div>
        )}
        {step === 2 && (
          <div className='card'>
            <Row className='d-flex justify-content-center mb-5'>
              <p>Selecionar cobertura</p>
            </Row>

            {coverages &&
              coverages.length > 0 &&
              coverages.map((el) => {
                return (
                  <Row key={el.packDTO?.extraID}>
                    <CoverageCard
                      coverage={el}
                      selectCoverage={() => selectCoverage(el)}
                      selected={selectedCoverage === el}
                    />
                  </Row>
                );
              })}
            <Row className='d-flex justify-content-center mt-2 mb-2'>
              <Col>Valor estimado</Col>
              {price && (
                <Col className='d-flex justify-content-end'>{price}€</Col>
              )}
            </Row>
            <Row className='d-flex justify-content-center mt-2 mb-2'>
              <a onClick={back} className='mr-3 mt-2'>
                Voltar
              </a>
              <Button variant='outline-secondary' onClick={() => getExtras()}>
                Continuar
              </Button>
            </Row>
          </div>
        )}
        {step === 3 && (
          <div className='card'>
            <Row className='d-flex justify-content-center mb-5'>
              <p>Selecionar extras</p>
            </Row>

            <FormGroup className='extras_list'>
              {extras &&
                extras.length > 0 &&
                extras.map((el) => {
                  return (
                    <div key={el.extraID}>
                      <Row>
                        <Col>
                          <FormControlLabel
                            control={<Checkbox color='default' />}
                            label={el.extra?.toUpperCase()}
                            onChange={() => handleExtras(el)}
                            checked={selectedExtras.includes(el)}
                          />
                          <p className='excess'>Franquia {el.excess}€</p>
                        </Col>
                        <Col md={4}>
                          <Row className='d-flex justify-content-end'>
                            <h2 className='value'>{el.value}€</h2>
                          </Row>
                        </Col>
                      </Row>
                      <Row>
                        <p className='description_extra'>{el.description}</p>
                      </Row>
                    </div>
                  );
                })}
            </FormGroup>
            <Row className='d-flex justify-content-center mt-2 mb-2'>
              <Col>Valor estimado</Col>
              {price && (
                <Col className='d-flex justify-content-end'>{price}€</Col>
              )}
            </Row>
            <Row className='d-flex justify-content-center mt-2 mb-2'>
              <a onClick={back} className='mr-3 mt-2'>
                Voltar
              </a>
              <Button
                variant='outline-secondary'
                onClick={() => goToPersonalData()}
              >
                Continuar
              </Button>
            </Row>
          </div>
        )}

        {step === 4 && (
          <div className='card'>
            <Row className='d-flex justify-content-center mb-5'>
              <p>Dados pessoais</p>
            </Row>
            <Row className='d-flex justify-content-center mb-5'>
              <FormControl component='fieldset'>
                <RadioGroup
                  aria-label='gender'
                  name='controlled-radio-buttons-group'
                  onChange={handleChangePersonalData}
                >
                  <FormControlLabel
                    value='personal'
                    control={<Radio color='default' checked={showMyData} />}
                    label='Este veículo é para mim'
                  />
                  <FormControlLabel
                    value='other'
                    control={<Radio color='default' checked={showOtherData} />}
                    label='Este veículo é para outra pessoa'
                  />
                </RadioGroup>
              </FormControl>
            </Row>
            <Row>
              <div className='p-4'>
                {showMyData && (
                  <ProfileData data={true} onSave={saveProfile} back={back} />
                )}
                {showOtherData && (
                  <ProfileData data={false} onSave={saveProfile} back={back} />
                )}
              </div>
            </Row>
          </div>
        )}
        {step === 5 && (
          <div className='card'>
            <Row className='d-flex justify-content-center mb-5'>
              <p>Resumo</p>
            </Row>
            <Row className='d-flex justify-content-center mb-5'>
              <Col md={12}>
                <p>
                  Veículo: {selectedVehicle.brand} {selectedVehicle.model}
                </p>
              </Col>
              <Col md={12}>
                <p>
                  Levantamento: {selectedPickup.station} em{' '}
                  {datePickup && dayjs(datePickup).format('YYYY-MM-DD HH:mm')}
                </p>
              </Col>
              <Col md={12}>
                <p>
                  Entrega: {selectedDropOff.station} em{' '}
                  {dateDropOff && dayjs(dateDropOff).format('YYYY-MM-DD HH:mm')}
                </p>
              </Col>
              <Col md={12}>
                <p>Cobertura: {selectedCoverage.packDTO?.extra}</p>
              </Col>
              <Col md={12}>
                <p>Extras:</p>
              </Col>
              <Col md={12}>
                <ul>
                  {selectedExtras.map((el) => {
                    return <li key={el.extraID}>{el.extra}</li>;
                  })}
                </ul>
              </Col>
            </Row>
            <Row className='d-flex justify-content-center mt-2 mb-2'>
              <Col>Valor estimado</Col>
              {price && (
                <Col className='d-flex justify-content-end'>{price}€</Col>
              )}
            </Row>
            <Row className='d-flex justify-content-center mt-2 mb-2'>
              <a onClick={back} className='mr-3 mt-2'>
                Voltar
              </a>
              <Button variant='outline-secondary' onClick={() => submit()}>
                Reservar
              </Button>
            </Row>
            <Row className='d-flex justify-content-center mt-2 mb-2'>

              <Col><p>No ato de levantamento do carro será exigido um cartão de crédito físico em nome do titular da reserva para Depósito de segurança que será devolvido no término do contrato*</p></Col>
            </Row>
            <Row>
              <Col className='d-flex justify-content-center mt-2 mb-2 footNote'>Oferta limitada ao stock existente*</Col>
            </Row>
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      <ModalBase
        serviceId={2}
        Component={component}
        closeButton={closeButton}
        open={open}
        onClose={onClose}
      ></ModalBase>
      <Dialog
        open={alertDialog}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>{alertConfig.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            {alertConfig.message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setAlertDialog(false);
              if (alertConfig.redirect) {
                closeButton();
                setStep(1);
              }
            }}
            color='primary'
            autoFocus
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ModalGuerin;

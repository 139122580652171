import Pricing from '../screens/Pricing/Pricing';
import PricingCreate from '../screens/Pricing/PricingCreate';
import PricingPromocode from '../screens/Pricing/PricingPromocode';

const routes: MyRoute[] = [
  {
    path: '/pricing',
    exact: true,
    component: Pricing,
    role: 'ROLE_PRICING',
    auth: true,
  },
  {
    path: '/pricing/create',
    exact: true,
    component: PricingCreate,
    role: 'ROLE_PRICING',
    auth: true,
  },
  {
    path: '/promocode',
    exact: true,
    component: PricingPromocode,
    role: 'ROLE_PRICING_USER',
    auth: true,
  },
];

interface MyRoute {
  path: string;
  exact: boolean;
  component: React.FC<{}>;
  role: string;
  auth: boolean;
}

export default routes;

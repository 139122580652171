import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';
class PackHeader extends Component {
  render() {
    return (
      <div className={this.props.class}>
        <Row className='pl-0 mt-5 pack_header'>
          <Col md={3} xs={6} className='pr-0'>
            <img src={this.props.img} alt='' />
          </Col>
          <Col md={6} className='px-0 d-none d-sm-block'>
            <hr />
          </Col>
          <Col md={2} xs={6}>
            <Row className='justify-content-end'>
              <p>€ {this.props.value}</p>
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}

export default PackHeader;

import styled from 'styled-components';

const Title = styled.h1`
  font-size: 30px;
  font-weight: bold;
  padding: 20px 0 60px 0;
  text-indent: 10px;
`;

export default Title;

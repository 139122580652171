import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Row, Col, Image, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { companyService } from '../../../services/companyService';
import {
  CLIENT_PAGE,
  CLIENT_VIEW_PAGE_TITLE,
} from '../../../constants/clientConstants';
import { Title, Content, Styles } from '../../../style/client';
import { clientService } from 'src/services/clientService';
import { useHistory } from 'react-router-dom';
import { iCan } from '../../../redux/sessionReducer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit} from '@fortawesome/free-solid-svg-icons';

const ClientView: React.FC = () => {
  const [services, setServices] = useState([
    { serviceId: Number(), service: String(), description: String() },
  ]);
  const [client, setClient] = useState({} as Client);
  const { id } = useParams();
  const history = useHistory();

  useEffect(() => {
    const getServices = async () => {
      const result = await companyService.getClientServices(id);
      if (result.data) {
        let services = result.data.filter((el) => el.serviceId !== 6);
        setServices(services);
      }
    };

    getClient(id).then((result) => {
      if (result) {
        setClient(result);
      } else {
        history.push(`/clients`);
      }
    });
    getServices();
  }, []);



  const getProfile = () => {
    if (client.userContact) {
      if (client.userContact.profileDescription === null) {
        return '-';
      }
    } else {
      return '-';
    }
    return client.userContact?.profileDescription;
  };

  const getClient = async (id) => {
    try {
      const result = await clientService.getById(id);
      return result.data as Client;
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <Title>{CLIENT_VIEW_PAGE_TITLE}</Title>

      <Content>
        <div className='content'>
          <Container fluid className='card-no-tabs client_view'>
            <Row
              style={{
                textAlign: 'center',
                font: 'normal normal normal 18px/22px Roboto',
                letterSpacing: '0px',
                color: '#878787',
                opacity: 1,
                marginLeft: '82.5px',
                marginTop: '51px',
              }}
            >
              <Link className='color-back' to={'/clients'}>
                <p>
                  <u>&#60; {CLIENT_PAGE.BACK}</u>
                </p>
              </Link>
              
              <Col md={{ offset: 10 }}>
                {iCan('ROLE_CREATE_CLIENT') && (
                  <Link to={`/clients/edit/${id}`}>
                    <Button
                      variant='outline-info'
                      size='lg'
                      className='float-right'
                      style={Styles.button}
                      data-testid='newClientButton'
                    >
                      <FontAwesomeIcon icon={faEdit} />
                    </Button>
                  </Link>
                )}
              </Col>
            </Row>
        

            <Row>
              <Col
                style={{
                  textAlign: 'left',
                  font: 'normal normal bold 26px/34px Roboto',
                  letterSpacing: '0px',
                  color: '#2292CC',
                  opacity: 1,
                  marginLeft: '82.5px',
                }}
              >
                <h3>{client.name}</h3>
              </Col>

              <Col>
                <Row className={'d-flex justify-content-end mr-5'}>
                  {/* <div className={'edit_button'} onClick={() => console.log('edit')}>
              <FontAwesomeIcon
                color={'#2292cc'}
                icon={faEdit}
                className={'icon'}
              />
            </div> */}
                  {/*        <div className={'edit_button'}>
              <FontAwesomeIcon
                color={'#2292cc'}
                icon={faTrashAlt}
                className={'icon'}
              />
            </div> */}
                </Row>
              </Col>
            </Row>

            <Row
              style={{
                textAlign: 'left',
                font: 'normal normal normal 16px/19px Roboto',
                letterSpacing: '0px',
                color: '#1E1E1E',
                opacity: 1,
                margin: '15.99px 0 61.64px 82.5px',
              }}
            >
              <p>{CLIENT_PAGE.DESCRIPTION}</p>
            </Row>

            <Row className='division' style={Styles.clientPageDivision}>
              <Col>
                <Row style={{ margin: '0 -40px 0 -40px' }}>
                  <Col style={Styles.clientPageDivisionHeader}>
                    <h4>{CLIENT_PAGE.COMPANY}</h4>
                  </Col>
                  <Col md={3} className='center'>
                    <h4>{CLIENT_PAGE.LOGO}</h4>
                  </Col>
                </Row>
                <hr />
              </Col>
            </Row>

            <Row>
              <Col lg={4} style={{ margin: '15px 0 0 125.5px' }}>
                <h6 className='light'>{CLIENT_PAGE.NAME}</h6>
                <h6 className='bold'>
                  {client.name !== null ? client.name : '-'}
                </h6>
              </Col>

              <Col style={{ margin: '15px 0 0 0px' }}>
                <h6 className='light'>{CLIENT_PAGE.NIF}</h6>
                <h6 className='bold'>
                  {client.nif !== null ? client.nif : '-'}
                </h6>
              </Col>

              <Col md={3} className='center'>
                {client.imageFile ? (
                  <Image src={client.imageFile} roundedCircle width='100' />
                ) : (
                  <p>-</p>
                )}
              </Col>
            </Row>

            <Row className='division' style={Styles.clientPageDivision}>
              <Col>
                <Row style={{ margin: '62px -40px 0 -40px' }}>
                  <Col style={Styles.clientPageDivisionHeader}>
                    <h4>{CLIENT_PAGE.CONTACT_INFO}</h4>
                  </Col>
                </Row>
                <hr />
              </Col>
            </Row>

            <Row>
              <Col lg={4} style={{ margin: '15px 0 0 125.5px' }}>
                <h6 className='light'>{CLIENT_PAGE.FULLNAME}</h6>
                <h6 className='bold'>
                  {client.userContact &&
                    (client.userContact.name !== null
                      ? client.userContact.name
                      : '-')}
                </h6>
              </Col>

              <Col style={{ margin: '15px 0 0 0px' }}>
                <h6 className='light'>{CLIENT_PAGE.EMAIL}</h6>
                <h6 className='bold'>
                  {client.userContact &&
                    (client.userContact.email !== null
                      ? client.userContact.email
                      : '-')}
                </h6>
              </Col>
            </Row>

            <Row>
              <Col lg={4} style={{ margin: '65px 0 0 125.5px' }}>
                <h6 className='light'>{CLIENT_PAGE.PROFILE}</h6>
                <h6 className='bold'>{getProfile()}</h6>
              </Col>
              <Col style={{ margin: '65px 0 0 0px' }}>
                <h6 className='light'>{CLIENT_PAGE.CONTACT}</h6>
                {client.userContact ? (
                  <h6 className='bold'>
                    (+{client.userContact.countryCode}){' '}
                    {client.userContact.phoneNumber}
                  </h6>
                ) : (
                  <p>-</p>
                )}
              </Col>
            </Row>

            <Row className='division' style={Styles.clientPageDivision}>
              <Col>
                <Row style={{ margin: '62px -40px 0 -40px' }}>
                  <Col style={Styles.clientPageDivisionHeader}>
                    <h4>{CLIENT_PAGE.CONTRACT}</h4>
                  </Col>
                </Row>
                <hr />
              </Col>
            </Row>

            <Row>
              <Col lg={4} style={{ margin: '15px 0 0 125.5px' }}>
                <h6 className='light'>{CLIENT_PAGE.BALANCE_LIMIT}</h6>

                <h6 className='bold'>{client.balance?.toFixed(2)} €</h6>
              </Col>

              <Col style={{ margin: '15px 0 0 0' }}>
                <h6 className='light'>{CLIENT_PAGE.HIRED_SERVICES}</h6>
                {services.map((item) => {
                  return <h6 key={item.service + String(item.description)}>{item.description}</h6>;
                })}
              </Col>
            </Row>
          </Container>
        </div>
      </Content>
    </>
  );
};

export default ClientView;

export interface Company {
  name: string;
  id: number;
}

export interface Client {
  id: number;
  name: string;
  nif: number;
  imageFile?: string;
  imageName?: string;
  balance: number;
  userContact: UserContact;
  clientServices: number[];
  status: string;
  dateCreated: Date;
  dateLastUpdated: Date;
}

export interface UserContact {
  id: number;
  name: string;
  email: string;
  countryCode: number;
  phoneNumber: number;
  profile: string;
  clientId: number;
  profileDescription: string;
}

export interface Service {
  active: Boolean;
  dateCreated: Date;
  description: String;
  id: Number;
  name: String;
  pathLocationFiles?: String;
}

export function validateSize(file) {
  var FileSize = file.size / 1024 / 1024; // in MB
  if (FileSize > 2) {
    alert('File size exceeds 2 MB');
    return false;
  }
  return true;
}

//Products
export const service = [
  {
    id: 0,
    service: 'Kinto One',
    label: 'Kinto',
    uk: 'b78d868b-05d5-4e49-a146-7290e6997263',
    image: require('../assets/logos/kinto-config.png'),
    imageList: require('../assets/logos/kinto-one-logo-on-2x.png'),
  },
  {
    id: 1,
    service: 'Guerin',
    label: 'guerin',
    uk: '61e9b58d-08b5-461c-b784-45ae76aadf07',
    image: require('../assets/Services/Guerin.png'),
    imageList: require('../assets/logos/guerin-xs-logo-on-2x.png'),
  },
  {
    id: 2,
    service: 'Xtracars',
    label: 'xtracars',
    uk: '8196b810-c1c8-4976-b2eb-b5aa88898e82',
    image: require('../assets/Services/Xtracars.png'),
    imageList: require('../assets/logos/xtracars-xs-logo-on-2x.png'),
  },
  {
    id: 3,
    service: 'Rodinhas',
    label: 'rodinhas',
    uk: '36459709-b040-48d9-9f5c-b92fc8b68735',
    image: require('../assets/Services/Rodinhas.png'),
    imageList: require('../assets/logos/rodinhas-xs-logo-on-2x.png'),
  },
  {
    id: 4,
    service: 'BeDriven',
    label: 'bedriven',
    uk: 'cca684d6-4786-4a8a-b354-ffc835a60b9b',
    image: require('../assets/Services/Bedriven.png'),
    imageList: require('../assets/logos/bedriven-xs-logo-on-2x.png'),
  },
];

export const fuel = [
  { id: 1, name: 'Gasolina' },
  { id: 2, name: 'Diesel' },
  { id: 3, name: 'Hibrido' },
  { id: 4, name: 'Elétrico' },
];

export const group = [
  { id: 'MINI', name: 'MINI' },
  { id: 'C', name: 'C' },
  { id: 'C1', name: 'C1' },
  { id: 'E', name: 'E' },
  { id: 'J', name: 'J' },
  { id: 'E1', name: 'E1' },
  { id: 'SUVM', name: 'SUVM' },
  { id: 'J1', name: 'J1' },
  { id: 'Q', name: 'Q' },
  { id: 'F', name: 'F' },
  { id: '10', name: '10' },
  { id: 'SUVC', name: 'SUVC' },
  { id: 'N', name: 'N' },
  { id: 'G', name: 'G' },
  { id: 'A', name: 'A' },
  { id: 'O', name: 'O' },
  { id: 'L', name: 'L' },
  { id: 'NA', name: 'NA' },
  { id: 'H', name: 'H' },
  { id: 'Z', name: 'Z' },
  { id: '02', name: '02' },
  { id: '02A', name: '02A' },
  { id: '03', name: '03' },
  { id: '04', name: '04' },
  { id: '05', name: '05' },
  { id: '01', name: '01' },
  { id: '01', name: '01' },
  { id: 'P', name: 'P' },
];

export const transmition = [
  { id: 1, name: 'Manual' },
  { id: 2, name: 'Automática' },
];

export const category = [
  { id: 1, name: 'Económico' },
  { id: 2, name: 'SUV' },
  { id: 3, name: 'Intermédio' },
  { id: 4, name: 'Premium' },
  { id: 5, name: 'Minivan' },
  { id: 6, name: 'Comerciais' },
];

export const term = [
  { id: 24, name: '24 meses' },
  { id: 36, name: '36 meses' },
  { id: 48, name: '48 meses' },
  { id: 60, name: '60 meses' },
];

export const productAux = {
  file: null,
  vDisplacement: null,
  vHorses: null,
  tripNumber: 0,
  vDoor: null,
  vBad: null,
  vConsumption: null,
  vFuel: null,
  vCat: null,
  vStreaming: null,
  vSubscribe: null,
  validSubscribe: null,
  vSafePrice: null,
  vSafeCivil: false,
  vSafeOneWay: false,
  vSafeCirc: false,
  vSafePriceBase: 0.0,
  vSafePriceMedium: 0.0,
  vSafePricePremium: 0.0,
  vSafePriceSuperPremium: 0.0,
  vSafeRoofBase: false,
  vSafeRoofMedium: false,
  vSafeRoofPremium: false,
  vSafeRoofSuperPremium: false,
  vSafeCoverBase: false,
  vSafeCoverMedium: false,
  vSafeCoverPremium: false,
  vSafeCoverSuperPremium: false,
  vSafeGlassesBase: false,
  vSafeGlassesMedium: false,
  vSafeGlassesPremium: false,
  vSafeGlassesSuperPremium: false,
  vFranchiseReductionBase: false,
  vFranchiseReductionMedium: false,
  vFranchiseReductionPremium: false,
  vFranchiseReductionSuperPremium: false,
  vSafeSDCWBase: false,
  vSafeSDCWMedium: false,
  vSafeSDCWPremium: false,
  vSafeSDCWSuperPremium: false,
  vSafePeoplesBase: false,
  vSafePeoplesMedium: false,
  vSafePeoplesPremium: false,
  vSafePeoplesSuperPremium: false,
  vSafePricePlusBase: 0.0,
  vSafePricePlusMedium: 0.0,
  vSafePricePlusPremium: 0.0,
  vSafePriceSuperPlusPremium: 0.0,
  vSafeDescription: null,
  vComplementPriceDayGlasses: 0.0,
  vComplementPriceDayFranchiseRed: 0.0,
  vComplementPriceDaySCDW: 0.0,
  vComplementPriceDayPeoples: 0.0,
  vComplementPriceDayPeoplesPlus: 0.0,
  vComplementPriceDayPeopleChild: 0.0,
  vComplementPriceDaySeatChild: 0.0,
  vComplementPriceDaySeatChildBaby: 0.0,
  vComplementPriceDayGPS: 0.0,
  vComplementPriceDayWiFi: 0.0,
  vComplementPriceDayCrossBorder: 0.0,
  vComplementPriceDayTollService: 0.0,
  vRulesDescription: null,
  active: null,
  srcFile: null,
  fromMigration: null,
  dtCreate: null,
  dtLastUpdate: null,
  verifiedByUser: null,
  state: 0,
  company: {
    uk: null,
  },
  franqMX: 0.0,
  carBrand: null,
  carModel: null,
  carGroup: '0',
  name: null,
  sipp: null,
  baseInformation: null,
  description: null,
  descriptionResume: null,
  subDescription: null,
  priceNotIVA: 0.0,
  tax: 0.0,
  price: 0.0,
  addicionalData: null,
  validation: null,
  addicionalPayment: null,
  lackDescription: null,
  _1A6Days: 0.0,
  _7A29Days: 0.0,
  more30Days: 0.0,
  franqMN: 0.0,
  sCDW: 0.0,
  carType: null,
  duration: 0.0,
  distance: 0.0,
  distanceKm: 0,
  other: null,
  totalMonth: 0,
  initPrice: 0.0,
  priceMonth: 0.0,
  vAdvantageMaintenance: false,
  vAdvantageDamage: false,
  vAdvantageReplaceVehicle: false,
  vAdvantageAssistance: false,
  vAdvantageTire: false,
  benefitsDescription: null,
};

export default function tripRodinhas(service) {
  console.log('R', service.name);
  if (service.name === 'rodinhas') {
    const aux = [];
    for (let i = 0; i < 10; i++) {
      let auxRodinhas = {
        tripId: i + 1,
        _5km: 0.0,
        _10km: 0.0,
        _15km: 0.0,
        price: null,
      };
      aux.push(auxRodinhas);
    }
    console.log('R', aux);
    return aux;
  }
  if (service.name === 'bedriven') {
    const aux = [];
    for (let i = 0; i < 10; i++) {
      let auxBedriven = {
        tripId: null,
        _5km: null,
        _10km: null,
        _15km: null,
        price: null,
      };
      aux.push(auxBedriven);
      console.log('R', aux);
    }
    return aux;
  }
  return [];
}

//Clients
export const clientAux = {
  status: 0,
  companyName: null,
  nif: null,
  picProfile: null,
  name: null,
  email: null,
  profile: 1,
  indicative: 1,
  phone: null,
  balance: 0.0,
  deleted: false,
  finlog: true,
  guerin: true,
  xtracars: true,
  rodinhas: true,
  bedriven: true,
};

export const clientProfile = [
  {
    id: 'GM',
    name: 'Gestor de mobilidade',
  },
];

//ver lista https://www.e-konomista.pt/indicativos-de-paises/
export const phoneIndicative = [
  {
    id: 1,
    name: 'PT (+351)',
    value: 351,
  },
  {
    id: 2,
    name: 'FR (+33)',
    value: 3,
  },
  {
    id: 3,
    name: 'ES (+34)',
    value: 34,
  },
  {
    id: 4,
    name: 'BE (+32)',
    value: 32,
  },
  {
    id: 5,
    name: 'DK (+45)',
    value: 45,
  },
  {
    id: 6,
    name: 'IT (+39)',
    value: 39,
  },
  {
    id: 7,
    name: 'IR (+353)',
    value: 353,
  },
];

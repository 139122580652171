import React, { useState, useEffect, useRef } from 'react';
import { Form, Container, Row, Col, Image, Button } from 'react-bootstrap';
import { Title, Content, Styles } from '../../../style/content';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faUsers, faBox } from '@fortawesome/free-solid-svg-icons';
import { CardsData } from './dashboard.interface';
import { dashboardService } from 'src/services/GM/dashboardService';

const Dashboard: React.FC = () => {
  const [cardsData, setCardsData] = useState([] as CardsData[]);

  useEffect(() => {
    getCardsData();
  }, []);

  const getCardsData = async () => {
    const result = await dashboardService.cardsData();
    if (result) {
      setCardsData(result);
    } else {
      console.log(result.message);
    }
  };
  const getIcon = (service) => {
    switch (service) {
      case 'USER':
        return faUser;
      case 'USERS':
        return faBox;
      case 'GROUP':
        return faUsers;
      default:
        return faUser;
    }
  };

  return (
    <>
      <Title>Bem-vindo!</Title>

      <Content>
        <Container fluid className='card-no-tabs dashboardGM'>
          <Row>
            {cardsData &&
              cardsData.length > 0 &&
              cardsData.map((el) => {
                return (
                  <Col
                    className='d-flex justify-content-around'
                    key={el.serviceDescription}
                  >
                    <div className='status_card'>
                      <p>
                        <FontAwesomeIcon
                          size='3x'
                          icon={getIcon(el.serviceType)}
                          color='#FFF'
                        />
                      </p>
                      <h1 className='title'>{el.size}</h1>
                      <h3 className='sub_title'>{el.serviceDescription}</h3>
                    </div>
                  </Col>
                );
              })}
          </Row>
        </Container>
      </Content>
    </>
  );
};

export default Dashboard;

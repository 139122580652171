import React from 'react'
import { Badge } from 'react-bootstrap'

const BadgeComponent = ({ badge }) => {
    const chooseBadge = badge => {
        let badgeObject = { name: '', type: '' }

        switch (badge) {
            case 'DRAFT':
                badgeObject.name = 'rascunho'
                badgeObject.type = 'info'
                break
            case 'PUBLISHED':
                badgeObject.name = 'publicado'
                badgeObject.type = 'success'
                break
            case 'WAITING':
                badgeObject.name = 'aguardando verif.'
                badgeObject.type = 'warning'
                break
            case 'INACTIVE':
                badgeObject.name = 'inativo'
                badgeObject.type = 'danger'
                break
            case 'EXPIRED':
                badgeObject.name = 'expirado'
                badgeObject.type = 'dark'
                break
        }

        return badgeObject
    }

    return (
        <Badge pill variant={chooseBadge(badge).type}>
            {chooseBadge(badge).name}
        </Badge>
    )
}

export default BadgeComponent
import React from 'react';
import {
  Container,
  Row,
  Col,
  Table,
  Image,
  Badge,
  Tooltip,
  OverlayTrigger,
  Button,
} from 'react-bootstrap';
import { Styles } from '../../../style/client';
import { TABLE_HEADERS, BADGE_LABEL } from '../../../constants/clientConstants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { iCan } from 'src/redux/sessionReducer';

export const UsersTable = ({ clientList, setClientPage, deleteClient }) => {
  const getStatusBadge = (status) => {
    switch (status) {
      case 'ACTIVE':
        return (
          <Badge overlap="rectangular"  style={Styles.activeBadge} pill variant='success'>
            {' '}
            {BADGE_LABEL.ACTIVE}{' '}
          </Badge>
        );
      case 'INACTIVE':
        return (
          <Badge overlap="rectangular"  style={Styles.inactiveBadge} variant='warning'>
            {' '}
            {BADGE_LABEL.INACTIVE}{' '}
          </Badge>
        );
      case 'CANCELED':
        return (
          <Badge overlap="rectangular"  style={Styles.canceledBadge} pill variant='danger'>
            {' '}
            {BADGE_LABEL.CANCELED}{' '}
          </Badge>
        );
      case 'DELETED':
        return (
          <Badge overlap="rectangular"  style={Styles.canceledBadge} pill variant='info'>
            {' '}
            {BADGE_LABEL.DELETED}{' '}
          </Badge>
        );
      default:
        break;
    }
  };

  const initialName = (words, maxLenght) => {
    'use strict';

    return words.slice(0, maxLenght) + "...";
  };

  return (
    <Table
      data-testid='clientTable'
      responsive
      className={'client_list users_table'}
    >
      <thead>
        <tr data-testid='tableHeader'>
          <th>Utilizador</th>
          <th>Perfil</th>
          <th>Grupo</th>
          <th>Estado</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {clientList &&
          clientList.map((client, _) => (
            <tr data-testid='clientRow' key={client.id} className='client_row'>
              <td className='align-middle'>
                <Container>
                  <Row>
                    <Col md='auto'>
                      {client.imageFile && (
                        <Image
                          src={client.imageFile}
                          roundedCircle
                          style={{ height: 40, width: 'auto' }}
                        />
                      )}
                      {!client.imageFile && (
                        <Image
                          src={require('../../../assets/avatar/profile.png')}
                          roundedCircle
                          style={{ height: 40, width: 'auto' }}
                        />
                      )}
                    </Col>
                    <Col md='auto' style={Styles.clientName}>
                      <Row style={{ fontSize: 14 }} >
                  
                      <OverlayTrigger
                        key={"name-tooltiptop"}
                        placement="right"
                        overlay={
                          <Tooltip id={`tooltipName-top`}>
                          {client.name}
                          </Tooltip>
                        }>
                        <span>
                          {client.name.length > 30
                          ? initialName(client.name, 30)
                          : client.name}                                                 
                          </span>
                        </OverlayTrigger>
                      </Row>
                      <Row style={{ fontSize: 10 }}>{client.email}</Row>
                    </Col>
                  </Row>
                </Container>
              </td>

              <td colSpan='1' className='align-middle'>
                {['top'].map((placement) => (
                  <OverlayTrigger
                    key={placement}
                    placement={placement}
                    overlay={
                      <Tooltip id={`tooltip-${placement}`}>
                        {client.profileInitials !== null
                          ? client.profileDescription
                          : '-'}
                      </Tooltip>
                    }
                  >
                    <Button variant='light'>
                      {client.profileInitials !== null
                        ? client.profileInitials
                        : '-'}
                    </Button>
                  </OverlayTrigger>
                ))}
              </td>

              <td className='align-middle'>
                <Container>
                  <Row>
                    <div className='social-networks mt-4'>
                      {client.services?.length > 0 &&
                        client.services.map((number, i) => {
                          return (
                            <i className={`icon-service-${number}`} key={i}></i>
                          );
                        })}
                      {client.group?.service?.length > 0 &&
                        client.group?.service?.map((number, i) => {
                          return (
                            <i className={`icon-service-${number}`} key={i}></i>
                          );
                        })}
                      {!client.services?.length > 0 &&
                        !client.group?.service?.length > 0 && <p>0 serviços</p>}
                    </div>
                  </Row>
                </Container>
              </td>

              <td className='align-middle'>
                <Container>
                  <Row>
                    <Col md='auto'>{getStatusBadge(client.status)}</Col>
                  </Row>
                </Container>
              </td>

              <td className='align-middle'>
                <Container>
                  <Row>
                    {iCan('ROLE_READ_USER') && (
                      <FontAwesomeIcon
                        color='#eeeeee'
                        icon={faUserEdit}
                        size='lg'
                        className='mr-4'
                        onClick={() => setClientPage(client)}
                      />
                    )}
                    {iCan('ROLE_DELETE_USER') && (
                      <FontAwesomeIcon
                        color='#eeeeee'
                        icon={faTrashAlt}
                        size='lg'
                        onClick={() => deleteClient(client)}
                      />
                    )}
                  </Row>
                </Container>
              </td>
            </tr>
          ))}
      </tbody>
    </Table>
  );
};

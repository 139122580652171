import api from '../utils/api';

export const notificationsService = {
  getAll,
  notificationsSeen,
};

async function getAll() {
  const response = await api.apiPrivate
    .get('/api/product/notifications')
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        return error.request;
      }
      throw error;
    });
  return response;
}

async function notificationsSeen(data) {
  const response = await api.apiPrivate
    .patch('/api/product/notifications/seen', data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        return error.request;
      }
      throw error;
    });
  return response;
}

import api from '../../utils/api';

export const dashboardService = {
  cardsData,
};

async function cardsData() {
  const response = await api.apiPrivate
    .get('api/comercial-profile/dashboard/product-manager')
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        return error.request;
      }
      throw error;
    });
  return response;
}

import React, { useState, useEffect, useRef } from 'react';
import { Form, Container, Row, Col, Image, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SweetAlert from 'react-bootstrap-sweetalert';
import {
  faUserCircle,
  faCheckCircle,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { Title, Content, Styles } from '../../../style/client';
import { groupsService } from 'src/services/GM/groupsService';
import { userService } from 'src/services/GM/userService';
import { servicesService } from 'src/services/GM/servicesService';
import { SweetAlertType } from 'react-bootstrap-sweetalert/dist/types';
import { User, AlertConfig } from '../Users/users.interface';
import { MobilityGroup } from './groups.interface';
import { useHistory } from 'react-router-dom';

const GroupsCreate: React.FC = () => {
  const [users, setUsers] = useState({} as User[]);
  const [usersGroup, setUserGroup] = useState({} as User[]);
  const [selectedUsersToAdd, setSelectedUsersToAdd] = useState([]);
  const [selectedUsersToRemove, setSelectedUsersToRemove] = useState([]);
  const [selectedServices, setSelectedServices] = useState([] as Number[]);
  const [services, setServices] = useState([
    { serviceId: Number(), service: String(), rules: String() },
  ]);
  const [selectedUsersLenght, setSelectedUsersLenght] = useState(0);
  const [group, setGroup] = useState({} as MobilityGroup);
  const [alertDialog, setAlertDialog] = useState(false);
  const [alertDialogConfig, setAlertDialogConfig] = useState({} as AlertConfig);
  const [selectedGroup, setSelectedGroup] = useState(Number);
  const [review, setReview] = useState(false);
  const history = useHistory();

  useEffect(() => {
    setGroup({ ...group });
    getUsersWithoutGroup();
    getServices();
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const groupId = urlParams.get('groupid');
    if (groupId) {
      setSelectedGroup(groupId as never);
      getServiceConfig(groupId);
    }
  }, []);

  const getServices = async () => {
    const result = await servicesService.getServices();
    if (result) {
      setServices(result);
    }
  };

  const getServiceConfig = async (id) => {
    const result = await groupsService.getById(id);
    if (result) {
      setGroup(result);
      result.mobilityGroupServiceDTO.map((el) => {
        updateSelectedServices(el.serviceId);
      });
    }
  };

  const getCompany = (id: any) => {
    return {
      1: 'kinto one',
      2: 'guerin',
      3: 'xtracars',
      4: 'rodinhas',
      42: 'rodinhas',
      5: 'bedriven',
    }[id];
  };

  const createGroup = async () => {
    //services information
    let newServices = [{}];
    if (selectedServices.length > 0) {
      selectedServices.forEach((item) => {
        let index = services.findIndex((el) => el.serviceId == item);
        if (index !== -1) {
          let rule = services[index].rules as string;
          newServices.push({
            rules: rule as never,
            serviceId: item as never,
          });
        }
      });
    }
    newServices.shift();

    //users
    let newUsers = group.users;

    if (selectedUsersToRemove.length > 0) {
      selectedUsersToRemove.forEach((id) => {
        let index = newUsers.findIndex((user) => user.id == id);
        if (index !== -1) {
          newUsers.splice(index, 1);
        }
      });
    }

    if (selectedUsersToAdd.length > 0) {
      selectedUsersToAdd.forEach((id) => {
        let index = users.findIndex((user) => user.id == id);
        if (index !== -1) {
          newUsers.push(users[index]);
        }
      });
    }

    const result = await groupsService.edit({
      ...group,
      mobilityGroupServiceDTO: newServices,
      users: newUsers,
    });

    if (result.status === 200) {
      alert('Sucesso', result.message, 'success', true);
    } else {
      alert('Erro', result.message, 'error');
    }
  };

  const getUsersWithoutGroup = async () => {
    const result = await userService.getUsersWithoutGroup();
    if (result) {
      setUsers(result);
    } else {
      alert('Erro', result.message, 'error');
    }
  };

  const checkFields = () => {
    if (
      group.name !== undefined &&
      group.name !== '' &&
      group.acronym !== undefined &&
      group.acronym !== '' &&
      group.plafondValue !== undefined &&
      group.plafondValue !== 0
    ) {
      return true;
    }
    return false;
  };

  const deleteGroup = async () => {
    if (selectedGroup) {
      const result = await groupsService.deleteGroup(selectedGroup);

      if (result.status === 200) {
        alert('Sucesso', result.message, 'success', true);
      } else {
        alert('Erro', result.message, 'error');
      }
    }
  };

  const updateSelectedServices = (id) => {
    setSelectedServices((prevSelectedServices) => {
      if (prevSelectedServices.includes(id)) {
        return prevSelectedServices.filter((item) => item !== id);
      } else {
        return [...prevSelectedServices, id];
      }
    });
  };

  const updateSelectedUsers = (id: never, type) => {
    if (type === 'add') {
      if (selectedUsersToAdd.includes(id)) {
        setSelectedUsersToAdd((prevSelectedUsersToAdd) =>
          prevSelectedUsersToAdd.filter((number) => number !== id)
        );
      } else {
        setSelectedUsersToAdd((prevSelectedUsersToAdd) => [
          ...prevSelectedUsersToAdd,
          id,
        ]);
      }
    } else if (type === 'remove') {
      if (selectedUsersToRemove.includes(id)) {
        setSelectedUsersToRemove((prevSelectedUsersToRemove) =>
          prevSelectedUsersToRemove.filter((number) => number !== id)
        );
      } else {
        setSelectedUsersToRemove((prevSelectedUsersToRemove) => [
          ...prevSelectedUsersToRemove,
          id,
        ]);
      }
    }
  };

  const alert = (
    title: String,
    message: String,
    type: SweetAlertType,
    redirect: Boolean = false
  ) => {
    setAlertDialog(true);
    setAlertDialogConfig({
      title: title,
      message: message,
      type: type,
      redirect: redirect,
    });
  };

  return (
    <>
      <Title>Grupos de Mobilidade</Title>

      <Content>
        <div className='content'>
          <Container
            fluid
            className='card-no-tabs client_create groups_create_information'
          >
            <Row
              style={{
                textAlign: 'center',
                font: 'normal normal normal 18px/22px Roboto',
                letterSpacing: '0px',
                color: '#878787',
                opacity: 1,
                marginLeft: '82.5px',
                marginTop: '51px',
              }}
            >
              <Link className='color-back' to={'/gm/groups'}>
                <p>
                  <u>&#60; Voltar</u>
                </p>
              </Link>
            </Row>

            <Row>
              <Col
                style={{
                  textAlign: 'left',
                  font: 'normal normal bold 26px/34px Roboto',
                  letterSpacing: '0px',
                  color: '#2292CC',
                  opacity: 1,
                  marginLeft: '82.5px',
                }}
              >
                <h3>Editar grupo de mobilidade</h3>
              </Col>

              <Col md={3}>
                <Button
                  variant='outline-info'
                  size='lg'
                  className='float-right'
                  style={Styles.button}
                  data-testid='newGroupButton'
                  onClick={deleteGroup}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </Button>
              </Col>
            </Row>

            <Row
              style={{
                textAlign: 'left',
                font: 'normal normal normal 16px/19px Roboto',
                letterSpacing: '0px',
                color: '#1E1E1E',
                opacity: 1,
                margin: '15.99px 0 61.64px 82.5px',
              }}
            >
              <p>Por favor, preencha as seguintes informações.</p>
            </Row>

            <Row className='division' style={Styles.clientPageDivision}>
              <Col>
                <Row style={{ margin: '0 -40px 0 -40px' }}>
                  <Col style={Styles.clientPageDivisionHeader}>
                    <h4>1. Informação identificativa</h4>
                  </Col>
                </Row>
                <hr />
              </Col>
            </Row>

            <Row style={{ marginRight: '40px' }}>
              <Col style={{ margin: '15px 0 0 125.5px' }}>
                <h6 className='light'>Designação do grupo</h6>
                <Row>
                  <Col>
                    <Form.Control
                      onChange={(e) =>
                        setGroup({ ...group, name: e.target.value })
                      }
                      defaultValue={group.name}
                      disabled={review}
                    />
                  </Col>
                </Row>
              </Col>
              <Col style={{ margin: '15px 0 0 0px' }}>
                <h6 className='light'>Acrónimo</h6>
                <Row>
                  <Col>
                    <Form.Control
                      onChange={(e) =>
                        setGroup({ ...group, acronym: e.target.value })
                      }
                      defaultValue={group.acronym}
                      disabled={review}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row style={{ marginRight: '75px' }}>
              <Col md={11} style={{ margin: '35px 40px 0 125.5px' }}>
                <h6 className='light'>Descrição</h6>
                <Row>
                  <Col>
                    <Form.Control
                      as='textarea'
                      onChange={(e) =>
                        setGroup({ ...group, description: e.target.value })
                      }
                      defaultValue={group.description}
                      disabled={review}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col md={11} style={{ margin: '35px 0 35px 125.5px' }}>
                <h6 className='light'>Plafond global</h6>
                <Row>
                  <Col lg={4}>
                    <Form.Control
                      type={'number'}
                      onChange={(e) =>
                        setGroup({
                          ...group,
                          plafondValue: Number(e.target.value),
                        })
                      }
                      defaultValue={group.plafondValue}
                      disabled={review}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row className='division' style={Styles.clientPageDivision}>
              <Col>
                <Row style={{ margin: '0 -40px 0 -40px' }}>
                  <Col style={Styles.clientPageDivisionHeader}>
                    <h4>2. Serviços de mobilidade</h4>
                  </Col>
                </Row>
                <hr />
              </Col>
            </Row>

            <Row>
              <Col md={11} style={{ margin: '35px 0 35px 125.5px' }}>
                <Row className='services'>
                  {services &&
                    services.length > 0 &&
                    services.map((item) => {
                      return (
                        <Col
                          lg={5}
                          style={{ marginTop: '5px' }}
                          key={item.serviceId}
                        >
                          <Row
                            style={{ marginLeft: '5px', marginBottom: '10px' }}
                          >
                            <Form.Check
                              type={'checkbox'}
                              id={`checkbox-${getCompany(item.serviceId)}`}
                              onChange={() =>
                                updateSelectedServices(item.serviceId)
                              }
                              checked={selectedServices.some(
                                (serviceID) => serviceID === item.serviceId
                              )}
                              disabled={review}
                            />
                            {item.serviceId && (
                              <label
                              className='groups_create_logo_label'
                                htmlFor={`checkbox-${getCompany(
                                  item.serviceId
                                )}`}
                              >
                                <img
                                  src={require(`../../../assets/logos/${getCompany(
                                    item.serviceId
                                  )}.png`)}
                                  alt={`${getCompany(item.serviceId)}`}
                                  className='groups_create_logo'
                                />
                              </label>
                            )}
                          </Row>
                        </Col>
                      );
                    })}
                </Row>
              </Col>
            </Row>

            <Row className='division' style={Styles.clientPageDivision}>
              <Col>
                <Row style={{ margin: '0 -40px 0 -40px' }}>
                  <Col style={Styles.clientPageDivisionHeader}>
                    <h4>3. Adicionar utilizadores</h4>
                  </Col>
                </Row>
                <hr />
              </Col>
            </Row>
            <h4 className='users_title'>
              Adicionar utilizadores individualmente
            </h4>
            <Row className='users_subtitle'>
              <Col>
                <p className=''>utilizadores ainda sem grupo atribuído</p>
              </Col>
              <Col>
                <p className='selected_users'>
                  Utilizadores selecionados {selectedUsersToAdd.length}
                </p>
              </Col>
            </Row>

            <Row
              style={{ margin: '30px 0 0 110px' }}
              className='d-flex justify-content-around'
            >
              {users &&
                users.length > 0 &&
                users.map((item, i) => {
                  return (
                    <Col
                      lg={3}
                      key={'col' + String(i)}
                      className='users_card mr-1 mt-3'
                      onClick={(e) =>
                        review
                          ? ''
                          : updateSelectedUsers(item.id as never, 'add')
                      }
                    >
                      <Row>
                        <Col lg={2} className='mr-2'>
                          {selectedUsersToAdd.includes(item.id as never) && (
                            <FontAwesomeIcon
                              size='2x'
                              icon={faCheckCircle}
                              color='#2292CC'
                            />
                          )}
                          {!selectedUsersToAdd.includes(item.id as never) && (
                            <FontAwesomeIcon
                              size='2x'
                              icon={faUserCircle}
                              color='#878787'
                            />
                          )}
                        </Col>
                        <Col>
                          <h3>{item.name}</h3>
                          <p>{item.email}</p>
                        </Col>
                      </Row>
                    </Col>
                  );
                })}
            </Row>

            <Row className='division' style={Styles.clientPageDivision}>
              <Col>
                <Row style={{ margin: '50px -40px 0 -40px' }}>
                  <Col style={Styles.clientPageDivisionHeader}>
                    <h4>4. Remover utilizadores</h4>
                  </Col>
                </Row>
                <hr />
              </Col>
            </Row>
            <h4 className='users_title'>
              Remover utilizadores individualmente
            </h4>
            <Row className='users_subtitle'>
              <Col>
                <p className=''>utilizadores já atribuídos a este grupo</p>
              </Col>
              <Col>
                <p className='selected_users'>
                  Utilizadores selecionados {selectedUsersToRemove.length}
                </p>
              </Col>
            </Row>

            <Row
              style={{ margin: '30px 0 0 110px' }}
              className='d-flex justify-content-around'
            >
              {group &&
                group.users &&
                group.users.length > 0 &&
                group.users.map((item, i) => {
                  return (
                    <Col
                      lg={3}
                      key={'col' + String(i)}
                      className='users_card mr-1 mt-3'
                      onClick={(e) =>
                        review
                          ? ''
                          : updateSelectedUsers(item.id as never, 'remove')
                      }
                    >
                      <Row>
                        <Col lg={2} className='mr-2'>
                          {selectedUsersToRemove.includes(item.id as never) && (
                            <FontAwesomeIcon
                              size='2x'
                              icon={faCheckCircle}
                              color='#2292CC'
                            />
                          )}
                          {!selectedUsersToRemove.includes(
                            item.id as never
                          ) && (
                            <FontAwesomeIcon
                              size='2x'
                              icon={faUserCircle}
                              color='#878787'
                            />
                          )}
                        </Col>
                        <Col>
                          <h3>{item.name}</h3>
                          <p>{item.email}</p>
                        </Col>
                      </Row>
                    </Col>
                  );
                })}
            </Row>

            <Row>
              <Col md={11}>
                <Row
                  className='division bottom-divider'
                  style={{ margin: '141.36px 0 167.5px 0' }}
                >
                  <Col className='center'>
                    <Button
                      style={Styles.okButton}
                      variant='outline-info'
                      size='lg'
                      onClick={() => (review ? createGroup() : setReview(true))}
                      disabled={!checkFields()}
                    >
                      {review ? 'Editar grupo' : 'Continuar'}
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>

            {alertDialog && (
              <SweetAlert
                type={alertDialogConfig?.type}
                title={alertDialogConfig?.title ?? ''}
                onConfirm={() => {
                  setAlertDialog(false);
                  if (alertDialogConfig.redirect) {
                    history.push('/gm/groups');
                  }
                }}
              >
                {alertDialogConfig?.message ?? ''}.
              </SweetAlert>
            )}
          </Container>
        </div>
      </Content>
    </>
  );
};

export default GroupsCreate;

//GESTOR DE MOBILIDADE
import Dashboard from '../screens/GC/Dashboard/Dashboard';

const routes: MyRoute[] = [
    {
      path: '/gc',
      exact: true,
      component: Dashboard,
      role: 'ROLE_COMMERCIAL_MANAGER',
      auth: true,
    },
    {
        path: '/gc/dashboard',
        exact: true,
        component: Dashboard,
        role: 'ROLE_READ_COMMERCIAL_DASHBOARD',
        auth: true,
      },
];

interface MyRoute {
    path: string;
    exact: boolean;
    component: React.FC<{}>;
    role: string;
    auth: boolean;
}

export default routes;
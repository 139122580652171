import React from 'react';
import { Container } from 'react-bootstrap';
import { Title, Content } from '../../style/content';
import { URL_FE_PUBLIC } from '../../constants/config';
const Help: React.FC = () => {
  return (
    <>
      <Title>Ajuda</Title>

      <Content>
        <Container fluid className='card-no-tabs content_card user_help'>
          <h1>Ajuda</h1>
          <iframe
            title='FAQ'
            id='faq_iframe'
            src={`${URL_FE_PUBLIC}/faq-app`}
            style={{ width: '100%', height: '100vh', border: 'unset' }}
          ></iframe>
        </Container>
      </Content>
    </>
  );
};

export default Help;

import React, { useState, useEffect, useRef } from 'react';
import { Form, Container, Row, Col, Image, Button } from 'react-bootstrap';
import { Title, Content, Styles } from '../../style/content';
import { subscriptionService } from 'src/services/subscriptionService';
import {
  deleteCart,
  deletePackPay,
  getAuthPay,
  getPackToPay,
  stopProcess,
} from '../../redux/cartReducer';
import { SERVER_BACKOFFICE } from '../../constants/config';
import { useHistory } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { SERVER_ENV } from "../../constants/config";

const Payment: React.FC = () => {
  const history = useHistory();
  const [alertDialog, setAlertDialog] = useState(false);
  const [alertConfig, setAlertConfig] = useState({
    title: '',
    message: '',
    redirect: false,
    redirectURL: '',
  });
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    const loadExternalScript = () => {
      let paymentId = getAuthPay();

      function addErrorText() {
        let card = document.querySelector('.wpwl-control-cardHolder');
        if (card) {
          card.classList.add('wpwl-has-error');
          document.querySelectorAll('.wpwl-has-error').forEach((headline) => {
            headline.insertAdjacentHTML(
              'afterend',
              '<div class="wpwl-hint wpwl-hint-cardHolderError">Invalid card holder</div>'
            );
          });
        }
      }

      function validateHolder(e) {
        let holder = document.getElementsByName('card.holder');
        if (holder) {
          let input = holder as unknown as HTMLInputElement;
          let inputValue = input[0].value;
          if (inputValue) {
            if (inputValue.trim().length < 2) {
              addErrorText();
              return false;
            }
            return true;
          }

          addErrorText();
          return false;
        }

        addErrorText();
        return false;
      }

      window.wpwlOptions = {
        style: 'card',
        brandDetection: 'true',
        paymentTarget: '_top',
        onReady: function (e) {
          let card = document.querySelector('.wpwl-form-card');
          if (card) {
            let button = card.querySelector('.wpwl-button-pay');
            if (button) {
              button.addEventListener('click', (e) => {
                validateHolder(e);
              });
            }
          }
        },
        onBeforeSubmitCard: function (e) {
          return validateHolder(e);
        },
      };

      if (paymentId) {
        const externalScript = document.createElement('script');
        if (SERVER_ENV === 'prod') {
          externalScript.src = `https://eu-prod.oppwa.com/v1/paymentWidgets.js?checkoutId=${paymentId}`;  
        } else {
          externalScript.src = `https://test.oppwa.com/v1/paymentWidgets.js?checkoutId=${paymentId}`;  
        }
        
        externalScript.id = 'external';
        externalScript.async = true;
        externalScript.type = 'text/javascript';
        externalScript.setAttribute('crossorigin', 'anonymous');
        document.body.appendChild(externalScript);
      }
    };

    let id = getId();
    if (id === null) {
      loadExternalScript();
    } else {
      checkPayment(id);
    }
  }, []);

  const getId = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const code = urlParams.get('id');
    return code;
  };
  const checkPayment = async (id) => {
    try {
      const result = await subscriptionService.checkPayment(id);
      if (result && result.paid) {
        let packs = getPackToPay();
        if (packs) {
          const response = await subscriptionService.createPack(
            JSON.parse(packs)
          );
          if (response.newResourceId) {
            await subscriptionService.paymentToSubscription({
              checkoutId: id,
              listPack: response.newResourceId,
            });
            deletePackPay();
            deleteCart();
            stopProcess();

            alert(
              'Sucesso',
              'Pagamento realizado com sucesso',
              true,
              '/user/subscriptions'
            );
          } else {
            alert('Erro', response.message, false, '');
          }
        }
      } else {
        alert('Erro', 'Pagamento não autorizado', true, '/cart');
      }
    } catch (e) {
      console.log(e);
    }
  };

  const alert = (
    title: string,
    message: string,
    redirect: boolean,
    redirectURL?: string
  ) => {
    setAlertConfig({
      title: title,
      message: message,
      redirect: redirect,
      redirectURL: redirectURL ? redirectURL : '',
    });
    setAlertDialog(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <Title>Pagamento</Title>

      <Content>
        <Container fluid className='card-no-tabs user_payment'>
          <h1>Método de pagamento</h1>
          <h3>Preencha os dados necessários.</h3>
          <div id='payment-number'></div>
          <form
            action={`${SERVER_BACKOFFICE}/cart/payment`}
            className='paymentWidgets'
            data-brands='VISA MASTER AMEX'
          ></form>
        </Container>
        <Dialog
          open={alertDialog}
          onClose={handleClose}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle id='alert-dialog-title'>{alertConfig.title}</DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-description'>
              {alertConfig.message}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                if (alertConfig.redirect) {
                  history.push(alertConfig.redirectURL);
                }
                setAlertDialog(false);
              }}
              color='primary'
              autoFocus
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </Content>
    </>
  );
};

export default Payment;

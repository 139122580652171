import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Form, Col, Row, Modal, Button, Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTrashAlt,
  faPowerOff,
  faArrowUp,
} from '@fortawesome/free-solid-svg-icons';
import SweetAlert from 'react-bootstrap-sweetalert';

import { motion } from 'framer-motion';
import Loading from 'react-fullscreen-loading';
import BadgeComponent from '../../../components/Badge';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Title, Content } from '../../../style/content';
import HistoryData from './HistoryData';
import { productService } from '../../../services/productService';
import { useParams, Link, useHistory } from 'react-router-dom';
import { iCan } from '../../../redux/sessionReducer';
import {
  animationInvisible,
  animationVariants,
  animationVisible,
} from 'src/components/Animations/animations';

const Finlog: React.FC = () => {
  const [finlog, setFinlog] = useState({
    ar: '',
    active: false,
    calculationTotalMileage: 0,
    carCategory: '',
    carDisplacement: Number(),
    carDoors: Number(),
    carFuel: '',
    carHorses: Number(),
    carOther: '',
    carPlaces: Number(),
    codeIdentification: '',
    engineCc: '',
    fuelType: '',
    id: '',
    informationBase: '',
    ipo: 0,
    leaseTermNo: '',
    make: '',
    name: '',
    no: '',
    maintenance: false,
    imageFile: '',
    power: 0,
    priceExclVAT: 0,
    productState: 'DRAFT',
    quoteValidUntil: '',
    replacementTransportB: false,
    serviceId: 0,
    subscriptionAvailability: new Date(),
    subscriptionValidate: null as any,
    uuidImageFile: '',
    damageInsurance: false,
    deadline: 0,
    hourAssistance: false,
    inputValue: Number(),
    kmIncluded: 0,
    monthlyIncome: Number(),
    monthlyAmountInclVAT: Number(),
    replacementCar: false,
    replacementTransport: '',
    unlimitedTires: false,
    hasTyres: false,
    numberOfTyres: 0,
    tyresType: '',
    additionalBenefits: '',
  });

  const [isEdit, setIsEdit] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [showDeactivateModal, setShowDeactivateModal] = useState(false);
  const [showDeactivate, setShowDeactivate] = useState(false);
  const [showRemove, setShowRemove] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [errorDialog, setErrorDialog] = useState(false);
  const [successDialog, setSuccessDialog] = useState(false);
  const [userData, setUserData] = useState({});
  const [alertMsg, setAlertMsg] = useState('');
  const [image, setImage] = useState({
    preview: '',
    raw: '',
    base64: '' as any,
    name: '',
  });
  const { productId } = useParams();
  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);

    const getProduct = async (companyName: string, productId: string) => {
      try {
        const result = await productService.getProductByCompany(
          companyName,
          productId
        );
        setFinlog({
          ...finlog,
          ...result.data,
          subscriptionAvailability: result.data.subscriptionAvailability
            ? new Date(result.data.subscriptionAvailability)
            : new Date(),
        });

        setIsEdit(result.data.productState === 'PUBLISHED' ? true : false);

        setUserData({
          userVerified: result.data.userVerified,
          dateCreated: result.data.dateCreated,
          dateLastUpdated: result.data.dateLastUpdated,
          userPublished: result.data.userPublished,
          userCreated: result.data.userCreated,
        });
      } catch (error) {
        console.log(error);
      }
    };

    if (productId) getProduct('finlog', productId);
  }, []);

  const removeProduct = async (productId: string) => {
    setShowRemoveModal(false);
    setIsLoading(true);

    try {
      const response = await productService.removeProductByCompany(
        'finlog',
        productId
      );
      setAlertMsg(response.data.message);
      setSuccessDialog(true);
    } catch (error) {
      setAlertMsg(error.response.data.message);
      setErrorDialog(true);
    } finally {
      setIsLoading(false);
    }
  };

  const getBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleChange = async (e) => {
    const file = e.target.files[0];

    const fileSize = file.size / 1024 / 1024;

    if (fileSize > 2) {
      setErrorDialog(true);
      setAlertMsg('Arquivo deve ter menos que 2 MB');
      return false;
    }

    getBase64(file).then((data) => {
      setImage({
        preview: URL.createObjectURL(file),
        raw: file,
        base64: data,
        name: file.name,
      });
    });
  };

  const changeStatusProduct = async (productId: string) => {
    setShowDeactivateModal(false);
    setIsLoading(true);

    try {
      const response = await productService.changeProductByCompany(
        'finlog',
        productId,
        !finlog.active
      );
      setFinlog({
        ...finlog,
        active: !finlog.active,
        productState: finlog.active ? 'INACTIVE' : 'PUBLISHED',
      });
      setSuccessDialog(true);
      setAlertMsg(response.data.message);
    } catch (error) {
      setErrorDialog(true);
      setAlertMsg(error.response.data.message);
    } finally {
      setIsLoading(false);
    }
  };

  const getProduct = async (companyName: string, productId: string) => {
    try {
      const result = await productService.getProductByCompany(
        companyName,
        productId
      );
      setFinlog({
        ...finlog,
        ...result.data,
        subscriptionAvailability: result.data.subscriptionAvailability
          ? new Date(result.data.subscriptionAvailability)
          : new Date(),
      });

      setUserData({
        userVerified: result.data.userVerified,
        dateCreated: result.data.dateCreated,
        dateLastUpdated: result.data.dateLastUpdated,
        userPublished: result.data.userPublished,
        userCreated: result.data.userCreated,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const update = async (finlog: any) => {
    setIsLoading(true);

    const data = {
      additionalBenefits: finlog.additionalBenefits,
      damageInsurance: finlog.damageInsurance,
      deadline: finlog.deadline,
      subscriptionAvailability: finlog.subscriptionAvailability,
      subscriptionValidate: finlog.subscriptionValidate,
      hourAssistance: finlog.hourAssistance,
      id: finlog.id,
      informationBase: finlog.informationBase,
      inputValue: finlog.inputValue,
      kmIncluded: finlog.kmIncluded,
      maintenance: finlog.maintenance,
      monthlyIncome: finlog.monthlyIncome,
      replacementCar: finlog.replacementCar,
      replacementTransport: finlog.replacementTransport,
      unlimitedTires: finlog.unlimitedTires,
      uuidImageFile: finlog.uuidImageFile,
      name: finlog.name,
      productState: finlog.productState,
      additionalData: finlog.additionalData,
    };

    Object.keys(data).forEach((key) =>
      data[key] === '' || data[key] === null ? delete data[key] : data[key]
    );

    if (image.name) {
      data['imageName'] = image.name;
      data['imageFile'] = image.base64;
    }

    try {
      const result = await productService.updateByCompany(
        'finlog',
        data,
        finlog.id
      );
      setSuccessDialog(true);
      setAlertMsg(result.data.message);
      getProduct('finlog', productId);
    } catch (error) {
      // setAlertMsg(error.response.data.message)
      console.log(error);
      setErrorDialog(true);
    } finally {
      setIsLoading(false);
    }
  };

  const create = async (product: any) => {
    setIsLoading(true);

    /**
     * Filter all falsy values ( "", 0, false, null, undefined )
     */
    Object.keys(product).forEach((key) =>
      product[key] === '' ? (product[key] = null) : product[key]
    );

    try {
      const result = await productService.integration('finlog', product);
      setAlertMsg(result.data.message);
      setSuccessDialog(true);
    } catch (error) {
      setAlertMsg(error.response.data.message);
      setErrorDialog(true);
    } finally {
      setIsLoading(false);
    }
  };

  /*
  const publish = async (productForm: any) => {
    setIsLoading(true);

    const data = { ...productForm };

    /**
     * Filter all falsy values ( "", 0, false, null, undefined )
     */
  /*
    Object.keys(data).forEach(
      (key) =>
        (data[key] === '' || data[key] === null || data[key] === 0) &&
        delete data[key]
    );
    delete data['dateCreated'];
    delete data['dateLastUpdated'];

    data.productState = 'PUBLISHED';

    if (!image.name) {
      delete data['fileExtension'];
      delete data['uuidImageFile'];
      delete data['imageFile'];
      delete data['imageName'];
    } else {
      data['imageName'] = image.name;
      data['imageFile'] = image.base64;
    }

    try {
      const result = await productService.updateByCompany(
        'finlog',
        data,
        productForm.id
      );
      setFinlog({ ...finlog, productState: 'PUBLISHED' });
      setAlertMsg(result.data.message);
      setSuccessDialog(true);
    } catch (error) {
      setAlertMsg(error.response.data.message);
      setErrorDialog(true);
    } finally {
      setIsLoading(false);
    }
  };
  */

  const isEmpty = (value) => {
    return String(value).length;
  };

  const checkFields = () => {
    const photo = finlog.uuidImageFile || image.name;

    const fields = [photo];

    return fields.some((item) => !item);
  };

  return (
    <>
      <Title>Kinto One</Title>

      <Content>
        <Container
          fluid
          className='card-no-tabs'
          style={{ paddingLeft: 80, paddingBottom: 50 }}
        >
          <Row
            style={{
              textAlign: 'center',
              font: 'normal normal normal 18px/22px Roboto',
              letterSpacing: '0px',
              color: '#878787',
              opacity: 1,
              marginLeft: '8px',
              marginTop: '51px',
            }}
          >
            <Link className='color-back' to={'/products'}>
              <p>
                <u>&#60; Voltar</u>
              </p>
            </Link>
          </Row>
          <Wrapper className=''>
            {isLoading && (
              <Loading
                loading
                background='rgba(255, 255, 255, .4)'
                loaderColor='#3498db'
              />
            )}

            {successDialog && (
              <SweetAlert
                success
                title='Sucesso!'
                onConfirm={() => {
                  setSuccessDialog(false);
                  setAlertMsg('');
                  history.push('/products');
                }}
              >
                {alertMsg}
              </SweetAlert>
            )}

            {errorDialog && (
              <SweetAlert
                danger
                title='Erro!'
                onConfirm={() => {
                  setErrorDialog(false);
                  setAlertMsg('');
                }}
              >
                {alertMsg}
              </SweetAlert>
            )}

            <Modal backdrop='static' show={showDeactivateModal} size='lg'>
              <Modal.Header>
                <h2>Produto Kinto One - {finlog.name}</h2>
              </Modal.Header>
              <Modal.Body>
                {finlog.uuidImageFile && (
                  <img
                    src={
                      finlog.uuidImageFile
                        ? finlog.uuidImageFile
                        : require('../../../assets/car_placeholder.png')
                    }
                    alt=''
                  />
                )}
                <FontAwesomeIcon icon={faPowerOff} />
                <h2 className='pt-5'>
                  Tem certeza que pretende{' '}
                  {finlog.active ? 'desativar' : 'ativar'}?
                </h2>
                <p className='disclaimer'>
                  Poderá reverter a sua ação mais tarde.
                </p>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant='remove'
                  onClick={() => changeStatusProduct(productId)}
                >
                  SIM, {finlog.active ? 'DESATIVAR' : 'ATIVAR'}
                </Button>
                <Button
                  variant='back'
                  onClick={() => setShowDeactivateModal(false)}
                >
                  NÃO, VOLTAR
                </Button>
              </Modal.Footer>
            </Modal>

            <Modal backdrop='static' show={showRemoveModal} size='lg'>
              <Modal.Header>
                <h2>Produto Kinto One - {finlog.name}</h2>
              </Modal.Header>
              <Modal.Body>
                {finlog.uuidImageFile && (
                  <img src={finlog.uuidImageFile} alt='' />
                )}
                <FontAwesomeIcon icon={faTrashAlt} className='remove' />
                <h2 className='pt-5'>Tem certeza que pretende eliminar?</h2>
                <p className='disclaimer'>Esta ação não pode ser revertida.</p>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant='remove'
                  onClick={() => removeProduct(productId)}
                >
                  SIM, ELIMINAR
                </Button>
                <Button
                  variant='back'
                  onClick={() => setShowRemoveModal(false)}
                >
                  NÃO, VOLTAR
                </Button>
              </Modal.Footer>
            </Modal>

            {productId && (
              <div className='pb-4'>
                <Logo>
                  <img
                    src={require(`../../../assets/logos/kinto-one-logo-on-2x.png`)}
                    alt=''
                  />
                  <div className='actions'>
                    <BadgeComponent badge={finlog.productState} />
                    {(finlog.productState === 'PUBLISHED' ||
                      finlog.productState === 'INACTIVE') && (
                      <div
                        className='wrapper'
                        onMouseEnter={() => setShowDeactivate(true)}
                        onMouseLeave={() => setShowDeactivate(false)}
                      >
                        <FontAwesomeIcon
                          icon={faPowerOff}
                          onClick={() => setShowDeactivateModal(true)}
                          onMouseEnter={() => setShowDeactivate(true)}
                        />
                        <motion.div
                          initial={animationVisible}
                          animate={showDeactivate ? 'visible' : 'invisible'}
                          exit={animationInvisible}
                          transition={{ duration: 0.3 }}
                          variants={animationVariants}
                        >
                          <p className='text-deactivate'>
                            {finlog.active
                              ? 'desativar produto'
                              : 'ativar produto'}
                          </p>
                        </motion.div>
                      </div>
                    )}

                    {finlog.productState === 'DRAFT' && (
                      <div
                        className='wrapper remove'
                        onMouseEnter={() => setShowRemove(true)}
                        onMouseLeave={() => setShowRemove(false)}
                        onClick={() => setShowRemoveModal(true)}
                      >
                        <FontAwesomeIcon
                          icon={faTrashAlt}
                          onClick={() => setShowRemoveModal(true)}
                          className='remove'
                        />

                        <motion.div
                          initial={animationVisible}
                          animate={showRemove ? 'visible' : 'invisible'}
                          exit={animationInvisible}
                          transition={{ duration: 0.3 }}
                          variants={animationVariants}
                        >
                          <p className='text-remove'>eliminar</p>
                        </motion.div>
                      </div>
                    )}
                  </div>
                </Logo>
              </div>
            )}

            {productId && <HistoryData userData={userData} />}

            <div className='form'>
              <h6>1. Informação identificativa</h6>
              <div className='inputs'>
                <Form>
                  <Row>
                    <Col>
                      <Form.Label>Nome</Form.Label>
                      <Form.Control
                        disabled={true}
                        onChange={(e) =>
                          setFinlog({ ...finlog, name: e.target.value })
                        }
                        value={finlog.name}
                      />
                    </Col>
                    <Col>
                      <Form.Label>Código identificação</Form.Label>
                      <Form.Control
                        disabled={true}
                        onChange={(e) =>
                          setFinlog({ ...finlog, id: e.target.value })
                        }
                        value={finlog.id}
                      />
                    </Col>
                    <Col>
                      <Form.Label>Número de cálculo</Form.Label>
                      <Form.Control
                        disabled={true}
                        onChange={(e) =>
                          setFinlog({ ...finlog, no: e.target.value })
                        }
                        value={finlog.no}
                      />
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>

            <div className='form'>
              <h6>2. Recursos gráficos</h6>
              <div className='inputs section-upload'>
                <div>
                  {image.preview ? (
                    <img src={image.preview} />
                  ) : (
                    <img src={finlog.uuidImageFile} />
                  )}
                </div>
                {(finlog.productState === 'DRAFT' ||
                  finlog.productState === 'PUBLISHED') && (
                  <Form onSubmit={(e) => e.preventDefault()}>
                    <input
                      type='file'
                      id='upload-button'
                      style={{ display: 'none' }}
                      accept='image/x-png, image/jpeg'
                      onChange={handleChange}
                    />
                    <label htmlFor='upload-button'></label>
                    <label htmlFor='upload-button'>
                      <div className='big-btn btn-fix-upload'>
                        <FontAwesomeIcon icon={faArrowUp} />
                      </div>
                    </label>
                    <p className='text-center pt-3'>
                      Imagens em formato png/jpeg. Limite: 2mb.
                    </p>
                  </Form>
                )}
              </div>
            </div>

            <div className='form'>
              <h6>3. Detalhes do produto</h6>
              <div className='inputs'>
                <Form>
                  <Row>
                    <Col>
                      <label>Informaçāo base</label>
                      <Form.Control
                        as='textarea'
                        rows={4}
                        disabled={true}
                        value={finlog.informationBase}
                        onChange={(e) =>
                          setFinlog({
                            ...finlog,
                            informationBase: e.target.value,
                          })
                        }
                      />
                    </Col>
                  </Row>
                  <Row className='pt-3'>
                    <Col>
                      <Form.Label>Cilindrada*</Form.Label>
                      <Form.Control
                        placeholder='Cilindrada'
                        value={finlog.carDisplacement}
                        disabled={true}
                      />
                    </Col>
                    <Col>
                      <Form.Label>Cavalos*</Form.Label>
                      <Form.Control
                        placeholder='Cavalos'
                        value={finlog.carHorses}
                        disabled={true}
                      />
                    </Col>
                  </Row>

                  <Row className='pt-3'>
                    <Col>
                      <Form.Label>N. de lugares*</Form.Label>
                      <Form.Control
                        placeholder='N. de lugares'
                        value={finlog.carPlaces}
                        disabled={true}
                      />
                    </Col>
                    <Col>
                      <Form.Label>N. de portas*</Form.Label>
                      <Form.Control
                        placeholder='N. de portas'
                        value={finlog.carDoors}
                        disabled={true}
                      />
                    </Col>
                  </Row>

                  <Row className='pt-3'>
                    <Col>
                      <Form.Label>Combustível*</Form.Label>
                      <Form.Control
                        placeholder='Combustível'
                        value={finlog.carFuel}
                        disabled={true}
                      />
                    </Col>
                    <Col>
                      <Form.Label>Categoria*</Form.Label>
                      <Form.Control
                        placeholder='Categoria'
                        value={finlog.carCategory}
                        disabled={true}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <Form.Label>Disponibilidade para subscrição*</Form.Label>
                      <Col className='px-0'>
                        <DatePicker
                          disabled={true}
                          dateFormat='dd/MM/yyyy'
                          selected={
                            finlog.subscriptionAvailability
                              ? new Date(finlog.subscriptionAvailability)
                              : new Date()
                          }
                          onChange={(date) =>
                            date &&
                            setFinlog({
                              ...finlog,
                              subscriptionAvailability: new Date(
                                date.toString()
                              ),
                            })
                          }
                        />
                      </Col>
                    </Col>
                    <Col>
                      <Form.Label>Validade de produto*</Form.Label>
                      <Col className='px-0'>
                        <DatePicker
                          disabled={true}
                          dateFormat='dd/MM/yyyy'
                          className='qa-datepicker-validate'
                          selected={
                            finlog.subscriptionValidate
                              ? new Date(finlog.subscriptionValidate)
                              : null
                          }
                          onChange={(date) =>
                            date &&
                            setFinlog({
                              ...finlog,
                              subscriptionValidate: new Date(date.toString()),
                            })
                          }
                        />
                      </Col>
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>

            <div className='form'>
              <h6>4. Definição do produto</h6>
              <div className='inputs'>
                <Form>
                  <Row>
                    <Col>
                      <Form.Control value={finlog.deadline} disabled={true} />
                    </Col>
                    <Col>
                      <Form.Control value={finlog.kmIncluded} disabled={true} />
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <Form.Label>Valor entrada inicial Sem IVA*</Form.Label>
                      <Form.Control
                        value={finlog.inputValue}
                        type='number'
                        disabled={true}
                      />
                    </Col>
                    <Col>
                      <Form.Label>Renda mensal sem IVA*</Form.Label>
                      <Form.Control
                        value={finlog.monthlyIncome ? finlog.monthlyIncome : ''}
                        disabled={true}
                        type='number'
                      />
                    </Col>
                    <Col>
                      <Form.Label>Renda mensal com IVA*</Form.Label>
                      <Form.Control
                        value={
                          finlog.monthlyAmountInclVAT
                            ? finlog.monthlyAmountInclVAT.toFixed(2)
                            : ''
                        }
                        disabled={true}
                        type='number'
                        onChange={(e) =>
                          setFinlog({
                            ...finlog,
                            monthlyAmountInclVAT: +e.target.value,
                          })
                        }
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <Form.Label>Serviços incluídos</Form.Label>
                      <div className='wrapper-input pt-2'>
                        <div className='round'>
                          <input
                            type='checkbox'
                            id='maintenance'
                            checked={finlog.maintenance}
                          />
                          <label htmlFor='maintenance'></label>
                        </div>
                        <div className='wrapper-icon'>
                          <i className='icon-manutencao'></i>
                        </div>
                        <label htmlFor='maintenance' className='label-input'>
                          Manutençāo
                        </label>
                      </div>
                    </Col>
                    <Col className='pt-3'>
                      <Form.Label></Form.Label>
                      <div className='wrapper-input'>
                        <div className='round'>
                          <input
                            type='checkbox'
                            id='24h'
                            checked={finlog.hourAssistance}
                          />
                          <label htmlFor='24h'></label>
                        </div>
                        <div className='wrapper-icon'>
                          <i className='icon-assistencia-24h'></i>
                        </div>
                        <label htmlFor='24h' className='label-input'>
                          Assistência 24h
                        </label>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col className='pt-2'>
                      <div className='wrapper-input'>
                        <div className='round'>
                          <input
                            type='checkbox'
                            id='security'
                            checked={finlog.damageInsurance}
                          />
                          <label htmlFor='security'></label>
                        </div>
                        <div className='wrapper-icon'>
                          <i className='icon-seguro-danos-proprios'></i>
                        </div>
                        <label htmlFor='security' className='label-input'>
                          Seguro de danos próprios
                        </label>
                      </div>
                    </Col>
                    {finlog.tyresType !== null && (
                      <Col className='pt-2'>
                        <div className='wrapper-input'>
                          <div className='round'>
                            <input
                              type='checkbox'
                              id='unlimited-wheel'
                              checked={finlog.hasTyres}
                            />
                            <label htmlFor='unlimited-wheel'></label>
                          </div>
                          <div className='wrapper-icon'>
                            <i className='icon-pneus-ilimitados'></i>
                          </div>
                          <label
                            htmlFor='unlimited-wheel'
                            className='label-input'
                          >
                            {finlog.tyresType}{' '}
                            {finlog.numberOfTyres > 0
                              ? ': ' + finlog.numberOfTyres
                              : ''}
                          </label>
                        </div>
                      </Col>
                    )}
                  </Row>

                  <Row>
                    <Col className='pt-2'>
                      <div className='wrapper-input'>
                        <div className='round'>
                          <input
                            type='checkbox'
                            id='replace-car'
                            checked={finlog.replacementCar}
                          />
                          <label htmlFor='replace-car'></label>
                        </div>
                        <div className='wrapper-icon'>
                          <i className='icon-viatura-substituicao'></i>
                        </div>
                        <label htmlFor='replace-car' className='label-input'>
                          Viatura de substituição
                        </label>
                      </div>
                    </Col>
                    <Col></Col>
                  </Row>

                  <Row className='pt-3'>
                    <Col>
                      <label>Notas legais</label>
                      <Form.Control
                        as='textarea'
                        rows={4}
                        value={finlog.additionalBenefits}
                        onChange={(e) =>
                          setFinlog({
                            ...finlog,
                            additionalBenefits: e.target.value,
                          })
                        }
                        disabled={isEdit}
                      />
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>

            <div className='form'>
              <div className='text-center'>
                {!productId && !isEdit && iCan('ROLE_CREATE_PRODUCT') && (
                  <button
                    className='big-btn'
                    onClick={() => setIsEdit(!isEdit)}
                  >
                    CONTINUAR
                  </button>
                )}
                {productId &&
                  (finlog.productState === 'DRAFT' ||
                    finlog.productState === 'PUBLISHED') &&
                  iCan('ROLE_CREATE_PRODUCT') && (
                    <button
                      onClick={() => update(finlog)}
                      disabled={checkFields()}
                      className={`big-btn ml-4 ${
                        checkFields() ? 'opacity-3' : ''
                      }`}
                    >
                      GUARDAR ALTERAÇŌES
                    </button>
                  )}
                {!productId && isEdit && iCan('ROLE_CREATE_PRODUCT') && (
                  <button className='big-btn' onClick={() => create(finlog)}>
                    GUARDAR RASCUNHO
                  </button>
                )}
              </div>

              {!productId && isEdit && iCan('ROLE_CREATE_PRODUCT') && (
                <div className='text-center pt-5'>
                  <p className='back-edit' onClick={() => setIsEdit(!isEdit)}>
                    Voltar a edição
                  </p>
                </div>
              )}
            </div>
          </Wrapper>
        </Container>
      </Content>
    </>
  );
};

const Logo = styled.div`
  padding: 0 80px;
  display: flex;
  justify-content: space-between;
  max-width: 87%;

  .actions {
    height: 20px;
    display: flex;
    width: 200px;
    justify-content: space-between;

    p {
      font-weight: bold;
      position: relative;
      right: 10px;
    }

    .text-remove {
      color: #ff0000;
      right: 20px;
    }

    .remove:hover {
      color: #ff0000;
    }

    .text-deactivate {
      position: relative;
      right: 20px;
    }

    .edit:hover {
      color: #2292cc;
    }

    .text-edit {
      color: #2292cc;
    }

    svg {
      cursor: pointer;
    }

    .wrapper {
      width: 50px;
    }
  }
`;

const Wrapper = styled.div`
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  input:disabled {
    /* -webkit-text-fill-color: gray !important; */
  }
  .react-datepicker-wrapper {
    width: 100% !important;
  }
  .react-datepicker__input-container input {
    width: 100% !important;
    height: 38px;
    padding-left: 10px;
  }

  .wrapper-icon {
    background: #baddf0;
    position: relative;
    left: 10px;
    bottom: 15px;
    padding: 10px;
    border-radius: 10px;
  }

  .form {
    padding: 50px 80px;

    .btn-fix-upload {
      text-align: center;
      padding-top: 19px;
    }

    .back-edit {
      font-weight: bold;
      text-decoration: underline;
      cursor: pointer;
    }

    h6 {
      border-bottom: 1px solid #eee;
      padding-bottom: 10px;
      color: #2292cc;
    }

    .section-upload {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;

      img {
        padding: 10px;
        width: 500px;
      }
    }

    .inputs {
      padding: 20px 0;

      .upload {
        width: 200px;
        height: 100px;
        border-radius: 20px;
        box-shadow: 3px 15px 5px -6px rgba(158, 195, 240, 0.62);
      }
    }
  }
`;

export default Finlog;

import api from '../../utils/api';

export const groupsService = {
  create,
  edit,
  getById,
  deleteGroup,
};

async function create(data) {
  const response = await api.apiPrivate
    .post('api/comercial-profile/mobility-group/create-mobility-group', data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        console.log(error.request);
        return error.request;
      }
      throw error;
    });
  return response;
}

async function edit(data) {
  const response = await api.apiPrivate
    .put('api/comercial-profile/mobility-group/edit-mobility-group', data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        console.log(error.request);
        return error.request;
      }
      throw error;
    });
  return response;
}

async function deleteGroup(id) {
  const response = await api.apiPrivate
    .delete(`api/comercial-profile/mobility-group/delete/${id}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        console.log(error.request);
        return error.request;
      }
      throw error;
    });
  return response;
}

async function getById(id) {
  const response = await api.apiPrivate
    .get(`api/comercial-profile/mobility-group/${id}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        console.log(error.request);
        return error.request;
      }
      throw error;
    });
  return response;
}

import api from '../utils/api';

export const cmsService = {
  getById,
  getByScreenId,
  loadScreensAction,
  updateOnboarding,
};

async function getById(id) {
  const response = await api.apiPrivate
    .get(`api/backoffice/onboarding/${id}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        console.log(error.request);
        return error.request;
      }
      throw error;
    });
  return response;
}
async function getByScreenId(id) {
  const response = await api.apiPrivate
    .get(`api/backoffice/onboarding/screen/${id}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        console.log(error.request);
        return error.request;
      }
      throw error;
    });
  return response;
}

async function loadScreensAction() {
  const response = await api.apiPrivate
    .get('api/backoffice/onboarding/screens')
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        console.log(error.request);
        return error.request;
      }
      throw error;
    });
  return response;
}

async function updateOnboarding(data) {
  const response = await api.apiPrivate
    .post('api/backoffice/onboarding/update-onboarding', data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        console.log(error.request);
        return error.request;
      }
      throw error;
    });
  return response;
}

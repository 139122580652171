import React from 'react';
import Loading from 'react-fullscreen-loading';
const Waiting: React.FC = () => {
  return (
    <>
      <Loading
        loading
        background='rgba(255, 255, 255, .4)'
        loaderColor='#3498db'
      />
    </>
  );
};

export default Waiting;

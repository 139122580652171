import React, { useState, useEffect } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { configuratorService } from 'src/services/configuratorService';
import { productService } from 'src/services/productService';
import ModalBase from './ModalBase';
import { Segment, Product } from './cart.interface';
import Radio from '@material-ui/core/Radio';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toFixed } from '../../utils/utils';
import { storeCart, getCart } from '../../redux/cartReducer';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import SliderConfigurator from './slider_style';
import { PackProductDTO } from '../Configurator/packs.interface';

export const ModalXtracars = ({ closeButton, open, onClose, product }) => {
  const [segments, setSegments] = useState([] as Segment[]);
  const [activeSegment, setActiveSegment] = useState(Number);
  const [products, setProducts] = useState([] as Product[]);
  const [activeProduct, setActiveProduct] = useState([] as Product);
  const [numberMonths, setNumberMonths] = useState(1);
  const [price, setPrice] = useState(0);
  const [canPrevious, setCanPrevious] = useState(false);
  const [canNext, setCanNext] = useState(false);
  const [cartIndex, setCartIndex] = useState(-1);
  const [isNewProduct, setIsNewProduct] = useState(false);

  const useStyles = makeStyles({
    root: {
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    icon: {
      borderRadius: '50%',
      width: 16,
      height: 16,

      backgroundColor: 'rgba(254, 193, 2, 0.2)',
      'input:hover ~ &': {
        backgroundColor: '#FEC102',
      },
      'input:disabled ~ &': {
        boxShadow: 'none',
        background: 'rgba(254, 193, 2,.5)',
      },
    },
    checkedIcon: {
      backgroundColor: '#FEC102',
      backgroundImage:
        'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
      '&:before': {
        display: 'block',
        width: 16,
        height: 16,
        backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
        content: '""',
      },
      'input:hover ~ &': {
        backgroundColor: '#FEC102',
      },
    },
  });
  const classes = useStyles();

  useEffect(() => {
    getSegments();
    getXtracarsProducts();
  }, []);

  useEffect(() => {
    updateMonth(numberMonths);
  }, [numberMonths]);

  useEffect(() => {
    filterSegments(activeSegment);
  }, [activeSegment]);

  useEffect(() => {
    if (activeProduct) {
      updateMonth(numberMonths);
    }
  }, [activeProduct]);

  useEffect(() => {
    if (open && product === null) {
      setActiveProduct(products[0]);
      setIsNewProduct(true);
      if (products.length > 1) {
        setCanNext(true);
      }
    }
    if (!open) {
      setIsNewProduct(false);
      setActiveProduct({} as Product);
    }
  }, [open, products]);

  useEffect(() => {
    if (product && product.serviceId === 3) {
      getProduct();
      setNumberMonths(product.valuePack);
    }
  }, [product]);

  const getSegments = async () => {
    try {
      const resultSegments =
        await configuratorService.getGuerinXtracarsSegments();
      if (resultSegments) {
        setSegments(resultSegments);
      }
    } catch (error) {
    } finally {
    }
  };

  const filterSegments = async (segment) => {
    if (segment) {
      setCanNext(false);
      setCanPrevious(false);
      let _products = await getXtracarsProducts();
      if (_products && _products.data.length > 0) {
        let filteredProducts = _products.data.filter((el) => {
          if (el.segment == segment) {
            return el;
          }
        });
        setProducts(filteredProducts);
        if (filteredProducts.length > 0) {
          setActiveProduct(filteredProducts[0]);
          if (filteredProducts.length > 1) {
            setCanPrevious(true);
          }
        } else {
          setActiveProduct([] as Product);
          setPrice(0);
        }
      }
    }
  };

  const getProduct = async () => {
    if (product.id) {
      storeCartIndex(product.id);
      if (products.length > 0) {
        let index = products.findIndex((el) => el.id == product.productId);
        if (index !== -1) {
          setActiveProduct(products[index]);
          if (products.length > 1) {
            setCanNext(true);
            if (products[index - 1]) {
              setCanPrevious(true);
            }
          }
        }
      }
    }
  };

  const storeCartIndex = (id) => {
    let cart = getCart();
    if (cart) {
      let array = JSON.parse(cart) as PackProductDTO[];
      if (array) {
        let index = array.findIndex((el) => el.id === id);
        if (index !== -1) {
          setCartIndex(index);
        }
      }
    }
  };

  const getXtracarsProducts = async () => {
    const products = await productService.getProductsByGuerinXtracars(
      'xtracars',
      'active=true&deleted=false&productState=PUBLISHED&serviceId=3'
    );
    if (products && products.data.length > 0) {
      setProducts(products.data);
      return products;
    }
  };

  const next = () => {
    let index = products.findIndex((el) => el.id === activeProduct.id);
    if (index !== -1) {
      if (products[index + 1]) {
        setActiveProduct(products[index + 1]);
        setCanPrevious(true);
        if (!products[index + 2]) {
          setCanNext(false);
        }
      }
    }
  };
  const previous = () => {
    let index = products.findIndex((el) => el.id === activeProduct.id);
    if (index !== -1) {
      if (products[index - 1]) {
        setActiveProduct(products[index - 1]);
        setCanNext(true);
        if (!products[index - 2]) {
          setCanPrevious(false);
        }
      }
    }
  };

  const updateMonth = (value) => {
    if (activeProduct) {
      setPrice(calculateValue(value));
    }
  };

  const calculateValue = (value) => {
    if(activeProduct && typeof activeProduct?.rentDailyValue !== 'undefined' && typeof value === 'number') {
      return toFixed((activeProduct?.rentDailyValue * value * 30),2);
    } else {
      return 0
    }
  };

  const save = () => {
    if (activeProduct.name && price > 0) {
      let cart = getCart();
      if (cart) {
        let array = JSON.parse(cart) as PackProductDTO[];
        if (isNewProduct) {
          let newProduct = {
            valuePack: numberMonths,
            productId: activeProduct.id,
            productImage: activeProduct.uuidImageFile,
            balance: price,
            productName: activeProduct.name,
            id: activeProduct.productId,
            serviceId: 3,
          };
          array.push(newProduct);
          storeCart(array);
          closeButton(3);
        } else {
          if (array && array[cartIndex] && price > 0) {
            array[cartIndex] = {
              ...array[cartIndex],
              valuePack: numberMonths,
              productId: activeProduct.id,
              productImage: activeProduct.uuidImageFile,
              balance: price,
              productName: activeProduct.name,
            };
            storeCart(array);
            closeButton(3);
          }
        }
      } else {
        let newProduct = [
          {
            valuePack: numberMonths,
            productId: activeProduct.id,
            productImage: activeProduct.uuidImageFile,
            balance: price,
            productName: activeProduct.name,
            id: activeProduct.productId,
            serviceId: 3,
          },
        ];

        storeCart(newProduct);
        closeButton(3);
      }
    }
  };

  const component = () => {
    return (
      <div className='cart_modal modal_xtracars'>
        <Row className='card'>
          <p>Veículo</p>
          <Row>
            {segments &&
              segments.length > 0 &&
              segments.map((el) => {
                return (
                  <Col md={4} key={el.segmentDescription}>
                    <Row className='row_segment'>
                      <Col md={3}>
                        <img
                          src={el.imageUrl}
                          alt={el.segmentDescription}
                          className='segment_image'
                        />
                      </Col>
                      <Col md={5} className='pr-0'>
                        <p className='segment_text'>{el.segmentDescription}</p>
                      </Col>
                      <Col md={2} className='pl-0'>
                        <Radio
                          checked={activeSegment === el.segmentId}
                          onChange={() => setActiveSegment(el.segmentId)}
                          value='Total'
                          name='radio-button-demo'
                          disableRipple
                          color='default'
                          checkedIcon={
                            <span
                              className={clsx(
                                classes.icon,
                                classes.checkedIcon
                              )}
                            />
                          }
                          icon={<span className={classes.icon} />}
                        />
                      </Col>
                    </Row>
                  </Col>
                );
              })}
          </Row>
          <Row className='row_car'>
            <Col className='d-flex justify-content-center'>
              <FontAwesomeIcon
                color={canPrevious ? '#FEC102' : '#eeeeee'}
                icon={faChevronLeft}
                size='lg'
                className='mr-4'
                onClick={() => (canPrevious ? previous() : '')}
              />
            </Col>
            <Col md={8}>
              <div className='car_card align-content-center'>
                <Row className='d-flex justify-content-center'>
                  {activeProduct  && (
                    <img
                      src={activeProduct.uuidImageFile ? activeProduct.uuidImageFile : require('../../assets/car_placeholder.png')}
                      alt={activeProduct.name}
                      className='car_image'
                    />
                  )}
                  {products.length === 0 && (
                    <img
                      src={require('../../assets/cart/nodata.png')}
                      alt='Sem resultados'
                      className='car_image'
                    />
                  )}
                </Row>
                <Row className='d-flex justify-content-center'>
                  {activeProduct && activeProduct.name && (
                    <p className='car_name'>{activeProduct.name}</p>
                  )}
                  {products.length === 0 && (
                    <p className='car_name'>Sem resultados</p>
                  )}
                </Row>
                <Row className='d-flex justify-content-center'>
                  {activeProduct && activeProduct.name && (
                    <p className='car_details'>
                      {activeProduct.carPlaces
                        ? activeProduct.carPlaces + ' lugares | '
                        : ''}
                      {activeProduct.carDoors
                        ? activeProduct.carDoors + ' portas'
                        : ''}
                    </p>
                  )}
                  {products.length === 0 && (
                    <p className='car_details'>
                      Altere os filtros e tente novamente
                    </p>
                  )}
                </Row>
              </div>
            </Col>
            <Col className='d-flex justify-content-center'>
              <FontAwesomeIcon
                color={canNext ? '#FEC102' : '#eeeeee'}
                icon={faChevronRight}
                size='lg'
                className='mr-4'
                onClick={() => (canNext ? next() : '')}
              />
            </Col>
          </Row>
        </Row>
        <Row className='card mt-4'>
          <Row className='row_configure'>
            <Col md={3}>
              <p>Número de meses</p>
            </Col>
            <Col>
              <SliderConfigurator
                value={numberMonths}
                marks
                min={1}
                max={12}
                className='ml-1'
                onChangeCommitted={(e, value) =>
                  setNumberMonths(value as number)
                }
              />
            </Col>
            <Col md={3}>
              <p>
                {numberMonths}
                {numberMonths && numberMonths > 1 ? ' meses' : ' mês'}
              </p>
            </Col>
          </Row>
        </Row>
        <Row className='row_value mt-4 mb-8'>
          <Col md={8}>
            <p className='value_label_text'>Valor estimado total:</p>
          </Col>
          <Col md={4}>
            <p className='value_text' style={{ color: '#FEC102' }}>
              {toFixed(price, 2)}€
            </p>
          </Col>
          <Col md={8}>
            <p className='value_label_text'>Valor estimado diário:</p>
          </Col>
          <Col md={4}>
            <p className='value_text' style={{ color: '#FEC102' }}>
              {activeProduct ? toFixed(activeProduct.rentDailyValue, 2) : 0}€
            </p>
          </Col>
        </Row>
        <Row className='row_button mt-4'>
          <Button className='button' onClick={save}>
            GUARDAR
          </Button>
        </Row>
      </div>
    );
  };

  return (
    <>
      <ModalBase
        serviceId={3}
        Component={component}
        closeButton={closeButton}
        open={open}
        onClose={onClose}
        product={product}
      ></ModalBase>
    </>
  );
};

export default ModalXtracars;

import React, { useState, useEffect, useRef } from 'react';
import { Form, Container, Row, Col, Image, Button } from 'react-bootstrap';
import { ReservationProduct } from './reservation.interface';
import { toFixed } from '../../../utils/utils';
import moment from 'moment';
import 'moment/locale/pt-br';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUserEdit,
  faInfoCircle,
  faCheckCircle,
  faExclamationCircle,
  faClock,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';

export const ProductCard = ({ product, editProduct, acceptContract, cancelProduct }) => {
  let _product = product as ReservationProduct;

  const getLogo = (serviceId) => {
    const service = {
      1: require('../../../assets/logos/kinto-one-logo-on-2x.png'),
      2: require('../../../assets/logos/guerin-xs-logo-on-2x.png'),
      3: require('../../../assets/logos/xtracars-xs-logo-on-2x.png'),
      4: require('../../../assets/logos/rodinhas-xs-logo-on-2x.png'),
      5: require('../../../assets/logos/bedriven-xs-logo-on-2x.png'),
    };

    return service[serviceId];
  };

  const getDescription = (product: ReservationProduct) => {
    let test = moment.updateLocale('pt', null);
    switch (product.serviceId) {
      case 2:
        return (
          <div className='description'>
            <h4>Levantamento</h4>
            <p>{product.pickUp}</p>
            <p>{moment(product.startService).format('LLL')}</p>
            <h4>Entrega</h4>
            <p>{product.dropOff}</p>
            <p>{moment(product.endService).format('LLL')}</p>
          </div>
        );
      case 3:
        return (
          <div className='description'>
            <h4>Levantamento</h4>
            <p>{product.pickUp}</p>
            <p>{moment(product.startService).format('LLL')}</p>
            <h4>Entrega</h4>
            <p>{product.dropOff}</p>
            <p>{moment(product.endService).format('LLL')}</p>
          </div>
        );
      case 4:
        return (
          <div className='description'>
            <p>
              <span className='bold'>Início: </span>
              {moment(product.pickUpDate).format('L')}
            </p>
            <p>
              <span className='bold'>Fim: </span>
              {moment(product.dropOffDate).format('L')}
            </p>

            {product.packItineraryDTO?.map((el, index) => {
              return (
                <div key={index}>
                  <p>
                    {el.childrenQuantity}{' '}
                    {Number(el.childrenQuantity) > 1 ? 'crianças' : 'criança'}
                  </p>
                  <p>
                    <span className='bold'>Dias:</span>
                    {translateArray(el.weekDays).join(', ')}
                  </p>
                  <Row>
                    <Col>
                      <h4>Levantamento</h4>
                      <p>
                        <span className='bold'>Cidade:</span> {el.cityPickUp}
                      </p>
                      <p>
                        <span className='bold'>Horário:</span> {el.hourPickUp}
                      </p>
                    </Col>
                    <Col>
                      <h4>Entrega</h4>
                      <p>
                        <span className='bold'>Cidade:</span> {el.cityDropOff}
                      </p>
                      <p>
                        <span className='bold'>Horário:</span> {el.hourDropOff}
                      </p>
                    </Col>
                  </Row>
                </div>
              );
            })}
          </div>
        );
      default:
        return '';
    }
  };

  const getBrandText = (serviceId) => {
    const brandText = {
      1: 'Renting',
      2: 'Rent-a-car',
      3: 'Aluguer Flexível',
      4: 'Transporte de Crianças',
      5: 'TVDE',
      6: 'Aluguer Flexível',
    };

    return brandText[serviceId];
  };

  const getProductName = () => {
    switch (_product.serviceId) {
      case 2:
        return `${_product.brand} ${_product.model} ou SIMILAR`;
      case 3:
        return ` ${_product.brand} ${_product.model} ou SIMILAR`;
      case 4:
        return `Rodinhas`;
      case 5:
        return 'TVDE';
      default:
        return '';
    }
  };

  const getMessage = (status) => {

    let newStatus = status.toUpperCase()

    const title = {
      ACCEPTED: 'Reserva confirmada',
      SUCCESS: 'Reserva confirmada',
      APPROVED: 'Reserva confirmada',
      CONFIRMED: 'Reserva confirmada',
      SUBSCRIBED: 'Aguarda confirmação',
      WAITING: 'Aguarda confirmação',
      PENDING: 'Aguarda confirmação',
      DECLINED: 'Reserva rejeitada',
      REJECTED: 'Reserva rejeitada',
      ERROR: 'Reserva rejeitada',
      CANCELED: 'Reserva cancelada',
      FINISHED: 'Reserva finalizada',
      NO_SHOW: 'Reserva finalizada',
      QUOTING: 'Aguarda confirmação',
    }

    const text = {
      ACCEPTED: 'Usufrua da sua reserva',
      SUCCESS: 'Usufrua da sua reserva',
      APPROVED: 'Usufrua da sua reserva',
      CONFIRMED: 'Usufrua da sua reserva',
      SUBSCRIBED: 'Em veículos especiais poderá demorar até 48h',
      WAITING: 'Em veículos especiais poderá demorar até 48h',
      PENDING: 'Em veículos especiais poderá demorar até 48h',
      DECLINED: 'Não foi possível confirmar a sua reserva',
      REJECTED: 'Não foi possível confirmar a sua reserva',
      ERROR: 'Não foi possível confirmar a sua reserva',
      CANCELED: 'A reserva foi cancelada pelo cliente',
      FINISHED: 'A reserva já foi finalizada',
      NO_SHOW: 'A reserva foi cancelada por não comparecimento',
      QUOTING: 'Aguarda confirmação do orçamento enviado',
    }

    let obj = { title: '', text: '' }

    if (text[newStatus]) {
      obj.text = text[newStatus]
    } else {
      obj.text = "Sem informação da reserva"
    }

    if (title[newStatus]) {
      obj.title = title[newStatus]
    } else {
      obj.title = "Reserva"
    }

    return obj
  }

  const getBadgeColor = (status) => {
    if (status === 'Pending' || status === 'Waiting') {
      return 'rgb(255, 202, 0)';
    } else if (status === 'Quoting') {
      return 'rgb(255, 202, 0)';
    } else if (status === 'Approved' || status === 'Confirmed') {
      return 'rgb(40, 196, 144)';
    } else if (status === 'Finished') {
      return 'rgb(40, 144, 196)';
    } else {
      return 'rgb(231, 16, 75)';
    }
  };

  const getBadgeIcon = (status) => {
    if (status === 'Pending' || status === 'Waiting') {
      return faClock;
    } else if (status === 'Quoting') {
      return faInfoCircle;
    } else if (status === 'Approved' || status === 'Confirmed' || status === 'Finished') {
      return faCheckCircle;
    } else {
      return faExclamationCircle;
    }
  };

  const translateArray = (days) => {
    let array = [] as string[];
    days?.forEach((day) => {
      array.push(weekdaysTranslate(day));
    });
    return array;
  };

  const weekdaysTranslate = (day) => {
    switch (day) {
      case 'MONDAY':
        return 'Segunda-feira';
      case 'TUESDAY':
        return 'Terça-feira';
      case 'WEDNESDAY':
        return 'Quarta-feira';
      case 'THURSDAY':
        return 'Quinta-feira';
      case 'FRIDAY':
        return 'Sexta-feira';
      case 'SATURDAY':
        return 'Sábado';
      case 'SUNDAY':
        return 'Domingo';
      default:
        return '';
    }
  };

  return (
    <div className='reserve_card'>
      <Col sm={12} key={_product.model} className='px-0'>
        <div>
          <Row>
            <Col lg={3} xl={2}>
              <img src={getLogo(_product.serviceId)} className='service_logo' />
              <p className='text_service'>{getBrandText(_product.serviceId)}</p>
            </Col>
            <Col>
              <p className='text_product_name'>{getProductName()}</p>
              <p>Reserva nº: {_product.reserveId}</p>
              {getDescription(_product)}
            </Col>
            <Col lg={2} md={5}>
              <img src={_product.image} className='service_logo' />
            </Col>
            <Col lg={2} md={12}>
              <Row className='d-flex justify-content-center'>
                <div className={`circle-service-${product.serviceId} circle`}>
                  <div className={`badge_alert`}>
                    <FontAwesomeIcon
                      icon={getBadgeIcon(product.status)}
                      color={getBadgeColor(product.status)}
                    />
                  </div>

                  <p>{toFixed(product.value, 2)}€</p>
                </div>
              </Row>

              {product.serviceId === 4 && product.status === 'Quoting' && (
                <Row className='d-flex justify-content-center mt-4'>
                  <Button
                    variant='outline-secondary'
                    onClick={() => acceptContract(product)}
                  >
                    Confirmar
                  </Button>
                </Row>
              )}
            </Col>

            <Col md={1} className='actions_col'>
              {(product.serviceId === 2 || product.serviceId === 3) && (product.status === "Approved" || product.status === "Confirmed") && (
                <div>
                  <Row
                    onClick={() => {
                      editProduct(product);
                    }}
                  >
                    <FontAwesomeIcon
                      color='#2292CC'
                      icon={faUserEdit}
                      size='1x'
                      className='ml-4 mb-3'
                    />
                  </Row>
                  <Row
                    onClick={() => {
                      cancelProduct(product);
                    }}
                  >
                    <FontAwesomeIcon
                      color='#2292CC'
                      icon={faTrash}
                      size='1x'
                      className='ml-4 mb-3'
                    />
                  </Row></div>)}
            </Col>
          </Row>
        </div>
      </Col>
      {product.status && (<div>
        <Row className='status_card'>
          <p><span className='status_title'>{getMessage(product.status).title}</span><span className='status_text'>{getMessage(product.status).text}</span></p>
        </Row>
      </div>)}

    </div>
  );
};

export default ProductCard;

import React, { useState, useEffect, useRef } from 'react';
import { Form, Container, Row, Col, Image, Button } from 'react-bootstrap';
import { Title, Content, Styles } from '../../../style/client';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faUsers } from '@fortawesome/free-solid-svg-icons';
const UserCreate: React.FC = () => {
  return (
    <>
      <Title>Utilizadores</Title>

      <Content>
        <div className='content'>
          <Container fluid className='card-no-tabs user_create'>
            <div className='header'>
              <Row>
                <Link className='color-back' to={'/gm/users'}>
                  &#60; Voltar
                </Link>
              </Row>
              <h2>Registo de novos utilizadores</h2>
              <p>Escolha uma das opções de registo abaixo:</p>
            </div>
            <Row>
              <Col className='d-flex justify-content-around'>
                <Link to={'/gm/users/create/one'}>
                  <div className='choose_box'>
                    <FontAwesomeIcon size='3x' icon={faUser} color='#FFF' />
                  </div>
                </Link>
              </Col>

              <Col className='d-flex justify-content-around'>
                <Link to={'/gm/users/create/many'}>
                  <div className='choose_box'>
                    <FontAwesomeIcon size='3x' icon={faUsers} color='#FFF' />
                  </div>
                </Link>
              </Col>
            </Row>
            <Row>
              <Col className='d-flex justify-content-around'>
                <h3>Adicionar um utilizador</h3>
              </Col>
              <Col className='d-flex justify-content-around'>
                <h3>Adicionar conjunto de utilizadores</h3>
              </Col>
            </Row>
          </Container>
        </div>
      </Content>
    </>
  );
};

export default UserCreate;

import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Image, Button } from 'react-bootstrap';
import { MobilityProfileProductDTO } from './subscriptions.interface';
import { toFixed } from '../../../utils/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faExclamationCircle,
  faClock,
  faInfoCircle,
} from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';

export const ProductCard = ({ product, booking }) => {
  let _product = product as MobilityProfileProductDTO;
  const history = useHistory();
  const getLogo = (serviceId) => {
    const service = {
      1: require('../../../assets/logos/kinto-one-logo-on-2x.png'),
      2: require('../../../assets/logos/guerin-xs-logo-on-2x.png'),
      3: require('../../../assets/logos/xtracars-xs-logo-on-2x.png'),
      4: require('../../../assets/logos/rodinhas-xs-logo-on-2x.png'),
      5: require('../../../assets/logos/bedriven-xs-logo-on-2x.png'),
    };

    return service[serviceId];
  };

  const getDescription = (product) => {
    switch (product.serviceId) {
      case 1:
        return `${product.kms} km | ${
          product.valuePack ? product.valuePack + 'meses' : ''
        } `;
      case 2:
        return `${product.valuePack} x  ${
          product.valuePack && product.valuePack > 1 ? 'dias' : 'dia'
        }`;

      case 3:
        return `${product.valuePack} x  ${
          product.valuePack && product.valuePack > 1 ? 'meses' : 'mês'
        }`;

      case 4:
        return `${product.pickupZone} | ${product.dropoffZone}`;
      case 5:
        return 'TVDE';
      default:
        return '';
    }
  };

  const getBrandText = (serviceId) => {
    const brandText = {
      1: 'Renting',
      2: 'Rent-a-car',
      3: 'Aluguer Flexível',
      4: 'Transporte de Crianças',
      5: 'TVDE',
      6: 'Aluguer Flexível',
    };

    return brandText[serviceId];
  };

  const getProductName = () => {
    switch (product.serviceId) {
      case 1:
        return `${product.productName} ou SIMILAR`;
      case 2:
        return `${product.productName} ou SIMILAR`;
      case 3:
        return `${product.productName} ou SIMILAR`;
      case 4:
        return `${product.productName}`;
      case 5:
        return 'TVDE';
      default:
        return '';
    }
  };

  const getBadgeColor = (status) => {
    let _status = status.toUpperCase();
    if (_status === 'PENDING' || _status === 'WAITING') {
      return 'rgb(255, 202, 0)';
    } else if (_status === 'QUOTING') {
      return 'rgb(255, 202, 0)';
    } else if (_status === 'APPROVED') {
      return 'rgb(40, 196, 144)';
    } else if (_status === 'ACCEPTED') {
      return 'rgb(40, 196, 144)';
    } else if (_status === 'ERROR') {
      return 'rgb(231, 16, 75)';
    } else {
      return 'rgb(231, 16, 75)';
    }
  };

  const getBadgeIcon = (status) => {
    let _status = status.toUpperCase();
    if (_status === 'PENDING' || _status === 'WAITING') {
      return faClock;
    } else if (_status === 'QUOTING') {
      return faInfoCircle;
    } else if (_status === 'APPROVED') {
      return faCheckCircle;
    } else if (_status === 'ACCEPTED') {
      return faCheckCircle;
    } else if (_status === 'ERROR') {
      return faExclamationCircle;
    } else {
      return faExclamationCircle;
    }
  };

  const getProducImage = (product) => {
    switch (product.serviceId) {
      case 1:
        return product.productImage ? `${product.productImage}` : require('../../../assets/car_placeholder.png');
      case 2:
        return require('../../../assets/car_placeholder.png');
      case 3:
        return require('../../../assets/car_placeholder.png');
      case 4:
        return `${product.productImage}`;
      case 5:
        return 'TVDE';
      default:
        return '';
    }
  };

  return (
    <>
      <Col sm={12} key={_product.id} className='px-0'>
        <div className='product_card_subs'>
          <Row>
            <Col md={2} xs={12}>
              <img src={getLogo(_product.serviceId)} className='service_logo' />
              <p className='text_service'>{getBrandText(_product.serviceId)}</p>
            </Col>
            <Col md={5} xs={12}>
              {(product.serviceId === 2 || product.serviceId === 3) && (
                <>
                  {product.subscriptionStatus !== 'APPROVED' && (
                    <>
                      <p className='text_product_name'>{getProductName()}</p>
                      <p>{getDescription(_product)}</p>
                    </>
                  )}
                  {product.subscriptionStatus === 'APPROVED' && (
                    <p className='text_approved'>{getDescription(_product)}</p>
                  )}
                </>
              )}

              {product.serviceId !== 2 && product.serviceId !== 3 && (
                <>
                  <p className='text_product_name'>{getProductName()}</p>
                  <p>{getDescription(_product)}</p>
                </>
              )}
            </Col>
            <Col md={2} xs={12} className='align-content-middle'>
              <img src={getProducImage(_product)} className='service_logo' />
            </Col>
            <Col md={3} xs={12}>
              <Row className='d-flex justify-content-center'>
                <div className={`circle-service-${product.serviceId} circle`}>
                  <div className={`badge_alert`}>
                    <FontAwesomeIcon
                      icon={getBadgeIcon(product.subscriptionStatus)}
                      color={getBadgeColor(product.subscriptionStatus)}
                    />
                  </div>

                  <p>{toFixed(product.balance, 2)}€</p>
                </div>
                <div>
                </div>
              </Row>
              {product.subscriptionStatus === 'APPROVED' &&
                product.serviceId !== 4 && product.hasReservation === false && (
                  <Row className='d-flex justify-content-center mt-4'>
                    <Button
                      variant='outline-secondary'
                      onClick={() => booking(product)}
                    >
                      Reservar
                    </Button>
                  </Row>
                )}
              {product.subscriptionStatus === 'APPROVED' &&
                product.serviceId === 4 && (
                  <Row className='d-flex justify-content-center mt-4'>
                    <Button
                      variant='outline-secondary'
                      onClick={() =>
                        history.push(
                          `/usercorporate/reservations#${product.packId}`
                        )
                      }
                    >
                      Ver reserva
                    </Button>
                  </Row>
                )}
            </Col>
          </Row>
        </div>
      </Col>
    </>
  );
};

export default ProductCard;

import React, { useState, useEffect, useRef } from 'react';
import { Form, Container, Row, Col, Image, Button } from 'react-bootstrap';
import Modal from '@material-ui/core/Modal';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Tooltip from '@material-ui/core/Tooltip';
import Divider from '@material-ui/core/Divider';
import Loading from 'react-fullscreen-loading';
import { faTimes, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { notificationsService } from '../../services/notificationsService';
import { Notification } from './notification.interface';
export const ModalNotifications = ({
  closeButton,
  open,
  onClose,
  showAlert,
}) => {
  const [notifications, setNotification] = useState([] as Notification[]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getNotifications();
  }, []);

  useEffect(() => {
    getNotifications();
  }, [open]);

  const getLogo = (serviceId) => {
    const service = {
      1: require('../../assets/logos/kinto-one-logo-on-2x.png'),
      2: require('../../assets/cart/guerin.png'),
      3: require('../../assets/cart/xtracars.png'),
      4: require('../../assets/cart/rodinhas.png'),
      5: require('../../assets/logos/bedriven-xs-logo-on-2x.png'),
      7: require('../../assets/cart/rodinhas.png'),
    };

    return service[serviceId];
  };

  const markAsRead = async (item: Notification) => {
    setIsLoading(true);
    let data = { notificationId: item.notificationId, packProductId: item.packProductId }
    let result = await notificationsService.notificationsSeen(data);
    if (result) {
      getNotifications();
    }
    setIsLoading(false);
  };

  const getNotifications = async () => {
    let result = await notificationsService.getAll();
    if (result.length > 0) {
      setNotification(result);
      let notification = 0;
      result.map((el) => {
        if (el.seen === false) {
          notification += 1;
        }
      });
      showAlert(notification);
    }
  };

  return (
    <>
      <Modal open={open} onClose={onClose} className='modal_notifications'>
        <>
      {isLoading && (
        <Loading
          loading
          background='rgba(255, 255, 255, .4)'
          loaderColor='#3498db'
        />
      )}
        <Container className='modal_base' style={{ backgroundColor: '#00aeef' }}>
          <Row className='justify-content-end'>
            <button onClick={() => closeButton()} className='button_close'>
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </Row>
          <Row className='d-flex justify-content-center mt-4 mb-4'>
            <h3>Notificações</h3>
          </Row>

          <List
            style={{
              width: '100%',
              backgroundColor: '#ffffff66',
              borderRadius: 30,
            }}
          >
            {notifications &&
              notifications.length > 0 &&
              notifications.map((el, index) => {
                if (el.seen === false) {
                  return (
                    <div key={'seen_false' + index}>
                      <ListItem>
                        <ListItemAvatar>
                          <img
                            src={getLogo(el.serviceId)}
                            className='logo_service'
                          />
                        </ListItemAvatar>
                        <ListItemText
                          primary={el.title}
                          secondary={el.message}
                        />
                        <>
                          <Tooltip title='Marcar como lida'>
                            <ListItemAvatar className={'seen_notification'}>
                              <>
     
                                <FontAwesomeIcon
                                  icon={faEyeSlash}
                                  color={'#fefefe'}
                                  onClick={() => markAsRead(el)}
                                />
                    
                              </>
                            </ListItemAvatar>
                          </Tooltip>
                        </>
                      </ListItem>
                      <Divider
                        variant='inset'
                        component='li'
                        style={{ width: '80%' }}
                      />
                    </div>
                  );
                }
              })}
            {notifications && notifications.length === 0 && (
              <ListItem>
                <ListItemText secondary={'Sem notificações para exibir'} />
              </ListItem>
            )}
          </List>
          <details>
            <summary>Notificações lidas</summary>
            <List
              style={{
                width: '100%',
                backgroundColor: '#ffffff66',
                borderRadius: 30,
              }}
            >
              {notifications &&
                notifications.length > 0 &&
                notifications.map((el, index) => {
                  if (el.seen === true) {
                    return (

                      <div key={'seen_true' + index}>
                        <ListItem>
                          <ListItemAvatar>
                            <img
                              src={getLogo(el.serviceId)}
                              className='logo_service'
                            />
                          </ListItemAvatar>
                          <ListItemText
                            primary={el.title}
                            secondary={el.message}
                          />
                          <>
                            <Tooltip title='Já lida'>
                              <ListItemAvatar
                                className={'seen_notification notification_read'}
                              >
                                <FontAwesomeIcon icon={faEye} color={'#fefefe'} />
                              </ListItemAvatar>
                            </Tooltip>
                          </>
                        </ListItem>
                        <Divider
                          variant='inset'
                          component='li'
                          style={{ width: '80%' }}
                        />
                      </div>
                    );
                  }
                })}
              {notifications && notifications.length === 0 && (
                <ListItem>
                  <ListItemText secondary={'Sem notificações para exibir'} />
                </ListItem>
              )}
            </List>

          </details>
        </Container>
        </>
      </Modal>
    </>
  );
};

export default ModalNotifications;

import React, { useState, useEffect, useRef } from 'react';
import { Form, Container, Row, Col, Image, Button } from 'react-bootstrap';
import { configuratorService } from '../../services/configuratorService';
import { Pack, PackProductDTO } from './packs.interface';
import { toFixed } from '../../utils/utils';
import { storeCart, getCart } from '../../redux/cartReducer';

export const PackCard = ({ pack, clickEdit }) => {
  const openPack = (pack: Pack) => {
    clickEdit(pack);
    const cart = getCart();
    if (cart) {
      let cartArray = JSON.parse(cart) as PackProductDTO[];
      if (pack.packProductDTO) {
        pack.packProductDTO.map((prod) => {
          let index = cartArray.findIndex((el) => el.id === prod.id);
          if (index === -1) {
            if (pack.packProductDTO && pack.packProductDTO?.length > 0) {
              pack.packProductDTO?.map((el) => {
                cartArray.push(el);
              });
            }
          }
        });

        storeCart(cartArray);
      }
    } else {
      let cartArray = [] as PackProductDTO[];
      if (pack.packProductDTO && pack.packProductDTO?.length > 0) {
        pack.packProductDTO?.map((el) => {
          cartArray.push(el);
        });
        storeCart(cartArray);
      }
    }
  };

  const getLogo = (serviceId) => {
    const service = {
      1: require('../../assets/logos/kinto-one-logo-on-2x.png'),
      2: require('../../assets/logos/guerin-xs-logo-on-2x.png'),
      3: require('../../assets/logos/xtracars-xs-logo-on-2x.png'),
      4: require('../../assets/logos/rodinhas-xs-logo-on-2x.png'),
      5: require('../../assets/logos/bedriven-xs-logo-on-2x.png'),
    };

    return service[serviceId];
  };

  const getDescription = (service: PackProductDTO) => {
    switch (service.serviceId) {
      case 1:
        return `${service.appDetailsInfo} km | ${service.appDetails} meses`;
      case 2:
        return `${service.valuePack} x  ${
          service.valuePack && service.valuePack > 1 ? 'dias' : 'dia'
        }`;
      case 3:
        return `${service.valuePack} x  ${
          service.valuePack && service.valuePack > 1 ? 'meses' : 'mês'
        }`;
      case 4:
        return (
          <>
            <span className='mb-0'>{`${service.pickupZone} | ${service.dropoffZone}`}</span>
            <br />
            <span>{`${
              service.numberChildren && service.numberChildren === 1
                ? service.numberChildren + ' criança'
                : service.numberChildren + ' crianças'
            } | ${service.numberPerWeek + ' x por semana'}`}</span>
          </>
        );
      case 5:
        return 'TVDE';
      default:
        return '';
    }
  };

  return (
    <>
      <Col sm={12} key={pack.id} className='d-flex justify-content-center'>
        <div className='pack_card'>
          <h3>Pacote {toFixed(pack.monthlyValue, 2)}€</h3>
          <h4>{pack.name}</h4>
          {pack.packProductDTO !== null &&
            pack.packProductDTO?.length > 0 &&
            pack.packProductDTO.map((service: PackProductDTO) => {
              return (
                <div key={service.id}>
                  <Row className='divisor'></Row>
                  <Row className='service_row'>
                    <Col md={3} className='align-middle'>
                      <img
                        src={getLogo(service.serviceId)}
                        className='service_logo'
                      />
                    </Col>
                    <Col>
                      <p className='product_name'>{service.productName}</p>
                      <p className='product_description'>
                        {getDescription(service)}
                      </p>
                    </Col>
                    <Col md={3}>
                      <Row className='d-flex justify-content-end'>
                        <div
                          className={`circle-service-${service.serviceId} circle`}
                        >
                          <p>{toFixed(service.balance, 2)}€</p>
                        </div>
                      </Row>
                    </Col>
                  </Row>
                </div>
              );
            })}
          <Row className='d-flex justify-content-center mt-4'>
            <div className='edit_button' onClick={() => openPack(pack)}>
              Editar
            </div>
          </Row>
        </div>
      </Col>
    </>
  );
};

export default PackCard;

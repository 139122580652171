import React, { useState, useEffect } from 'react';
import { Form, Container, Row, Col } from 'react-bootstrap';
import { Title, Content } from '../../style/content';
import { User, AlertConfig, Country } from './profile.interface';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import SweetAlert from 'react-bootstrap-sweetalert';
import { SweetAlertType } from 'react-bootstrap-sweetalert/dist/types';
import DatePicker from 'react-datepicker';
import { profileService } from '../../services/User/profileService';
import Loading from 'react-fullscreen-loading';
import { AuthService } from '../../services/auth.service';
import dayjs from 'dayjs';
import { stopProcess, getProcess } from '../../redux/cartReducer';
import { useHistory } from 'react-router-dom';
import { storeUserDetails, defaultRoute } from '../../redux/sessionReducer';
import { generateArrayOfYears, subtractYears } from '../../utils/utils';

const Profile: React.FC = () => {
  const [user, setUser] = useState({} as User);
  const [countries, setCountries] = useState([] as Country[]);
  // const [fileInputKey, setFileInputKey] = useState('12345');
  const [file, setFile] = useState({ fileName: '', file: '' });
  const [alertDialog, setAlertDialog] = useState(false);
  const [alertDialogConfig, setAlertDialogConfig] = useState({} as AlertConfig);
  let fileInputField: HTMLInputElement;
  const [fileSrc, setFileSrc] = useState('');
  const [avatarDisplay, setAvatarDisplay] = useState('none');
  const [noAvatarDisplay, setNoAvatarDisplay] = useState('none');
  const [isLoading, setIsLoading] = useState(true);
  const [years, setYears] = useState([] as Number[]);
  const [yearsFuture, setYearsFuture] = useState([] as Number[]);
  const history = useHistory();
  const months = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ];
  useEffect(() => {
    getData();
    getCountries();

    let _years = generateArrayOfYears('past', 16) as Number[];
    setYears(_years);
    let _yearsFuture = generateArrayOfYears('future') as Number[];
    setYearsFuture(_yearsFuture);
  }, []);

  useEffect(() => {
    getAvatar();
    // eslint-disable-next-line
  }, [user]);

  const getData = async () => {
    const result = await AuthService.getUserFromSubscription(true);
    if (result) {
      let user = result;
      if (result.dateBirth) {
        user = { ...user, dateBirth: new Date(result.dateBirth) };
      }
      if (result.documentExpiryDate) {
        user = {
          ...user,
          documentExpiryDate: new Date(result.documentExpiryDate),
        };
      }
      if (result.driverLicenseExpiryDate) {
        user = {
          ...user,
          driverLicenseExpiryDate: new Date(result.driverLicenseExpiryDate),
        };
      }
      setUser(user);
      setIsLoading(false);
    } else {
      console.log(result.message);
    }
  };

  const getCountries = async () => {
    const result = await profileService.listCountries();
    if (result) {
      setCountries(result);
    } else {
      console.log(result.message);
    }
  };

  const handleNewFileUpload = (e: any) => {
    if (e.target.files.length) {
      const file = e.target.files[0];
      const fileSize = file.size / 1024 / 1024;

      if (fileSize > 2) {
        alert('Erro', 'Arquivo deve ter menos que 2 MB', 'error');
        return false;
      }

      getBase64(file).then((data) => {
        let base64String = data as string;
        //base64String = base64String.split(',')[1];
        setFile({ fileName: file.name, file: base64String });
        setFileSrc(base64String);
      });
      // setFileInputKey(Math.random().toString(36).substring(7));
      setAvatarDisplay('block');
      setNoAvatarDisplay('none');
    }
  };
  const getBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };
  const alert = (
    title: String,
    message: String,
    type: SweetAlertType,
    redirect: Boolean = false
  ) => {
    setAlertDialog(true);
    setAlertDialogConfig({
      title: title,
      message: message,
      type: type,
      redirect: redirect,
    });
  };

  const getAvatar = () => {
    setAvatarDisplay('block');
    setNoAvatarDisplay('none');
    if (file.file !== '') {
      setFileSrc(file.file);
    } else if (user.imageUrl) {
      setFileSrc(user.imageUrl);
    } else {
      setAvatarDisplay('none');
      setNoAvatarDisplay('block');
    }
  };

  const clearAvatar = () => {
    setFile({ fileName: '', file: '' });
    setFileSrc('../../assets/avatar/profile.png');
    if (fileInputField) {
      if (fileInputField.value) {
        fileInputField.value = '';
      }
    }
    setUser((prev) => ({ ...prev, imageUrl: "" }));
    setAvatarDisplay('none');
    setNoAvatarDisplay('block');
  };

  const save = async () => {
    setIsLoading(true);
    let newUser = { ...user };

    newUser = {
      ...newUser,
      dateBirth: newUser.dateBirth
        ? dayjs(newUser.dateBirth).format('YYYY-MM-DD')
        : undefined,
      documentExpiryDate: newUser.documentExpiryDate
        ? dayjs(newUser.documentExpiryDate).format('YYYY-MM-DD')
        : undefined,
      driverLicenseExpiryDate: newUser.driverLicenseExpiryDate
        ? dayjs(newUser.driverLicenseExpiryDate).format('YYYY-MM-DD')
        : undefined,
    };
    if (newUser.imageUrl) {
      if (file.file.split(',')[1] !== null) {
        newUser.imageUrl = file.file.split(',')[1];
      } else {
        newUser.imageUrl = file.file;
      }
    } else {
      if (file.file) {
        newUser.imageUrl = file.file.split(',')[1];
      }
    }

    const vatValidation = await profileService.vatValidation({
      nif: newUser.nif,
    });
    if (vatValidation.status === 200) {
      const result = await profileService.updateUser(newUser);
      if (result.status === 200) {
        alert('Sucesso', result.message, 'success', true);
        const savedUser = await AuthService.getUserFromSubscription(true);
        storeUserDetails(savedUser);
        setIsLoading(false);
      } else {
        if (result.errors.length > 0) {
          let message = [] as String[];
          // eslint-disable-next-line
          result.errors.map((el) => {
            if (el.defaultMessage) {
              message.push(el.defaultMessage);
            }
          });
          alert('Erro', message.toString(), 'error');
          setIsLoading(false);
        } else {
          alert('Erro', result.message, 'error');
          setIsLoading(false);
        }
      }
    } else if(vatValidation.status === 503){
      console.log("vatValidation: ", vatValidation)
      alert('Erro', vatValidation.error, 'error');
      setIsLoading(false);
    }else{
      console.log("vatValidation: ", vatValidation)
      alert('Erro', vatValidation.errors[0].defaultMessage, 'error');
      setIsLoading(false);
    }
  };

  return (
    <>
      <Title>Editar Perfil</Title>

      <Content>
        <Container fluid className='card-no-tabs user_profile'>
          <Row className='header_divisor'>
            <Col>
              <h2>Dados pessoais</h2>
            </Col>
            <Col md={3}>
              <h2>Foto</h2>
            </Col>
          </Row>
          <Row className='form_content'>
            <Col>
              <label htmlFor='name'>Nome completo</label>
              <Form.Control
                onBlur={(e) => setUser({ ...user, name: e.target.value })}
                defaultValue={user.name}
                id='name'
              />
            </Col>
            <Col md={3}>
              <Row className='justify-content-end'>
                <FontAwesomeIcon
                  icon={faTrashAlt}
                  onClick={() => clearAvatar()}
                  style={{ display: avatarDisplay }}
                />
              </Row>
              <Row className='d-flex justify-content-center'>
                <input
                  type='file'
                  ref={(thisInput) =>
                    (fileInputField = thisInput as HTMLInputElement)
                  }
                  onChange={handleNewFileUpload}
                  accept='images'
                  className='input_file'
                />
                <img
                  src={fileSrc ?? ''}
                  alt='Avatar'
                  className='avatar_img'
                  style={{ display: avatarDisplay }}
                />
                <img
                  src={require('../../assets/avatar/profile.png')}
                  alt='Avatar'
                  className='avatar_img'
                  style={{ display: noAvatarDisplay }}
                />
              </Row>
              <Row style={{ justifyContent: 'center' }}>
                <p className='small_note'>
                  Clique na imagem para editar - Fotografia do tipo passe com
                  menos de 2 MB e com 400x400px
                </p>
              </Row>
            </Col>

            <Col md={12}>
              <label htmlFor='contact' className='mb-0'>
                Contacto
              </label>
            </Col>
            <Col md={12}>
              <p className='small'>
                Para aumento da segurança da conta, insira o número de
                telemóvel.
              </p>
            </Col>
            <Col md={5}>
              {countries.length > 0 && (
                <Form.Control
                  as='select'
                  onChange={(e) =>
                    setUser({ ...user, countryCode: Number(e.target.value) })
                  }
                  value={user.countryCode}
                >
                  {countries?.map((country: Country, index) => {
                    return (
                      <option
                        value={country.countryPhoneCode}
                        key={country.name + index + 'xx'}
                      >
                        {country.name} (+{country.countryPhoneCode})
                      </option>
                    );
                  })}
                </Form.Control>
              )}
            </Col>
            <Col md={4}>
              <Form.Control
                onBlur={(e) =>
                  setUser({ ...user, phoneNumber: Number(e.target.value) })
                }
                type='number'
                defaultValue={user.phoneNumber}
                id='contact'
              />
            </Col>
            <Col md={12}>
              <label htmlFor='birthday' className='mt-3'>
                Data de nascimento
              </label>
            </Col>

            <Col>
              <DatePicker
                selected={user.dateBirth}
                dateFormat='dd/MM/yyyy'
                maxDate={subtractYears(new Date(), 16)}
                onChange={(date) =>
                  date &&
                  setUser({
                    ...user,
                    dateBirth: new Date(date.toString()),
                  })
                }
                renderCustomHeader={({
                  date,
                  changeYear,
                  changeMonth,
                  decreaseMonth,
                  increaseMonth,
                  prevMonthButtonDisabled,
                  nextMonthButtonDisabled,
                }) => (
                  <div
                    style={{
                      margin: 10,
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <button
                      onClick={decreaseMonth}
                      disabled={prevMonthButtonDisabled}
                    >
                      {'<'}
                    </button>
                    <select
                      value={dayjs(date).year()}
                      onChange={({ target: { value } }) =>
                        changeYear(Number(value))
                      }
                    >
                      {years.map((option) => (
                        <option
                          key={option.toString()}
                          value={option.toString()}
                        >
                          {option}
                        </option>
                      ))}
                    </select>
                    <select
                      value={months[dayjs(date).month()]}
                      onChange={({ target: { value } }) =>
                        changeMonth(months.indexOf(value))
                      }
                    >
                      {months.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                    <button
                      onClick={increaseMonth}
                      disabled={nextMonthButtonDisabled}
                    >
                      {'>'}
                    </button>
                  </div>
                )}
              />
            </Col>
            <Col md={12}>
              <p className='mt-3'>
                Género <span className='small'>(Opcional)</span>
              </p>
            </Col>
            <Col md={12}>
              <Row>
                <Form.Check
                  custom
                  inline
                  name='checkbox-female'
                  type={'radio'}
                  id={`checkbox-female`}
                  style={{ marginLeft: '15px' }}
                  onChange={(e) => setUser({ ...user, userGender: 'FEMALE' })}
                  checked={user.userGender === 'FEMALE'}
                  className='mr-0'
                />
                <label htmlFor='checkbox-female'>Feminino</label>
                <Form.Check
                  custom
                  inline
                  name='checkbox-male'
                  type={'radio'}
                  id={`checkbox-male`}
                  style={{ marginLeft: '15px' }}
                  onChange={(e) => setUser({ ...user, userGender: 'MALE' })}
                  checked={user.userGender === 'MALE'}
                  className='mr-0'
                />
                <label htmlFor='checkbox-male'>Masculino</label>
              </Row>
            </Col>
            <Col md={12}>
              <label htmlFor='nif' className='mt-3'>
                NIF
              </label>
            </Col>
            <Col md={6}>
              <Form.Control
                onBlur={(e) => setUser({ ...user, nif: e.target.value })}
                type='number'
                defaultValue={user.nif}
                id='nif'
              />
            </Col>
          </Row>

          <Row className='header_divisor'>
            <Col>
              <h2 className='mt-5'>Morada</h2>
            </Col>
          </Row>
          <Row className='form_content'>
            <Col md={12}>
              <label htmlFor='address'>Morada</label>
            </Col>
            <Col md={12}>
              <Form.Control
                onBlur={(e) => {
                  setUser({ ...user, address: e.target.value });
                }}
                defaultValue={user.address}
                id='address'
              />
            </Col>
            <Col md={6}>
              <label htmlFor='country' className='mt-3'>
                País
              </label>
            </Col>
            <Col md={6}>
              <label htmlFor='district' className='mt-3'>
                Distrito
              </label>
            </Col>
            <Col md={6}>
              <Form.Control
                as='select'
                onChange={(e) => setUser({ ...user, country: e.target.value })}
                value={user.country ? user.country : ''}
                id='country'
              >
                <option value={''} key={''}>
                  {'Selecione'}
                </option>
                {countries.map((country: Country, index) => {
                  return (
                    <option
                      value={country.alpha2Code}
                      key={country.alpha2Code + index + 'al'}
                    >
                      {country.name}
                    </option>
                  );
                })}
              </Form.Control>
            </Col>
            <Col md={6}>
              <Form.Control
                onBlur={(e) => setUser({ ...user, district: e.target.value })}
                defaultValue={user.district}
                id='district'
              />
            </Col>
            <Col md={6}>
              <label htmlFor='postalcode' className='mt-3'>
                Código Postal
              </label>
            </Col>
            <Col md={6}>
              <label htmlFor='region' className='mt-3'>
                Localidade
              </label>
            </Col>
            <Col md={6}>
              <Form.Control
                onBlur={(e) => setUser({ ...user, postalCode: e.target.value })}
                defaultValue={user.postalCode}
                id='postalcode'
              />
            </Col>
            <Col md={6}>
              <Form.Control
                onBlur={(e) => setUser({ ...user, region: e.target.value })}
                defaultValue={user.region}
                id='region'
              />
            </Col>
          </Row>
          <Row className='header_divisor'>
            <Col>
              <h2 className='mt-5'>Carta de condução</h2>
            </Col>
          </Row>
          <Row className='form_content'>
            <Col md={6}>
              <label htmlFor='driverLicense'>Número</label>
            </Col>
            <Col md={6}>
              <label htmlFor='driverLicenseExpiryDate'>Data de validade</label>
            </Col>
            <Col md={6}>
              <Form.Control
                onBlur={(e) =>
                  setUser({ ...user, driverLicense: e.target.value })
                }
                defaultValue={user.driverLicense}
                id='driverLicense'
              />
            </Col>

            <Col md={6}>
              <DatePicker
                selected={user.driverLicenseExpiryDate}
                dateFormat='dd/MM/yyyy'
                minDate={new Date()}
                onChange={(date) =>
                  date &&
                  setUser({
                    ...user,
                    driverLicenseExpiryDate: new Date(date.toString()),
                  })
                }
                id='driverLicenseExpiryDate'
                renderCustomHeader={({
                  date,
                  changeYear,
                  changeMonth,
                  decreaseMonth,
                  increaseMonth,
                  prevMonthButtonDisabled,
                  nextMonthButtonDisabled,
                }) => (
                  <div
                    style={{
                      margin: 10,
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <button
                      onClick={decreaseMonth}
                      disabled={prevMonthButtonDisabled}
                    >
                      {'<'}
                    </button>
                    <select
                      value={dayjs(date).year()}
                      onChange={({ target: { value } }) =>
                        changeYear(Number(value))
                      }
                    >
                      {yearsFuture.map((option) => (
                        <option
                          key={option.toString()}
                          value={option.toString()}
                        >
                          {option}
                        </option>
                      ))}
                    </select>
                    <select
                      value={months[dayjs(date).month()]}
                      onChange={({ target: { value } }) =>
                        changeMonth(months.indexOf(value))
                      }
                    >
                      {months.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                    <button
                      onClick={increaseMonth}
                      disabled={nextMonthButtonDisabled}
                    >
                      {'>'}
                    </button>
                  </div>
                )}
              />
            </Col>
            <Col md={12}>
              <label htmlFor='driverLicenseExpiryDate' className='mt-3'>
                País
              </label>
            </Col>
            <Col md={6}>
              <Form.Control
                as='select'
                onChange={(e) =>
                  setUser({
                    ...user,
                    driverLicenseIssuingCountry: e.target.value,
                  })
                }
                value={
                  user.driverLicenseIssuingCountry
                    ? user.driverLicenseIssuingCountry
                    : ''
                }
                id='driverLicenseIssuingCountry'
              >
                <option value={''} key={''}>
                  {'Selecione'}
                </option>
                {countries.map((country: Country) => {
                  return (
                    <option value={country.alpha2Code} key={country.alpha2Code}>
                      {country.name}
                    </option>
                  );
                })}
              </Form.Control>
            </Col>
          </Row>
          <Row className='header_divisor'>
            <Col>
              <h2 className='mt-5'>Documento de identificação</h2>
            </Col>
          </Row>
          <Row className='form_content'>
            <Col md={6}>
              <label htmlFor='identificationCard'>Número</label>
            </Col>
            <Col md={6}>
              <label htmlFor='documentExpiryDate'>Data de validade</label>
            </Col>
            <Col md={6}>
              <Form.Control
                onChange={(e) =>
                  setUser({ ...user, identificationCard: e.target.value })
                }
                defaultValue={user.identificationCard}
                id='identificationCard'
              />
            </Col>
            <Col md={6}>
              <DatePicker
                selected={user.documentExpiryDate}
                dateFormat='dd/MM/yyyy'
                minDate={new Date()}
                onChange={(date) =>
                  date &&
                  setUser({
                    ...user,
                    documentExpiryDate: new Date(date.toString()),
                  })
                }
                id='documentExpiryDate'
                renderCustomHeader={({
                  date,
                  changeYear,
                  changeMonth,
                  decreaseMonth,
                  increaseMonth,
                  prevMonthButtonDisabled,
                  nextMonthButtonDisabled,
                }) => (
                  <div
                    style={{
                      margin: 10,
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <button
                      onClick={decreaseMonth}
                      disabled={prevMonthButtonDisabled}
                    >
                      {'<'}
                    </button>
                    <select
                      value={dayjs(date).year()}
                      onChange={({ target: { value } }) =>
                        changeYear(Number(value))
                      }
                    >
                      {yearsFuture.map((option) => (
                        <option
                          key={option.toString()}
                          value={option.toString()}
                        >
                          {option}
                        </option>
                      ))}
                    </select>
                    <select
                      value={months[dayjs(date).month()]}
                      onChange={({ target: { value } }) =>
                        changeMonth(months.indexOf(value))
                      }
                    >
                      {months.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                    <button
                      onClick={increaseMonth}
                      disabled={nextMonthButtonDisabled}
                    >
                      {'>'}
                    </button>
                  </div>
                )}
              />
            </Col>
          </Row>
          <Row className='d-flex justify-content-center mt-5'>
            <button
              className='big-btn ml-4'
              onClick={() => {
                save();
              }}
            >
              GUARDAR ALTERAÇÕES
            </button>
          </Row>
        </Container>
        {alertDialog && (
          <SweetAlert
            type={alertDialogConfig?.type}
            title={alertDialogConfig?.title ?? ''}
            onConfirm={async () => {
              setAlertDialog(false);
              if (alertDialogConfig?.type === 'success') {
                if (getProcess() === 'true') {
                  history.push('/cart');
                  stopProcess();
                } else {
                  let route = await defaultRoute();
                  history.push(route);
                }
                if (alertDialogConfig.redirect) {
                  window.location.reload();
                }
              }
            }}
          >
            {alertDialogConfig?.message ?? ''}.
          </SweetAlert>
        )}
      </Content>
      {isLoading && (
        <Loading
          loading
          background='rgba(255, 255, 255, .4)'
          loaderColor='#3498db'
        />
      )}
    </>
  );
};

export default Profile;

import api from '../utils/api';

export const productService = {
  create,
  getAll,
  integration,
  updateByCompany,
  deleteProduct,
  getIdCompany,
  getIdProduct,
  createUpload,
  getProductByCompany,
  getProductsByCompany,
  removeProductByCompany,
  changeProductByCompany,
  getProductsGuerinXtracars,
  getProductGuerinXtracars,
  updateProductGuerinXtracars,
  getProductsByGuerinXtracars,
  getProductsByGuerinXtracarsDropdown,
  getProduct,
  createGuerinXtracars,
  removeGuerinXtracars,
  activeGuerinXtracars,
  uploadSheetRodinhas,
  getRodinhasProduct,
  createRodinhasProduct,
  downloadTemplateRodinhas,
  getGuerinXtracarsSegments,
  getFinlogAll,
  getFinlogById,
};

let prefix = '/api/product';

if (process.env.REACT_APP_ENV === 'local') {
  prefix = '';
}

const sendPhoto = (data) => {
  const body = new FormData();
  body.append('file', data.file);
  body.append('product_uk', data.uk);
  console.log('body', data, body);

  return body;
};

async function integration(companyName, body) {
  try {
    const response = await api.apiPrivate.post(
      `${prefix}/product-${companyName}/integration`,
      body
    );
    return response;
  } catch (error) {
    throw error;
  }
}

async function create(companyName, body) {
  try {
    const response = await api.apiPrivate.post(
      `${prefix}/product-${companyName}/create-product`,
      body
    );
    return response;
  } catch (error) {
    throw error;
  }
}

async function createGuerinXtracars(companyName, body) {
  try {
    const response = await api.apiPrivate.post(
      `${prefix}/product-guerin-xtracars/create-${companyName}`,
      body
    );
    return response;
  } catch (error) {
    throw error;
  }
}

async function getAll(queryString) {
  try {
    const response = await api.apiPrivate.get(
      `${prefix}/product/products-pageable`
    );
    return response;
  } catch (error) {
    throw error;
  }
}

async function getProductsByCompany(companyName, queryString = '') {
  try {
    const response = await api.apiPrivate.get(
      `${prefix}/product-${companyName}/${
        companyName === 'bedriven' || companyName === 'rodinhas'
          ? 'all-products'
          : 'products'
      }?${queryString}`
    );
    return response;
  } catch (error) {
    throw error;
  }
}

async function getProductsByGuerinXtracars(companyName, queryString = '') {
  try {
    const response = await api.apiPrivate.get(
      `${prefix}/product-guerin-xtracars/products-${companyName}?${queryString}`
    );
    return response;
  } catch (error) {
    throw error;
  }
}

async function getProductsByGuerinXtracarsDropdown(
  companyName,
  queryString = ''
) {
  try {
    const response = await api.apiPrivate.get(
      `${prefix}/product/products-guerin-xtracars`
    );
    return response;
  } catch (error) {
    throw error;
  }
}

async function getProductByCompany(companyName, productId) {
  try {
    const response = await api.apiPrivate.get(
      `${prefix}/product-${companyName}/${productId}`
    );
    return response;
  } catch (error) {
    throw error;
  }
}

async function removeProductByCompany(companyName, productId) {
  try {
    let response;

    if (companyName === 'rodinhas' || companyName === 'bedriven') {
      response = await api.apiPrivate.delete(
        `/api/product/product-${companyName}/delete/${productId}`
      );
    } else {
      response = await api.apiPrivate.delete(
        `/api/product/product-${companyName}/${productId}`
      );
    }

    return response;
  } catch (error) {
    throw error;
  }
}

async function removeGuerinXtracars(companyName, productId) {
  try {
    const response = await api.apiPrivate.delete(
      `${prefix}/product-guerin-xtracars/delete-${companyName}/${productId}`
    );
    return response;
  } catch (error) {
    throw error;
  }
}

async function changeProductByCompany(companyName, productId, data) {
  try {
    const response = await api.apiPrivate.put(
      `${prefix}/product-${companyName}/active-product/${productId}`,
      { active: data }
    );
    return response;
  } catch (error) {
    throw error;
  }
}

async function activeGuerinXtracars(companyName, productId, data) {
  try {
    const response = await api.apiPrivate.put(
      `/api/product/product-guerin-xtracars/active-product-${companyName}/${productId}`,
      { active: data }
    );
    return response;
  } catch (error) {
    throw error;
  }
}

async function updateByCompany(companyName, data, companyId = '') {
  try {
    const response = api.apiPrivate.put(
      `${prefix}/product-${companyName}/${companyId}`,
      data
    );
    return response;
  } catch (error) {
    throw error;
  }
}

async function deleteProduct(body) {
  try {
    const response = await api.apiPrivate.delete(`/product`, { data: body });
    console.log('sucesso');
    console.log(response);
    // TODO: Mudar redirecionamento da página
  } catch (error) {
    // TODO: Criar o tratamento de erro
    throw error;
  }
}

async function getIdProduct(idProduct) {
  try {
    const response = await api.apiPrivate.get(`/product/${idProduct}`);
    console.log('sucesso');
    console.log(response);
    return response.data;
    // TODO: Mudar redirecionamento da página
  } catch (error) {
    // TODO: Criar o tratamento de erro
    throw error;
  }
}

async function getIdCompany(idCompany) {
  try {
    const response = await api.apiPrivate.get(`/product/company/${idCompany}`);
    return response;
  } catch (error) {
    throw error;
  }
}

async function createUpload(data) {
  try {
    const response = await api.apiPrivate.post(
      `/product/profile-image`,
      sendPhoto(data)
    );
    console.log('sucesso');
    console.log('E', response);
    // TODO: Mudar redirecionamento da página
  } catch (error) {
    // TODO: Criar o tratamento de erro
    throw error;
  }
}

async function getProductsGuerinXtracars() {
  try {
    const response = await api.apiPrivate.get(
      'api/product/product/products-guerin-xtracars'
    );
    return response;
  } catch (error) {
    throw error;
  }
}

async function getProductGuerinXtracars(company, id) {
  try {
    const response = await api.apiPrivate.get(
      `api/product/product-guerin-xtracars/search-${company}/${id}`
    );
    return response;
  } catch (error) {
    throw error;
  }
}

async function getProduct(id) {
  try {
    const response = await api.apiPrivate.get(`api/product/product/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
}

async function updateProductGuerinXtracars(company, data) {
  try {
    const response = await api.apiPrivate.put(
      `${prefix}/product-guerin-xtracars/edit-${company}/${data.id}`,
      data
    );
    return response;
  } catch (error) {
    throw error;
  }
}

async function uploadSheetRodinhas(data) {
  const response = await api.apiPrivate
    .post(`${prefix}/product-rodinhas/upload-sheet`, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        console.log(error.request);
        return error.request;
      }
      throw error;
    });
  return response;
}

async function getRodinhasProduct(id) {
  try {
    const fees = await api.apiPrivate.get(
      `${prefix}/product-rodinhas/rodinhas-fees/${id}`
    );
    const prices = await api.apiPrivate.get(
      `${prefix}/product-rodinhas/rodinhas-prices/${id}`
    );
    const discounts = await api.apiPrivate.get(
      `${prefix}/product-rodinhas/rodinhas-discounts/${id}`
    );

    return { zones: fees.data, prices: prices.data, discounts: discounts.data };
  } catch (error) {
    throw error;
  }
}

async function createRodinhasProduct(data) {
  try {
    const response = await api.apiPrivate.post(
      `${prefix}/product-rodinhas/create-new-product`,
      data
    );

    return response;
  } catch (error) {
    throw error;
  }
}

async function downloadTemplateRodinhas() {
  try {
    const response = await api.apiPrivate.get(
      `${prefix}/product-rodinhas/download-template`
    );

    return response;
  } catch (error) {
    throw error;
  }
}

async function getGuerinXtracarsSegments() {
  try {
    const response = await api.apiPrivate.get(
      `${prefix}/product-guerin-xtracars/all-segments`
    );

    return response;
  } catch (error) {
    throw error;
  }
}

async function getFinlogAll() {
  try {
    const response = await api.apiPrivate.get(
      `${prefix}/pre-subscription/all-finlog-products`
    );

    return response;
  } catch (error) {
    throw error;
  }
}

async function getFinlogById(id) {
  try {
    const response = await api.apiPrivate.get(
      `${prefix}/pre-subscription/finlog-product/${id}`
    );

    return response;
  } catch (error) {
    throw error;
  }
}

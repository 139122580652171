export const getCreationDate = (dateCreated) => {
  var date = new Date(dateCreated);
  var month = date.getUTCMonth() + 1; //months from 1-12
  var day = date.getUTCDate();
  var year = date.getUTCFullYear();

  return `${day}/${month}/${year}`;
};

export const toFixed = (num, fixed) => {
  if (isNaN(num)) {
    return 0.0;
  }

  let calc = (+(Math.round(+(num + 'e' + fixed)) + 'e' + -fixed)).toFixed(fixed)

  if (isNaN(Number(calc))) {
    return 0.0;
  }

  return Number(calc)
};

export const generateArrayOfYears = (
  type,
  maxPast = 18,
  minPast = 100,
  maxFuture = 50
) => {
  let max;
  let min;
  let years = [];

  if (type === 'past') {
    max = new Date().getFullYear() - maxPast;
    min = new Date().getFullYear() - minPast;
  } else if (type === 'future') {
    max = new Date().getFullYear() + maxFuture;
    min = new Date().getFullYear();
  }

  for (let i = max; i >= min; i--) {
    years.push(i);
  }
  return years;
};


export const formatStationString = (str) => {
  let lower = ['o', 'a', 'de', 'da', 'do']
  let result = []
  let array = str.split(' ')
  array.forEach((e) => {if (!lower.includes(e)) { result.push(e.charAt(0).toUpperCase() + e.slice(1).toLowerCase()); } else { result.push(e.toLowerCase()) } })
  return result.join(' ')
}

export const subtractYears = (date, years) => {
  date.setFullYear(date.getFullYear() - years);
  return date;
}
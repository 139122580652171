import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Form } from 'react-bootstrap';
import ModalBase from './ModalBase';
import { Itinerary, PackItineraryDTO } from './cart.interface';
import { storeCart, getCart } from '../../redux/cartReducer';
import { PackProductDTO } from '../Configurator/packs.interface';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DatePicker from 'react-datepicker';
import TextField from '@material-ui/core/TextField';
import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { autocompleteService } from 'src/services/autocompleteService';
import AutoCompleteRodinhas from 'src/components/AutoCompleteRodinhas';
import { packService } from 'src/services/packService';

export const ModalRodinhasItinerary = ({
  closeButton,
  open,
  onClose,
  product,
}) => {
  const [rodinhasMaxChildrens, setRodinhasMaxChildrens] = useState(9);
  const [rodinhasMaxNumberPerWeek, setRodinhasMaxNumberPerWeek] = useState(10);
  const [itinerary, setItinerary] = useState({} as Itinerary);
  const [packItineraryDTO, setPackItineraryDTO] = useState(
    [] as PackItineraryDTO[]
  );
  const [cartIndex, setCartIndex] = useState(-1);

  const [error, setError] = useState({});
  const [postalCodeError, setPostalCodeError] = useState({});
  const [addressDetailsPickUp, setAddressDetailsPickUp] = useState({});
  const [addressDetailsDropOff, setAddressDetailsDropOff] = useState({});

  useEffect(() => {
    if (product) {
      setRodinhasMaxChildrens(product.numberChildren);
      setRodinhasMaxNumberPerWeek(product.numberPerWeek);
      if (product.packItineraryDTO === undefined) {
        setItinerary({
          packId: product.pack,
          serviceType: '',
          startService: new Date(),
          endService: new Date(),
          packItineraryDTO: [],
          packProducts: [
            {
              productId: product.productId,
              serviceId: product.serviceId,
              valueProductPack: product.valuePack,
            },
          ],
          remainderValuePack: 0,
        });

        setPackItineraryDTO([
          {
            comeBack: false,
            childrenQuantity: '1',
            weekDays: [],
            cityPickUp: '',
            cityDropOff: '',
            hourDropOff: '',
            hourPickUp: '',
            productId: product.productId,
            pickUp: '',
            dropOff: '',
            postalCodeDropOff: '',
            postalCodePickup: '',
            comeBackPickUp: '',
            comeBackDropOff: '',
          },
        ]);
      } else {
        setItinerary({
          packId: product.pack,
          serviceType: '',
          startService: new Date(product.startService),
          endService: new Date(product.endService),
          packItineraryDTO: [],
          packProducts: [
            {
              productId: product.productId,
              serviceId: product.serviceId,
              valueProductPack: product.valuePack,
            },
          ],
          remainderValuePack: 0,
        });
        setPackItineraryDTO([...product.packItineraryDTO]);
      }

      storeCartIndex(product.id);
    }
  }, [product]);

  const save = async () => {
    let ready = false;
    packItineraryDTO.map((el) => {
      ready =
        el.childrenQuantity !== '' &&
        el.cityDropOff !== '' &&
        el.cityPickUp !== '' &&
        el.comeBack !== undefined &&
        el.dropOff !== '' &&
        el.hourDropOff !== '' &&
        el.hourPickUp !== '' &&
        el.pickUp !== '' &&
        el.postalCodeDropOff !== '' &&
        el.postalCodePickup !== '' &&
        el.productId !== '' ;

      if (el.comeBack == true) {
        ready = el.comeBackPickUp !== '' && el.comeBackDropOff !== '';
      }
    });

    if (ready) {
      let cart = getCart();
      if (cart) {
        let numberPerWeek = packItineraryDTO.map(itinerary => itinerary.weekDays?.length).reduce( (a,b) => (a??0) +(b??0) , 0);
        product.numberPerWeek = numberPerWeek;
        const result = await packService.calculateRodinhasValue(product);
        let array = JSON.parse(cart) as PackProductDTO[];
        if (array && array[cartIndex]) {
          let _iti = { ...itinerary, packItineraryDTO: packItineraryDTO };
          array[cartIndex] = {
            ...array[cartIndex],
            balance: result.data ? result.data : 0.0,
            startService: itinerary.startService?.toISOString(),
            endService: itinerary.endService?.toISOString(),
            packItineraryDTO: packItineraryDTO,
            numberPerWeek: numberPerWeek
          };
          storeCart(array);
          closeButton(7);
        }
      }
    }
  };

  const getPickUpAddressDetails = async (value, index, e) => {
    if (value) {
      let addressDetails = await autocompleteService.getAddressDetails(value);
      let postalCode = addressDetails['postalCode'] ? addressDetails['postalCode'] : '';
      updateValue('postalCodePickup', postalCode, index, e)
      let city = addressDetails['city'] ? addressDetails['city'] : '';
      updateValue('cityPickUp', city, index, e);
    }
  }

  const getDropOffAddressDetails = async (value, index, e) => {
    if (value) {
      let addressDetails = await autocompleteService.getAddressDetails(value);
      let postalCode = addressDetails['postalCode'] ? addressDetails['postalCode'] : '';
      updateValue('postalCodeDropOff', postalCode, index, e);
      let city = addressDetails['city'] ? addressDetails['city'] : '';
      updateValue('cityDropOff', city, index, e);
    }
  }

  const deleteItinerary = (index) => {
    let _iti = packItineraryDTO;
    if (_iti[index]) {
      _iti.splice(index, 1);
      setPackItineraryDTO([..._iti]);
    }
  };

  const updateValue = (key, value, index, e) => {
    if (key === 'weekDays') {
      let newArr = [...packItineraryDTO];
      if (newArr[index].weekDays === undefined) {
        newArr[index].weekDays = [value];
      } else {
        if (!newArr[index].weekDays?.includes(value)) {
            newArr[index].weekDays?.push(value);
        } else {
          let _index = newArr[index].weekDays?.indexOf(value);
          if (_index !== -1 && _index !== undefined) {
            newArr[index].weekDays?.splice(_index, 1);
          }
        }
      }

      setPackItineraryDTO(newArr);
      return;
    }

    if (key === 'comeBack' && value === false) {
      let newArr = [...packItineraryDTO];
      newArr[index].comeBackPickUp = '';
      newArr[index].comeBackDropOff = '';
      setPackItineraryDTO(newArr);
    }

    let newArr = [...packItineraryDTO];
    newArr[index][key] = value;
    setPackItineraryDTO(newArr);
  };


  const addEmptyItinerary = () => {
    let array = packItineraryDTO;
    array.push({
      comeBack: false,
      childrenQuantity: '1',
      weekDays: [],
      cityDropOff: '',
      hourDropOff: '',
      hourPickUp: '',
      productId: product.productId,
      pickUp: '',
      postalCodeDropOff: '',
      postalCodePickup: '',
      comeBackPickUp: '',
      comeBackDropOff: '',
    });
    setPackItineraryDTO([...array]);
  };

  const storeCartIndex = (id) => {
    let cart = getCart();
    if (cart) {
      let array = JSON.parse(cart) as PackProductDTO[];
      if (array) {
        let index = array.findIndex((el) => el.id === id);
        if (index !== -1) {
          setCartIndex(index);
        }
      }
    }
  };

  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;

    var checkboxerror = false;
    var postalCodeError = false;

    let newArr = [...packItineraryDTO];
    let errors = { ...error }
    let postalCodeErrors = {}
    for (var index = 0; index <= newArr.length - 1; index++) {

      //test postal code
      let pickupValue = form.elements["postalCodePickup" + index].value;
      let dropOffValue = form.elements["postalCodeDropOff" + index].value;
      let pattern = /^\d{4}-\d{3}?$/;
      let resultPickup = pickupValue.match(pattern);
      let resultDropoff = dropOffValue.match(pattern);

      postalCodeErrors["postalCodePickup" + index] = !resultPickup;
      postalCodeErrors["postalCodeDropOff" + index] = !resultDropoff;

      if (!resultPickup || !resultDropoff) {
        postalCodeError = true;
        addressDetailsPickUp["postalCodePickup" + index] = { postalCode: pickupValue, city: '' };
        setAddressDetailsPickUp(addressDetailsPickUp);
        addressDetailsDropOff["postalCodeDropOff" + index] = { postalCode: dropOffValue, city: '' };
        setAddressDetailsDropOff(addressDetailsDropOff);
      }


      form.elements["postalCodePickup" + 0].validity.BadInput = true
      if (newArr[index].weekDays !== undefined) {
        if (newArr[index].weekDays!.length > 0) {
          errors[index] = false;
        }
        else {
          errors[index] = true;
          checkboxerror = true;
        }
      }
    }

    setPostalCodeError(postalCodeErrors);
    setError(errors);
    if (form.checkValidity() === false || checkboxerror === true || postalCodeError) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      save();
      setValidated(false);
      checkboxerror = false;
      setError({});
    }
    setValidated(true);
    return;
  };

  const weekDays = {
    SEG: "MONDAY",
    TER: "TUESDAY",
    QUA: "WEDNESDAY",
    QUI: "THURSDAY",
    SEX: "FRIDAY",
    SAB: "SATURDAY",
    DOM: "SUNDAY"
  };


  const component = () => {


    return (
      <>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <div className='cart_modal modal_rodinhas'>
            <Row className='card'>
              <Row>
                <Col md={7}>
                  <p>Início de serviço</p>
                </Col>
                <Col md={5}>
                  <DatePicker
                    selected={
                      itinerary.startService !== undefined
                        ? new Date(itinerary.startService)
                        : new Date()
                    }
                    dateFormat='dd/MM/yyyy'
                    onChange={(date) =>
                      date &&
                      setItinerary({
                        ...itinerary,
                        startService: new Date(date.toString()),
                      })
                    }
                    minDate={new Date()}
                  />
                </Col>
                <Col md={7} className='mt-3'>
                  <p>Fim de serviço</p>
                </Col>
                <Col md={5} className='mt-3'>
                  <DatePicker
                    selected={itinerary.endService}
                    dateFormat='dd/MM/yyyy'
                    onChange={(date) =>
                      date &&
                      setItinerary({
                        ...itinerary,
                        endService: new Date(date.toString()),
                      })
                    }
                    minDate={new Date()}
                  />
                </Col>
              </Row>
              <Row>
                <Col className='mt-3'>
                  <p>Pick-Up e Drop-Off</p>
                </Col>
              </Row>

              {packItineraryDTO.map((el, index) => {
                return (
                  <Row key={index + 'row_iti'}>
                    <Col className='mb-2 mt-3 title_col' md={4}>
                      <p className='mb-0 title'>Itinerário {index + 1}</p>
                    </Col>
                    <Col className='mb-2 align-content-center mt-3' md={8}>
                      <Row>
                        <Col>
                          <hr />
                        </Col>
                        {index !== 0 && (
                          <Col md={1}>
                            <FontAwesomeIcon
                              icon={faTimesCircle}
                              onClick={(el) => deleteItinerary(index)}
                            />
                          </Col>
                        )}
                      </Row>
                    </Col>
                    <Col className='mb-2' md={8}>
                      <p>Número de crianças</p>
                    </Col>
                    <Col md={4}>
                      <Form.Control
                        required
                        as='select'
                        custom
                        value={packItineraryDTO[index].childrenQuantity}
                        onChange={(e) => {
                          let _itinerary = itinerary;
                          packItineraryDTO[index].childrenQuantity =
                            e.target.value;
                          setItinerary({ ..._itinerary });
                          setValidated(false);
                        }}
                      >
                        {Array.from(
                          { length: rodinhasMaxChildrens },
                          (_, i) => i + 1
                        ).map((el) => {
                          return (
                            <option value={el} key={el + 'nchildren'}>
                              {el}
                            </option>
                          );
                        })}
                      </Form.Control>
                    </Col>
                    <Col md={12}>
                      <p>Pick-Up</p>
                    </Col>
                    <Col md={12}>
                      <AutoCompleteRodinhas //items={addressesPickUp} 
                        text={packItineraryDTO[index].pickUp}
                        onChange={(value) => { updateValue('pickUp', value, index, null); }}
                        className='Pickup-text'
                        onSelect={(label, value) => {
                          updateValue('pickUp', label, index, null);
                          getPickUpAddressDetails(value, index, null);
                        }}
                      ></AutoCompleteRodinhas>
                    </Col>
                    <Col md={4} className='mt-2'>
                      <TextField
                        required
                        type='time'
                        onBlur={(e) => {
                          updateValue('hourPickUp', e.target.value, index, e);
                        }}
                        defaultValue={packItineraryDTO[index].hourPickUp}
                        id={`hourPickUp'${index}`}
                      />
                    </Col>
                    <Col md={4} className='mt-2'>
                      <Form.Control
                        required
                        type='text'
                        isInvalid={postalCodeError[`postalCodePickup${index}`]}
                        onBlur={(e) => {
                          updateValue(
                            'postalCodePickup',
                            e.target.value,
                            index,
                            e
                          );
                        }}
                        defaultValue={packItineraryDTO[index].postalCodePickup}
                        id={`postalCodePickup${index}`}
                      />
                    </Col>
                    <Col md={4} className='mt-2'>
                      <Form.Control
                        required
                        type='text'
                        minLength={3}
                        onBlur={(e) => {
                          updateValue('cityPickUp', e.target.value, index, e);
                        }}
                        defaultValue={packItineraryDTO[index].cityPickUp}
                        id={`cityPickUp'${index}`}
                        placeholder={'Localidade'}
                      />
                    </Col>
                    <Col md={12} className='mt-4' style={{ paddingTop: '20px' }}>
                      <p>Drop-Off</p>
                    </Col>
                    <Col md={12}>
                      <AutoCompleteRodinhas //items={addressesDropOff} 
                        text={packItineraryDTO[index].dropOff}
                        onChange={(value) => { updateValue('dropOff', value, index, null); }}
                        className='Dropoff-text'
                        onSelect={(label, value) => {
                          updateValue('dropOff', label, index, null);
                          getDropOffAddressDetails(value, index, null);
                        }}
                      ></AutoCompleteRodinhas>
                    </Col>
                    <Col md={4} className='mt-2'>
                      <TextField
                        type='time'
                        onBlur={(e) => {
                          updateValue('hourDropOff', e.target.value, index, e);
                        }}
                        defaultValue={packItineraryDTO[index].hourDropOff}
                        id={`hourDropOff'${index}`}
                      />
                    </Col>
                    <Col md={4} className='mt-2'>
                      <Form.Control
                        type='text'
                        required
                        isInvalid={postalCodeError[`postalCodeDropOff${index}`]}
                        onChange={() => {
                          setValidated(false)
                        }}
                        onBlur={(e) => {
                          updateValue(
                            'postalCodeDropOff',
                            e.target.value,
                            index,
                            e
                          );
                        }}
                        defaultValue={addressDetailsDropOff[`postalCodeDropOff${index}`]?.postalCode ? addressDetailsDropOff[`postalCodeDropOff${index}`]?.postalCode : packItineraryDTO[index].postalCodeDropOff}
                        id={`postalCodeDropOff${index}`}
                      />
                    </Col>
                    <Col md={4} className='mt-2'>
                      <Form.Control
                        type='text'
                        minLength={3}
                        required
                        onBlur={(e) => {
                          updateValue('cityDropOff', e.target.value, index, e);
                        }}
                        defaultValue={packItineraryDTO[index].cityDropOff}
                        id={`cityDropOff'${index}`}
                        placeholder={'Localidade'}
                      />
                    </Col>
                    <Col md={12} className='mt-4'>
                      <p>Dias da semana</p>
                    </Col>
                    <Col md={12}>
                      <Row className='pl-3'>
                        <FormControl
                          required
                          error={error[index]}
                          variant="standard"
                        >
                          <FormGroup row>
                            {Object.entries(weekDays).map(([key, value]) => (
                              <FormControlLabel
                                key={index + "-" + key}
                                label={key}
                                value={value}
                                control={
                                  <Checkbox
                                    checked={packItineraryDTO[index].weekDays?.includes(value)}
                                    onChange={(e) => {
                                      updateValue('weekDays', value, index, e);
                                      let errors = { ...error };
                                      errors[index] = false;
                                      setError(errors);
                                    }
                                    }
                                    name='checkedB'
                                  />
                                }
                              />
                            ))}
                          </FormGroup>
                           <FormHelperText hidden={!error[index]} >Por favor selecione o(s) dia(s) da semana que pretende</FormHelperText>
                        </FormControl>
                      </Row>
                    </Col>
                    <Col md={12} className='mt-4'>
                      <p>Viagem com regresso?</p>
                    </Col>
                    <Col md={12}>
                      <Row className='pl-3'>
                        <RadioGroup
                          row
                          aria-label='gender'
                          name='gender1'
                          value={packItineraryDTO[index].comeBack}
                          onChange={(e) =>
                            updateValue(
                              'comeBack',
                              e.target.value === 'true',
                              index,
                              e
                            )
                          }
                        >
                          <FormControlLabel
                            value={true}
                            control={<Radio />}
                            label='Sim'
                          />
                          <FormControlLabel
                            value={false}
                            control={<Radio />}
                            label='Não'
                          />
                        </RadioGroup>
                      </Row>
                      {packItineraryDTO[index].comeBack === true && (
                        <Row className='pl-3'>
                          <Col>
                            <p>Levantamento</p>
                            <TextField
                              type='time'
                              onBlur={(e) => {
                                updateValue(
                                  'comeBackPickUp',
                                  e.target.value,
                                  index,
                                  e
                                );
                              }}
                              defaultValue={
                                packItineraryDTO[index].comeBackPickUp
                              }
                              id={`comeBackPickUp'${index}`}
                            />
                          </Col>
                          <Col>
                            <p>Entrega</p>
                            <TextField
                              type='time'
                              onBlur={(e) => {
                                updateValue(
                                  'comeBackDropOff',
                                  e.target.value,
                                  index,
                                  e
                                );
                              }}
                              defaultValue={
                                packItineraryDTO[index].comeBackDropOff
                              }
                              id={`comeBackDropOff'${index}`}
                            />
                          </Col>
                        </Row>
                      )}
                    </Col>
                  </Row>
                );
              })}
              <Row className='mt-4'>
                <Col>
                  <p onClick={addEmptyItinerary} className='add_text'>
                    Adicionar outro itinerário
                  </p>
                </Col>
              </Row>
            </Row>
            <Row className='row_button'>
              <Button className='button' type='submit'>
                GUARDAR
              </Button>
            </Row>
          </div>
        </Form>
      </>
    );
  };
  return (
    <>
      <ModalBase
        serviceId={7}
        Component={component}
        closeButton={closeButton}
        open={open}
        onClose={onClose}
      ></ModalBase>
    </>
  );
};

export default ModalRodinhasItinerary;

import React, { useState, useEffect } from 'react';
import {
  Container,
  Col,
  Row,
  Form,
  InputGroup,
  Button,
  Pagination,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faPlus } from '@fortawesome/free-solid-svg-icons';
import { Title, Content, Styles } from '../../style/client';
import { pricingService } from '../../services/pricingService';
import Loading from 'react-fullscreen-loading';
import { PricingTable } from './Components/PricingTable';
import { useHistory } from 'react-router-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Link } from 'react-router-dom';
import { iCan } from 'src/redux/sessionReducer';
import { SweetAlertType } from 'react-bootstrap-sweetalert/dist/types';

const Pricing: React.FC = () => {
  const [promocodeList, setPromocodeList] = useState([] as any);
  const [promocodePerPage] = useState(15);
  const [isLoading, setIsLoading] = useState(true);
  const [searchFilter, setSearchFilter] = useState('');
  const [activePage, setActivePage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [filter, setFilter] = useState('');
  const history = useHistory();
  const [alertDialog, setAlertDialog] = useState(false);
  const [promocodeToDelete, setPromocodeToDelete] = useState('');
  const [alertDialogConfig, setAlertDialogConfig] = useState({} as AlertConfig);

  useEffect(() => {
    let mounted = true;
    setIsLoading(true);

    getPromoCodes(activePage, filter).then((result) => {
      if (result) {
        setPromocodeList(result.content);
        setTotalPages(result.totalPages);
        setTotalElements(
          result.totalElements !== undefined ? result.totalElements : 0
        );
      }

      if (mounted) {
        setIsLoading(false);
      }
    });

    return function cleanup() {
      mounted = false;
    };
    // eslint-disable-next-line
  }, [activePage, filter]);

  const getPromoCodes = async (activePage, filter) => {
    try {
      return pricingService.getAll(promocodePerPage, activePage, filter);
    } catch (error) {
      console.log(error);
    }
  };

  const reloadPromocodes = async () => {
    getPromoCodes(activePage, filter).then((result) => {
      if (result) {
        setPromocodeList(result.content);
        setTotalPages(result.totalPages);
        setTotalElements(
          result.totalElements !== undefined ? result.totalElements : 0
        );
      }
    });
  }
  

  const getNumberOfUsersMessage = () => {
    return totalElements + ' códigos registados';
  };

  const filterUsersIconClick = () => {
    setActivePage(0);
    setFilter(searchFilter);
  };

  const filterUsersEnterClick = (event) => {
    //Enter key up
    if (event.keyCode === 13) {
      event.preventDefault();

      setActivePage(0);
      setFilter(searchFilter);
    } else {
      setSearchFilter(event.target.value);
    }
  };

  const previousActivePage = () => {
    if (activePage - 1 >= 0) {
      setActivePage(activePage - 1);
    }
  };

  const nextActivePage = () => {
    if (activePage + 1 < totalPages) {
      setActivePage(activePage + 1);
    }
  };

  const createPaginationItem = (pageIndex, pageNumber, activePage) => {
    return (
      <Pagination.Item
        className='pagination'
        onClick={(e) => setActivePage(pageIndex)}
        key={pageIndex}
        active={pageIndex === activePage}
      >
        {pageNumber}
      </Pagination.Item>
    );
  };

  const getPaginationPages = () => {
    const delta = 1;

    let items = [] as any;
    let active = activePage;

    for (
      let i = Math.max(2, activePage - delta);
      i <= Math.min(totalPages - 1, activePage + delta);
      i++
    ) {
      items.push(createPaginationItem(i - 1, i, active));
    }

    if (activePage - delta > 2) {
      if (items.length === totalPages - 2) {
        items.unshift(createPaginationItem(1, 2, active));
      } else {
        items.unshift(<Pagination.Ellipsis className='pagination' key={`paginationpromo${Math.random()}`} />);
      }
    }

    if (activePage + delta < totalPages - 1) {
      if (items.length === totalPages - 3) {
        items.push(
          createPaginationItem(totalPages - 2, totalPages - 1, active)
        );
      } else {
        items.push(<Pagination.Ellipsis className='pagination' key={`paginationpricing${Math.random()}`}/>);
      }
    }

    // first page
    items.unshift(createPaginationItem(0, 1, active));

    if (totalPages <= 1) return items;

    // last page
    items.push(createPaginationItem(totalPages - 1, totalPages, active));

    return items;
  };

  const handleUsersPageChange = (user) => {
    history.push(`/gm/users/view/${user.id}`);
  };

  const deletePromocode = (promocode) => {
    setPromocodeToDelete(promocode.id);
    alert(
      'Apagar promocode',
      'Tem a certeza que deseja apagar este promocode?',
      'warning'
    );
  };

  const confirmPromocodeDelete = async () => {
    if (promocodeToDelete !== '' && promocodeToDelete !== undefined) {
      const result = await pricingService.edit(promocodeToDelete);
      if (result.status === 200) {
        alert('Apagar promocode', result.message, 'success');
        reloadPromocodes()
      } else {
        alert('Apagar promocode', result.message, 'error');
      }
    }
  };

  const alert = (title: String, message: String, type: SweetAlertType) => {
    setAlertDialog(true);
    setAlertDialogConfig({
      title: title,
      message: message,
      type: type,
    });
  };

  return (
    <>
      {isLoading && (
        <Loading
          loading
          background='rgba(255, 255, 255, .4)'
          loaderColor='#3498db'
        />
      )}

      <>
        <Title>Promoções</Title>

        <Content>
          <div className='content'>
            <Container fluid className='card-no-tabs'>
              <Row className='division'>
                <Col md={{ offset: 10 }}>
                  {iCan('ROLE_PRICING') && (
                    <Link to={'/pricing/create/'}>
                      <Button
                        variant='outline-info'
                        size='lg'
                        className='float-right'
                        style={Styles.button}
                        data-testid='newClientButton'
                      >
                        <FontAwesomeIcon icon={faPlus} />
                      </Button>
                    </Link>
                  )}
                </Col>
              </Row>

              <Row>
                <Col className='title'>
                  <Title data-testid='numberOfClients'>
                    {getNumberOfUsersMessage()}
                  </Title>
                </Col>
              </Row>

              <Row
                style={Styles.searchRow}
                xs={1}
                sm={1}
                md={1}
                lg={2}
                className='justify-content-center'
              >
                <Col style={Styles.searchCol}>
                  <InputGroup>
                    <Form.Control
                      data-testid='searchBar'
                      className='input'
                      type='text'
                      onKeyUp={(e) => filterUsersEnterClick(e)}
                      placeholder='Pesquise por código'
                    />
                    <div
                      className='search-icon'
                      data-testid='searchIcon'
                      onClick={(e) => filterUsersIconClick()}
                    >
                      <FontAwesomeIcon
                        style={Styles.searchIcon}
                        color='white'
                        icon={faSearch}
                      />
                    </div>
                  </InputGroup>
                </Col>
              </Row>

              <Row style={Styles.clientTable}>
                <PricingTable
                  promocodeList={promocodeList}
                  setPromocodePage={handleUsersPageChange}
                  deletePromocode={deletePromocode}
                />
              </Row>

              <Row className='justify-content-center'>
                <Pagination>
                  <Pagination.Prev
                    style={Styles.pageItem}
                    onClick={(e) => previousActivePage()}
                  />
                  {getPaginationPages()}
                  <Pagination.Next
                    style={Styles.pageItem}
                    onClick={(e) => nextActivePage()}
                  />
                </Pagination>
              </Row>
            </Container>
          </div>
        </Content>
        {alertDialog && (
          <SweetAlert
            type={alertDialogConfig?.type}
            title={alertDialogConfig?.title ?? ''}
            showCancel={false}
            showCloseButton={true}
            onCancel={() => {
              setAlertDialog(false);
            }}
            onConfirm={() => {
              setAlertDialog(false);
              if (alertDialogConfig?.type === 'warning') {
                confirmPromocodeDelete();
              }
            }}
          >
            {alertDialogConfig?.message ?? ''}.
          </SweetAlert>
        )}
      </>
    </>
  );
};

export default Pricing;
export interface AlertConfig {
  title: String;
  message: String;
  type: SweetAlertType;
}

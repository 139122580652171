import { userConstants } from "../constants/userConstants";
const initialState = {
  data: "XXXXXXXX",
  token: ""
};

function registerReducer(state = initialState, action) {
  switch (action.type) {
    case userConstants.LOGIN: {
      return {
        ...state,
        token: action.payload
      };
    }

    case userConstants.LOGOUT: {
      return {
        ...state,
        token: action.payload
      };
    }

    default:
      return state;
  }
}

export default registerReducer;

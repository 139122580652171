import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Vehicle } from './reservation.interface';

export const VehicleCard = ({ vehicle }) => {
  let _vehicle = vehicle as Vehicle;

  return (
    <div className='vehicle_card'>
      <Row>
        <Col md={3}>
          <img
            src={require('../../../assets/car_placeholder.png')}
            className='image'
          />
        </Col>
        <Col md={6}>
          <p className='name'>
            {_vehicle.brand} {_vehicle.model}
          </p>
          <p className='description'>
            Lugares: {_vehicle.seats} / Portas: {_vehicle.doors}
          </p>
          <p className='description'>Grupo: {_vehicle.group}</p>
        </Col>
        {_vehicle.dayValue && (
          <Col md={3}>
            <p className='value'>{_vehicle.dayValue} €</p>
          </Col>
        )}
      </Row>
    </div>
  );
};

export default VehicleCard;

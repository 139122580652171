import React, { useState, useEffect } from 'react';
import {
  Container,
  Col,
  Row,
  Form,
  InputGroup,
  Pagination,
} from 'react-bootstrap';
import Modal from '@material-ui/core/Modal';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { Styles } from '../../../style/client';
import { clientService } from '../../../services/clientService';
import { ClientTable } from '../../../components/Clients/ClientTable';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export const ModalClient = ({ closeButton, open, onClose }) => {
  const [clientList, setClientList] = useState([] as any);
  // const [isLoading, setIsLoading] = useState(true);
  const [searchFilter, setSearchFilter] = useState('');
  const [activePage, setActivePage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  // const [totalElements, setTotalElements] = useState(0);
  const [filter, setFilter] = useState('');

  useEffect(() => {
    let mounted = true;
    // setIsLoading(true);

    getClients(activePage, filter).then((result) => {
      if (result) {
        setClientList(result.data.content);

        setTotalPages(result.data.totalPages);
        // setTotalElements(result.data.totalElements);
      }

      if (mounted) {
        // setIsLoading(false);
      }
    });

    return function cleanup() {
      mounted = false;
    };
  }, [activePage, filter]);

  const getClients = async (activePage, filter) => {
    try {
      return clientService.getAll(5, activePage, filter);
    } catch (error) {
      console.log(error);
    }
  };

  const filterClientsIconClick = () => {
    setActivePage(0);
    setFilter(searchFilter);
  };

  const filterClientsEnterClick = (event) => {
    //Enter key up
    if (event.keyCode === 13) {
      event.preventDefault();

      setActivePage(0);
      setFilter(searchFilter);
    } else {
      setSearchFilter(event.target.value);
    }
  };

  const previousActivePage = () => {
    if (activePage - 1 >= 0) {
      setActivePage(activePage - 1);
    }
  };

  const nextActivePage = () => {
    if (activePage + 1 < totalPages) {
      setActivePage(activePage + 1);
    }
  };

  const createPaginationItem = (pageIndex, pageNumber, activePage) => {
    return (
      <Pagination.Item
        className='pagination'
        onClick={(e) => setActivePage(pageIndex)}
        key={pageIndex}
        active={pageIndex === activePage}
      >
        {pageNumber}
      </Pagination.Item>
    );
  };

  const getPaginationPages = () => {
    const delta = 1;

    let items = [] as any;
    let active = activePage;

    for (
      let i = Math.max(2, activePage - delta);
      i <= Math.min(totalPages - 1, activePage + delta);
      i++
    ) {
      items.push(createPaginationItem(i - 1, i, active));
    }

    if (activePage - delta > 2) {
      if (items.length === totalPages - 2) {
        items.unshift(createPaginationItem(1, 2, active));
      } else {
        items.unshift(<Pagination.Ellipsis className='pagination' key={`modalclient${Math.random()}`} />);
      }
    }

    if (activePage + delta < totalPages - 1) {
      if (items.length === totalPages - 3) {
        items.push(
          createPaginationItem(totalPages - 2, totalPages - 1, active)
        );
      } else {
        items.push(<Pagination.Ellipsis className='pagination' />);
      }
    }

    // first page
    items.unshift(createPaginationItem(0, 1, active));

    if (totalPages <= 1) return items;

    // last page
    items.push(createPaginationItem(totalPages - 1, totalPages, active));

    return items;
  };

  const handleClientPageChange = (client) => {
    closeButton();
    onClose(client);
  };
  return (
    <Modal open={open} onClose={onClose} className='princing_modal'>
      <Container className='princing_modal_container'>
        <Row className='justify-content-end'>
          <button
            onClick={() => {
              closeButton();
            }}
            className='button_close'
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </Row>
        <Row
          style={Styles.searchRow}
          xs={1}
          sm={1}
          md={1}
          lg={2}
          className='justify-content-center'
        >
          <Col style={Styles.searchCol}>
            <InputGroup>
              <Form.Control
                data-testid='searchBar'
                className='input'
                type='text'
                onKeyUp={(e) => filterClientsEnterClick(e)}
                placeholder='Pesquise por cliente'
              />
              <div
                className='search-icon'
                data-testid='searchIcon'
                onClick={(e) => filterClientsIconClick()}
              >
                <FontAwesomeIcon
                  style={Styles.searchIcon}
                  color='white'
                  icon={faSearch}
                />
              </div>
            </InputGroup>
          </Col>
        </Row>

        <Row style={Styles.clientTable}>
          <ClientTable
            clientList={clientList}
            setClientPage={handleClientPageChange}
          />
        </Row>

        <Row className='justify-content-center'>
          <Pagination>
            <Pagination.Prev
              style={Styles.pageItem}
              onClick={(e) => previousActivePage()}
            />
            {getPaginationPages()}
            <Pagination.Next
              style={Styles.pageItem}
              onClick={(e) => nextActivePage()}
            />
          </Pagination>
        </Row>
      </Container>
    </Modal>
  );
};

export default ModalClient;

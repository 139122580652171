import React, { useState, useEffect, useRef } from 'react';
import { Form, Container, Row, Col, Image, Button } from 'react-bootstrap';
import Modal from '@material-ui/core/Modal';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { contactService } from 'src/services/contactService';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export const ModalContact = ({ closeButton, open, onClose, product }) => {
  const [text, setText] = useState('');
  const [alertDialog, setAlertDialog] = useState(false);
  const [alertConfig, setAlertConfig] = useState({
    title: '',
    message: '',
    redirect: false,
    redirectURL: '',
  });

  const send = async () => {
    if (text !== '') {
      let data = { product: product, text: text };
      let response = await contactService
        .requestContact(JSON.stringify(data))
        .then(async (result) => {
          if(result.status === 200) {
            setAlertConfig({
                title: 'Sucesso',
                message: result.message,
                redirect: false,
                redirectURL: '',
              });
              setAlertDialog(true);
              setText('');
              closeButton(8);
          } else {
            setAlertConfig({
                title: 'Erro',
                message: 'Erro ao enviar a mensagem',
                redirect: false,
                redirectURL: '',
              });
              setAlertDialog(true);
          }
        })
        .catch((e) => {
          setAlertConfig({
            title: 'Erro',
            message: 'Erro ao enviar a mensagem',
            redirect: false,
            redirectURL: '',
          });
          setAlertDialog(true);
        });
    }
  };

  return (
    <div>
      <Modal open={open} onClose={onClose} className='container_modal_contact'>
        <Container
          className='modal_base'
          style={{ backgroundColor: '#d2f2fd64' }}
        >
          <Row className='justify-content-end'>
            <button
              onClick={() => {
                closeButton(8);
                setText('');
              }}
              className='button_close'
            >
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </Row>
          <Row className='d-flex justify-content-center mt-2'>
            <h4 style={{ color: '#000000' }}>Entrar em contacto</h4>
          </Row>
          <Row className='d-flex justify-content-center mt-4 mb-4'>
            <Form.Control
              as='textarea'
              className='textarea_contact'
              defaultValue={text}
              onChange={(e) => setText(e.target.value)}
            />
          </Row>
          <Row className='row_button'>
            <Button className='button' onClick={send}>
              ENVIAR
            </Button>
          </Row>
        </Container>
      </Modal>
      <Dialog
        open={alertDialog}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>{alertConfig.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            {alertConfig.message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setAlertDialog(false);
            }}
            color='primary'
            autoFocus
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ModalContact;

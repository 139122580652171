//USER
import Dashboard from '../screens/User/Dashboard/Dashboard';
import Subscriptions from '../screens/User/Subscriptions/Subscriptions';
import Documents from '../screens/User/Documents/Documents';
import Help from '../screens/Help/Help';
import Invoices from '../screens/Invoices/Invoices';
import Reservations from '../screens/User/Reservations/Reservations';

const routes: MyRoute[] = [
  {
    path: '/user/dashboard',
    exact: true,
    component: Dashboard,
    role: 'ROLE_READ_USER_DASHBOARD',
    auth: true,
  },
  {
    path: '/user/subscriptions',
    exact: true,
    component: Subscriptions,
    role: 'ROLE_PARTICULAR_USER',
    auth: true,
  },
  {
    path: '/user/reservations',
    exact: true,
    component: Reservations,
    role: 'ROLE_PARTICULAR_USER',
    auth: true,
  },
  {
    path: '/user/documents',
    exact: true,
    component: Documents,
    role: 'ROLE_PARTICULAR_USER',
    auth: true,
  },
  {
    path: '/user/help',
    exact: true,
    component: Help,
    role: 'ROLE_PARTICULAR_USER',
    auth: true,
  },
  {
    path: '/user/invoices',
    exact: true,
    component: Invoices,
    role: 'ROLE_GET_USER_INVOICES',
    auth: true,
  },
];

interface MyRoute {
  path: string;
  exact: boolean;
  component: React.FC<{}>;
  role: string;
  auth: boolean;
}

export default routes;

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Form, Col, Row, Modal, Button, Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowUp,
  faTrashAlt,
  faPowerOff,
  faEdit,
} from '@fortawesome/free-solid-svg-icons';

import { motion } from 'framer-motion';
import Loading from 'react-fullscreen-loading';
import SweetAlert from 'react-bootstrap-sweetalert';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Title, Content } from '../../../style/content';
import BadgeComponent from '../../../components/Badge';
import HistoryData from './HistoryData';
import { productService } from '../../../services/productService';
import dayjs from 'dayjs';
import { useParams, Link, useHistory } from 'react-router-dom';
import { iCan } from '../../../redux/sessionReducer';
import {
  animationInvisible,
  animationVariants,
  animationVisible,
} from 'src/components/Animations/animations';

const Bedriven: React.FC = () => {
  const [product, setProduct] = useState({
    active: false,
    additionalData: '',
    summaryDescription: '',
    additionalPayments: '',
    carCategory: '',
    carDisplacement: '',
    carDoors: 0,
    carFuel: '',
    carHorses: '',
    carOther: '',
    carPlaces: 0,
    codeIdentification: '',
    definitions: [{ balanceValue: '', packValue: Number() }],
    description: '',
    gracePeriod: '',
    id: '',
    informationBase: '',
    make: '',
    name: '',
    productState: '',
    serviceId: 0,
    subscriptionAvailability: new Date(),
    subscriptionValidate: null as any,
    tax: Number(),
    uuidImageFile: '',
    validations: '',
    imageName: '',
  });
  const [isEdit, setIsEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [showDeactivateModal, setShowDeactivateModal] = useState(false);
  const [showDeactivate, setShowDeactivate] = useState(false);
  const [showRemove, setShowRemove] = useState(false);
  const [errorDialog, setErrorDialog] = useState(false);
  const [successDialog, setSuccessDialog] = useState(false);
  const [definitionsList, setDefinitionsList] = useState([] as any);
  const [alertMsg, setAlertMsg] = useState('');
  const [image, setImage] = useState({
    preview: '',
    raw: '',
    base64: '' as any,
    name: '',
  });
  const [userData, setUserData] = useState({});
  const { productId } = useParams();
  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);

    const getProduct = async (companyName: string, productId: string) => {
      setIsLoading(true);

      try {
        const result = await productService.getProductByCompany(
          companyName,
          productId
        );
        setProduct({ ...product, ...result.data });

        setIsEdit(result.data.productState === 'PUBLISHED' ? true : false);

        setDefinitionsList(
          createDefinitionsList(
            result.data.definitions.filter((item) => item).length
          )
        );

        setUserData({
          userVerified: result.data.userVerified,
          dateCreated: result.data.dateCreated,
          dateLastUpdated: result.data.dateLastUpdated,
          userPublished: result.data.userPublished,
          userCreated: result.data.userCreated,
        });
      } catch (error) {
        setErrorDialog(true);
      } finally {
        setIsLoading(false);
      }
    };

    if (productId) getProduct('bedriven', productId);

    const { length } = product.definitions;

    if (!productId) setDefinitionsList(createDefinitionsList(length));
  }, []);

  const createDefinitionsList = (length: number) => {
    return Array.from(
      { length: 10 - length },
      () =>
        new Object({
          balanceValue: '',
          packValue: Number(),
        })
    );
  };

  const fileToBase64 = (filename, filepath) => {
    return new Promise((resolve) => {
      var file = new File([filename], filepath);
      var reader = new FileReader();
      // Read file content on file loaded event
      reader.onload = function (event: any) {
        resolve(event.target.result);
      };

      // Convert data to base64
      reader.readAsDataURL(file);
    });
  };

  const getBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleChange = async (e) => {
    const file = e.target.files[0];

    const fileSize = file.size / 1024 / 1024;

    if (fileSize > 2) {
      setErrorDialog(true);
      setAlertMsg('Arquivo deve ter menos que 2 MB');
      return false;
    }

    getBase64(file).then((data) => {
      setImage({
        preview: URL.createObjectURL(file),
        raw: file,
        base64: data,
        name: file.name,
      });
    });
  };

  const getProduct = async (companyName: string, productId: string) => {
    setIsLoading(true);

    try {
      const result = await productService.getProductByCompany(
        companyName,
        productId
      );
      setProduct({ ...product, ...result.data });

      setDefinitionsList(
        createDefinitionsList(
          result.data.definitions.filter((item) => item).length
        )
      );

      setUserData({
        userVerified: result.data.userVerified,
        dateCreated: result.data.dateCreated,
        dateLastUpdated: result.data.dateLastUpdated,
        userPublished: result.data.userPublished,
        userCreated: result.data.userCreated,
      });
    } catch (error) {
      setErrorDialog(true);
    } finally {
      setIsLoading(false);
    }
  };

  const removeProduct = async (productId: string) => {
    setShowRemoveModal(false);
    setIsLoading(true);

    try {
      const response = await productService.removeProductByCompany(
        'bedriven',
        productId
      );
      setSuccessDialog(true);
      setAlertMsg(response.data.message);
    } catch (error) {
      setErrorDialog(true);
      setAlertMsg(error.response.data.message);
    } finally {
      setIsLoading(false);
    }
  };

  const changeStatusProduct = async (productId: string) => {
    setShowDeactivateModal(false);
    setIsLoading(true);

    setProduct({ ...product, active: !product.active });

    try {
      const response = await productService.changeProductByCompany(
        'bedriven',
        productId,
        !product.active
      );
      setProduct({
        ...product,
        active: !product.active,
        productState: product.active ? 'INACTIVE' : 'PUBLISHED',
      });
      setSuccessDialog(true);
      setAlertMsg(response.data.message);
    } catch (error) {
      setErrorDialog(true);
      setAlertMsg(error.response.data.message);
    } finally {
      setIsLoading(false);
    }
  };

  const update = async (productForm: any) => {
    setIsLoading(true);

    const data = { ...productForm };

    /**
     * Filter all falsy values ( "", 0, false, null, undefined )
     */
    Object.keys(data).forEach(
      (key) =>
        (data[key] === '' || data[key] === null || data[key] === 0) &&
        delete data[key]
    );

    /*
     * Merge definitions
     */
    const filteredDefinitions = definitionsList.reduce((map, definition) => {
      if (definition.balanceValue && definition.packValue) {
        map.push(definition);
      }

      return map;
    }, []);

    const list = [...productForm.definitions, ...filteredDefinitions];
    data.definitions = list;

    delete data.dateLastUpdated;
    delete data.dateCreated;

    if (!image.name) {
      delete data['imageName'];
      delete data['imageFile'];
    } else {
      data['imageName'] = image.name;
      data['imageFile'] = image.base64;
    }

    try {
      const result = await productService.updateByCompany(
        'bedriven',
        data,
        productForm.id
      );
      setAlertMsg(result.data.message);
      setSuccessDialog(true);
      getProduct('bedriven', productId);
    } catch (error) {
      // setAlertMsg(error.response.data.message)
      console.log(error);
      setErrorDialog(true);
    } finally {
      setIsLoading(false);
    }
  };

  const publish = async (productForm: any) => {
    setIsLoading(true);

    const data = { ...productForm };

    /**
     * Filter all falsy values ( "", 0, false, null, undefined )
     */
    Object.keys(data).forEach(
      (key) =>
        (data[key] === '' || data[key] === null || data[key] === 0) &&
        delete data[key]
    );
    delete data['dateCreated'];
    delete data['dateLastUpdated'];

    data.productState = 'PUBLISHED';

    if (!image.name) {
      delete data['fileExtension'];
      delete data['uuidImageFile'];
      delete data['imageFile'];
      delete data['imageName'];
    }

    try {
      const result = await productService.updateByCompany(
        'bedriven',
        data,
        data.id
      );
      setProduct({ ...product, productState: 'PUBLISHED' });
      setAlertMsg(result.data.message);
      setSuccessDialog(true);
    } catch (error) {
      setAlertMsg(error.response.data.message);
      setErrorDialog(true);
    } finally {
      setIsLoading(false);
    }
  };

  const create = async (productForm: any, status: string) => {
    setIsLoading(true);

    const data = { ...productForm };

    /*
     * Merge definitions
     */
    const filteredDefinitions = definitionsList.reduce((map, definition) => {
      if (definition.balanceValue && definition.packValue) {
        map.push(definition);
      }

      return map;
    }, []);

    const list = [...productForm.definitions, ...filteredDefinitions];
    data.definitions = list;

    /**
     * Change status
     */
    data.productState = status;

    /**
     * Filter all falsy values ( "", 0, false, null, undefined )
     */
    Object.keys(data).forEach(
      (key) =>
        (data[key] === '' || data[key] === null || data[key] === 0) &&
        delete data[key]
    );
    // data.subscriptionAvailability = dayjs(data.subscriptionAvailability).format('MM-DD-YYYY')
    // data.subscriptionValidate = dayjs(data.subscriptionValidate).format('MM-DD-YYYY')
    delete data.dateLastUpdated;
    delete data.dateCreated;

    if (image.name) {
      data['imageName'] = image.name;
      data['imageFile'] = image.base64;
    }

    try {
      const result = await productService.create('bedriven', data);
      setSuccessDialog(true);
      setAlertMsg(result.data.message);
    } catch (error) {
      setErrorDialog(true);
      setAlertMsg(error.response.data.message);
    } finally {
      setIsLoading(false);
    }
  };

  const updateValue = (property: string, value: any) => {
    if (+value !== +value || value === 'e') return false;

    const newProduct = { ...product };

    newProduct[property] = value;

    setProduct({ ...product, ...newProduct });
  };

  const updateDefintion = (
    property: string,
    i: any,
    value: string,
    target: string
  ) => {
    if (+value !== +value || value === 'e') return false;

    if (target === 'own') {
      const newProduct = { ...product };

      newProduct.definitions[i][property] = value;

      setProduct({ ...product, ...newProduct });
    } else {
      definitionsList[i][property] = value;

      setDefinitionsList([...definitionsList]);
    }
  };

  const checkFields = () => {
    const photo = product.uuidImageFile || image.name;

    const fields = [
      product.name,
      product.tax,
      product.definitions[0].balanceValue,
      product.definitions[0].packValue,
      product.subscriptionAvailability,
      product.subscriptionValidate,
      photo,
    ];

    return fields.some((item) => !item);
  };

  return (
    <>
      <Title>Bedriven</Title>

      <Content>
        <Container
          fluid
          className='card-no-tabs'
          style={{ paddingLeft: 80, paddingBottom: 50 }}
        >
          <Row
            style={{
              textAlign: 'center',
              font: 'normal normal normal 18px/22px Roboto',
              letterSpacing: '0px',
              color: '#878787',
              opacity: 1,
              marginLeft: '8px',
              marginTop: '51px',
            }}
          >
            <Link className='color-back' to={'/products'}>
              <p>
                <u>&#60; Voltar</u>
              </p>
            </Link>
          </Row>
          <Wrapper>
            {isLoading && (
              <Loading
                loading
                background='rgba(255, 255, 255, .4)'
                loaderColor='#3498db'
              />
            )}

            {successDialog && (
              <SweetAlert
                success
                title='Sucesso!'
                onConfirm={() => {
                  setSuccessDialog(false);
                  setAlertMsg('');
                  history.push('/products');
                }}
              >
                {alertMsg}
              </SweetAlert>
            )}

            {errorDialog && (
              <SweetAlert
                danger
                title='Erro!'
                onConfirm={() => {
                  setErrorDialog(false);
                  setAlertMsg('');
                }}
              >
                {alertMsg}
              </SweetAlert>
            )}
            <Modal
              backdrop='static'
              show={showDeactivateModal}
              size='lg'
              keyboard
              onHide={() => setShowDeactivateModal(false)}
            >
              <Modal.Header>
                <h2>Produto Bedriven - {product.name}</h2>
              </Modal.Header>
              <Modal.Body>
                {product.uuidImageFile && (
                  <img src={product.uuidImageFile} alt='' />
                )}
                <FontAwesomeIcon icon={faPowerOff} />
                <h2 className='pt-5'>
                  Tem certeza que pretende{' '}
                  {product.active ? 'desativar' : 'ativar'}?
                </h2>
                <p className='disclaimer'>
                  Poderá reverter a sua ação mais tarde.
                </p>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant='remove'
                  onClick={() => changeStatusProduct(productId)}
                >
                  SIM, {product.active ? 'DESATIVAR' : 'DESATIVAR'}
                </Button>
                <Button
                  variant='back'
                  onClick={() => setShowDeactivateModal(false)}
                >
                  NÃO, VOLTAR
                </Button>
              </Modal.Footer>
            </Modal>

            <Modal
              backdrop='static'
              show={showRemoveModal}
              size='lg'
              keyboard
              onHide={() => setShowRemoveModal(false)}
            >
              <Modal.Header>
                <h2>Produto Bedriven - {product.name}</h2>
              </Modal.Header>
              <Modal.Body>
                {product.uuidImageFile && (
                  <img src={product.uuidImageFile} alt='' />
                )}
                <FontAwesomeIcon icon={faTrashAlt} className='remove' />
                <h2 className='pt-5'>Tem certeza que pretende eliminar?</h2>
                <p className='disclaimer'>Esta ação não pode ser revertida.</p>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant='remove'
                  onClick={() => {
                    removeProduct(productId);
                  }}
                >
                  SIM, ELIMINAR
                </Button>
                <Button
                  variant='back'
                  onClick={() => setShowRemoveModal(false)}
                >
                  NÃO, VOLTAR
                </Button>
              </Modal.Footer>
            </Modal>

            {productId && (
              <div className='pb-5'>
                <Logo>
                  <img
                    src={require(`../../../assets/logos/bedriven-xs-logo-on-2x.png`)}
                    alt=''
                  />
                  {productId && (
                    <div className='actions'>
                      <BadgeComponent badge={product.productState} />
                      {(product.productState === 'PUBLISHED' ||
                        product.productState === 'INACTIVE') && (
                        <div
                          className='wrapper text-center'
                          onMouseEnter={() => setShowDeactivate(true)}
                          onMouseLeave={() => setShowDeactivate(false)}
                        >
                          <FontAwesomeIcon
                            icon={faPowerOff}
                            onClick={() => setShowDeactivateModal(true)}
                            onMouseEnter={() => setShowDeactivate(true)}
                          />

                          <motion.div
                            initial={animationVisible}
                            animate={showDeactivate ? 'visible' : 'invisible'}
                            exit={animationInvisible}
                            transition={{ duration: 0.3 }}
                            variants={animationVariants}
                          >
                            <p className='text-deactivate text-center'>
                              {product.active
                                ? 'desativar produto'
                                : 'ativar produto'}
                            </p>
                          </motion.div>
                        </div>
                      )}

                      {product.productState === 'DRAFT' && (
                        <div
                          className='wrapper remove'
                          onMouseEnter={() => setShowRemove(true)}
                          onMouseLeave={() => setShowRemove(false)}
                        >
                          <FontAwesomeIcon
                            icon={faTrashAlt}
                            onClick={() => setShowRemoveModal(true)}
                            className='remove'
                          />
                          <motion.div
                            initial={animationVisible}
                            animate={showRemove ? 'visible' : 'invisible'}
                            exit={animationInvisible}
                            transition={{ duration: 0.3 }}
                            variants={animationVariants}
                          >
                            <p className='text-remove'>eliminar</p>
                          </motion.div>
                        </div>
                      )}
                    </div>
                  )}
                </Logo>
              </div>
            )}

            {!productId && (
              <div className='form'>
                <Row>
                  <Col xs={2}>
                    <img
                      src={require('../../../assets/logos/bedriven-2x-logo.png')}
                      alt='guerin'
                    />
                  </Col>
                </Row>
              </div>
            )}

            {productId && <HistoryData userData={userData} />}

            <div className='form'>
              <h6>1. Informação identificativa</h6>
              <div className='inputs'>
                <Form>
                  <Row>
                    <Col>
                      <Form.Label>Nome*</Form.Label>
                      <Form.Control
                        readOnly={isEdit}
                        onChange={(e) =>
                          setProduct({ ...product, name: e.target.value })
                        }
                        value={product.name}
                      />
                    </Col>
                    <Col>
                      <Form.Label>Código identificação</Form.Label>
                      <Form.Control
                        readOnly={true}
                        onChange={(e) =>
                          setProduct({ ...product, id: e.target.value })
                        }
                        value={product.id}
                      />
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>

            <div className='form'>
              <h6>2. Recursos gráficos</h6>
              <div className='inputs section-upload'>
                <div>
                  {image.preview ? (
                    <img src={image.preview} />
                  ) : (
                    <img src={product.uuidImageFile} />
                  )}
                </div>
                {((productId &&
                  (product.productState === 'DRAFT' ||
                    product.productState === 'PUBLISHED')) ||
                  !productId) && (
                  <Form onSubmit={(e) => e.preventDefault()}>
                    <input
                      type='file'
                      id='upload-button'
                      style={{ display: 'none' }}
                      accept='image/x-png, image/jpeg'
                      onChange={handleChange}
                    />
                    <label htmlFor='upload-button'></label>
                    <label htmlFor='upload-button'>
                      {/* <input type="button" className="big-btn" onClick={e => e.preventDefault()}>
                                        <label htmlFor="upload-button">
                                            <FontAwesomeIcon icon={faArrowUp} />
                                        </label>
                                    </input> */}
                      <div className='big-btn btn-fix-upload'>
                        <FontAwesomeIcon icon={faArrowUp} />
                      </div>
                    </label>
                    <p className='text-center pt-3'>
                      Imagens em formato png/jpeg. Limite: 2mb.
                    </p>
                  </Form>
                )}
              </div>
            </div>

            <div className='form'>
              <h6>3. Detalhes do produto</h6>
              <div className='inputs'>
                <Form>
                  <Row>
                    <Col>
                      <label>Informaçāo base</label>
                      <Form.Control
                        as='textarea'
                        value={product.informationBase}
                        rows={4}
                        readOnly={isEdit}
                        onChange={(e) =>
                          setProduct({
                            ...product,
                            informationBase: e.target.value,
                          })
                        }
                      />
                    </Col>
                  </Row>
                  <Row className='pt-3'>
                    <Col>
                      <label>Descriçāo resumida</label>
                      <Form.Control
                        as='textarea'
                        value={product.summaryDescription}
                        rows={4}
                        readOnly={isEdit}
                        onChange={(e) =>
                          setProduct({
                            ...product,
                            summaryDescription: e.target.value,
                          })
                        }
                      />
                    </Col>
                  </Row>
                  <Row className='pt-3'>
                    <Col>
                      <label>Descriçāo completa</label>
                      <Form.Control
                        as='textarea'
                        value={product.description}
                        rows={4}
                        readOnly={isEdit}
                        onChange={(e) =>
                          setProduct({
                            ...product,
                            description: e.target.value,
                          })
                        }
                      />
                    </Col>
                  </Row>
                  <Row className='pt-3'>
                    <Col>
                      <Form.Label>Disponibilidade para subscrição*</Form.Label>
                      <Col>
                        <DatePicker
                          selected={
                            product.subscriptionAvailability
                              ? dayjs(product.subscriptionAvailability).toDate()
                              : new Date()
                          }
                          dateFormat='dd/MM/yyyy'
                          className='qa-datepicker-availability'
                          disabled={isEdit}
                          onChange={(date) =>
                            date &&
                            setProduct({
                              ...product,
                              subscriptionAvailability: new Date(
                                date.toString()
                              ),
                            })
                          }
                        />
                      </Col>
                    </Col>
                    <Col>
                      <Form.Label>Validade do produto*</Form.Label>
                      <Col>
                        <DatePicker
                          selected={
                            product.subscriptionValidate
                              ? new Date(product.subscriptionValidate)
                              : null
                          }
                          dateFormat='dd/MM/yyyy'
                          className='qa-datepicker-validate'
                          disabled={isEdit}
                          onChange={(date) =>
                            date &&
                            setProduct({
                              ...product,
                              subscriptionValidate: new Date(date.toString()),
                            })
                          }
                        />
                      </Col>
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>

            <div className='form'>
              <h6>4. Definiçāo do produto</h6>
              <div className='inputs'>
                <Form>
                  <div className='product-definition'>
                    <div className='header'>
                      <p className='title'>SALDO EM VIAGENS</p>
                      <p className='title'>VALOR DO PACK</p>
                    </div>

                    {product.definitions.map((definition, i) => {
                      return (
                        <Row key={i}>
                          <div className='container'>
                            <Col xs={5}>
                              <Form.Control
                                value={product.definitions[i].balanceValue}
                                disabled={isEdit}
                                onChange={(e) =>
                                  updateDefintion(
                                    'balanceValue',
                                    i,
                                    e.target.value.replace(/\D/g, ''),
                                    'own'
                                  )
                                }
                              />
                            </Col>
                            <Col xs={5}>
                              <Form.Control
                                value={
                                  product.definitions[i].packValue
                                    ? product.definitions[i].packValue
                                    : ''
                                }
                                disabled={isEdit}
                                onChange={(e) =>
                                  updateDefintion(
                                    'packValue',
                                    i,
                                    e.target.value,
                                    'own'
                                  )
                                }
                              />
                            </Col>
                          </div>
                        </Row>
                      );
                    })}

                    {definitionsList.map((definition, i) => {
                      return (
                        <Row key={i}>
                          <div className='container'>
                            <Col xs={5}>
                              <Form.Control
                                value={definitionsList[i].balanceValue}
                                disabled={isEdit}
                                onChange={(e) =>
                                  updateDefintion(
                                    'balanceValue',
                                    i,
                                    e.target.value.replace(/\D/g, ''),
                                    'definitionsList'
                                  )
                                }
                                className='ml-2'
                              />
                            </Col>
                            <Col xs={5}>
                              <Form.Control
                                value={
                                  definitionsList[i].packValue
                                    ? definitionsList[i].packValue
                                    : ''
                                }
                                disabled={isEdit}
                                onChange={(e) =>
                                  updateDefintion(
                                    'packValue',
                                    i,
                                    e.target.value,
                                    'definitionList'
                                  )
                                }
                                className='ml-2'
                                type='number'
                              />
                            </Col>
                          </div>
                        </Row>
                      );
                    })}
                  </div>

                  <Row className='pt-3'>
                    <Col xs={3}>
                      <Form.Label>Imposto (Taxa aplicável)*</Form.Label>
                      <Form.Control
                        value={product.tax ? product.tax : ''}
                        disabled={isEdit}
                        onChange={(e) => updateValue('tax', e.target.value)}
                        className='ml-2'
                      />
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>

            <div className='form'>
              <h6>5. Regras de subscrição</h6>
              <div className='inputs'>
                <Form>
                  <Row>
                    <Col>
                      <label>Dados adicionais necessarios</label>
                      <Form.Control
                        as='textarea'
                        rows={4}
                        readOnly={isEdit}
                        value={product.additionalData}
                        onChange={(e) =>
                          setProduct({
                            ...product,
                            additionalData: e.target.value,
                          })
                        }
                      />
                    </Col>
                  </Row>
                  <Row className='pt-3'>
                    <Col>
                      <label>Validaçōes</label>
                      <Form.Control
                        as='textarea'
                        rows={4}
                        readOnly={isEdit}
                        value={product.validations}
                        onChange={(e) =>
                          setProduct({
                            ...product,
                            validations: e.target.value,
                          })
                        }
                      />
                    </Col>
                  </Row>

                  <Row className='pt-3'>
                    <Col>
                      <label>Pagamentos adicionais</label>
                      <Form.Control
                        as='textarea'
                        rows={4}
                        readOnly={isEdit}
                        value={product.additionalPayments}
                        onChange={(e) =>
                          setProduct({
                            ...product,
                            additionalPayments: e.target.value,
                          })
                        }
                      />
                    </Col>
                  </Row>

                  <Row className='pt-3'>
                    <Col>
                      <label>
                        Período de carência para alterações/cancelamento
                      </label>
                      <Form.Control
                        as='textarea'
                        rows={4}
                        readOnly={isEdit}
                        value={product.gracePeriod}
                        onChange={(e) =>
                          setProduct({
                            ...product,
                            gracePeriod: e.target.value,
                          })
                        }
                      />
                    </Col>
                  </Row>
                </Form>
              </div>

              <div className='text-center'>
                {!productId && !isEdit && iCan('ROLE_CREATE_PRODUCT') && (
                  <button
                    className='big-btn'
                    onClick={() => setIsEdit(!isEdit)}
                  >
                    CONTINUAR
                  </button>
                )}
                {productId &&
                  product.productState === 'DRAFT' &&
                  iCan('ROLE_CREATE_PRODUCT') && (
                    <button className='big-btn' onClick={() => update(product)}>
                      GUARDAR ALTERAÇŌES
                    </button>
                  )}
                {productId &&
                  product.productState === 'DRAFT' &&
                  iCan('ROLE_CREATE_PRODUCT') && (
                    <button
                      className={`big-btn ml-4 ${
                        checkFields() ? 'opacity-3' : ''
                      }`}
                      onClick={() => publish(product)}
                      disabled={checkFields()}
                    >
                      PUBLICAR
                    </button>
                  )}
                {!productId && isEdit && iCan('ROLE_CREATE_PRODUCT') && (
                  <button
                    className='big-btn'
                    onClick={() => {
                      create(product, 'DRAFT');
                    }}
                  >
                    GUARDAR RASCUNHO
                  </button>
                )}
                {!productId && isEdit && iCan('ROLE_CREATE_PRODUCT') && (
                  <button
                    className={`big-btn ml-4 ${
                      checkFields() ? 'opacity-3' : ''
                    }`}
                    onClick={() => create(product, 'PUBLISHED')}
                    disabled={checkFields()}
                  >
                    PUBLICAR
                  </button>
                )}
              </div>

              {!productId && isEdit && iCan('ROLE_CREATE_PRODUCT') && (
                <div className='text-center pt-5'>
                  <p className='back-edit' onClick={() => setIsEdit(!isEdit)}>
                    Voltar a edição
                  </p>
                </div>
              )}
            </div>
          </Wrapper>
        </Container>
      </Content>
    </>
  );
};

const Logo = styled.div`
  padding: 0 80px;
  display: flex;
  justify-content: space-between;
  max-width: 87%;

  .actions {
    height: 20px;
    display: flex;
    width: 200px;
    justify-content: space-between;

    p {
      font-weight: bold;
      position: relative;
      right: 10px;
    }

    .text-remove {
      color: #ff0000;
      right: 20px;
    }

    .remove:hover {
      color: #ff0000;
    }

    .text-deactivate {
      position: relative;
      right: 20px;
    }

    .edit:hover {
      color: #2292cc;
    }

    .text-edit {
      color: #2292cc;
    }

    svg {
      cursor: pointer;
    }

    .wrapper {
      width: 50px;
    }
  }
`;

const Wrapper = styled.div`
  padding: 0 20px;
  display: flex;
  flex-direction: column;

  .header_guerin {
    width: 100%;
  }

  .no-border {
    border: 0 !important;
  }

  .product-definition {
    width: 100%;
    .header {
      display: flex;
      justify-content: space-evenly;

      .title {
        background-color: #2292cc;
        color: #fff;
        padding: 30px;
        border-radius: 30px;
        font-weight: bold;
      }
    }

    .container {
      width: 70%;
      border-bottom: 1px solid #ccc;
      padding: 10px;
      display: flex;
      justify-content: space-between;
    }
  }

  .form {
    padding: 50px 80px;

    .btn-fix-upload {
      text-align: center;
      padding-top: 19px;
    }

    img {
      width: 200px;
    }

    .back-edit {
      font-weight: bold;
      text-decoration: underline;
      cursor: pointer;
    }

    h6 {
      border-bottom: 1px solid #eee;
      text-indent: 50px;
      color: #2292cc;
    }

    .section-upload {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;

      img {
        padding: 10px;
      }
    }

    .inputs {
      padding: 20px 0;

      .upload {
        width: 200px;
        height: 100px;
        border-radius: 20px;
        box-shadow: 3px 15px 5px -6px rgba(158, 195, 240, 0.62);
      }
    }
  }
`;

export default Bedriven;

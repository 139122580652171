//GESTOR DE MOBILIDADE
import Dashboard from '../screens/GM/Dashboard/Dashboard';
import Groups from '../screens/GM/Groups/Groups';
import GroupsCreate from '../screens/GM/Groups/GroupsCreate';
import GroupsUpdate from '../screens/GM/Groups/GroupsUpdate';
import Help from '../screens/Help/Help';
import Invoices from '../screens/Invoices/Invoices';
import Users from '../screens/GM/Users/Users';
import UsersCreate from '../screens/GM/Users/UsersCreate';
import UsersCreateOne from '../screens/GM/Users/UsersCreateOne';
import UsersCreateMany from '../screens/GM/Users/UsersCreateMany';
import UsersView from '../screens/GM/Users/UsersView';
import Documents from '../screens/GM/Documents/Documents';
import Services from '../screens/GM/Services/Services';
import ServicesGroup from '../screens/GM/Services/ServicesGroup';
import ServicesUser from '../screens/GM/Services/ServicesUser';
import ServicesConfig from '../screens/GM/Services/ServicesConfig';

const routes: MyRoute[] = [
  {
    path: '/gm',
    exact: true,
    component: Dashboard,
    role: 'ROLE_USER_SYSTEM',
    auth: true,
  },
  {
    path: '/gm/dashboard',
    exact: true,
    component: Dashboard,
    role: 'ROLE_USER_SYSTEM',
    auth: true,
  },
  {
    path: '/gm/groups',
    exact: true,
    component: Groups,
    role: 'ROLE_READ_GROUP',
    auth: true,
  },
  {
    path: '/gm/groups/create',
    exact: true,
    component: GroupsCreate,
    role: 'ROLE_CREATE_GROUP',
    auth: true,
  },
  {
    path: '/gm/groups/update',
    exact: true,
    component: GroupsUpdate,
    role: 'ROLE_UPDATE_GROUP',
    auth: true,
  },
  {
    path: '/gm/help',
    exact: true,
    component: Help,
    role: 'ROLE_READ_MOBILITY_HELP',
    auth: true,
  },
  {
    path: '/gm/invoices',
    exact: true,
    component: Invoices,
    role: 'ROLE_GET_MOBILITY_MANAGER_INVOICES',
    auth: true,
  },
  {
    path: '/gm/users',
    exact: true,
    component: Users,
    role: 'ROLE_READ_USER',
    auth: true,
  },
  {
    path: '/gm/users/create',
    exact: true,
    component: UsersCreate,
    role: 'ROLE_CREATE_USER',
    auth: true,
  },
  {
    path: '/gm/users/create/one',
    exact: true,
    component: UsersCreateOne,
    role: 'ROLE_CREATE_USER',
    auth: true,
  },
  {
    path: '/gm/users/create/many',
    exact: true,
    component: UsersCreateMany,
    role: 'ROLE_CREATE_USER',
    auth: true,
  },
  {
    path: '/gm/users/view/:id',
    exact: true,
    component: UsersView,
    role: 'ROLE_READ_USER',
    auth: true,
  },
  {
    path: '/gm/documents',
    exact: true,
    component: Documents,
    role: 'ROLE_USER_SYSTEM',
    auth: true,
  },
  {
    path: '/gm/services',
    exact: true,
    component: Services,
    role: 'ROLE_READ_SERVICE_MANAGEMENT',
    auth: true,
  },
  {
    path: '/gm/services/user',
    exact: true,
    component: ServicesUser,
    role: 'ROLE_CREATE_SERVICE_MANAGEMENT',
    auth: true,
  },
  {
    path: '/gm/services/group',
    exact: true,
    component: ServicesGroup,
    role: 'ROLE_CREATE_SERVICE_MANAGEMENT',
    auth: true,
  },
  {
    path: '/gm/services/user/config',
    exact: true,
    component: ServicesConfig,
    role: 'ROLE_CREATE_SERVICE_MANAGEMENT',
    auth: true,
  },
];

interface MyRoute {
  path: string;
  exact: boolean;
  component: React.FC<{}>;
  role: string;
  auth: boolean;
}

export default routes;

import api from '../utils/api';

export const movementsService = {
  DownloadFile
};

async function DownloadFile(startDate, endDate) {
  const response = await api.apiPrivate
    .get(`api/comercial-profile/movements?beginDate=${startDate}&endDate=${endDate}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        console.log(error.request);
        return error.request;
      }
      throw error;
    });
  return response;
}

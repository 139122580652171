import React, { useState, useEffect, useRef } from 'react';
import { Form, Container, Row, Col, Image, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Title, Content, Styles } from '../../../style/client';
import { userService } from 'src/services/GM/userService';
import { SweetAlertType } from 'react-bootstrap-sweetalert/dist/types';
import { User, AlertConfig } from './users.interface';
import { MobilityGroup } from './../Groups/groups.interface';
import Download from 'src/assets/icons/rodinhas/Download';

const UserCreate: React.FC = () => {
  const [users, setUsers] = useState([] as User[]);
  const [file, setFile] = useState({ fileName: '', file: '' });
  const [alertDialog, setAlertDialog] = useState(false);
  const [mobilityGroups, setMobilityGroups] = useState([] as MobilityGroup[]);
  const [selectedGroup, setSelectedGroup] = useState(Number);
  const [alertDialogConfig, setAlertDialogConfig] = useState({} as AlertConfig);
  const fileInputField = useRef(null);
  const [fileInputKey, setFileInputKey] = useState('12345');

  useEffect(() => {
    getMobilityGroups();
  }, []);

  useEffect(() => {
    if (file.fileName !== '' && file.file !== '') {
      uploadSheet();
    }
  }, [file]);

  const createUsers = async (users) => {
    const newUsers = users;

    if (selectedGroup !== undefined && selectedGroup !== 0) {
      newUsers.forEach((v) => {
        v.groupId = selectedGroup;
        console.log(v.groupId);
      });
    }
    const result = await userService.createMany({ users: newUsers });
    if (result.status === 200) {
      alert('Sucesso', result.message, 'success');
    } else {
      alert('Erro', result.message, 'error');
    }
  };

  const getMobilityGroups = async () => {
    const result = await userService.getMobilityGroups();
    if (result) {
      setMobilityGroups(result);
    } else {
      console.log(result.message);
    }
  };

  const uploadSheet = async () => {
    try {
      const result = await userService.uploadSheet(file);
      if (result.users) {
        setUsers(result.users);
      } else {
        alert('Erro', result.message, 'error');
        setFile({ fileName: '', file: '' });
      }
    } catch (e) {
      console.log(e);
    }
  };

  const alert = (
    title: String,
    message: String,
    type: SweetAlertType,
    redirect: Boolean = false
  ) => {
    setAlertDialog(true);
    setAlertDialogConfig({
      title: title,
      message: message,
      type: type,
      redirect: redirect,
    });
  };

  const handleNewFileUpload = (e: any) => {
    if (e.target.files.length) {
      const file = e.target.files[0];
      const fileSize = file.size / 1024 / 1024;

      if (fileSize > 2) {
        alert('Erro', 'Arquivo deve ter menos que 2 MB', 'error');
        return false;
      }

      getBase64(file).then((data) => {
        let base64String = data as string;
        base64String = base64String.split(',')[1];
        setFile({ fileName: file.name, file: base64String });
      });
      setFileInputKey(Math.random().toString(36).substring(7));
    }
  };

  const getBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const downloadTemplate = async () => {
    const response = await userService.downloadUserTemplate();
    if (response.data) {
      const link = document.createElement('a');
      link.href = response.data;
      link.setAttribute('download', 'template.xlsx');
      document.body.appendChild(link);
      link.click();
    }
  };

  return (
    <>
      <Title>Utilizadores</Title>

      <Content>
        <div className='content'>
          <Container fluid className='card-no-tabs user_create--many'>
            <Row
              style={{
                textAlign: 'center',
                font: 'normal normal normal 18px/22px Roboto',
                letterSpacing: '0px',
                color: '#878787',
                opacity: 1,
                marginLeft: '82.5px',
                marginTop: '51px',
              }}
            >
              <Link className='color-back' to={'/gm/users/create'}>
                <p>
                  <u>&#60; Voltar</u>
                </p>
              </Link>
            </Row>
            <Row>
              <Col
                xs={12}
                sm={12}
                md={8}
                lg={8}
                style={{
                  textAlign: 'left',
                  font: 'normal normal bold 26px/34px Roboto',
                  letterSpacing: '0px',
                  color: '#2292CC',
                  opacity: 1,
                  marginLeft: '82.5px',
                }}
              >
                <h3>Adicionar conjunto de utilizadores</h3>
              </Col>
              <Col xs={12} sm={12} md={4} lg={2}>
                <div
                  className='dowload_template'
                  onClick={() => downloadTemplate()}
                >
                  <Download />
                  <a onClick={() => downloadTemplate()}>Users template.xlsx</a>
                </div>
              </Col>
            </Row>

            <Row
              style={{
                textAlign: 'left',
                font: 'normal normal normal 16px/19px Roboto',
                letterSpacing: '0px',
                color: '#1E1E1E',
                opacity: 1,
                margin: '15.99px 0 61.64px 82.5px',
              }}
            >
              <p>
                Por favor, faça upload do ficheiro com as informações dos novos
                utilizadores.
              </p>
            </Row>

            <Row>
              <Col md={11}>
                {file.fileName !== '' && (
                  <Row className='justify-content-center'>
                    <div className='file_name'>
                      <Row>
                        <Col>
                          <p>{file.fileName}</p>
                        </Col>
                        <Col className='d-flex justify-content-end'>
                          <p>
                            <FontAwesomeIcon
                              size='1x'
                              icon={faTimesCircle}
                              color='#000'
                              onClick={() => {
                                setFile({ fileName: '', file: '' });
                                setUsers([] as User[]);
                              }}
                            />
                          </p>
                        </Col>
                      </Row>
                    </div>
                  </Row>
                )}
                <Row className='division bottom-divider'>
                  <Col className='center'>
                    <input
                      type='file'
                      accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                      ref={fileInputField}
                      onChange={handleNewFileUpload}
                      key={fileInputKey}
                    />
                    <Button
                      style={Styles.okButton}
                      variant='outline-info'
                      size='lg'
                      onClick={() => createUsers(users)}
                    >
                      Upload
                    </Button>
                  </Col>
                </Row>
                <Row className='d-flex justify-content-center'>
                  <p>Ficheiros .XLSX suportados</p>
                </Row>
              </Col>
            </Row>
            {users && users.length > 0 && (
              <>
                <Row className='division' style={Styles.clientPageDivision}>
                  <Col>
                    <Row style={{ margin: '62px -40px 0 -40px' }}>
                      <Col style={Styles.clientPageDivisionHeader}>
                        <h4>Grupo mobilidade</h4>
                      </Col>
                    </Row>
                    <hr />
                    <p style={{ margin: '0px 0 0 70px' }}>
                      Clique sobre o grupo para adicionar. Pode também adicionar
                      mais tarde.
                    </p>
                  </Col>
                </Row>

                <Row style={{ margin: '30px 0 0 110px' }}>
                  {mobilityGroups &&
                    mobilityGroups.length > 0 &&
                    mobilityGroups.map((item, i) => {
                      return (
                        <Col lg={4} key={'col' + String(i)}>
                          <Row>
                            <Form.Check
                              custom
                              inline
                              name='group1'
                              type={'radio'}
                              id={`checkbox-group${item.id}`}
                              style={{ marginLeft: '15px' }}
                              onChange={(e) => setSelectedGroup(item.id)}
                            />
                            <p>{item.acronym}</p>
                          </Row>
                        </Col>
                      );
                    })}
                </Row>

                <Row className='division' style={Styles.clientPageDivision}>
                  <Col>
                    <Row style={{ margin: '62px -40px 0 -40px' }}>
                      <Col style={Styles.clientPageDivisionHeader}>
                        <h4>Lista de utilizadores adicionada</h4>
                      </Col>
                    </Row>
                    <hr />
                    <p style={{ margin: '0px 0 0 70px' }}>
                      Foi concluída com sucesso a submissão do seu ficheiro.
                      Verifique, por favor, as informações geradas e confirme a
                      criação de utilizadores.
                    </p>
                  </Col>
                </Row>
                <Row style={{ margin: '30px 0 0 110px' }}>
                  {users &&
                    users.length > 0 &&
                    users.map((item, i) => {
                      return (
                        <Col
                          lg={3}
                          key={'col' + String(i)}
                          className='mobility__group--card ml-2 mr-2 mb-4'
                        >
                          <Row>
                            <Col lg={2}>
                              <FontAwesomeIcon
                                size='2x'
                                icon={faUserCircle}
                                color='#878787'
                              />
                            </Col>
                            <Col>
                              <h3>{item.name}</h3>
                              <p>{item.email}</p>
                            </Col>
                          </Row>
                        </Col>
                      );
                    })}
                </Row>

                <Row>
                  <Col md={11}>
                    <Row
                      className='division bottom-divider'
                      style={{ margin: '141.36px 0 167.5px 0' }}
                    >
                      <Col className='center'>
                        <Button
                          style={Styles.okButton}
                          variant='outline-info'
                          size='lg'
                          onClick={() => createUsers(users)}
                        >
                          Registar Utilizador
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </>
            )}
            {alertDialog && (
              <SweetAlert
                type={alertDialogConfig?.type}
                title={alertDialogConfig?.title ?? ''}
                onConfirm={() => {
                  setAlertDialog(false);
                }}
              >
                {alertDialogConfig?.message ?? ''}.
              </SweetAlert>
            )}
          </Container>
        </div>
      </Content>
    </>
  );
};

export default UserCreate;

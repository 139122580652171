import React, { useState, useEffect, useRef } from 'react';
import { Form, Container, Row, Col, Image, Button } from 'react-bootstrap';
import { Title, Content, Styles } from '../../style/content';
import ReactDevicePreview from 'react-device-preview';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Loading from 'react-fullscreen-loading';
import { cmsService } from '../../services/cmsService';
import { OnboardingScreen } from './onboarding.interface';
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const Index: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [value, setValue] = useState(0);
  const [onboardingConfig, setOnboardingConfig] = useState(
    [] as OnboardingScreen[]
  );
  const fileInputField = useRef(null);
  const [alertDialog, setAlertDialog] = useState(false);
  const [alertConfig, setAlertConfig] = useState({
    title: '',
    message: '',
    redirect: false,
    redirectURL: '',
  });

  useEffect(() => {
    getScreens();
  }, []);

  const getScreens = async () => {
    const config = await cmsService.loadScreensAction();
    if (config.length > 0) {
      config.sort(dynamicSort('screenId'));
      setOnboardingConfig(config);
      setIsLoading(false);
    }
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const updateTitleOnboarding = (newValue, index) => {
    let config = [...onboardingConfig];
    config[index].title = newValue;
    console.log(config);
    setOnboardingConfig(config);
  };

  const updateDescriptionOnboarding = (newValue, index) => {
    let config = [...onboardingConfig];
    config[index].description = newValue;
    setOnboardingConfig(config);
  };

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  const handleNewFileUpload = (e: any, index) => {
    if (e.target.files.length) {
      const file = e.target.files[0];
      const fileSize = file.size / 1024 / 1024;

      if (fileSize > 1) {
        alert('Erro', 'Arquivo deve ter menos que 1 MB', false);
        return false;
      }

      getBase64(file).then((data) => {
        let base64String = data as string;
        let config = [...onboardingConfig];
        config[index].imageUrl = base64String;
        config[index].imageFile = base64String.split(',')[1];
        config[index].imageName = file.name;
        setOnboardingConfig(config);
      });
    }
  };
  const getBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const alert = (
    title: string,
    message: string,
    redirect: boolean,
    redirectURL?: string
  ) => {
    setAlertConfig({
      title: title,
      message: message,
      redirect: redirect,
      redirectURL: redirectURL ? redirectURL : '',
    });
    setAlertDialog(true);
  };

  const save = async (index) => {
    setIsLoading(true);
    let config = onboardingConfig;
    delete config[index].imageUrl;
    const result = await cmsService.updateOnboarding(onboardingConfig[index]);
    console.log(result);
    if (result.message) {
      setIsLoading(false);
      alert('Sucesso', result.message, false);
    } else {
      setIsLoading(false);
      alert('Erro', 'Erro ao atualizar o Ecrã', false);
      getScreens();
    }
  };

  const dynamicSort = (property) => {
    let sortOrder = 1;
    if (property[0] === '-') {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a, b) {
      let result =
        a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
      return result * sortOrder;
    };
  };

  return (
    <>
      {isLoading && (
        <Loading
          loading
          background='rgba(255, 255, 255, .4)'
          loaderColor='#3498db'
        />
      )}
      <Title>CMS</Title>

      <Content>
        <Container fluid className='card-no-tabs CMS_main mb-4'>
          <Row>
            <Col>
              <h3>CMS - Mobile app</h3>
              <p>Consulte e edite as imagens e textos da aplicação móvel.</p>
            </Col>
          </Row>
          <Row className='mt-4'>
            <Col>
              <h4>Onboarding</h4>
            </Col>
          </Row>
          <Row className='mt-4'>
            <Col md={12} xs={12} lg={6} xl={5}>
              <ReactDevicePreview device='iphonex' scale='0.6'>
                <Row>
                  <Col md={12}>
                    {onboardingConfig[value] && (
                      <>
                        <img
                          src={onboardingConfig[value].imageUrl}
                          className='onboarding_image'
                        />

                        <h1> {onboardingConfig[value].title} </h1>
                        <p> {onboardingConfig[value].description} </p>
                      </>
                    )}
                  </Col>
                </Row>
              </ReactDevicePreview>
            </Col>
            <Col md={8} xs={12} sm={12} lg={6} xl={7}>
              <Tabs
                value={value}
                onChange={handleChange}
                variant='scrollable'
                scrollButtons='auto'
                indicatorColor='primary'
                aria-label='scrollable auto tabs example'
              >
                {onboardingConfig.map((item) => {
                  return <Tab label={item.screenId} key={item.screenId} />;
                })}
              </Tabs>
              {onboardingConfig.map((item, index) => {
                return (
                  <TabPanel value={value} index={index} key={item.screenId}>
                    <Row>
                      <Col md={12} className='ml-2'>
                        <p>Título sob a imagem</p>
                      </Col>
                      <Col md={12}>
                        <Form.Control
                          onChange={(e) =>
                            updateTitleOnboarding(e.target.value, index)
                          }
                          defaultValue={onboardingConfig[index].title}
                        />
                      </Col>
                      <Col md={12} className='ml-2 mt-4'>
                        <p>Descrição</p>
                      </Col>
                      <Col md={12}>
                        <Form.Control
                          onChange={(e) =>
                            updateDescriptionOnboarding(e.target.value, index)
                          }
                          defaultValue={onboardingConfig[index].description}
                        />
                      </Col>
                      <Col md={12} className='ml-2 mt-4'>
                        <p>Imagem</p>
                      </Col>
                    </Row>

                    <Row className='justify-content-start pl-4 mt-2'>
                      <input
                        type='file'
                        ref={fileInputField}
                        onChange={(e) => handleNewFileUpload(e, index)}
                        accept='image/*'
                      />
                    </Row>
                    <Row className='justify-content-end pr-4'>
                      <Button
                        onClick={() => save(index)}
                        className='button mt-4'
                        variant='outline-info'
                      >
                        GUARDAR
                      </Button>
                    </Row>
                  </TabPanel>
                );
              })}
            </Col>
          </Row>
        </Container>
        <Dialog
          open={alertDialog}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle id='alert-dialog-title'>{alertConfig.title}</DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-description'>
              {alertConfig.message}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setAlertDialog(false)} color='primary'>
              Cancelar
            </Button>
            <Button
              onClick={() => {
                setAlertDialog(false);
              }}
              color='primary'
              autoFocus
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </Content>
    </>
  );
};

export default Index;

import React, { useState, useEffect } from 'react';
import { Dropdown, Nav } from 'react-bootstrap';
import { faBell } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Badge from '@material-ui/core/Badge';
import styled from 'styled-components';
import { AuthService } from '../../services/auth.service';
import { getUserDetails, getUserProfile, setUserProfile, removeUserProfile, isManager } from '../../redux/sessionReducer';
import { useHistory } from 'react-router-dom';
import { IDGO_PROD, IDGO_PRE, SERVER_ENV, SERVER_BACKOFFICE } from '../../constants/config';
import ModalNotifications from './ModalNotifications';

export const NavigationBar = () => {
  const [openNotification, setOpenNotification] = useState(false);
  const [showNotification, setShowNotification] = useState(0);
  const [user, setUser] = useState({});
  const history = useHistory();

  useEffect(() => {
    getProfile();
  }, []);

  const logout = async () => {
    try {
      await AuthService.logout();
    } catch (e) {
      console.log(e);
    }

    if (SERVER_ENV === 'prod') {
      window.location.replace(
        `${IDGO_PROD}/logout.php?response_type=code&client_id=mycarflix&lang=pt&state=${SERVER_ENV}`
      );
    } else {
      window.location.replace(
        `${IDGO_PRE}/logout.php?response_type=code&client_id=mycarflix&lang=pt&state=${SERVER_ENV}`
      );
    }
  };

  const getProfile = async () => {
    let result = await getUserDetails();
    if (result) {
      result = JSON.parse(result);
      setUser(result);
    }
  };

  const changeRole = () => {
    let result = getUserProfile();
    if (result) {
      removeUserProfile()
    } else {
      setUserProfile('user_corporate')
    }
    window.location.replace(
      `${SERVER_BACKOFFICE}`
    )
  };

  const openButton = () => {
    setOpenNotification(true);
  };

  const closeButton = async () => {
    setOpenNotification(false);
  };

  const onClose = async () => { };

  const showAlert = async (number) => {
    setShowNotification(number);
  };

  return (
    <div className='navigationbar_component'>
      <Navbar>
        <div className='logo'>
          <img
            src={require('../../assets/logos/real_logo.png')}
            alt='myCarflix'
            className='logo'
          />
        </div>
        <div className='user'>
          <Badge overlap="rectangular"
            badgeContent={showNotification}
            color='error'
            onClick={openButton}
          >
            <FontAwesomeIcon
              size='lg'
              icon={faBell}
              color={'#2292CC'}
              onClick={openButton}
            />
          </Badge>
          <div className='line' />

          <Dropdown>
            <Dropdown.Toggle id='dropdown-basic' variant='light'>
              {user && user.imageUrl && (
                <div className='image-cropper'>
                  <img src={user.imageUrl} alt='Profile' className='' />
                </div>
              )}
              {!user.imageUrl && (
                <img
                  src={require('../../assets/avatar/profile.png')}
                  alt='avatar'
                  className='logo'
                  style={{ height: 40, width: 40 }}
                />
              )}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={() => history.push('/profile')}>
                Editar perfil
              </Dropdown.Item>
              {isManager() && (
                <Dropdown.Item onClick={() => changeRole()}>
                  Alterar perfil
                </Dropdown.Item>)}
              <Dropdown.Item onClick={() => logout()}>Logout</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </Navbar>
      <ModalNotifications
        open={openNotification}
        closeButton={closeButton}
        onClose={onClose}
        showAlert={showAlert}
      ></ModalNotifications>
    </div>
  );
};

const Navbar = styled.nav`
  padding: 20px 0;
  display: flex;
  margin: 0 8%;
  justify-content: space-between;

  .logo {
    max-width: 280px;
    width:100%;
    height: auto;
    display: flex;
    align-items: center;
  }

  .avatar {
    width: 40px;
  }

  div.menu {
    display: flex;
    align-items: center;

    ul.menu {
      list-style-type: none;
      display: flex;

      li {
        margin: 20px;
        color: #2292cc;
      }
    }
  }

  div.user {
    display: flex;
    align-items: center;
  }
`;

import axios from 'axios';
import {
  HEADERS,
  URL_API_PUBLIC,
  URL_BASE,
  SERVER_ENV,
  HEADERS_PUBLIC,
  IDGO_PRE,
  IDGO_PROD,
} from '../constants/config';
import { getUser, getUserProfile } from '../redux/sessionReducer';

let API_URL = URL_BASE;

if (process.env.REACT_APP_ENV === 'local') {
  API_URL = process.env.REACT_APP_LOCAL_URL;
}

const apiPrivate = axios.create({
  baseURL: API_URL,
  headers: HEADERS,
});

if (SERVER_ENV === 'localNoLogin') {
  apiPrivate.defaults.headers.common['Authorization'] =
    'b1f910d9-c5e3-46d6-a7ec-2f10ce66c005';
}

let profile = ''
if(getUserProfile()) {
  profile = getUserProfile();
}

apiPrivate.defaults.headers.common['x-mycarflix-profile'] = profile.replaceAll('"','')
apiPrivate.defaults.headers.common['X-origin'] = 'web';
apiPrivate.defaults.headers.common["Access-Control-Allow-Headers'"] = '*';
apiPrivate.defaults.headers.common['Content-Type'] =
  'application/json;charset=UTF-8';

apiPrivate.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (SERVER_ENV !== 'localNoLogin') {
      if (401 === error.response?.status) {
        if (SERVER_ENV === 'prod') {
          window.location.replace(
            `${IDGO_PROD}/login.php?response_type=code&client_id=mycarflix&lang=pt&state=${SERVER_ENV}`
          );
        } else {
          window.location.replace(
            `${IDGO_PRE}/login.php?response_type=code&client_id=mycarflix&lang=pt&state=${SERVER_ENV}`
          );
        }
      }
    }
    return Promise.reject(error);
  }
);

apiPrivate.interceptors.request.use(
  (config) => {
    return getUser()
      .then((user) => {
        user = JSON.parse(user);
        if (user && user.token) {
          config.headers['Authorization'] = 'Bearer ' + user.token;
        }
        return Promise.resolve(config);
      })
      .catch((error) => {
        console.log(error);
        return Promise.resolve(error);
      });
  },
  (error) => {
    return Promise.reject(error);
  }
);

const apiPublic = axios.create({
  baseURL: URL_API_PUBLIC,
  headers: HEADERS_PUBLIC,
  auth: {
    username: 'account-service',
    password: 'secret',
  },
});

const apiExternal = axios.create({
  baseURL: '',
  headers: HEADERS,
});



const apiSitePublic = axios.create({
  baseURL: URL_API_PUBLIC,
  headers: HEADERS_PUBLIC,
});


apiSitePublic.interceptors.request.use(
  (config) => {
    return getUser()
      .then((user) => {
        user = JSON.parse(user);
        if (user && user.token) {
          let newHeaders = HEADERS
          newHeaders.authorization= 'Bearer ' + user.token;
          let newConfig = {
            ...config,
          };
          return Promise.resolve(newConfig);
        } else {
          return Promise.resolve(config);
        }
      })
      .catch((error) => {
        return Promise.resolve(error);
      });
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default {
  apiPrivate,
  apiPublic,
  apiExternal,
  apiSitePublic,
};

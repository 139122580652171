import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Form, Col, Row, Modal, Button, Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTrashAlt,
  faPowerOff,
  faEdit,
  faArrowUp,
} from '@fortawesome/free-solid-svg-icons';

import { motion } from 'framer-motion';
import Loading from 'react-fullscreen-loading';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Title, Content } from '../../../style/content';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import dayjs from 'dayjs';
import HistoryData from './HistoryData';
import { productService } from '../../../services/productService';
import { ProductGuerin } from '../../../utils/Models/productGuerin';
import BadgeComponent from '../../../components/Badge';
import { useParams, Link, useHistory } from 'react-router-dom';
import { iCan } from '../../../redux/sessionReducer';
import {
  animationInvisible,
  animationVariants,
  animationVisible,
} from 'src/components/Animations/animations';

const Guerin: React.FC = () => {
  const [product, setProduct] = useState({} as ProductGuerin);

  const [isEdit, setIsEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [showDeactivateModal, setShowDeactivateModal] = useState(false);
  const [showDeactivate, setShowDeactivate] = useState(false);
  const [showRemove, setShowRemove] = useState(false);
  const [errorDialog, setErrorDialog] = useState(false);
  const [successDialog, setSuccessDialog] = useState(false);
  const [userData, setUserData] = useState({});
  const [productList, setProductList] = useState([]);
  const [alertMsg, setAlertMsg] = useState('');
  const [mainProduct, setMainProduct] = useState({} as any);
  const [image, setImage] = useState({
    preview: '',
    raw: '',
    base64: '' as any,
    name: '',
  });
  const [segments, setSegments] = useState([
    {
      id: Number(),
      imageUrl: '',
      segmentDescription: '',
      segmentId: Number(),
    },
  ]);
  const { productId } = useParams();
  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);

    const getProduct = async (companyName: string, productId: string) => {
      try {
        setIsLoading(true);
        const result = await productService.getProductGuerinXtracars(
          companyName,
          productId
        );
        setProduct({
          ...product,
          ...result.data,
          subscriptionAvailability: result.data.subscriptionAvailability
            ? new Date(result.data.subscriptionAvailability)
            : new Date(),
        });

        setIsEdit(result.data.productState === 'PUBLISHED' ? true : false);

        setUserData({
          userVerified: result.data.userVerified,
          dateCreated: result.data.dateCreated,
          dateLastUpdated: result.data.dateLastUpdated,
          userPublished: result.data.userPublished,
          userCreated: result.data.userCreated,
        });
        const resultSegments = await productService.getGuerinXtracarsSegments();
        setSegments(resultSegments.data);
      } catch (error) {
        setErrorDialog(true);
      } finally {
        setIsLoading(false);
      }
    };

    const getProducts = async () => {
      setIsLoading(true);
      try {
        const result =
          await productService.getProductsByGuerinXtracarsDropdown();
        setProductList(result.data);
      } catch (error) {
        setErrorDialog(true);
      } finally {
        setIsLoading(false);
      }
    };

    if (productId) getProduct('guerin', productId);
    getProducts();
  }, []);

  const removeProduct = async (productId: string) => {
    setShowRemoveModal(false);
    setIsLoading(true);

    try {
      const response = await productService.removeGuerinXtracars(
        'guerin',
        productId
      );
      setSuccessDialog(true);
      setAlertMsg(response.data.message);
    } catch (error) {
      setErrorDialog(true);
      setAlertMsg(error.response.data.message);
    } finally {
      setIsLoading(false);
    }
  };

  const publish = async (productForm: any) => {
    setIsLoading(true);

    const data = { ...productForm };

    /**
     * Filter all falsy values ( "", 0, false, null, undefined )
     */
    Object.keys(data).forEach(
      (key) =>
        (data[key] === '' || data[key] === null || data[key] === 0) &&
        delete data[key]
    );
    delete data['dateCreated'];
    delete data['dateLastUpdated'];

    data.productState = 'PUBLISHED';
    data['serviceId'] = 2;
    data['productId'] = data.productId;

    if (!image.name) {
      delete data['fileExtension'];
      delete data['uuidImageFile'];
      delete data['imageFile'];
      delete data['imageName'];
    } else {
      data['imageName'] = image.name;
      data['imageFile'] = image.base64;
    }

    try {
      let result;

      if (productForm.id) {
        result = await productService.updateProductGuerinXtracars(
          'guerin',
          data
        );
      } else {
        result = await productService.createGuerinXtracars('guerin', data);
      }
      setProduct({ ...product, productState: 'PUBLISHED' });
      setAlertMsg(result.data.message);
      setSuccessDialog(true);
    } catch (error) {
      setAlertMsg(error.response.data.message);
      setErrorDialog(true);
    } finally {
      setIsLoading(false);
    }
  };

  const getBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleChange = async (e) => {
    const file = e.target.files[0];

    const fileSize = file.size / 1024 / 1024;

    if (fileSize > 2) {
      setErrorDialog(true);
      setAlertMsg('Arquivo deve ter menos que 2 MB');
      return false;
    }

    getBase64(file).then((data) => {
      setImage({
        preview: URL.createObjectURL(file),
        raw: file,
        base64: data,
        name: file.name,
      });
    });
  };

  const changeStatusProduct = async (productId: string) => {
    setShowDeactivateModal(false);
    setIsLoading(true);

    try {
      const response = await productService.activeGuerinXtracars(
        'guerin',
        productId,
        !product.active
      );
      setProduct({
        ...product,
        active: !product.active,
        productState: product.active ? 'INACTIVE' : 'PUBLISHED',
      });
      setSuccessDialog(true);
      setAlertMsg(response.data.message);
    } catch (error) {
      setErrorDialog(true);
      setAlertMsg(error.response.data.message);
    } finally {
      setIsLoading(false);
    }
  };

  const updateProduct = async (productForm: any, company: string) => {
    setIsLoading(true);

    const productData = { ...productForm };

    /**
     * Set productID
     */
    productData.productId = productData.productId;

    /**
     * Remove empty values.
     */
    Object.keys(productData).forEach((key) =>
      productData[key] === '' || productData[key] === null
        ? delete productData[key]
        : productData[key]
    );

    if (image.name) {
      productData['imageName'] = image.name;
      productData['imageFile'] = image.base64;
    }

    try {
      const response = await productService.updateProductGuerinXtracars(
        company,
        productData
      );
      setSuccessDialog(true);
      setAlertMsg(response.data.message);
    } catch (error) {
      setErrorDialog(true);
      setAlertMsg(error.response.data.message);
    } finally {
      setIsLoading(false);
    }
  };

  const create = async (productData: any, productState = 'DRAFT') => {
    const data = { ...productData };

    data['productId'] = productData['id'];
    data.productState = productState;
    data['serviceId'] = 2;
    data['active'] = true;
    data['deleted'] = false;

    /**
     * Filter all falsy values ( "", 0, false, null, undefined )
     */
    Object.keys(data).forEach(
      (key) =>
        (data[key] === '' || data[key] === null || data[key] === 0) &&
        delete data[key]
    );
    delete data['id'];

    if (image.name) {
      data['imageName'] = image.name;
      data['imageFile'] = image.base64;
    }

    setIsLoading(true);

    try {
      const result = await productService.createGuerinXtracars('guerin', data);
      setSuccessDialog(true);
      setAlertMsg(result.data.message);
    } catch (error) {
      setErrorDialog(true);
      setAlertMsg(error.response.data.message);
    } finally {
      setIsLoading(false);
    }
  };

  const integration = async (product: any) => {
    setIsLoading(true);

    /**
     * Filter all falsy values ( "", 0, false, null, undefined )
     */
    Object.keys(product).forEach((key) =>
      product[key] === '' ? (product[key] = null) : product[key]
    );

    try {
      const result = await productService.integration(
        'guerin-xtracars',
        product
      );
      setSuccessDialog(true);
      setAlertMsg(result.data.message);
    } catch (error) {
      setAlertMsg(error.response.data.message);
      setErrorDialog(true);
    } finally {
      setIsLoading(false);
    }
  };

  const isEmpty = (value) => {
    return String(value).length;
  };

  const checkFields = () => {
    const photo = product.uuidImageFile || image.name;

    const fields = [photo];

    return fields.some((item) => !item);
  };

  const getSegmentById = (id) => {
    if (segments.length > 0) {
      let index = segments.findIndex((segment) => segment.segmentId == id);
      if (index !== -1) {
        return segments[index].segmentDescription;
      }
    }
    return '';
  };

  return (
    <>
      <Title>Guerin</Title>

      <Content>
        <Container
          fluid
          className='card-no-tabs'
          style={{ paddingLeft: 80, paddingBottom: 50 }}
        >
          <Row
            style={{
              textAlign: 'center',
              font: 'normal normal normal 18px/22px Roboto',
              letterSpacing: '0px',
              color: '#878787',
              opacity: 1,
              marginLeft: '8px',
              marginTop: '51px',
            }}
          >
            <Link className='color-back' to={'/products'}>
              <p>
                <u>&#60; Voltar</u>
              </p>
            </Link>
          </Row>
          <Wrapper>
            {isLoading && (
              <Loading
                loading
                background='rgba(255, 255, 255, .4)'
                loaderColor='#3498db'
              />
            )}

            {successDialog && (
              <SweetAlert
                success
                title='Sucesso!'
                onConfirm={() => {
                  setSuccessDialog(false);
                  setAlertMsg('');
                  history.push('/products');
                }}
              >
                {alertMsg}.
              </SweetAlert>
            )}

            {errorDialog && (
              <SweetAlert
                danger
                title='Erro!'
                onConfirm={() => {
                  setErrorDialog(false);
                  setAlertMsg('');
                }}
              >
                {alertMsg}.
              </SweetAlert>
            )}

            <Modal backdrop='static' show={showDeactivateModal} size='lg'>
              <Modal.Header>
                <h2>Produto Guerin - {product.name}</h2>
              </Modal.Header>
              <Modal.Body>
                {product.uuidImageFile && (
                  <img
                    src={product.uuidImageFile}
                    alt=''
                    className='image-modal'
                  />
                )}
                <FontAwesomeIcon icon={faPowerOff} />
                <h2 className='pt-5'>
                  Tem certeza que pretende{' '}
                  {product.active ? 'desativar' : 'ativar'}?
                </h2>
                <p className='disclaimer'>
                  Poderá reverter esta ação mais tarde.
                </p>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant='remove'
                  onClick={() => changeStatusProduct(productId)}
                >
                  SIM, {product.active ? 'DESATIVAR' : 'ATIVAR'}
                </Button>
                <Button
                  variant='back'
                  onClick={() => setShowDeactivateModal(false)}
                >
                  NÃO, VOLTAR
                </Button>
              </Modal.Footer>
            </Modal>

            <Modal backdrop='static' show={showRemoveModal} size='lg'>
              <Modal.Header>
                <h2>Produto Guerin - {product.name}</h2>
              </Modal.Header>
              <Modal.Body>
                {product.uuidImageFile && (
                  <img
                    src={
                      product.uuidImageFile
                        ? product.uuidImageFile
                        : require('../../../assets/car_placeholder.png')
                    }
                    alt=''
                  />
                )}
                <FontAwesomeIcon icon={faTrashAlt} className='remove' />
                <h2 className='pt-5'>Tem certeza que pretende eliminar?</h2>
                <p className='disclaimer'>Esta ação não pode ser revertida.</p>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant='remove'
                  onClick={() => removeProduct(productId)}
                >
                  SIM, ELIMINAR
                </Button>
                <Button
                  variant='back'
                  onClick={() => setShowRemoveModal(false)}
                >
                  NÃO, VOLTAR
                </Button>
              </Modal.Footer>
            </Modal>

            {productId && (
              <div className='pb-4'>
                <Logo>
                  <img
                    src={require(`../../../assets/logos/guerin-xs-logo-on-2x.png`)}
                    alt=''
                  />
                  {productId && (
                    <div className='actions'>
                      <BadgeComponent badge={product.productState} />
                      {(product.productState === 'PUBLISHED' ||
                        product.productState === 'INACTIVE') && (
                        <div
                          className='wrapper'
                          onMouseEnter={() => setShowDeactivate(true)}
                          onMouseLeave={() => setShowDeactivate(false)}
                        >
                          <FontAwesomeIcon
                            icon={faPowerOff}
                            onClick={() => setShowDeactivateModal(true)}
                            onMouseEnter={() => setShowDeactivate(true)}
                          />
                          <motion.div
                            initial={animationVisible}
                            animate={showDeactivate ? 'visible' : 'invisible'}
                            exit={animationInvisible}
                            transition={{ duration: 0.3 }}
                            variants={animationVariants}
                          >
                            <p className='text-deactivate'>
                              {product.active
                                ? 'desativar produto'
                                : 'ativar produto'}
                            </p>
                          </motion.div>
                        </div>
                      )}

                      {product.productState === 'DRAFT' && (
                        <div
                          className='wrapper remove'
                          onMouseEnter={() => setShowRemove(true)}
                          onMouseLeave={() => setShowRemove(false)}
                        >
                          <FontAwesomeIcon
                            icon={faTrashAlt}
                            onClick={() => setShowRemoveModal(true)}
                            className='remove'
                          />
                          <motion.div
                            initial={animationVisible}
                            animate={showRemove ? 'visible' : 'invisible'}
                            exit={animationInvisible}
                            transition={{ duration: 0.3 }}
                            variants={animationVariants}
                          >
                            <p className='text-remove'>eliminar</p>
                          </motion.div>
                        </div>
                      )}
                    </div>
                  )}
                </Logo>
              </div>
            )}

            {!productId && (
              <div className='form'>
                <Row>
                  <Col xs={2}>
                    <img
                      src={require('../../../assets/logos/guerin-2x-logo.png')}
                      alt='guerin'
                    />
                  </Col>
                </Row>
              </div>
            )}

            {productId && <HistoryData userData={userData} />}

            <div className='form'>
              <h6>1. Produto</h6>
              {!product.canPublish && (
                <Row>
                  <Col md={12} xs={12} className='can-publish'>
                    Viatura não disponível. Não deve ser publicada.
                  </Col>
                </Row>
              )}
              <div className='inputs'>
                <Form>
                  <Row>
                    <Col>
                      <Form.Label>Categoria</Form.Label>
                      <Form.Control
                        disabled={true}
                        defaultValue={product.carCategory}
                      />
                    </Col>
                    <Col>
                      <Form.Label>Segmento</Form.Label>
                      <Form.Control
                        disabled={true}
                        defaultValue={getSegmentById(product.segment)}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Grupo</Form.Label>
                      <Form.Control
                        disabled={true}
                        defaultValue={product.groupID}
                      />
                    </Col>
                    <Col>
                      <Form.Label>Nome</Form.Label>
                      <Form.Control
                        disabled={true}
                        defaultValue={product.name}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={6}>
                      <Form.Label>Código de identificação</Form.Label>
                      <Form.Control disabled={true} defaultValue={product.id} />
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>

            <div className='form'>
              <h6>2. Recursos gráficos</h6>
              <div className='inputs section-upload'>
                <div>
                  {image.preview ? (
                    <img src={image.preview} />
                  ) : (
                    <img
                      src={
                        product.uuidImageFile
                          ? product.uuidImageFile
                          : require('../../../assets/car_placeholder.png')
                      }
                    />
                  )}
                </div>
                {(product.productState === 'DRAFT' ||
                  product.productState === 'PUBLISHED') && (
                  <Form onSubmit={(e) => e.preventDefault()}>
                    <input
                      type='file'
                      id='upload-button'
                      style={{ display: 'none' }}
                      accept='image/x-png, image/jpeg'
                      onChange={handleChange}
                    />
                    <label htmlFor='upload-button'></label>
                    <label htmlFor='upload-button'>
                      <div className='big-btn btn-fix-upload'>
                        <FontAwesomeIcon icon={faArrowUp} />
                      </div>
                    </label>
                    <p className='text-center pt-3'>
                      Imagens em formato png/jpeg. Limite: 2mb.
                    </p>
                  </Form>
                )}
              </div>
            </div>

            <div className='form'>
              <h6>3. Detalhes do produto</h6>
              <div className='inputs'>
                <Form>
                  <Row></Row>
                  <Row className='pt-3'></Row>
                  <Row className='pt-3'></Row>

                  <Row className='pt-3'>
                    <Col>
                      <Form.Label>Potência*</Form.Label>
                      <Form.Control
                        placeholder='Cilindrada'
                        disabled={true}
                        defaultValue={product.carDisplacement}
                        onChange={(e) =>
                          setProduct({
                            ...product,
                            carDisplacement: Number(
                              e.target.value.replace(/\D/g, '')
                            ),
                          })
                        }
                      />
                    </Col>
                    <Col>
                      <Form.Label>Cavalos*</Form.Label>
                      <Form.Control
                        placeholder='Cavalos'
                        disabled={true}
                        defaultValue={product.carHorses}
                        onChange={(e) =>
                          setProduct({
                            ...product,
                            carHorses: Number(
                              e.target.value.replace(/\D/g, '')
                            ),
                          })
                        }
                      />
                    </Col>
                  </Row>

                  <Row className='pt-3'>
                    <Col>
                      <Form.Label>N. de lugares*</Form.Label>
                      <Form.Control
                        placeholder='N. de lugares'
                        disabled={true}
                        defaultValue={product.carPlaces}
                        onChange={(e) =>
                          setProduct({
                            ...product,
                            carPlaces: Number(
                              e.target.value.replace(/\D/g, '')
                            ),
                          })
                        }
                      />
                    </Col>
                    <Col>
                      <Form.Label>N. de portas*</Form.Label>
                      <Form.Control
                        placeholder='N. de portas'
                        disabled={true}
                        defaultValue={product.carDoors}
                        onChange={(e) =>
                          setProduct({
                            ...product,
                            carDoors: Number(e.target.value.replace(/\D/g, '')),
                          })
                        }
                      />
                    </Col>
                  </Row>

                  <Row className='pt-3'>
                    <Col>
                      <Form.Label>Litragem</Form.Label>
                      <Form.Control
                        placeholder='Litragem'
                        defaultValue={product.numberOfSuitcases}
                        onChange={(e) =>
                          setProduct({
                            ...product,
                            numberOfSuitcases: Number(
                              e.target.value.replace(/\D/g, '')
                            ),
                          })
                        }
                        disabled={true}
                      />
                    </Col>
                    <Col>
                      <Form.Label>Consumo</Form.Label>
                      <Form.Control
                        onBlur={(e) =>
                          setProduct({
                            ...product,
                            carConsumption: Number(
                              e.target.value.replace(/\D/g, '')
                            ),
                          })
                        }
                        disabled={isEdit}
                        defaultValue={product.carConsumption}
                      />
                    </Col>
                  </Row>

                  <Row className='pt-3'>
                    <Col>
                      <Form.Label>Combustível*</Form.Label>
                      <Form.Control
                        placeholder='Combustível'
                        defaultValue={product.carFuel}
                        onChange={(e) =>
                          setProduct({ ...product, carFuel: e.target.value })
                        }
                        disabled={true}
                      />
                    </Col>
                    <Col>
                      <Form.Label>Transmissão*</Form.Label>
                      <Form.Control
                        defaultValue={
                          product.isAutomaticTransmission
                            ? 'Automático'
                            : 'Manual'
                        }
                        disabled={true}
                      />
                    </Col>
                  </Row>

                  <Row className='pt-3'>
                    <Col>
                      <Form.Label>Disponibilidade para subscrição*</Form.Label>
                      <Col className='px-0'>
                        <DatePicker
                          disabled={true}
                          className='qa-datepicker-availability'
                          selected={
                            product.subscriptionAvailability
                              ? dayjs(product.subscriptionAvailability).toDate()
                              : new Date()
                          }
                          dateFormat='dd/MM/yyyy'
                          onChange={(date) =>
                            date &&
                            setProduct({
                              ...product,
                              subscriptionAvailability: new Date(
                                date.toString()
                              ),
                            })
                          }
                        />
                      </Col>
                    </Col>
                    <Col>
                      <Form.Label>Validade do produto*</Form.Label>
                      <Col className='px-0'>
                        <DatePicker
                          disabled={true}
                          selected={
                            product.subscriptionValidate
                              ? new Date(product.subscriptionValidate)
                              : null
                          }
                          dateFormat='dd/MM/yyyy'
                          onChange={(date) =>
                            date &&
                            setProduct({
                              ...product,
                              subscriptionValidate: new Date(date.toString()),
                            })
                          }
                        />
                      </Col>
                    </Col>
                  </Row>
                  <Row className='pt-3'>
                    <Col>
                      <label>Informação adicional</label>
                      <Form.Control
                        as='textarea'
                        rows={4}
                        disabled={true}
                        readOnly={isEdit}
                        defaultValue={product.additionalInformation}
                        onChange={(e) =>
                          setProduct({
                            ...product,
                            additionalInformation: e.target.value,
                          })
                        }
                      />
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>

            <div className='form'>
              <h6>4. Definiçāo do produto</h6>
              <div className='inputs'>
                <Form>
                  <Row>
                    <Col>
                      <label>
                        Valor diário em intervalo de duração pré-definida do
                        aluguer
                      </label>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>1 dia*</Form.Label>
                      <Form.Control
                        defaultValue={product.one ? product.one : ''}
                        placeholder='/dia'
                        disabled={true}
                      />
                    </Col>
                    <Col>
                      <Form.Label>2 a 6 dias*</Form.Label>
                      <Form.Control
                        defaultValue={product.twoToSix ? product.twoToSix : ''}
                        placeholder='/dia'
                        disabled={true}
                      />
                    </Col>
                    <Col>
                      <Form.Label>7 a 24 dias*</Form.Label>
                      <Form.Control
                        defaultValue={
                          product.sevenToTwentyFour
                            ? product.sevenToTwentyFour
                            : ''
                        }
                        placeholder='/dia'
                        disabled={true}
                      />
                    </Col>
                    <Col>
                      <Form.Label>Mensal*</Form.Label>
                      <Form.Control
                        defaultValue={product.monthly ? product.monthly : ''}
                        placeholder='/dia'
                        disabled={true}
                      />
                    </Col>
                  </Row>
                  <Row className='pt-4 pb-2'>
                    <Col>
                      <label>Incluídos</label>
                    </Col>
                  </Row>
                  {product.extrasIncluded !== undefined &&
                    product.extrasIncluded.length > 0 &&
                    product.extrasIncluded.map((item, i) => {
                      return (
                        <Row key={i}>
                          <Col>
                            <div className='wrapper-input'>
                              <div className='round'>
                                <input
                                  type='checkbox'
                                  disabled={true}
                                  checked={true}
                                />
                                <label></label>
                              </div>
                              <label className='label-input'>
                                {item.extra}
                              </label>
                            </div>
                          </Col>
                        </Row>
                      );
                    })}
                  {product.extrasIncluded !== undefined &&
                    product.extrasIncluded.length == 0 && (
                      <Row>
                        <Col>
                          <div className='wrapper-input'>
                            <div className='round'>
                              <input
                                type='checkbox'
                                disabled={true}
                                checked={false}
                              />
                              <label></label>
                            </div>
                            <label className='label-input'>
                              Sem serviços incluídos
                            </label>
                          </div>
                        </Col>
                      </Row>
                    )}
                </Form>
              </div>
            </div>

            <div className='form'>
              <div className='text-center'>
                {!productId && !isEdit && iCan('ROLE_CREATE_PRODUCT') && (
                  <button
                    className='big-btn'
                    onClick={() => setIsEdit(!isEdit)}
                  >
                    CONTINUAR
                  </button>
                )}
                {productId &&
                  (product.productState === 'DRAFT' ||
                    product.productState === 'PUBLISHED') &&
                  iCan('ROLE_CREATE_PRODUCT') && (
                    <button
                      onClick={() => updateProduct(product, 'guerin')}
                      disabled={checkFields()}
                      className={`big-btn ml-4 ${
                        checkFields() ? 'opacity-3' : ''
                      }`}
                    >
                      GUARDAR ALTERAÇŌES
                    </button>
                  )}
                {/*productId &&
                  product.productState === 'DRAFT' &&
                  iCan('ROLE_CREATE_PRODUCT') && (
                    <button
                      className={`big-btn ml-4 ${
                        checkFields() ? 'opacity-3' : ''
                      }`}
                      onClick={() => publish(product)}
                      disabled={checkFields()}
                    >
                      PUBLICAR
                    </button>
                    )*/}
                {!productId && isEdit && iCan('ROLE_CREATE_PRODUCT') && (
                  <button className='big-btn' onClick={() => create(product)}>
                    GUARDAR RASCUNHO
                  </button>
                )}
                {!productId && isEdit && iCan('ROLE_CREATE_PRODUCT') && (
                  <button
                    className={`big-btn ml-4 ${
                      checkFields() ? 'opacity-3' : ''
                    }`}
                    onClick={() => create(product, 'PUBLISHED')}
                    disabled={checkFields()}
                  >
                    PUBLICAR
                  </button>
                )}
              </div>

              {!productId && isEdit && iCan('ROLE_CREATE_PRODUCT') && (
                <div className='text-center pt-5'>
                  <p className='back-edit' onClick={() => setIsEdit(!isEdit)}>
                    Voltar a edição
                  </p>
                </div>
              )}
            </div>
          </Wrapper>
        </Container>
      </Content>
    </>
  );
};

const Logo = styled.div`
  padding: 0 80px;
  display: flex;
  justify-content: space-between;
  max-width: 87%;

  .image-modal {
    width: 200px;
  }

  .actions {
    height: 20px;
    display: flex;
    width: 200px;
    justify-content: space-between;

    p {
      font-weight: bold;
      position: relative;
      right: 10px;
    }

    .text-remove {
      color: #ff0000;
      right: 20px;
    }

    .remove:hover {
      color: #ff0000;
    }

    .text-deactivate {
      position: relative;
      right: 20px;
    }

    .edit:hover {
      color: #2292cc;
    }

    .text-edit {
      color: #2292cc;
    }

    svg {
      cursor: pointer;
    }

    .wrapper {
      width: 50px;
    }
  }
`;

const Wrapper = styled.div`
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  .react-datepicker-wrapper {
    width: 100% !important;
  }

  .react-datepicker__input-container input {
    width: 100% !important;
    height: 38px;
    padding-left: 10px;
  }

  .header_guerin {
    width: 100%;
  }

  .no-border {
    border: 0 !important;
  }

  .complementary-products {
    display: flex;
    border-bottom: 1px solid #ccc;
    padding: 10px;
    width: 90%;
    margin: 20px 0px 0px 44px;

    .description {
      flex: 2;
      display: flex;
    }

    .input {
      flex: 1;
      display: flex;

      .day {
        color: #2292cc;
      }
    }

    i {
      color: #2292cc;
      font-size: 26px;
      position: relative;
      right: 50px;
    }
  }

  .coverage {
    display: flex;
    border-bottom: 1px solid #ccc;
    width: 90%;
    padding: 15px;
    margin: 20px 0px 0px 44px;

    .service {
      width: 80%;
      display: flex;
    }

    i {
      color: #2292cc;
      font-size: 26px;
      position: relative;
      right: 50px;
    }

    .base,
    .medium,
    .premium,
    .super {
      width: 30%;
      padding: 0 10px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .form {
    padding: 50px 80px;

    .btn-fix-upload {
      text-align: center;
      padding-top: 19px;
    }

    .back-edit {
      font-weight: bold;
      text-decoration: underline;
      cursor: pointer;
    }

    h6 {
      border-bottom: 1px solid #eee;
      color: #2292cc;
      padding-bottom: 10px;
    }

    .section-upload {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;

      img {
        padding: 10px;
        width: 300px;
      }
    }

    .inputs {
      padding: 20px 0;

      .upload {
        width: 200px;
        height: 100px;
        border-radius: 20px;
        box-shadow: 3px 15px 5px -6px rgba(158, 195, 240, 0.62);
      }
    }
  }
`;

export default Guerin;

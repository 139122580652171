import React, { useState, useEffect, useRef } from 'react';
import { Form, Container, Row, Col, Image, Button } from 'react-bootstrap';
import Modal from '@material-ui/core/Modal';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export const ModalBase = ({
  serviceId,
  closeButton,
  open,
  onClose,
  Component,
  product = null,
}) => {
  const getLogo = () => {
    const service = {
      1: require('../../assets/logos/kinto-one-logo-on-2x.png'),
      2: require('../../assets/cart/guerin.png'),
      3: require('../../assets/cart/xtracars.png'),
      4: require('../../assets/cart/rodinhas.png'),
      5: require('../../assets/logos/bedriven-xs-logo-on-2x.png'),
      7: require('../../assets/cart/rodinhas.png'),
    };

    return service[serviceId];
  };

  const getBrandText = () => {
    const brandText = {
      1: 'Renting',
      2: 'Rent-a-car',
      3: 'Aluguer Flexível',
      4: 'Transporte de Crianças',
      5: 'TVDE',
      6: 'Aluguer Flexível',
      7: 'Transporte de Crianças',
    };

    return brandText[serviceId];
  };

  const getBackgroundColor = () => {
    const backGroundColor = {
      1: '#00aeef',
      2: '#6dab24',
      3: '#3B3B3B',
      4: '#ffca00',
      5: '#d8001a',
      6: '#d8001a',
      7: '#ffca00',
    };
    return backGroundColor[serviceId];
  };

  return (
    <Modal open={open} onClose={onClose} className='container_modal_base'>
      <Container
        className='modal_base'
        style={{ backgroundColor: `${getBackgroundColor()}` }}
      >
        <Row className='justify-content-end'>
          <button
            onClick={() => closeButton(serviceId)}
            className='button_close'
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </Row>
        <Row className='d-flex justify-content-center mt-2'>
          {serviceId !== 7 && (
            <h3>{product === null ? 'Adicionar Serviço' : 'Editar serviço'}</h3>
          )}
          {serviceId === 7 && <h3>{'Editar itinerário'}</h3>}
        </Row>
        <Row className='d-flex justify-content-center mt-4 mb-4'>
          {/* <Col className='d-flex justify-content-center'>
            <img src={getLogo()} className='logo_service' />
          </Col> */}
          <Col className='d-flex justify-content-center brand_col'>
            <p>{getBrandText()}</p>
          </Col>
        </Row>
        <Component></Component>
      </Container>
    </Modal>
  );
};

export default ModalBase;

import React from 'react'
import { Table } from 'react-bootstrap'
import styled from 'styled-components'
import dayjs from 'dayjs'

interface Props {
    userData: any
}

const HistoryData = ({ userData }: Props) => {
    return (
        <Wrapper>
            <Table responsive>
                <thead>
                    <tr>
                        <th>Criação</th>
                        <th>Última alteração</th>
                        <th>Criado por</th>
                        <th>Publicado por</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{userData.dateCreated && dayjs(userData.dateCreated).format('DD/MM/YYYY HH:mm')}</td>
                        <td>{userData.dateLastUpdated && dayjs(userData.dateLastUpdated).format('DD/MM/YYYY HH:mm')}</td>
                        <td>{userData.userCreated}</td>
                        <td>{userData.userPublished}</td>
                    </tr>
                </tbody>
            </Table>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    border-bottom: 1px solid #2292CC;
    border-top: 1px solid #2292CC;
    width: 85%;
    margin: 20px 0 0 8%;
`

export default HistoryData
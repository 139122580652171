import api from '../utils/api';

export const contactService = {
    requestContact
};



async function requestContact(data) {
  const response = await api.apiPrivate
    .post('/api/comercial-profile/contact/request-contact', data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        return error.request;
      }
      throw error;
    });
  return response;
}

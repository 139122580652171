import React from 'react';
import { Container, Row, Col, Table, Image, Badge, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Styles } from '../../style/client';
import { TABLE_HEADERS, BADGE_LABEL } from '../../constants/clientConstants';
import { getCreationDate } from '../../utils/utils';

export const ClientTable = ({ clientList, setClientPage }) => {
  const getStatusBadge = (status) => {
    switch (status) {
      case 'ACTIVE':
        return (
          <Badge overlap="rectangular"  style={Styles.activeBadge} pill variant='success'>
            {' '}
            {BADGE_LABEL.ACTIVE}{' '}
          </Badge>
        );
      case 'INACTIVE':
        return (
          <Badge overlap="rectangular"  style={Styles.inactiveBadge} variant='warning'>
            {' '}
            {BADGE_LABEL.INACTIVE}{' '}
          </Badge>
        );
      case 'CANCELED':
        return (
          <Badge overlap="rectangular"  style={Styles.canceledBadge} pill variant='danger'>
            {' '}
            {BADGE_LABEL.CANCELED}{' '}
          </Badge>
        );
      default:
        break;
    }
  };

  const initialName = (words, maxLenght) => {
    'use strict';

    return words.slice(0, maxLenght) + "...";
  };

  return (
    <Table data-testid='clientTable' responsive className={'client_list'}>
      <thead>
        <tr data-testid='tableHeader'>
          <th>{TABLE_HEADERS.CLIENT}</th>
          <th>{TABLE_HEADERS.CONTACT}</th>
          <th>{TABLE_HEADERS.ACCOUNT_STATUS}</th>
          <th>{TABLE_HEADERS.ACCOUNT_CREATION}</th>
        </tr>
      </thead>
      <tbody>
        {clientList &&
          clientList.map((client, _) => (
            <tr
              onClick={() => setClientPage(client)}
              data-testid='clientRow'
              key={client.id}
              className='client_row'
            >
              <td className='align-middle'>
                <Container>
                  <Row>
                    <Col md='auto'>
                      <Image src={client.imageFile} roundedCircle />
                    </Col>
                    <Col md='auto' style={Styles.clientName}>
                      <Row>
                        <OverlayTrigger
                          key={"name-tooltiptop"}
                          placement="right"
                          overlay={
                            <Tooltip id={`tooltipName-top`}>
                              {client.name}
                            </Tooltip>
                          }>
                          <span>
                            {client.name.length > 20
                              ? initialName(client.name, 20)
                              : client.name}
                          </span>
                        </OverlayTrigger>
                      </Row>
                    </Col>
                  </Row>
                </Container>
              </td>

              <td className='align-middle'>
                <Container>
                  <Row>
                    <Col md='auto' style={Styles.clientRowBold}>
                      <Row>
                        <OverlayTrigger
                          key={"name-tooltiptop"}
                          placement="right"
                          overlay={
                            <Tooltip id={`tooltipName-top`}>
                              {client.userContact.name}
                            </Tooltip>
                          }>
                          <span>
                            {client.userContact.name.length > 30
                              ? initialName(client.userContact.name, 30)
                              : client.userContact.name}
                          </span>
                        </OverlayTrigger>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col md='auto' style={Styles.clientRowGray}>
                      {client.userContact.email}
                    </Col>
                  </Row>
                </Container>
              </td>

              <td className='align-middle'>
                <Container>
                  <Row>
                    <Col md='auto'>{getStatusBadge(client.status)}</Col>
                  </Row>
                </Container>
              </td>

              <td className='align-middle'>
                <Container>
                  <Row>
                    <Col
                      data-testid='creationDate'
                      md='auto'
                      style={Styles.clientRowBold}
                    >
                      {getCreationDate(client.dateCreated)}
                    </Col>
                  </Row>
                  <Row>
                    <Col md='auto' style={Styles.clientRowGray}>
                      {/* por Name of creator */}
                    </Col>
                  </Row>
                </Container>
              </td>
            </tr>
          ))}
      </tbody>
    </Table>
  );
};

import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Vehicle } from './reservation.interface';
import { toFixed } from '../../../utils/utils';

export const VehicleCard = ({ vehicle }) => {
  let _vehicle = vehicle as Vehicle;

  return (
    <div className='vehicle_card'>
      <Row>
        <Col md={3}>
          <img src={require('../../../assets/car_placeholder.png')} className='image' />
        </Col>
        <Col md={7}>
          <p className='name'>
            {_vehicle.brand} {_vehicle.model}
          </p>
          <p className='description'>
            Lugares: {_vehicle.seats} / Portas: {_vehicle.doors}
          </p>
          <p className='description'>Grupo: {_vehicle.group}</p>
        </Col>
        <Col md={2}>
          <p className='value'>
            {toFixed(_vehicle.dayValue, 2)}
            <span className='value_description'>€/dia</span>
          </p>
        </Col>
      </Row>
    </div>
  );
};

export default VehicleCard;

import { faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Table } from 'react-bootstrap';
import { invoicesService } from 'src/services/invoicesService';
import { Title, Content } from '../../style/content';
import { Invoice } from './invoices.interface';

const Invoices: React.FC = () => {
  const [invoices, setInvoices] = useState([] as Invoice[]);
  const months = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ];
  useEffect(() => {
    getInvoices();
  }, []);

  const getInvoices = async () => {
   const result = await invoicesService.getInvoices();
    if (result) {
      setInvoices(result);   
    } else {
      console.log(result.message);
    }
  };
  
  return (
    <>
      <Title>Histórico de Reservas</Title>

      <Content>
        <Container fluid className='card-no-tabs user__invoices'>
          <Table responsive className='mt-4'>
            <tbody>
              {invoices.length > 0 &&
                invoices.map((item, i) => {
                  console.log(item);
                  return (
                    <tr key={i}>
                      <td className='center_vertically'>
                        <p className='counter'>{i + 1}</p>
                      </td>
                      <td>
                        <Row>
                          <Col>
                            <p className='big_date'>
                              {dayjs(item.dueDate).date()} de{' '}
                              {months[dayjs(item.dueDate).get('month')]}
                            </p>
                          </Col>
                          <Col>
                            {i === 0 && (
                              <div hidden className='recent'>
                                <p className='mb-2'>Recente</p>
                              </div>
                            )}
                          </Col>
                        </Row>

                        <p className='secondary_text'>
                          Faturação - 01 a {dayjs(item.dueDate).daysInMonth()}{' '}
                          de {months[dayjs(item.dueDate).get('month')]} de{' '}
                          {dayjs(item.dueDate).get('year')}
                        </p>
                      </td>
                      <td className='center_vertically'>
                        <p className='secondary_text'>{item.username}</p>
                      </td>
                      <td className='center_vertically'>
                        <p className='secondary_text'>{item.documentDate}</p>
                      </td>
                      <td className='center_vertically'>
                        <p className='amount '>{item.amount}€</p>
                      </td>
                      <td
                        style={{ width: 30 }}
                        className='no_border center_vertically'
                      >
                        <a download={item.documentDate.concat("_", item.documentNo)} href={'data:application/pdf;base64,' + item.file}>
                        <Button>
                          <FontAwesomeIcon
                            size='1x'
                            icon={faArrowDown}
                            color='#CCCCCC'
                          />
                        </Button>
                        </a>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
          {invoices.length === 0 && <p>Sem faturas para exibir</p>}
        </Container>
      </Content>
    </>
  );
};


export default Invoices;

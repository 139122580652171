import api from '../utils/api';

export const subscriptionService = {
  getProfilePack,
  paymentMonth,
  balanceAvailable,
  reserveValue,
  getMessages,
  createPack,
  calculateValue,
  pay,
  checkPayment,
  paymentToSubscription,
  paymentChange
};

async function paymentChange(data) {
  const response = await api.apiPrivate
    .post('api/payment/payment/payment-by-category', data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        return error.request;
      }
      throw error;
    });
  return response;
}

async function getProfilePack() {
  const response = await api.apiPrivate
    .get('api/product/pack/profile?fillPacksUser=true')
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        console.log(error.request);
        return error.request;
      }
      throw error;
    });
  return response;
}

async function paymentMonth() {
  const response = await api.apiPrivate
    .get('api/payment/payment/payment-month')
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        console.log(error.request);
        return error.request;
      }
      throw error;
    });
  return response;
}

async function balanceAvailable() {
  const response = await api.apiPrivate
    .post('api/payment/payment/balance-available')
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        console.log(error.request);
        return error.request;
      }
      throw error;
    });
  return response;
}

async function reserveValue() {
  const response = await api.apiPrivate
    .post('api/payment/payment/reserve-value')
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        console.log(error.request);
        return error.request;
      }
      throw error;
    });
  return response;
}

async function getMessages(data) {
  const response = await api.apiPrivate
    .post('api/product/messages/subscription-messages', data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        console.log(error.request);
        return error.request;
      }
      throw error;
    });
  return response;
}

async function createPack(data) {
  const response = await api.apiPrivate
    .post('api/product/user-pack/create-pack', data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        console.log(error.request);
        return error.request;
      }
      throw error;
    });
  return response;
}

async function calculateValue(data) {
  const response = await api.apiPrivate
    .post('api/product/pack/value-to-payment', data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        console.log(error.request);
        return error.request;
      }
      throw error;
    });
  return response;
}

async function pay(data) {
  const response = await api.apiPrivate
    .post('api/payment/payment/sibs-payment', data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        console.log(error.request);
        return error.request;
      }
      throw error;
    });
  return response;
}

async function checkPayment(checkoutId) {
  const response = await api.apiPrivate
    .get(`api/payment/payment/sibs-payment-status/${checkoutId}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        console.log(error.request);
        return error.request;
      }
      throw error;
    });
  return response;
}

async function paymentToSubscription(data) {
  const response = await api.apiPrivate
    .post(`api/payment/payment/payment-to-subscription/`, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        console.log(error.request);
        return error.request;
      }
      throw error;
    });
  return response;
}

import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Packs from './Packs';
import Configurator from './Configurator';

const ConfiguratorMain: React.FC = () => {
  const [activeTab, setActiveTab] = useState(1);
  return (
    <>
      <Container fluid className='configurator_main'>
        <Row>
          <Col
            className={`d-flex justify-content-center configurator_tabs ${
              activeTab === 1 ? 'active' : ''
            }`}
            onClick={() => setActiveTab(1)}
          >
            <h2>PACOTES PREDEFINIDOS</h2>
          </Col>
          <Col
            className={`d-flex justify-content-center configurator_tabs ${
              activeTab === 2 ? 'active' : ''
            }`}
            onClick={() => setActiveTab(2)}
          >
            <h2>CONFIGURAR</h2>
          </Col>
        </Row>
        {activeTab === 1 && (
          <div className='configurator_tab'>
            <Packs></Packs>
          </div>
        )}
        {activeTab === 2 && (
          <div className='configurator_tab'>
            <Configurator></Configurator>
          </div>
        )}
      </Container>
    </>
  );
};

export default ConfiguratorMain;

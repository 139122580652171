//GESTOR DE PRODUTO
import ClientList from '../screens/GP/Clients/clientList';
import ClientCreate from '../screens/GP/Clients/clientCreate';
import ClientEdit from '../screens/GP/Clients/clientEdit';
import ClientView from '../screens/GP/Clients/clientView';
import Products from '../screens/GP/Products/Products';
import Rodinhas from '../screens/GP/Products/Rodinhas';
import Finlog from '../screens/GP/Products/Finlog';
import Guerin from '../screens/GP/Products/Guerin';
import Xtracars from '../screens/GP/Products/Xtracars';
import Bedriven from '../screens/GP/Products/Bedriven';
import Packs from '../screens/GP/Packs/Packs';
import PackView from '../screens/GP/Packs/PackView';
import PackCreate from '../screens/GP/Packs/PackCreate';
import ComercialUsers from 'src/screens/GP/Users/Users';
import ComercialUsersView from 'src/screens/GP/Users/UsersView';
import ComercialUsersCreate from 'src/screens/GP/Users/UsersCreate';
import Dashboard from '../screens/GP/Dashboard/Dashboard'
import Movements from '../screens/GP/Movements/movements'

const routes: MyRoute[] = [
  {
    path: '/products',
    exact: true,
    component: Products,
    role: 'ROLE_READ_PRODUCT',
    auth: true,
  },
  {
    path: '/products/rodinhas/create',
    exact: true,
    component: Rodinhas,
    role: 'ROLE_CREATE_PRODUCT',
    auth: true,
  },
  {
    path: '/products/rodinhas/view/:productId',
    exact: true,
    component: Rodinhas,
    role: 'ROLE_READ_PRODUCT',
    auth: true,
  },
  {
    path: '/products/finlog/create',
    exact: true,
    component: Finlog,
    role: 'ROLE_CREATE_PRODUCT',
    auth: true,
  },
  {
    path: '/products/finlog/view/:productId',
    exact: true,
    component: Finlog,
    role: 'ROLE_READ_PRODUCT',
    auth: true,
  },
  {
    path: '/products/guerin/create',
    exact: true,
    component: Guerin,
    role: 'ROLE_CREATE_PRODUCT',
    auth: true,
  },
  {
    path: '/products/guerin/view/:productId',
    exact: true,
    component: Guerin,
    role: 'ROLE_READ_PRODUCT',
    auth: true,
  },
  {
    path: '/products/xtracars/create',
    exact: true,
    component: Xtracars,
    role: 'ROLE_CREATE_PRODUCT',
    auth: true,
  },
  {
    path: '/products/xtracars/view/:productId',
    exact: true,
    component: Xtracars,
    role: 'ROLE_READ_PRODUCT',
    auth: true,
  },
  {
    path: '/products/bedriven/create',
    exact: true,
    component: Bedriven,
    role: 'ROLE_CREATE_PRODUCT',
    auth: true,
  },
  {
    path: '/products/bedriven/view/:productId',
    exact: true,
    component: Bedriven,
    role: 'ROLE_READ_PRODUCT',
    auth: true,
  },
  {
    path: '/packs',
    exact: true,
    component: Packs,
    role: 'ROLE_READ_PACK',
    auth: true,
  },
  {
    path: '/packs/view/:productId',
    exact: true,
    component: PackView,
    role: 'ROLE_READ_PACK',
    auth: true,
  },
  {
    path: '/packs/edit/:productId',
    exact: true,
    component: PackCreate,
    role: 'ROLE_UPDATE_PACK',
    auth: true,
  },
  {
    path: '/packs/create',
    exact: true,
    component: PackCreate,
    role: 'ROLE_CREATE_PACK',
    auth: true,
  },
  {
    path: '/clients',
    exact: true,
    component: ClientList,
    role: 'ROLE_READ_CLIENT',
    auth: true,
  },
  {
    path: '/clients/create',
    exact: true,
    component: ClientCreate,
    role: 'ROLE_CREATE_CLIENT',
    auth: true,
  },
  {
    path: '/clients/edit/:id',
    exact: true,
    component: ClientEdit,
    role: 'ROLE_CREATE_CLIENT',
    auth: true,
  },
  {
    path: '/clients/view/:id',
    exact: true,
    component: ClientView,
    role: 'ROLE_READ_CLIENT',
    auth: true,
  },
  {
    path: '/gp/users',
    exact: true,
    component: ComercialUsers,
    role: 'ROLE_READ_COMMERCIAL_MANAGER',
    auth: true,
  },
  {
    path: '/gp/users/create',
    exact: true,
    component: ComercialUsersCreate,
    role: 'ROLE_CREATE_COMMERCIAL_MANAGER',
    auth: true,
  },
  {
    path: '/gp/users/view/:id',
    exact: true,
    component: ComercialUsersView,
    role: 'ROLE_READ_COMMERCIAL_MANAGER',
    auth: true,
  },
  {
    path: '/gp/dashboard',
    exact: true,
    component: Dashboard,
    role: 'ROLE_READ_PRODUCT_DASHBOARD',
    auth: true,
  },
  {
    path: '/gp/movements',
    exact: true,
    component: Movements,
    role: 'ROLE_GET_RESERVE_MOVEMENTS',
    auth: true,
  },
];

interface MyRoute {
  path: string;
  exact: boolean;
  component: React.FC<{}>;
  role: string;
  auth: boolean;
}

export default routes;

import React, { useState, useEffect, useRef } from 'react';
import { Form, Container, Row, Col, Image, Button } from 'react-bootstrap';
import { Title, Content, Styles } from '../../../style/content';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers, faUser } from '@fortawesome/free-solid-svg-icons';
import { servicesService } from 'src/services/GM/servicesService';
import { userService } from 'src/services/GM/userService';
import { Link, useHistory } from 'react-router-dom';
import { MobilityGroup } from './../Groups/groups.interface';

const ServicesGroup: React.FC = () => {
  const [mobilityGroups, setMobilityGroups] = useState([] as MobilityGroup[]);
  const history = useHistory();

  useEffect(() => {
    getMobilityGroups();
  }, []);

  const getMobilityGroups = async () => {
    const result = await userService.getMobilityGroups();
    if (result) {
      setMobilityGroups(result);
    } else {
      console.log(result.message);
    }
  };

  const continueAction = (id) => {
    history.push(`/gm/services/user/config?groupid=${id}`);
  };

  return (
    <>
      <Title>Gerir serviços</Title>

      <Content>
        <Container
          fluid
          className='card-no-tabs servicesGM_main service_groups'
        >
          <Link className='color-back' to={'/gm/services/'}>
            <p>
              <u>&#60; Voltar</u>
            </p>
          </Link>
          <h1 className='title'>Escolha o grupo mobilidade</h1>
          <h3 className='subtitle'>
            Selecione o grupo mobilidade para o qual deseje configurar os
            serviços.
          </h3>
          <Row className='container_group_card d-flex justify-content-around'>
            {mobilityGroups &&
              mobilityGroups.length > 0 &&
              mobilityGroups.map((item, i) => {
                return (
                  <div
                    className='group_card'
                    onClick={(e) => continueAction(item.id)}
                    key={String(item.id)}
                  >
                    <span className='title'>{item.acronym}</span>
                    <span className='value'>
                      {item.plafondValue ? item.plafondValue : '0'}
                      {''}€
                    </span>
                    <span className='sub_value'>saldo mensal atribuído</span>
                    <div className='services_bar social-networks'>
                      {item.mobilityGroupServiceDTO.map((item, i) => {
                        return (
                          <i
                            className={`icon-service-${item.serviceId}`}
                            key={i}
                          ></i>
                        );
                      })}
                      {item.mobilityGroupServiceDTO &&
                        item.mobilityGroupServiceDTO.length == 0 && (
                          <p>Sem serviços</p>
                        )}
                    </div>
                    <span className='users_label'>
                      {item.users.length}{' '}
                      {item.users.length > 1 ? 'colaboradores' : 'colaborador'}
                    </span>
                  </div>
                );
              })}
          </Row>
        </Container>
      </Content>
    </>
  );
};

export default ServicesGroup;

import React, { useState, useEffect, useRef } from 'react';
import { Form, Container, Row, Col, Image, Button } from 'react-bootstrap';
import { Title, Content, Styles } from '../../../style/content';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers, faUser } from '@fortawesome/free-solid-svg-icons';
import { servicesService } from 'src/services/GM/servicesService';
import { Link } from 'react-router-dom';

const Services: React.FC = () => {
  const [services, setServices] = useState([
    { serviceId: Number(), service: String() },
  ]);

  useEffect(() => {
    getServices();
  }, []);

  const getServices = async () => {
    const result = await servicesService.getServices();
    if (result) {
      setServices(result);
    } else {
      console.log(result.message);
    }
  };

  const getCompany = (id: any) => {
    return {
      1: 'kinto',
      2: 'guerin',
      3: 'xtracars',
      4: 'rodinhas',
      42: 'rodinhas',
      5: 'bedriven',
    }[id];
  };

  return (
    <>
      <Title>Gerir serviços</Title>

      <Content>
        <Container fluid className='card-no-tabs servicesGM_main'>
          <h1 className='title'>Iniciar</h1>
          <h3 className='subtitle'>
            Escolha o tipo de configuração de serviços que pretende.
          </h3>
          <Row className='group_button_card'>
            <Link to={'/gm/services/user/'}>
              <div className='button_card'>
                <Row>
                  <p>
                    <FontAwesomeIcon
                      size='2x'
                      icon={faUser}
                      color='#FFF'
                      className='mr-2'
                    />
                    colaborador
                  </p>
                </Row>
              </div>
            </Link>
            <Link to={'/gm/services/group/'}>
              <div className='button_card'>
                <Row>
                  <p>
                    <FontAwesomeIcon
                      size='2x'
                      icon={faUsers}
                      color='#FFF'
                      className='mr-2'
                    />
                    GRUPOS MOBI
                  </p>
                </Row>
              </div>
            </Link>
          </Row>
          <h1 className='title'>Os seus serviços</h1>
          <h3 className='subtitle'>
            Abaixo estão indicados os serviços atualmente contratados no seu
            contrato.
          </h3>
          <div className='services'>
            {services &&
              services.length > 0 &&
              services.map((item) => {
                return (
                  item.serviceId && (
                    <img
                      src={require(`../../../assets/logos/${getCompany(
                        item.serviceId
                      )}-config.png`)}
                      alt={item.service}
                      className='service_card'
                      key={item.serviceId}
                    />
                  )
                );
              })}
          </div>

          <p className='description'>
            Para alterar o seu contrato e os serviços nele integrados,
            contacte-nos.
          </p>
        </Container>
      </Content>
    </>
  );
};

export default Services;

import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Form } from 'react-bootstrap';
import ModalBase from './ModalBase';
import { storeCart, getCart } from '../../redux/cartReducer';
import { PackProductDTO } from '../Configurator/packs.interface';
import { RodinhasProduct } from '../Configurator/Configurator';
import { configuratorService } from 'src/services/configuratorService';
import { packService } from 'src/services/packService';

export const ModalRodinhas = ({ closeButton, open, onClose, product, onSave }) => {
  const [rodinhasProduct, setRodinhasProduct] = useState(
    [] as RodinhasProduct[]
  );
  const [rodinhasRegions, setRodinhasRegions] = useState([] as string[]);
  const [rodinhasZones, setRodinhasZones] = useState([] as string[]);
  const [rodinhasSubZones, setRodinhasSubZones] = useState([] as string[]);
  const [rodinhasSubZonesDrop, setRodinhasSubZonesDrop] = useState(
    [] as string[]
  );
  const [rodinhasMaxChildrens, setRodinhasMaxChildrens] = useState(9);
  const [rodinhasMaxNumberPerWeek, setRodinhasMaxNumberPerWeek] = useState(10);
  const [rodinhasData, setRodinhasData] = useState({
    numberChildren: 1,
    region: '',
    pickupZone: '',
    pickupSubZone: '',
    dropoffZone: '',
    dropoffSubZone: '',
    numberPerWeek: 1,
    roundTrip: 'false',
  });
  const [cartIndex, setCartIndex] = useState(-1);
  const [isNewProduct, setIsNewProduct] = useState(false);

  useEffect(() => {
    getRodinhasProduct();
  }, []);

  useEffect(() => {
    getRegions();
  }, [rodinhasProduct]);

  useEffect(() => {
    if (open && product === null) {
      setIsNewProduct(true);
    }

    if (!open) {
      setIsNewProduct(false);
      setRodinhasData({
        numberChildren: 1,
        region: '',
        pickupZone: '',
        pickupSubZone: '',
        dropoffZone: '',
        dropoffSubZone: '',
        numberPerWeek: 1,
        roundTrip: 'false',
      });
    }
  }, [open, rodinhasProduct]);

  useEffect(() => {
    if (product && rodinhasProduct) {
      storeCartIndex(product.id);
      getZones(product.region);
      getSubZones(product.pickupZone, 'pickup');
      getSubZones(product.dropoffZone, 'dropoff');
      setRodinhasData({
        numberChildren: product.numberChildren,
        region: product.region,
        pickupZone: product.pickupZone,
        pickupSubZone: product.pickupSubZone,
        dropoffZone: product.dropoffZone,
        dropoffSubZone: product.dropoffSubZone,
        numberPerWeek: product.numberPerWeek,
        roundTrip: product.roundTrip,
      });
    }
  }, [product, rodinhasProduct]);

  const getRodinhasProduct = async () => {
    try {
      const product = await configuratorService.getRodinhasProduct();
      if (product) {
        setRodinhasProduct(product.zones);
        setRodinhasMaxNumberPerWeek(product.maxNumberTime);
        setRodinhasMaxChildrens(product.maxChild);
      }
    } catch (error) {
      console.log("error", error)
    } finally {
    }
  };

  function getRegions() {
    if (rodinhasProduct) {
      if (rodinhasProduct.length > 0) {
        let regions = [] as string[];
        rodinhasProduct.map((item) => {
          if (!regions.includes(item.region)) {
            regions.push(item.region);
          }
        });
        setRodinhasRegions(regions);
      }
    }
  }

  function getZones(region) {
    if (rodinhasProduct) {
      if (rodinhasProduct.length > 0) {
        let zones = [] as string[];
        rodinhasProduct.map((item) => {
          if (item.region === region) {
            if (!zones.includes(item.zone)) {
              zones.push(item.zone);
            }
          }
        });
        setRodinhasZones(zones);
      }
    }
  }

  function getSubZones(zone, type) {
    if (rodinhasProduct.length > 0) {
      let subZones = [] as string[];
      rodinhasProduct.map((item) => {
        if (item.zone === zone) {
          if (!subZones.includes(item.subZone)) {
            subZones.push(item.subZone);
          }
        }
      });
      if (type == 'pickup') {
        setRodinhasSubZones(subZones);
      }

      if (type == 'dropoff') {
        setRodinhasSubZonesDrop(subZones);
      }
    }
  }

  const save = async () => {
      let cart = getCart();
      let array = cart ? JSON.parse(cart) as PackProductDTO[] : [];
      if (isNewProduct) {
        let newProduct = {
          balance: 0.0,
          numberChildren: rodinhasData.numberChildren,
          region: rodinhasData.region,
          pickupZone: rodinhasData.pickupZone,
          pickupSubZone: rodinhasData.pickupSubZone,
          dropoffZone: rodinhasData.dropoffZone,
          dropoffSubZone: rodinhasData.dropoffSubZone,
          roundTrip: rodinhasData.roundTrip === 'true',
          id: rodinhasProduct[0].id ? rodinhasProduct[0].id : 0,
          productName: 'Rodinhas',
          productImage:
            'https://mcf-files-preprod-bucket.s3.eu-west-1.amazonaws.com/rodinhas-images/43d3be82-db1b-11eb-8d19-0242ac130003.png',
          serviceId: 4,
        };
        array.push(newProduct);
        storeCart(array);
        closeButton(4);
        onSave(newProduct);
      } else {
        if (array && array[cartIndex]) {
          array[cartIndex] = {
            ...array[cartIndex],
            balance: 0.0,
            numberChildren: rodinhasData.numberChildren,
            region: rodinhasData.region,
            pickupZone: rodinhasData.pickupZone,
            pickupSubZone: rodinhasData.pickupSubZone,
            dropoffZone: rodinhasData.dropoffZone,
            dropoffSubZone: rodinhasData.dropoffSubZone,
            roundTrip: rodinhasData.roundTrip === 'true',
          };
          storeCart(array);
          closeButton(4);
        }
      }
    
  };

  const storeCartIndex = (id) => {
    let cart = getCart();
    if (cart) {
      let array = JSON.parse(cart) as PackProductDTO[];
      if (array) {
        let index = array.findIndex((el) => el.id === id);
        if (index !== -1) {
          setCartIndex(index);
        }
      }
    }
  };

  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      save();
      setValidated(false);
    }
    setValidated(true);
  };

  const component = () => {
    return (
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <div className='cart_modal modal_rodinhas'>
          <Row className='card'>
            <Row className='mb-2'>
              <Col md={8}>
                <p>Número de crianças</p>
              </Col>
              <Col md={4}>
                <Form.Control
                  as='select'
                  custom
                  value={rodinhasData.numberChildren}
                  onChange={(e) => {
                    setRodinhasData({
                      ...rodinhasData,
                      numberChildren: Number(e.target.value),
                    });
                  }}
                >
                  {Array.from(
                    { length: rodinhasMaxChildrens },
                    (_, i) => i + 1
                  ).map((el) => {
                    return (
                      <option value={el} key={el + 'nchildren'}>
                        {el}
                      </option>
                    );
                  })}
                </Form.Control>
              </Col>
            </Row>
            <Row className='mb-2'>
              <Col md={8}>
                <p>Ida e Volta</p>
              </Col>
              <Col md={4}>
                <Form.Control
                  as='select'
                  custom
                  value={rodinhasData.roundTrip}
                  onChange={(e) => {
                    setRodinhasData({
                      ...rodinhasData,
                      roundTrip: e.target.value,
                    });
                  }}
                >
                  <option value={''}>Escolha</option>
                  <option value={'true'}>Sim</option>
                  <option value={'false'}>Não</option>
                </Form.Control>
              </Col>
            </Row>
            <Row className='mb-2'>
              <Col md={8}>
                <p>Região de Transporte</p>
              </Col>
              <Col md={4}>
                <Form.Control
                  required
                  as='select'
                  custom
                  isValid={false}
                  value={rodinhasData.region}
                  onChange={(e) => {
                    setRodinhasData({
                      ...rodinhasData,
                      region: e.target.value,
                      pickupZone: '',
                      pickupSubZone: '',
                      dropoffZone: '',
                      dropoffSubZone: '',
                    });
                    getZones(e.target.value);
                    setValidated(false);
                  }}
                >
                  <option value=''>Região</option>
                  {rodinhasRegions &&
                    rodinhasRegions.length > 0 &&
                    rodinhasRegions.map((el) => {
                      if (el !== '') {
                        return (
                          <option key={el} value={el}>
                            {el}
                          </option>
                        );
                      }
                    })}
                </Form.Control>
              </Col>
            </Row>
            <Row className='mb-2'>
              <Col md={4}>
                <p>Recolha</p>
              </Col>
              <Col md={4}>
                <Form.Control
                  required
                  as='select'
                  custom
                  value={rodinhasData.pickupZone}
                  onChange={(e) => {
                    setRodinhasData({
                      ...rodinhasData,
                      pickupZone: e.target.value,
                      pickupSubZone: '',
                    });
                    getSubZones(e.target.value, 'pickup');
                    setValidated(false);
                  }}
                >
                  <option value={''}>Escolha</option>
                  {rodinhasZones &&
                    rodinhasZones.length > 0 &&
                    rodinhasZones.map((el) => {
                      if (el !== '') {
                        return (
                          <option key={el} value={el}>
                            {el}
                          </option>
                        );
                      }
                    })}
                </Form.Control>
              </Col>
              <Col md={4}>
                <Form.Control
                  required
                  as='select'
                  custom
                  onChange={(e) => {
                    setRodinhasData({
                      ...rodinhasData,
                      pickupSubZone: e.target.value,
                    });
                    setValidated(false);
                  }}
                  value={rodinhasData.pickupSubZone}
                >
                  <option value={''}>Escolha</option>
                  {rodinhasSubZones &&
                    rodinhasSubZones.length > 0 &&
                    rodinhasSubZones.map((el) => {
                      return (
                        <option key={el} value={el}>
                          {el}
                        </option>
                      );
                    })}
                </Form.Control>
              </Col>
            </Row>
            <Row className='mb-2'>
              <Col md={4}>
                <p>Entrega</p>
              </Col>
              <Col md={4}>
                <Form.Control
                  required
                  as='select'
                  custom
                  onChange={(e) => {
                    setRodinhasData({
                      ...rodinhasData,
                      dropoffZone: e.target.value,
                      dropoffSubZone: '',
                    });
                    getSubZones(e.target.value, 'dropoff');
                    setValidated(false);
                  }}
                  value={rodinhasData.dropoffZone}
                >
                  <option value={''}>Escolha</option>
                  {rodinhasZones &&
                    rodinhasZones.length > 0 &&
                    rodinhasZones.map((el) => {
                      return (
                        <option key={el} value={el}>
                          {el}
                        </option>
                      );
                    })}
                </Form.Control>
              </Col>
              <Col md={4}>
                <Form.Control
                  required
                  as='select'
                  custom
                  onChange={(e) => {
                    setRodinhasData({
                      ...rodinhasData,
                      dropoffSubZone: e.target.value,
                    });
                    setValidated(false);
                  }}
                  value={rodinhasData.dropoffSubZone}
                >
                  <option value={''}>Escolha</option>
                  {rodinhasSubZonesDrop &&
                    rodinhasSubZonesDrop.length > 0 &&
                    rodinhasSubZonesDrop.map((el) => {
                      return (
                        <option key={el} value={el}>
                          {el}
                        </option>
                      );
                    })}
                </Form.Control>
              </Col>
            </Row>
          </Row>

          <Row className='row_button'>
            <Button className='button' type='submit'>
              GUARDAR
            </Button>
          </Row>
        </div>
      </Form>
    );
  };

  return (
    <>
      <ModalBase
        serviceId={4}
        Component={component}
        closeButton={closeButton}
        open={open}
        onClose={onClose}
        product={product}
      ></ModalBase>
    </>
  );
};

export default ModalRodinhas;

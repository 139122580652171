import React, { useEffect } from 'react';
import Loading from 'react-fullscreen-loading';
import { AuthService } from '../../services/auth.service';
import { IDGO_PROD, IDGO_PRE, SERVER_ENV } from '../../constants/config';

const Logout: React.FC = () => {
  useEffect(() => {
    logout();
  }, []);

  const logout = async () => {
    try {
      await AuthService.logout();
    } catch (e) {
      console.log(e);
    }

    if ((SERVER_ENV as string) === 'prod') {
      window.location.replace(
        `${IDGO_PROD}/logout.php?response_type=code&client_id=mycarflix&lang=pt&state=${SERVER_ENV}`
      );
    } else {
      window.location.replace(
        `${IDGO_PRE}/logout.php?response_type=code&client_id=mycarflix&lang=pt&state=${SERVER_ENV}`
      );
    }
  };

  return (
    <>
      <Loading
        loading
        background='rgba(255, 255, 255, .4)'
        loaderColor='#3498db'
      />
    </>
  );
};

export default Logout;

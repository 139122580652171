import React from 'react';
import { Container, Row, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc'
import tz from 'dayjs/plugin/timezone'

dayjs.extend(utc)
dayjs.extend(tz)
const timeZone = dayjs.tz.guess();

export const PricingTable = ({
  promocodeList,
  setPromocodePage,
  deletePromocode,
}) => {
  const getCompany = (id: any) => {
    return {
      1: 'Kinto One',
      2: 'Guerin',
      3: 'Xtracars',
      4: 'Rodinhas',
      5: 'Bedriven',
    }[id];
  };
  return (
    <Table
      data-testid='clientTable'
      responsive
      className={'client_list users_table'}
      size='sm'
    >
      <thead>
        <tr data-testid='tableHeader'>
          <th>Código</th>
          <th>Tipo de desconto</th>
          <th>Tipo de Cliente</th>
          <th>Tarifa</th>
          <th>Serviço</th>
          <th>Início</th>
          <th>Fim</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {promocodeList &&
          promocodeList.map((promocode, index) => (
            <tr
              data-testid='clientRow'
              key={promocode.id + index.toString()}
              className='client_row'
            >
              <td className='align-middle'>{promocode.code}</td>
              <td className='align-middle'>{promocode.discountType}</td>
              <td className='align-middle'>{promocode.clientType}</td>
              <td className='align-middle'>{promocode.fareType}</td>
              <td className='align-middle'>
                {getCompany(promocode.serviceId)}
              </td>
              <td className='align-middle'>
                {dayjs.utc(promocode.beginDate).tz(timeZone).format('DD/MM/YY HH:mm')}
              </td>
              <td className='align-middle'>
                {dayjs.utc(promocode.expirationDate).tz(timeZone).format('DD/MM/YY HH:mm')}
              </td>
              <td className='align-middle'>
                <Container>
                  <Row>
                    {/*     <FontAwesomeIcon
                      color='#eeeeee'
                      icon={faUserEdit}
                      size='lg'
                      className='mr-4'
                      onClick={() => setPromocodePage(promocode)}
                    /> */}
                <FontAwesomeIcon
                      color='#eeeeee'
                      icon={faTrashAlt}
                      size='lg'
                      onClick={() => deletePromocode(promocode)}
                    />
                  </Row>
                </Container>
              </td>
            </tr>
          ))}
      </tbody>
    </Table>
  );
};

const env_type = {
  local: 'local',
  dev: 'dev',
  preprod: 'preprod',
  production: 'prod',
};

let API_ENV = env_type.preprod
let URL_BASE =
  API_ENV === env_type.local
    ? 'http://localhost:3000'
    : API_ENV === env_type.dev
    ? 'http://172.16.102.28'
    : API_ENV === env_type.preprod
    ? 'https://quaapi.mycarflix.pt'
    : API_ENV === env_type.production
    ? 'https://api.mycarflix.pt'
    : 'https://api.mycarflix.pt';
let URL_API_PUBLIC = `${URL_BASE}:8084`;
let URL_API_PRIVATE = `${URL_BASE}:8084`;
let URL_FE_PUBLIC = 'http://localhost:3000';
let SERVER_ENV = env_type.local;
let URL_DEV = 'http://172.16.102.28';
let URL_QA = 'http://172.16.102.29';
let URL_LOCAL = 'http://localhost:3000';
let IDGO_PRE = 'https://demoidgo.rigorcg.pt/oauth2';
let IDGO_PROD = 'https://id.caetanogo.pt/oauth2';
let SERVER_BACKOFFICE = 'http://localhost:3000';

if (window._env_) {
  URL_API_PUBLIC = window._env_.serverAPIURL;
  URL_BASE = window._env_.serverAPIURL;
  URL_API_PRIVATE = window._env_.serverAPIURL;
  URL_FE_PUBLIC = window._env_.serverFEUrl;
  SERVER_ENV = window._env_.serverENV;
  URL_DEV = window._env_.serverDevURL;
  URL_QA = window._env_.serverQAURL;
  URL_LOCAL = window._env_.serverLocalURL;
  IDGO_PRE = window._env_.serverIDPreprod;
  IDGO_PROD = window._env_.serverIDProd;
  SERVER_BACKOFFICE = window._env_.serverBackoffice;
}

export {
  URL_BASE,
  URL_API_PUBLIC,
  URL_API_PRIVATE,
  URL_FE_PUBLIC,
  SERVER_ENV,
  URL_DEV,
  URL_QA,
  URL_LOCAL,
  IDGO_PRE,
  IDGO_PROD,
  SERVER_BACKOFFICE,
};

const HEADERS = {
  'Content-Type': 'application/json;charset=utf-8',
  Accept: 'application/json;charset=UTF-8',
  'Access-Control-Allow-Origin': '*',
  'Access-Control-Allow-Methods': '*',
  'Access-Control-Allow-Headers': '*',
  'x-mycarflix-agent': 'web',
};

const HEADERS_PUBLIC = {
  'Content-Type': 'application/json;charset=utf-8',
  Accept: 'application/json;charset=UTF-8',
  'Access-Control-Allow-Origin': '*',
  'Access-Control-Allow-Methods': '*',
  'Access-Control-Allow-Headers': '*',
  'x-mycarflix-agent': 'web',
  Authorization: '50a98fc1-8248-4dff-bad5-4a1e4be9e3e9',
};

export { HEADERS, HEADERS_PUBLIC };

const KEY_TOKEN = '@Auth:userToken';
const KEY_DETAILS = '@Auth:userDetails';
const KEY_PROFILE = '@Auth:userProfile';
const KEY_PROFILE_MANAGER = '@Auth:userProfileManager';
const KEY_CART = '@Cart:userCart';
const SUBS_PROC = '@Cart:subsProc';
const PACK_TO_PAY = '@Cart:packToPay';
const PAYMENT_AUTH = '@Cart:paymentAuth';
const GO_TO_CART = '@Cart:goTo';
const PROMO_CODE = '@Pricing:promoCode';

export {
  KEY_TOKEN,
  KEY_DETAILS,
  KEY_PROFILE,
  KEY_PROFILE_MANAGER,
  KEY_CART,
  SUBS_PROC,
  PACK_TO_PAY,
  PAYMENT_AUTH,
  GO_TO_CART,
  PROMO_CODE,
};

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useParams, Link, useHistory } from 'react-router-dom';
import { Form, Col, Row, Modal, Button, Container } from 'react-bootstrap';
import {
  faTrashAlt,
  faPowerOff,
  faEdit,
} from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-datepicker';
import dayjs from 'dayjs';
import Loading from 'react-fullscreen-loading';
import { motion } from 'framer-motion';
import SweetAlert from 'react-bootstrap-sweetalert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toFixed } from '../../../utils/utils';
import { packService } from '../../../services/packService';
import { productService } from '../../../services/productService';
import { companyService } from '../../../services/companyService';
import { Title, Content, Styles } from '../../../style/content';
import PackHeader from '../../../components/Packs/PackHeader';
import {
  animationInvisible,
  animationVariants,
  animationVisible,
} from 'src/components/Animations/animations';

interface Props {
  productId: any;
  reloadPacks: () => void;
}

const Pack: React.FC = () => {
  const [pack, setPack] = useState({
    active: true,
    deleted: false,
    monthlyValue: Number(),
    id: null,
    name: '',
    packValidate: null as any,
    packProductDTO: [
      {
        productId: '',
        productName: '',
        serviceId: '1',
        valuePack: '',
        pack: '',
        balance: 0,
        monthlyAmountInclVAT: 0,
      },
      {
        pack: '',
        productId: 0,
        productName: '',
        serviceId: '2',
        valuePack: '',
        groupId: '',
        segmentId: 0,
        segmentDescription: '',
        days: 0,
        balance: 0,
        segment: '',
      },
      {
        pack: '',
        productId: 0,
        productName: '',
        serviceId: '3',
        valuePack: '',
        groupId: '',
        segmentId: 0,
        segmentDescription: '',
        months: 0,
        balance: 0,
        segment: '',
      },
      {
        pack: '',
        productName: '',
        serviceId: '4',
        valuePack: '',
        region: '',
        pickupZone: '',
        pickupSubZone: '',
        dropoffZone: '',
        dropoffSubZone: '',
        numberPerWeek: '',
        roundTrip: '',
        numberChildren: 0,
        balance: 0,
      },
      {
        pack: '',
        productId: '',
        productName: '',
        serviceId: '5',
        valuePack: '',
        balance: 0,
      },
    ],
    listPackChildren: [] as any,
  });
  const [isEdit, setIsEdit] = useState(false);
  const [packEdition, setPackEdition] = useState(false);
  const [isResume, setIsResume] = useState(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showDeactivate, setShowDeactivate] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [modalActivePack, setModalActivePack] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');
  const [showCreatedPack, setShowCreatedPack] = useState(false);
  const [packSize, setPackSize] = useState(0);
  const [services, setServices] = useState([] as any);
  const { productId } = useParams();
  const [finlogList, setFinlogList] = useState([
    {
      name: '',
      id: Number(),
      productId: '',
      monthlyAmountInclVAT: Number(),
    },
  ]);
  const [guerinList, setGuerinList] = useState([
    {
      segment: '',
      name: '',
      id: Number(),
      groupID: '',
      productId: '',
      segmentDescription: '',
    },
  ]);
  const [xtracarsList, setXtracarsList] = useState([
    {
      segment: '',
      name: '',
      id: Number(),
      groupID: '',
      productId: '',
      segmentDescription: '',
    },
  ]);
  const [bedrivenList, setBedrivenList] = useState([]);

  //Finlog
  const [finlogPackValue, setFinlogPackValue] = useState(0.0);

  //Rodinhas
  const [rodinhasProduct, setRodinhasProduct] = useState();
  const [rodinhasPackValue, setRodinhasPackValue] = useState(0.0);
  const [rodinhasProductRegions, setRodinhasProductRegions] = useState([]);
  const [rodinhasZonesPickup, setRodinhasZonesPickup] = useState([]);
  const [rodinhasZonesDropoff, setRodinhasZonesDropoff] = useState([]);
  const [rodinhasSubZonesPickup, setRodinhasSubZonesPickup] = useState([]);
  const [rodinhasSubZonesDropoff, setRodinhasSubZonesDropoff] = useState([]);
  const [rodinhasSelectedConfig, setRodinhasSelectedConfig] = useState(
    {} as RodinhasConfig
  );
  const [rodinhasMaxNumberTime, setRodinhasMaxNumberTime] = useState(5);
  const [rodinhasMaxNumberChildrens, setRodinhasMaxNumberChildrens] =
    useState(5);
  const [rodinhasUpdated, setRodinhasUpdated] = useState(false);

  //Guerin
  const [guerinGroups, setGuerinGroups] = useState(['']);
  const [guerinPackValue, setGuerinPackValue] = useState(0.0);

  //Xtracars
  const [xtracarsGroups, setXtracarsGroups] = useState(['']);
  const [xtracarsPackValue, setXtracarsPackValue] = useState(0.0);

  const [totalPackValue, setTotalPackValue] = useState(0.0);
  const [segments, setSegments] = useState([
    {
      id: Number(),
      imageUrl: '',
      segmentDescription: '',
      segmentId: Number(),
    },
  ]);

  const [companies, setCompanies] = useState([]);
  const [dtoList, setDtoList] = useState([]);
  const [successDialog, setSuccessDialog] = useState(false);
  const [errorDialog, setErrorDialog] = useState(false);
  const history = useHistory();

  useEffect(() => {
    const getPack = async (packId: string) => {
      setIsLoading(true);
      try {
        const companies = await companyService.getAll();
        setCompanies(companies.data);
        createDTOSkeleton(companies.data);

        const result = await packService.getPackById(packId);
        await handleDTO(result.data, companies.data);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };

    const getCompanies = async () => {
      try {
        const result = await companyService.getAll();
        setCompanies({ ...result.data });
        createDTOSkeleton(result.data);
      } catch (error) {
        console.log(error);
      } finally {
      }
    };

    const getSegments = async () => {
      try {
        const resultSegments = await productService.getGuerinXtracarsSegments();
        setSegments(resultSegments.data);
      } catch (error) {
        console.log(error);
      } finally {
      }
    };
    getSegments();
    getProducts();
    getCompanies();

    if (productId) {
      getPack(productId);
    }
  }, []);

  useEffect(() => {
    let total =
      Number(rodinhasPackValue) +
      Number(guerinPackValue) +
      Number(xtracarsPackValue) +
      Number(finlogPackValue);
    pack.monthlyValue = total;
    const newPack = pack;
    newPack.monthlyValue = total;
    setPack({ ...pack, ...newPack });
    setTotalPackValue(total);
  }, [finlogPackValue, rodinhasPackValue, guerinPackValue, xtracarsPackValue]);

  const getProducts = async () => {
    try {
      const products = await Promise.allSettled([
        productService.getProductsByCompany(
          'finlog',
          'active=true&deleted=false&productState=PUBLISHED'
        ),
        productService.getProductsByGuerinXtracars(
          'guerin',
          'active=true&deleted=false&productState=PUBLISHED&serviceId=2'
        ),
        productService.getProductsByGuerinXtracars(
          'xtracars',
          'active=true&deleted=false&productState=PUBLISHED&serviceId=3'
        ),
        productService.getProductsByCompany(
          'rodinhas',
          'active=true&deleted=false&productState=PUBLISHED'
        ),
        // productService.getProductsByCompany(
        //   'bedriven',
        //   'deleted=false&productState=PUBLISHED'
        // ),
      ]);
      if (products[0].status === 'fulfilled')
        setFinlogList(handleDeleted(products[0]['value'].data));
      if (products[1].status === 'fulfilled') {
        setGuerinList(handleDeleted(products[1]['value'].data));
        guerinGroups.pop();
      }
      if (products[2].status === 'fulfilled') {
        setXtracarsList(handleDeleted(products[2]['value'].data));
        xtracarsGroups.pop();
      }

      if (products[3].status === 'fulfilled') {
        setRodinhasProduct(products[3]['value'].data[0]);
        updateRodinhasConfig('productId', products[3]['value'].data[0].id);
        //update regions
        let zones = products[3]['value'].data[0].zones;
        const regions = zones.reduce((acc, current) => {
          const x = acc.find((item) => item.region === current.region);
          if (!x) {
            return acc.concat([current]);
          } else {
            return acc;
          }
        }, []);
        setRodinhasProductRegions(regions);

        //limit number of times
        let prices = products[3]['value'].data[0].prices;
        let maxNumberTimes = 0;
        prices.map((item) => {
          if (item.numberTime > maxNumberTimes) {
            maxNumberTimes = item.numberTime;
          }
        });
        setRodinhasMaxNumberTime(maxNumberTimes);

        //limit number of childrens
        let discounts = products[3]['value'].data[0].discounts;
        let maxNumberChildrens = 0;
        discounts.map((item) => {
          if (item.children > maxNumberChildrens) {
            maxNumberChildrens = item.children;
          }
        });
        setRodinhasMaxNumberChildrens(maxNumberChildrens);
      }

      // if (products[4].status === 'fulfilled')
      //   setBedrivenList(handleDeleted(products[4]['value'].data));
    } catch (error) {
      console.log(error);
    } finally {
    }
  };

  const handleDeleted = (productList) => {
    return productList.filter((product) => !product.deleted);
  };

  useEffect(() => {
    if (productId && pack.id && !packEdition) {
      setPackEdition(true);
      if (guerinList) {
        const products = guerinList?.filter(
          (el) => el.segment === pack.packProductDTO[1].segmentId?.toString()
        );
        let groups: string[] = [];
        products.map((item) => {
          if (!groups.includes(item.groupID)) {
            groups.push(item.groupID);
          }
        });
        setGuerinGroups(groups);
        setGuerinPackValue(pack.packProductDTO[1].balance);
      }
      if (xtracarsList) {
        const products = xtracarsList?.filter(
          (el) => el.segment === pack.packProductDTO[2].segmentId?.toString()
        );
        let groups: string[] = [];
        products.map((item) => {
          if (!groups.includes(item.groupID)) {
            groups.push(item.groupID);
          }
        });
        setXtracarsGroups(groups);
        let value = toFixed(
          pack.packProductDTO[2].balance /
            Number(pack.packProductDTO[2].valuePack)
        );
        setXtracarsPackValue(value);
      }
      if (pack.packProductDTO[3].productId !== '' && !rodinhasUpdated) {
        let rodinhasPack = pack.packProductDTO[3];
        updateRodinhasConfig('region', rodinhasPack.region);
        updateRodinhasConfig('pickupZone', rodinhasPack.pickupZone);
        updateRodinhasConfig('pickupSubZone', rodinhasPack.pickupSubZone);
        updateRodinhasConfig('dropoffZone', rodinhasPack.dropoffZone);
        updateRodinhasConfig('dropoffSubZone', rodinhasPack.dropoffSubZone);
        updateRodinhasConfig('numberPerWeek', rodinhasPack.numberPerWeek);
        updateRodinhasConfig('numberChildren', rodinhasPack.numberChildren);
        updateRodinhasConfig('roundTrip', rodinhasPack.roundTrip);
        setRodinhasUpdated(true);
      }
      if (pack.packProductDTO[0].productId !== '') {
        setFinlogPackValue(
          toFixed(pack.packProductDTO[0].balance as number, 2)
        );
      }
    }
  }, [pack, productId]);

  const handleDTO = async (newPack: any, newCompanies: any) => {
    if (newPack?.listPackChildren?.length) {
      newPack.listPackChildren.forEach((child) => {
        const ids = child.packProductDTO.map((item) => item.serviceId);
        const dtos = createDTOSkeleton(newCompanies);
        const filtered = dtos.filter(
          (item: any) => !ids.includes(item.serviceId)
        );
        const result = [...filtered, ...child.packProductDTO];
        const sortedResult = result.sort((a, b) => a.serviceId - b.serviceId);
        child.packProductDTO = sortedResult;
      });

      setPack({ ...pack, ...newPack });
    } else {
      const ids = newPack.packProductDTO.map((item) => item.serviceId);
      const dtos = createDTOSkeleton(newCompanies);
      const filtered = dtos.filter(
        (item: any) => !ids.includes(item.serviceId)
      );
      const result = [...filtered, ...newPack.packProductDTO];
      const sortedResult = result.sort((a, b) => a.serviceId - b.serviceId);
      newPack.packProductDTO = sortedResult;

      setPack({ ...pack, ...newPack });
      setServices([...Array.from(new Set(ids))]);
    }
  };

  const createDTOSkeleton = (companiesList: any) => {
    const result = companiesList.reduce((map, item) => {
      map.push({
        productId: '',
        productName: '',
        serviceId: item.id,
        valuePack: '',
        region: '',
        pickupZone: '',
        pickupSubZone: '',
        dropoffZone: '',
        dropoffSubZone: '',
        numberPerWeek: '',
        roundTrip: '',
        numberChildren: 0,
        balance: '',
        months: '',
        days: '',
      });

      return map;
    }, []);

    setDtoList(result);

    return result;
  };

  const getCompany = (id: any) => {
    return {
      1: 'finlog',
      2: 'guerin',
      3: 'xtracars',
      4: 'rodinhas',
      42: 'rodinhas',
      5: 'bedriven',
    }[id];
  };

  const updatePack = (event: any, index: any, serviceId: any) => {
    const { options, selectedIndex, value } = event.target;

    const newPack = pack;

    newPack.packProductDTO[index].productId = value;
    newPack.packProductDTO[index].productName =
      options[selectedIndex].innerHTML;
    newPack.packProductDTO[index].serviceId = serviceId;

    setPack({ ...pack, ...newPack });

    if (index === 0) {
      let index = finlogList.findIndex((el) => el.id == value);
      if (index !== -1) {
        setFinlogPackValue(toFixed(finlogList[index].monthlyAmountInclVAT, 2));
      } else {
        setFinlogPackValue(0.0);
      }
    }
  };

  const updateGuerinXtracars = async (
    event: any,
    index: any,
    serviceId: any,
    key: any
  ) => {
    const { value } = event.target;
    const newPack = pack;
    newPack.packProductDTO[index][key] = value;

    //segment
    if (key === 'segmentId') {
      if (serviceId === 2) {
        const products = guerinList?.filter((el) => el.segment === value);
        let groups: string[] = [];
        products.map((item) => {
          if (!groups.includes(item.groupID)) {
            groups.push(item.groupID);
          }
        });
        setGuerinGroups(groups);
        setGuerinPackValue(0.0);
      } else if (serviceId === 3) {
        const products = xtracarsList?.filter((el) => el.segment === value);
        let groups: string[] = [];
        products.map((item) => {
          if (!groups.includes(item.groupID)) {
            groups.push(item.groupID);
          }
        });
        setXtracarsGroups(groups);
        setXtracarsPackValue(0.0);
      }
      newPack.packProductDTO[index].productId = '';
      newPack.packProductDTO[index].productName = '';
      newPack.packProductDTO[index].valuePack = '';
      newPack.packProductDTO[index].groupId = '';
    }

    //group
    if (key === 'groupId') {
      if (serviceId === 2) {
        const guerinIndex = guerinList?.findIndex(
          (el) => el.groupID == value.toString()
        );
        if (guerinIndex >= 0) {
          newPack.packProductDTO[index].productId = guerinList[guerinIndex].id;
        }
        setGuerinPackValue(0.0);
        newPack.packProductDTO[index].productName = '';
        newPack.packProductDTO[index].valuePack = '';
      } else if (serviceId === 3) {
        const xtracarsIndex = xtracarsList?.findIndex(
          (el) => el.groupID == value.toString()
        );
        if (xtracarsIndex >= 0) {
          newPack.packProductDTO[index].productId =
            xtracarsList[xtracarsIndex].id;
        }
        setXtracarsPackValue(0.0);
        newPack.packProductDTO[index].productName = '';
        newPack.packProductDTO[index].valuePack = '';
      }
    }

    //days or months
    if (key === 'days' || key === 'months') {
      newPack.packProductDTO[index].valuePack = value;
      const result = await packService.calculateGuerinXtracarsValue(
        pack.packProductDTO[index]
      );
      if (result.data) {
        if (key === 'days') {
          newPack.packProductDTO[index].productName = 'Guerin';
          setGuerinPackValue(result.data);
        } else if (key === 'months') {
          newPack.packProductDTO[index].productName = 'Xtracars';
          let total = toFixed(result.data, 2);
          setXtracarsPackValue(total);
        }
      }
    }

    setPack({ ...pack, ...newPack });
  };

  const updatePackChildren = (
    event: any,
    indexChildren: any,
    indexDTO: any,
    serviceId: any
  ) => {
    const { options, selectedIndex, value } = event.target;

    const newPack = pack;

    newPack.listPackChildren[indexChildren].packProductDTO[indexDTO].productId =
      value;
    newPack.listPackChildren[indexChildren].packProductDTO[
      indexDTO
    ].productName = options[selectedIndex].innerHTML;

    setPack({ ...pack, ...newPack });
  };

  const updateValuePack = (index: any, value) => {
    const newPack = pack;

    newPack.packProductDTO[index].valuePack = value;

    setPack({ ...pack, ...newPack });
  };

  const updateRodinhasConfig = (key: string, value: any) => {
    const config = rodinhasSelectedConfig;
    config[key] = value;
    setRodinhasSelectedConfig(config as RodinhasConfig);
    if (key === 'region') {
      deleteKeyRodinhasConfig('pickupZone');
      deleteKeyRodinhasConfig('dropoffZone');
      deleteKeyRodinhasConfig('pickupSubZone');
      deleteKeyRodinhasConfig('dropoffSubZone');
      setRodinhasSubZonesPickup([]);
      setRodinhasSubZonesDropoff([]);
      if (rodinhasProduct !== undefined) {
        let product = rodinhasProduct as any;
        if (product.zones !== undefined) {
          const zones = product.zones?.filter((el) => el.region === value);
          const zonesReduce = zones.reduce((acc, current) => {
            const x = acc.find((item) => item.zone === current.zone);
            if (!x) {
              return acc.concat([current]);
            } else {
              return acc;
            }
          }, []);
          setRodinhasZonesDropoff(zonesReduce);
          setRodinhasZonesPickup(zonesReduce);
        }
      }
    }
    if (key === 'pickupZone' || key === 'dropoffZone') {
      let product = rodinhasProduct as any;
      if (product.zones !== undefined) {
        const zones = product.zones?.filter((el) => el.zone === value);

        const zonesReduce = zones.reduce((acc, current) => {
          const x = acc.find((item) => item.subZone === current.subZone);
          if (!x) {
            return acc.concat([current]);
          } else {
            return acc;
          }
        }, []);
        if (key === 'pickupZone') {
          deleteKeyRodinhasConfig('pickupSubZone');
          setRodinhasSubZonesPickup(zonesReduce);
        } else if (key === 'dropoffZone') {
          deleteKeyRodinhasConfig('dropoffSubZone');
          setRodinhasSubZonesDropoff(zonesReduce);
        }
      }
    }
    validateRodinhasConfig();
  };

  const deleteKeyRodinhasConfig = (key: string) => {
    const config = rodinhasSelectedConfig;
    delete config[key];
    setRodinhasSelectedConfig(config as RodinhasConfig);
  };

  const validateRodinhasConfig = async () => {
    if (
      rodinhasSelectedConfig.region !== undefined &&
      rodinhasSelectedConfig.pickupZone !== undefined &&
      rodinhasSelectedConfig.pickupSubZone !== undefined &&
      rodinhasSelectedConfig.dropoffZone !== undefined &&
      rodinhasSelectedConfig.dropoffSubZone !== undefined &&
      rodinhasSelectedConfig.numberChildren !== undefined &&
      rodinhasSelectedConfig.roundTrip !== undefined &&
      rodinhasSelectedConfig.productId !== undefined
    ) {
      const result = await packService.calculateRodinhasValue(
        rodinhasSelectedConfig
      );

      if (result.data) {
        setRodinhasPackValue(result.data);
        updateValuePack(3, result.data);
        const newPack = pack;
        newPack.packProductDTO[3].productName = 'rodinhas';
        newPack.packProductDTO[3].productId =
          rodinhasSelectedConfig.productId.toString();
        newPack.packProductDTO[3].region = rodinhasSelectedConfig.region;
        newPack.packProductDTO[3].dropoffSubZone =
          rodinhasSelectedConfig.dropoffSubZone;
        newPack.packProductDTO[3].dropoffZone =
          rodinhasSelectedConfig.dropoffZone;
        newPack.packProductDTO[3].pickupSubZone =
          rodinhasSelectedConfig.pickupSubZone;
        newPack.packProductDTO[3].pickupZone =
          rodinhasSelectedConfig.pickupZone;
        newPack.packProductDTO[3].roundTrip = rodinhasSelectedConfig.roundTrip;
        newPack.packProductDTO[3].numberChildren =
          rodinhasSelectedConfig.numberChildren;
        newPack.packProductDTO[3].numberPerWeek =
          rodinhasSelectedConfig.numberPerWeek;

        setPack({ ...pack, ...newPack });
      }
    }
  };

  const updateValueChildren = (
    value: string,
    indexChildren: any,
    indexDTO: any
  ) => {
    const newPack = pack;

    newPack.listPackChildren[indexChildren].packProductDTO[indexDTO].valuePack =
      value;

    setPack({ ...pack, ...newPack });
  };

  const updateChildrenName = (name: string, index: any) => {
    const newPack = pack;

    newPack.listPackChildren[index].name = name;

    setPack({ ...pack, ...newPack });
  };

  const handlePackBeforeSubmit = (newPack: any) => {
    const handledPack = { ...newPack };

    /*
     * If there are variants, pass all packs to list pack children.
     */
    if (handledPack.listPackChildren.length > 0) {
      handledPack.listPackChildren.push({
        name: pack.name,
        packProductDTO: handledPack.packProductDTO,
      });
      handledPack.name = null;
      handledPack.packProductDTO = [];

      handledPack.listPackChildren.forEach((child: any) => {
        if (!child.name) {
          child.name = null;
          child.packProductDTO = {};
        } else {
          child.packProductDTO = child.packProductDTO.map((dto) => {
            if (!dto.productName) {
              dto.pack = null;
              dto.productId = null;
              dto.productName = null;
              dto.serviceId = null;
              dto.valuePack = null;
            }

            return dto;
          });
        }
      });
    } else {
      handledPack.packProductDTO = handledPack.packProductDTO.filter(
        (dto) => dto.productId
      );
    }

    return handledPack;
  };

  const removeEmptyVariants = (packs: any) => {
    const newPack = { ...packs };

    newPack.listPackChildren = newPack.listPackChildren.filter(
      (item) => item.packProductDTO.length
    );

    return newPack;
  };

  const createPack = async (newPack: any) => {
    setIsLoading(true);

    const dataPack = { ...newPack };
    const handledPack = handlePackBeforeSubmit(dataPack);
    const removedVairants = removeEmptyVariants(handledPack);
    const size = removedVairants.packProductDTO.length
      ? removedVairants.packProductDTO.length
      : 1;
    setPackSize(size);
    try {
      const result = await packService.create(removedVairants);
      // const handledVairants = handleVariants()
      // setPack({ ...pack,...handledVairants })
      setShowCreatedPack(true);
      setAlertMsg(result.message);
    } catch (error) {
      setAlertMsg(error?.response?.data?.message ?? 'Erro desconhecido.');
      setErrorDialog(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleIdBeforeSubmit = (handledPack: any) => {
    const handled = { ...handledPack };

    if (handled?.listPackChildren?.length) {
      handledPack.listPackChildren.forEach((item) => {
        item.packProductDTO = item.packProductDTO.map((dto) => {
          if (!dto.pack) {
            dto.pack = item.id;
          }

          if (!dto.productId) dto.productId = null;

          return dto;
        });
      });
    } else {
      handled.packProductDTO = handled.packProductDTO.map((item) => {
        if (!item.pack) {
          item.pack = handled.id;
        }

        return item;
      });
    }

    return handled;
  };

  const handleVariantUpdate = (packUpdate: any) => {
    const dataPack = { ...packUpdate };

    if (dataPack.packProductDTO) {
      dataPack.packProductDTO = dataPack.packProductDTO.filter(
        (dto) => dto.productName
      );
    }

    if (dataPack.listPackChildren) {
      dataPack.listPackChildren.forEach((child: any) => {
        child.packProductDTO = child.packProductDTO.filter((dto) => {
          if (!dto.productName) {
            dto.pack = null;
            dto.productId = null;
            dto.productName = null;
            dto.serviceId = null;
            dto.valuePack = null;
          }

          return dto;
        });
      });
    }

    return dataPack;
  };

  const update = async () => {
    setIsLoading(true);

    const data = { ...pack };

    const handledDTO = handleIdBeforeSubmit(data);

    const removed = handleVariantUpdate(handledDTO);

    try {
      const result = await packService.update(removed);
      setAlertMsg(result.data.message);
      setSuccessDialog(true);
    } catch (error) {
      setErrorDialog(true);
      setAlertMsg(error.response.data.message);
    } finally {
      setIsLoading(false);
    }
  };

  const removePack = async (productId: any) => {
    setIsLoading(true);
    setShowRemoveModal(false);

    try {
      const result = await packService.deletePack(productId);
      setSuccessDialog(true);
      setPack({ ...pack, deleted: true });
      setAlertMsg(result.data.message);
    } catch (error) {
      setErrorDialog(true);
      setAlertMsg(error.response.data.message);
    } finally {
      setIsLoading(false);
    }
  };

  const activePack = async (productId: string, newPack: any) => {
    setModalActivePack(false);
    setIsLoading(true);

    try {
      const result = await packService.activePack(productId, !newPack.active);
      newPack.active = !newPack.active;

      setPack({ ...pack, ...newPack });
      setSuccessDialog(true);
      setAlertMsg(result.data.message);
    } catch (error) {
      setAlertMsg(error.response.data.message);
      setErrorDialog(true);
    } finally {
      setIsLoading(false);
    }
  };

  const getTotalService = (servicesList: any) =>
    servicesList?.filter((item) => item.productId).length || 0;

  const checkFields = () => {
    let checkChildNames = false;
    let checkDTOName = false;

    let checkVariantDTO = false;
    let checkVariantChild = false;

    if (pack.packProductDTO) {
      if (!pack.name) checkDTOName = true;
    }

    if (pack.listPackChildren) {
      pack.listPackChildren.forEach((child) => {
        child.packProductDTO.forEach((dto) => {
          if (Number(dto.serviceId) > 1 && Number(dto.serviceId) < 5) {
            if (
              (dto.productName !== '' && dto.valuePack === '') ||
              (dto.valuePack !== '' && dto.productName === '')
            ) {
              checkVariantChild = true;
            }
          }
        });
      });
    }

    if (pack.listPackChildren?.length) {
      pack.listPackChildren.forEach((child) => {
        if (!child.name && !child.deleted) checkChildNames = true;
      });
    }

    const fields = [pack.monthlyValue, pack.packValidate];

    /* console.log(
      fields.some((item) => !item) ||
        checkDTOName ||
        checkChildNames ||
        checkVariantDTO ||
        checkVariantChild
    ); */
    return (
      fields.some((item) => !item) ||
      checkDTOName ||
      checkChildNames ||
      checkVariantDTO ||
      checkVariantChild
    );
  };

  const getPackHeaderValue = (product: any) => {
    if (product.balance) {
      if (product.serviceId == '3') {
        return toFixed(product.balance / product.valuePack, 2);
      }
      return toFixed(product.balance);
    } else {
      if (product.serviceId == '1') {
        return toFixed(finlogPackValue, 2) ?? 0.0;
      }
      if (product.serviceId == '2') {
        return toFixed(guerinPackValue, 2) ?? 0.0;
      }
      if (product.serviceId == '3') {
        return toFixed(xtracarsPackValue, 2) ?? 0.0;
      }
      if (product.serviceId == '4') {
        return toFixed(rodinhasPackValue, 2) ?? 0.0;
      }
    }
  };

  const form = () => (
    <div className='create_pack'>
      <h3>{packEdition ? 'Edição de' : 'Nova'} configuração de pacote</h3>
      <p>Por favor, preencha as seguintes informações.</p>
      <div>
        {pack.packProductDTO && (
          <>
            <div className='inputs'>
              <Form onSubmit={(e) => e.preventDefault()}>
                <Row>
                  <Col xs={11} className='mt-4'>
                    <Form.Label>
                      <p>Nome</p>
                    </Form.Label>
                    <Form.Control
                      onChange={(e) =>
                        setPack({ ...pack, name: e.target.value })
                      }
                      value={pack.name}
                      disabled={isEdit}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Label>
                      <p>Serviços incluídos e respetiva oferta</p>
                    </Form.Label>
                  </Col>
                </Row>

                <PackHeader
                  img={require('../../../assets/logos/kinto-one-logo-on-1x.png')}
                  value={finlogPackValue}
                />

                <Form.Group as={Row} xs={11}>
                  <Col xs={11} className='mt-4'>
                    <Form.Control
                      as='select'
                      custom
                      onChange={(e) => {
                        updatePack(e, 0, 1);
                      }}
                      value={pack.packProductDTO[0].productId}
                      disabled={isEdit}
                    >
                      <option value=''>Selecione um produto</option>
                      {finlogList.map((product: any) => {
                        return (
                          <option value={product.id} key={product.id}>
                            {product.name} {product.id}
                          </option>
                        );
                      })}
                    </Form.Control>
                  </Col>
                </Form.Group>

                <PackHeader
                  img={require('../../../assets/logos/guerin-xs-logo-on-2x.png')}
                  value={toFixed(guerinPackValue, 2)}
                />

                <Form.Group as={Row} xs={10}>
                  <Col xs={4} className='mt-4'>
                    <Form.Control
                      as='select'
                      onChange={(e) =>
                        updateGuerinXtracars(e, 1, 2, 'segmentId')
                      }
                      value={pack.packProductDTO[1].segmentId}
                      disabled={isEdit}
                      custom
                      placeholder={'Segmento'}
                    >
                      <option value=''>Segmento</option>
                      {segments.map((segment) => {
                        return (
                          <option
                            value={segment.segmentId}
                            key={segment.segmentId}
                          >
                            {segment.segmentDescription}
                          </option>
                        );
                      })}
                    </Form.Control>
                  </Col>
                  <Col xs={4} className='mt-4'>
                    <Form.Control
                      as='select'
                      onChange={(e) => updateGuerinXtracars(e, 1, 2, 'groupId')}
                      value={pack.packProductDTO[1].groupId}
                      disabled={isEdit || guerinGroups.length == 0}
                      custom
                    >
                      <option value=''>Grupo</option>
                      {guerinGroups.map((group) => {
                        return (
                          <option value={group} key={group}>
                            {group}
                          </option>
                        );
                      })}
                    </Form.Control>
                  </Col>
                  <Col xs={3} className='mt-4'>
                    <Form.Control
                      as='select'
                      custom
                      value={pack.packProductDTO[1].valuePack}
                      onChange={(e) => updateGuerinXtracars(e, 1, 2, 'days')}
                      disabled={
                        pack.packProductDTO[1]?.groupId === undefined ||
                        pack.packProductDTO[1]?.groupId === ''
                      }
                    >
                      <option value=''>Dias</option>
                      {Array.from(Array(28), (_, i) => {
                        return (
                          <option value={i + 1} key={i}>
                            {i + 1}x dia{i !== 0 ? 's' : ''}
                          </option>
                        );
                      })}
                    </Form.Control>
                  </Col>
                </Form.Group>

                <PackHeader
                  img={require('../../../assets/logos/xtracars-xs-logo-on-2x.png')}
                  value={xtracarsPackValue}
                />

                <Form.Group as={Row} xs={10}>
                  <Col xs={4} className='mt-4'>
                    <Form.Control
                      as='select'
                      onChange={(e) =>
                        updateGuerinXtracars(e, 2, 3, 'segmentId')
                      }
                      value={pack.packProductDTO[2].segmentId}
                      disabled={isEdit}
                      custom
                    >
                      <option value=''>Segmento</option>
                      {segments.map((segment) => {
                        return (
                          <option
                            value={segment.segmentId}
                            key={segment.segmentId}
                          >
                            {segment.segmentDescription}
                          </option>
                        );
                      })}
                    </Form.Control>
                  </Col>
                  <Col xs={4} className='mt-4'>
                    <Form.Control
                      as='select'
                      onChange={(e) => updateGuerinXtracars(e, 2, 3, 'groupId')}
                      value={pack.packProductDTO[2].groupId}
                      disabled={isEdit || xtracarsGroups.length == 0}
                      custom
                    >
                      <option value=''>Grupo</option>
                      {xtracarsGroups.map((group) => {
                        return (
                          <option value={group} key={group}>
                            {group}
                          </option>
                        );
                      })}
                    </Form.Control>
                  </Col>
                  <Col xs={3} className='mt-4'>
                    <Form.Control
                      as='select'
                      custom
                      value={pack.packProductDTO[2].valuePack}
                      onChange={(e) => updateGuerinXtracars(e, 2, 3, 'months')}
                      disabled={
                        pack.packProductDTO[2]?.groupId === undefined ||
                        pack.packProductDTO[2]?.groupId === ''
                      }
                    >
                      <option value=''>Meses</option>
                      {Array.from({ length: 12 }, (_, i) => i + 1).map(
                        (month) => (
                          <option value={month} key={month}>
                            {month} mês{month !== 1 && 'es'}
                          </option>
                        )
                      )}
                      );
                    </Form.Control>
                  </Col>
                </Form.Group>

                {/* RODINHAS */}

                <PackHeader
                  img={require('../../../assets/logos/rodinhas-xs-logo-on-2x.png')}
                  value={toFixed(rodinhasPackValue, 2)}
                />

                <Form.Group as={Row} xs={10}>
                  <Col xs={6} className='mt-4'>
                    <Form.Control
                      as='select'
                      custom
                      value={rodinhasSelectedConfig.region}
                      onChange={(e) =>
                        updateRodinhasConfig('region', e.target.value)
                      }
                      disabled={isEdit}
                    >
                      <option value=''>Região</option>
                      {rodinhasProductRegions.map((item: any) => {
                        return (
                          <option value={item.region} key={item.region}>
                            {item.region}
                          </option>
                        );
                      })}
                    </Form.Control>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} xs={10}>
                  <Col xs={6} className='mt-4'>
                    <Form.Control
                      as='select'
                      custom
                      value={rodinhasSelectedConfig.pickupZone}
                      onChange={(e) =>
                        updateRodinhasConfig('pickupZone', e.target.value)
                      }
                      disabled={isEdit}
                    >
                      <option value=''>Zona de Pick-up</option>
                      {rodinhasZonesPickup.map((zone: any) => {
                        if (zone.zone !== '') {
                          return (
                            <option value={zone.zone} key={zone.zone}>
                              {zone.zone}
                            </option>
                          );
                        }
                      })}
                    </Form.Control>
                  </Col>
                  <Col xs={5} className='mt-4'>
                    <Form.Control
                      as='select'
                      custom
                      value={rodinhasSelectedConfig.pickupSubZone}
                      onChange={(e) =>
                        updateRodinhasConfig('pickupSubZone', e.target.value)
                      }
                      disabled={isEdit}
                    >
                      <option value=''>Sub-zona de Pick-up</option>
                      {rodinhasSubZonesPickup.map((zone: any) => {
                        if (zone.subZone !== '') {
                          return (
                            <option value={zone.subZone} key={zone.subZone}>
                              {zone.subZone}
                            </option>
                          );
                        }
                      })}
                    </Form.Control>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} xs={10}>
                  <Col xs={6} className='mt-4'>
                    <Form.Control
                      as='select'
                      custom
                      value={rodinhasSelectedConfig.dropoffZone}
                      onChange={(e) =>
                        updateRodinhasConfig('dropoffZone', e.target.value)
                      }
                      disabled={isEdit}
                    >
                      <option value=''>Zona de Drop-off</option>
                      {rodinhasZonesDropoff.map((zone: any) => {
                        if (zone.zone !== '') {
                          return (
                            <option value={zone.zone} key={zone.zone}>
                              {zone.zone}
                            </option>
                          );
                        }
                      })}
                    </Form.Control>
                  </Col>
                  <Col xs={5} className='mt-4'>
                    <Form.Control
                      as='select'
                      custom
                      value={rodinhasSelectedConfig.dropoffSubZone}
                      onChange={(e) =>
                        updateRodinhasConfig('dropoffSubZone', e.target.value)
                      }
                      disabled={isEdit}
                    >
                      <option value=''>Sub-zona de Drop-off</option>
                      {rodinhasSubZonesDropoff.map((zone: any) => {
                        if (zone.subZone !== '') {
                          return (
                            <option value={zone.subZone} key={zone.subZone}>
                              {zone.subZone}
                            </option>
                          );
                        }
                      })}
                    </Form.Control>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} xs={10}>
                  <Col xs={4} className='mt-4'>
                    <Form.Control
                      as='select'
                      custom
                      value={rodinhasSelectedConfig.numberPerWeek}
                      onChange={(e) =>
                        updateRodinhasConfig(
                          'numberPerWeek',
                          parseInt(e.target.value)
                        )
                      }
                      disabled={isEdit}
                    >
                      <option value=''>Vezes por Semana</option>
                      {Array.from(Array(rodinhasMaxNumberTime), (_, i) => {
                        return (
                          <option value={i + 1} key={i}>
                            {i + 1} vez{i !== 0 ? 'es' : ''}
                          </option>
                        );
                      })}
                    </Form.Control>
                  </Col>
                  <Col xs={4} className='mt-4'>
                    <Form.Control
                      as='select'
                      custom
                      value={rodinhasSelectedConfig.roundTrip}
                      onChange={(e) =>
                        updateRodinhasConfig('roundTrip', e.target.value)
                      }
                      disabled={isEdit}
                    >
                      <option value=''>Ida e Volta</option>
                      <option value='true' key='roundTripTrue'>
                        Sim
                      </option>
                      <option value='false' key='roundTripFalse'>
                        Não
                      </option>
                    </Form.Control>
                  </Col>
                  <Col xs={3} className='mt-4'>
                    <Form.Control
                      as='select'
                      custom
                      value={rodinhasSelectedConfig.numberChildren}
                      onChange={(e) =>
                        updateRodinhasConfig(
                          'numberChildren',
                          parseInt(e.target.value)
                        )
                      }
                      disabled={isEdit}
                    >
                      <option value=''>Nº de Crianças</option>
                      {Array.from(Array(rodinhasMaxNumberChildrens), (_, i) => {
                        return (
                          <option value={i + 1} key={i}>
                            {i + 1} criança{i !== 0 ? 's' : ''}
                          </option>
                        );
                      })}
                    </Form.Control>
                  </Col>
                </Form.Group>

                {/* BEDRIVEN */}

                <PackHeader
                  class='pack_header_bedriven'
                  img={require('../../../assets/logos/bedriven-xs-logo-on-2x.png')}
                  value={'00.00'}
                />

                <Form.Group hidden as={Row} xs={10}>
                  <Col xs={11} className='mt-4'>
                    <Form.Control
                      as='select'
                      custom
                      onChange={(e) => updatePack(e, 4, 5)}
                      value={pack.packProductDTO[4].productId}
                      disabled={isEdit}
                    >
                      <option value=''>Selecione um produto</option>
                      {bedrivenList.map((product: any) => {
                        return (
                          <option value={product.id} key={product.id}>
                            {product.name}
                          </option>
                        );
                      })}
                    </Form.Control>
                  </Col>
                </Form.Group>
              </Form>
            </div>
          </>
        )}

        {!isResume &&
          pack?.listPackChildren?.length > 0 &&
          pack.listPackChildren.map((child, i) => {
            return (
              <>
                {!child.deleted && (
                  <div className='inputs' key={i}>
                    <div className='resume mt-5 mb-4'>
                      <p className='font-weight-bold'>
                        {pack?.listPackChildren[i]?.name?.toUpperCase() ?? ''}
                      </p>
                      <p className='services'>
                        {getTotalService(
                          pack?.listPackChildren[i]?.packProductDTO ?? []
                        )}{' '}
                        serviço(s)
                      </p>
                    </div>
                    <Form onSubmit={(e) => e.preventDefault()}>
                      <Row>
                        <Col xs={3}>
                          <Form.Label>Nome</Form.Label>
                          <Form.Control
                            value={pack.listPackChildren[i].name}
                            onChange={(e) =>
                              updateChildrenName(e.target.value, i)
                            }
                            disabled={isEdit}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Form.Label>
                            Serviços incluídos e respetiva oferta
                          </Form.Label>
                        </Col>
                      </Row>

                      <Form.Group as={Row} xs={10}>
                        <Form.Label column xs={2}>
                          <img
                            src={require('../../../assets/logos/kinto-one-logo-on-2x.png')}
                            alt=''
                          />
                        </Form.Label>

                        <Col xs={8} className='mt-4'>
                          <Form.Control
                            as='select'
                            custom
                            onChange={(e) => updatePackChildren(e, i, 0, 1)}
                            value={
                              pack.listPackChildren[i].packProductDTO[0]
                                .productId
                            }
                            disabled={isEdit}
                          >
                            <option value=''>Selecione um produto</option>
                            {finlogList.map((product: any) => {
                              return (
                                <option value={product.id} key={product.id}>
                                  {product.name}
                                </option>
                              );
                            })}
                          </Form.Control>
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} xs={10}>
                        <Form.Label column xs={2}>
                          <img
                            src={require('../../../assets/logos/guerin-xs-logo-on-2x.png')}
                            alt=''
                          />
                        </Form.Label>

                        <Col xs={5} className='mt-4'>
                          <Form.Control
                            as='select'
                            value={
                              pack.listPackChildren[i].packProductDTO[1]
                                .productId
                            }
                            onChange={(e) => updatePackChildren(e, i, 1, 2)}
                            custom
                            disabled={isEdit}
                          >
                            <option value=''>Selecione um produto</option>
                            {guerinList.map((product: any) => {
                              return (
                                <option value={product.id} key={product.id}>
                                  {product.name}
                                </option>
                              );
                            })}
                          </Form.Control>
                        </Col>

                        <Col xs={3} className='mt-4'>
                          <Form.Control
                            as='select'
                            custom
                            value={
                              pack.listPackChildren[i].packProductDTO[1]
                                .valuePack
                            }
                            onChange={(e) =>
                              updateValueChildren(e.target.value, i, 1)
                            }
                            disabled={isEdit}
                          >
                            <option value='' defaultValue='' key={'ini'}>
                              Dias
                            </option>
                            {Array.from(Array(10), (_, i) => {
                              return (
                                <option value={i + 1} defaultValue='' key={i}>
                                  {i + 1}x dia{i !== 0 ? 's' : ''}
                                </option>
                              );
                            })}
                          </Form.Control>
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} xs={10}>
                        <Form.Label column xs={2}>
                          <img
                            src={require('../../../assets/logos/xtracars-xs-logo-on-2x.png')}
                            alt=''
                          />
                        </Form.Label>

                        <Col xs={5} className='mt-4'>
                          <Form.Control
                            as='select'
                            custom
                            value={
                              pack.listPackChildren[i].packProductDTO[2]
                                .productId
                            }
                            onChange={(e) => updatePackChildren(e, i, 2, 3)}
                            disabled={isEdit}
                          >
                            <option value='' defaultValue=''>
                              Selecione um produto
                            </option>
                            {xtracarsList.map((product: any) => {
                              return (
                                <option value={product.id} key={product.id}>
                                  {product.name}
                                </option>
                              );
                            })}
                          </Form.Control>
                        </Col>

                        <Col xs={3} className='mt-4'>
                          <Form.Control
                            as='select'
                            custom
                            value={
                              pack.listPackChildren[i].packProductDTO[2]
                                .valuePack
                            }
                            onChange={(e) =>
                              updateValueChildren(e.target.value, i, 2)
                            }
                            disabled={isEdit}
                          >
                            <option value=''>Aluguer</option>
                            {Array.from(Array(12), (_, i) => {
                              return (
                                <option value={i + 1}>
                                  Aluguer de {i + 1} mes{i !== 0 ? 'es' : ''}
                                </option>
                              );
                            })}
                          </Form.Control>
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} xs={10}>
                        <Form.Label column xs={2}>
                          <img
                            src={require('../../../assets/logos/rodinhas-xs-logo-on-2x.png')}
                            alt=''
                          />
                        </Form.Label>

                        <Col xs={5} className='mt-4'>
                          <Form.Control
                            as='select'
                            custom
                            value={
                              pack.listPackChildren[i].packProductDTO[3]
                                .productId
                            }
                            onChange={(e) => updatePackChildren(e, i, 3, 4)}
                            disabled={isEdit}
                          >
                            <option value=''>Selecione um produto</option>
                          </Form.Control>
                        </Col>

                        <Col xs={3} className='mt-4'>
                          <Form.Control
                            as='select'
                            custom
                            value={
                              pack.listPackChildren[i].packProductDTO[3]
                                .valuePack
                            }
                            onChange={(e) =>
                              updateValueChildren(e.target.value, i, 3)
                            }
                            disabled={isEdit}
                          >
                            <option value='' defaultValue=''>
                              Crianças
                            </option>
                            {Array.from(Array(10), (_, i) => {
                              return (
                                <option value={i + 1}>
                                  {i + 1} criança{i !== 0 ? 's' : ''}
                                </option>
                              );
                            })}
                          </Form.Control>
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} xs={10}>
                        <Form.Label column xs={2}>
                          <img
                            src={require('../../../assets/logos/bedriven-xs-logo-on-2x.png')}
                            alt=''
                          />
                        </Form.Label>

                        <Col xs={6} className='mt-4'>
                          <Form.Control
                            as='select'
                            custom
                            value={
                              pack.listPackChildren[i].packProductDTO[4]
                                .productId
                            }
                            onChange={(e) => updatePackChildren(e, i, 4, 5)}
                            disabled={isEdit}
                          >
                            <option value=''>Selecione um produto</option>
                            {bedrivenList.map((product: any) => {
                              return (
                                <option value={product.id} key={product.id}>
                                  {product.name}
                                </option>
                              );
                            })}
                          </Form.Control>
                        </Col>
                      </Form.Group>
                    </Form>
                  </div>
                )}
              </>
            );
          })}
      </div>
      <Row className='pack_total_value'>
        <Col xs={5}></Col>
        <Col xs={6}>
          <Row>
            <Col className='pr-0'>
              <hr />
            </Col>
          </Row>
          <Row>
            <Col className='d-flex align-items-center'>
              <span className='description'>Valor total do pacote</span>
            </Col>
            <Col className='d-flex align-items-center justify-content-end'>
              <Row>
                <span className='value'>€ {toFixed(totalPackValue, 2)}</span>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <div className='details'>
        <Row className='mt-6'>
          <Col>
            <h3>Detalhes</h3>
          </Col>
        </Row>

        <Row>
          <Col xs={5}>
            <Row>
              <Col>
                <p>Validade do pacote</p>
              </Col>
            </Row>

            <DatePicker
              selected={pack.packValidate ? new Date(pack.packValidate) : null}
              onChange={(date) =>
                date &&
                setPack({
                  ...pack,
                  packValidate: new Date(date.toString()),
                })
              }
              dateFormat='dd/MM/yyyy'
              disabled={isEdit}
            />
          </Col>
          <Col xs={6} hidden>
            <Row>
              <Col>
                <Row className='pl-4'>
                  <Form.Check
                    custom
                    inline
                    type={'checkbox'}
                    id={`custom-inline-checkbox-1`}
                    disabled={isEdit}
                  />
                  <p>Destacar pack na app</p>
                </Row>
              </Col>
            </Row>
            <Form.Control as='select' custom disabled={isEdit}>
              <option value=''>Posição na app</option>
              {Array.from(Array(4), (_, i) => {
                return (
                  <option value={i + 1} key={i}>
                    {'posição '} {i + 1}
                  </option>
                );
              })}
            </Form.Control>
          </Col>
        </Row>
      </div>
    </div>
  );

  const resume = () => (
    <div>
      <Modal backdrop='static' show={modalActivePack} size='lg'>
        <Modal.Header></Modal.Header>
        <Modal.Body>
          <FontAwesomeIcon icon={faPowerOff} />
          <h2 className='pt-5'>
            Tem certeza que pretende {pack.active ? 'desativar' : 'ativar'}?
          </h2>
          <p className='disclaimer'>Poderá reverter a sua ação mais tarde.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='remove'
            onClick={() => {
              activePack(productId, pack);
              setModalActivePack(false);
            }}
          >
            SIM, {pack.active ? 'DESATIVAR' : 'ATIVAR'}
          </Button>
          <Button variant='back' onClick={() => setModalActivePack(false)}>
            NÃO, VOLTAR
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal backdrop='static' show={showRemoveModal} size='lg'>
        <Modal.Header></Modal.Header>
        <Modal.Body>
          <FontAwesomeIcon icon={faTrashAlt} className='remove' />
          <h2 className='pt-5'>Tem certeza que pretende eliminar?</h2>
          <p className='disclaimer'>Esta ação não pode ser revertida.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='remove' onClick={() => removePack(productId)}>
            SIM, ELIMINAR
          </Button>
          <Button variant='back' onClick={() => setShowRemoveModal(false)}>
            NÃO, VOLTAR
          </Button>
        </Modal.Footer>
      </Modal>

      <div className='form'>
        {!productId && (
          <>
            <h3>Nova configuração de pacote</h3>
            <p>Por favor, verifique as informações inseridas.</p>
          </>
        )}

        {!productId ||
          (!pack.deleted && (
            <Row>
              <Col>
                <h3>{pack.name}</h3>
              </Col>

              <Col md={{ offset: 4 }} className='d-flex justify-content-end'>
                <div
                  className='wrapper-actions'
                  onMouseEnter={() => setShowDeactivate(true)}
                  onMouseLeave={() => setShowDeactivate(false)}
                >
                  <FontAwesomeIcon
                    icon={faPowerOff}
                    onClick={() => setModalActivePack(true)}
                  />
                  <motion.div
                    initial={animationVisible}
                    animate={showDeactivate ? 'visible' : 'invisible'}
                    exit={animationInvisible}
                    transition={{ duration: 0.3 }}
                    variants={animationVariants}
                  >
                    <p className='text-deactivate font-weight-bold'>
                      {pack.active ? 'desativar testing' : 'ativar testing'}
                    </p>
                  </motion.div>
                </div>

                <div
                  className='wrapper-actions edit'
                  onMouseEnter={() => setShowEdit(true)}
                  onMouseLeave={() => setShowEdit(false)}
                  onClick={() => {
                    setIsEdit(false);
                    setIsResume(!isResume);
                  }}
                >
                  <FontAwesomeIcon
                    icon={faEdit}
                    className='edit'
                  />
                  <motion.div
                    initial={animationVisible}
                    animate={showEdit ? 'visible' : 'invisible'}
                    exit={animationInvisible}
                    transition={{ duration: 0.3 }}
                    variants={animationVariants}
                  >
                    <p className='text-edit font-weight-bold'>editar</p>
                  </motion.div>
                </div>
              </Col>
            </Row>
          ))}

        <div className={'pack_resume'}>
          <div className={!pack.active ? 'opacity' : ''}>
            <p>Valor total do Pacote</p>
            <h2 className='value my-4'>
              {toFixed(pack.monthlyValue, 2)}
              {'€'}
            </h2>
            <p>Serviços incluídos e respetiva oferta</p>

            <div className='mt-5'>
              {pack.packProductDTO && (
                <>
                  {pack.packProductDTO.map((product) => {
                    return (
                      <>
                        {product.productName && (
                          <>
                            <PackHeader
                              img={require(`../../../assets/logos/${getCompany(
                                product.serviceId
                              )}-xs-logo-on-2x.png`)}
                              value={getPackHeaderValue(product)}
                            />

                            <div className='font-weight-bold mt-3'>
                              {product.serviceId == '1' && product.productName}
                              {product.serviceId == '2' &&
                                'Grupo ' +
                                  product.groupId +
                                  ' | ' +
                                  product.segmentDescription}
                              {product.serviceId == '3' &&
                                'Grupo ' +
                                  product.groupId +
                                  ' | ' +
                                  product.segmentDescription}

                              {product.serviceId == '4' && (
                                <div>
                                  <Row>
                                    <Col>
                                      <p>Região</p>
                                      <h1>{product.region}</h1>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col>
                                      <p>Zona de Pick-up</p>
                                      <h1>{product.pickupZone}</h1>
                                    </Col>
                                    <Col>
                                      <p>Sub-zona de Pick-up</p>
                                      <h1>{product.pickupSubZone}</h1>
                                    </Col>
                                    <Col>
                                      <p>Zona de Drop-off</p>
                                      <h1>{product.dropoffZone}</h1>
                                    </Col>
                                    <Col>
                                      <p>Sub-zona de Drop-off</p>
                                      <h1>{product.dropoffSubZone}</h1>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col>
                                      <p>Vezes por semana</p>
                                      <h1>{product.numberPerWeek}</h1>
                                    </Col>
                                    <Col>
                                      <p>Ida e Volta</p>
                                      <h1>
                                        {product.roundTrip ? 'Sim' : 'Não'}
                                      </h1>
                                    </Col>
                                    <Col>
                                      <p>Nº de Crianças</p>
                                      <h1>{product.numberChildren}</h1>
                                    </Col>
                                  </Row>
                                </div>
                              )}
                            </div>
                          </>
                        )}
                      </>
                    );
                  })}
                </>
              )}
              {pack?.listPackChildren?.length > 0 &&
                pack.listPackChildren.map((pack: any, i) => {
                  return (
                    pack.name && (
                      <ul key={i}>
                        <li className='name pt-5'>{pack.name}</li>
                        {pack.packProductDTO?.length &&
                          pack.packProductDTO.map((product) => {
                            return (
                              <div>
                                {product.productName && (
                                  <li>
                                    <Form.Group as={Row} xs={10}>
                                      <Form.Label column xs={2}>
                                        {product.serviceId && (
                                          <img
                                            src={require(`../../../assets/logos/${getCompany(
                                              product.serviceId
                                            )}-xs-logo-on-2x.png`)}
                                            alt=''
                                          />
                                        )}
                                      </Form.Label>

                                      <Col xs={8} className='mt-4'>
                                        <p className='font-weight-bold mt-3 value-name'>
                                          {product.productName} -{' '}
                                          {product.valuePack}
                                        </p>
                                      </Col>
                                    </Form.Group>
                                  </li>
                                )}
                              </div>
                            );
                          })}
                      </ul>
                    )
                  );
                })}
            </div>
          </div>
          <Row className='footer'>
            <Col>
              <p>Validade do pack</p>
              <h1>{dayjs(pack.packValidate).format('DD/MM/YYYY')}</h1>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <Title>Pacote</Title>

      <Content>
        <Container fluid className='card-no-tabs' style={{ paddingLeft: 80 }}>
          <Row
            style={{
              textAlign: 'center',
              font: 'normal normal normal 18px/22px Roboto',
              letterSpacing: '0px',
              color: '#878787',
              opacity: 1,
              marginLeft: '8px',
              marginTop: '51px',
            }}
          >
            <Link className='color-back' to={'/packs'}>
              <p>
                <u>&#60; Voltar</u>
              </p>
            </Link>
          </Row>
          {isLoading && (
            <Loading
              loading
              background='rgba(255, 255, 255, .4)'
              loaderColor='#3498db'
            />
          )}

          {errorDialog && (
            <SweetAlert
              error
              title='Erro!'
              onConfirm={() => {
                setErrorDialog(false);
                setAlertMsg('');
              }}
            >
              {alertMsg}
            </SweetAlert>
          )}

          {successDialog && (
            <SweetAlert
              success
              title='Sucesso!'
              onConfirm={() => {
                setSuccessDialog(false);
                setAlertMsg('');
                history.push('/packs');
              }}
            >
              {alertMsg}
            </SweetAlert>
          )}

          <Modal
            backdrop='static'
            show={showCreatedPack}
            size='lg'
            dialogClassName='custom-dialog'
          >
            <Modal.Header>
              <div>
                <h2>Pacote criado</h2>
                <p className='font-weight-normal'>
                  Sua configuração do pacote foi criada com sucesso!
                </p>
              </div>
            </Modal.Header>
            <Modal.Body>
              <div className='pack mb-5'>
                <div className={`wrapper ${!pack.active ? 'opacity' : ''}`}>
                  <h5 className='text-center'>Pacote {pack.name}</h5>
                  <h4 className='text-center'>
                    <span className='big-text'>
                      {toFixed(pack.monthlyValue, 2)}€
                    </span>
                    /mês
                  </h4>
                  <div className='social-networks mt-4'>
                    {services?.length > 0 &&
                      services.map((number, i) => {
                        return (
                          <i className={`icon-service-${number}`} key={i}></i>
                        );
                      })}
                  </div>
                  <p className='text-center mt-4'>
                    Pacote de {packSize}
                    {packSize == 1 ? ' serviço' : ' serviços'}
                  </p>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button
                className='big-btn ml-4'
                onClick={() => history.push('/packs')}
              >
                OK
              </button>
            </Modal.Footer>
          </Modal>

          {!isResume && form()}

          {isResume && resume()}

          <div className='form'>
            <div className='text-center pt-5 pb-5'>
              <div className='text-center'>
                {!isEdit && !isResume && (
                  <button
                    className={`big-btn ${checkFields() ? 'opacity-3' : ''}`}
                    onClick={() => {
                      setIsEdit(!isEdit);
                      window.scrollTo(0, 0);
                    }}
                    disabled={checkFields()}
                  >
                    CONTINUAR
                  </button>
                )}

                {/* {!isEdit && <button className="big-btn">Ok</button>} */}
                {!productId && isEdit && !isResume && (
                  <button
                    className='big-btn ml-4'
                    onClick={() => {
                      setIsResume(true);
                      window.scrollTo(0, 0);
                    }}
                  >
                    CONTINUAR
                  </button>
                )}
                {!productId && isEdit && isResume && (
                  <button
                    className='big-btn ml-4'
                    onClick={() => createPack(pack)}
                  >
                    CRIAR PACOTE
                  </button>
                )}
                {productId && isEdit && (
                  <button className='big-btn ml-4' onClick={() => update()}>
                    GUARDAR ALTERAÇÕES
                  </button>
                )}
              </div>
            </div>

            {isEdit && (
              <div className='text-center pb-5'>
                <a
                  className='back-edit'
                  onClick={() => {
                    setIsEdit(!isEdit);
                    setIsResume(false);
                  }}
                >
                  Voltar a edição
                </a>
              </div>
            )}
          </div>
        </Container>
      </Content>
    </>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  .header_guerin {
    width: 100%;
  }

  .no-border {
    border: 0 !important;
  }

  .wrapper-actions {
    text-align: center;
    cursor: pointer;

    .text-edit {
      color: #2292cc;
    }

    .text-remove {
      color: #ff0000;
    }
  }

  .product-definition {
    width: 100%;
    .header {
      display: flex;
      justify-content: space-evenly;

      .title {
        background-color: #2292cc;
        color: #fff;
        padding: 30px;
        border-radius: 30px;
        font-weight: bold;
      }
    }

    .container {
      width: 70%;
      border-bottom: 1px solid #ccc;
      padding: 10px;
      display: flex;
      justify-content: space-between;
    }
  }

  .form {
    padding: 50px 0px;

    .opacity {
      opacity: 0.4;
    }

    .price {
      width: 300px;
      height: 90px;
      font-size: 30px;
    }

    .price::placeholder {
      font-size: 30px;
      color: #000;
    }

    .react-date-picker__wrapper {
      border: 0;
    }

    .back-edit {
      font-weight: bold;
      text-decoration: underline;
      cursor: pointer;
    }

    .resume2 {
      color: #2292cc;
    }

    .value-name {
      text-indent: 20px;
    }

    .resume {
      border-bottom: 1px solid #eee;
      display: flex;
      justify-content: space-between;
      text-indent: 48px;
      padding: 0;
      margin: 0;

      p {
        margin: 0;
        padding: 0;
      }

      p.services {
        color: #2292cc;
      }

      span.remove a {
        padding: 0 10px;
        color: #00000069;
        cursor: pointer;
      }

      span.remove a:hover {
        color: #df0000;
      }
    }

    .btn-hover:hover {
      color: #2292cc;
    }

    h6 {
      border-bottom: 1px solid #eee;
      text-indent: 50px;
      color: #2292cc;
    }

    .section-upload {
      display: flex;
      justify-content: center;
    }

    ul {
      list-style-type: none;

      li {
        border-bottom: 1px solid #ccc;
      }

      li.name {
        text-transform: uppercase;
        font-weight: bold;
      }
    }

    .inputs {
      padding: 20px 0;

      .upload {
        width: 200px;
        height: 100px;
        border-radius: 20px;
        box-shadow: 3px 15px 5px -6px rgba(158, 195, 240, 0.62);
      }
    }

    .validity {
      padding: 50px;

      .title {
        color: #808080;
      }
    }

    .resume-value {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #ccc;
      padding-left: 50px;
      margin: 0;

      p {
        font-weight: bold;
        font-size: 20px;
        margin: 0;
      }

      h4 {
        font-size: 16px;
        font-weight: bold;
      }
    }
  }
`;

export interface RodinhasConfig {
  region: string;
  pickupZone: string;
  pickupSubZone: string;
  dropoffZone: string;
  dropoffSubZone: string;
  numberPerWeek: string;
  roundTrip: string;
  numberChildren: number;
  productId: number;
}

export default Pack;

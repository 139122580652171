import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Form } from 'react-bootstrap';
import ModalBase from './ModalBase';
import dayjs from 'dayjs';
import { makeStyles } from '@material-ui/core/styles';
import { toFixed } from '../../../utils/utils';
import DatePicker from 'react-datepicker';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Loading from 'react-fullscreen-loading';
import { ReservationProduct, Child } from './reservation.interface';
import { bookingService } from '../../../services/bookingService';
import { generateArrayOfYears } from '../../../utils/utils';

export const ModalRodinhas = ({ closeButton, open, onClose, contract }) => {
  const [step, setStep] = useState(1);

  const [_contract, setContract] = useState({} as ReservationProduct);
  const [childrenList, setChildrenList] = useState([] as Child[]);
  const [alertDialog, setAlertDialog] = useState(false);
  const [alertDialogCancel, setAlertDialogCancel] = useState(false);
  const [alertConfig, setAlertConfig] = useState({
    title: '',
    message: '',
    redirect: false,
    redirectURL: '',
  });
  const months = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ];

  const [years, setYears] = useState([] as Number[]);
  const [isLoading, setIsLoading] = useState(false);

  const useStyles = makeStyles({
    root: {
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    icon: {
      borderRadius: '50%',
      width: 16,
      height: 16,

      backgroundColor: 'rgba(109, 171, 36, 0.2)',
      'input:hover ~ &': {
        backgroundColor: '#6dab24',
      },
      'input:disabled ~ &': {
        boxShadow: 'none',
        background: 'rgba(109,171,36,.5)',
      },
    },
    checkedIcon: {
      backgroundColor: '#6dab24',
      backgroundImage:
        'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
      '&:before': {
        display: 'block',
        width: 16,
        height: 16,
        backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
        content: '""',
      },
      'input:hover ~ &': {
        backgroundColor: '#6dab24',
      },
    },
  });
  const classes = useStyles();

  useEffect(() => {
    if (contract) {
      setContract(contract);

      if (contract.packItineraryDTO) {
        let rodinhasMaxChildrens = 0;
        contract.packItineraryDTO.foreach((el) => {
          if (rodinhasMaxChildrens < el.childrenQuantity) {
            rodinhasMaxChildrens = el.childrenQuantity;
          }
        });
        if (childrenList.length === 0) {
          addNewChildren(rodinhasMaxChildrens);
        }
      }
      let _years = generateArrayOfYears('past', 0) as Number[];
      setYears(_years);
    }
  }, [contract]);

  useEffect(() => {
    if (!open) {
      setStep(1);
    }
  }, [open]);

  const handleClose = () => {
    setStep(1);
    onClose()
  };

  const updateValue = (key, value, index) => {
    let _list = [...childrenList];

    if (_list[index]) {
      _list[index][key] = value;
    }
    setChildrenList(_list);
  };

  const alert = (
    title: string,
    message: string,
    redirect: boolean,
    redirectURL?: string
  ) => {
    setAlertConfig({
      title: title,
      message: message,
      redirect: redirect,
      redirectURL: redirectURL ? redirectURL : '',
    });
    setAlertDialog(true);
  };

  const addNewChildren = (numberChildren) => {
    let array = [...childrenList];
    let children = {
      name: '',
      height: '',
      birthDate: '',
      registrationFee: '',
      phone: '',
      itineraryList: [],
    } as Child;
    for (let index = 0; index < numberChildren; index++) {
      array.push(children);
    }
    setChildrenList(array);
  };

  const deleteChildren = (index) => {
    let _list = childrenList;
    if (childrenList.length > 1) {
      if (_list[index]) {
        _list.splice(index, 1);
        setChildrenList([..._list]);
      }
    }
  };

  const submit = async (status) => {
    setIsLoading(true);
    let data = {
      childrenRodinhasDTO: JSON.parse(JSON.stringify(childrenList)),
      packId: _contract.packId,
      productId: _contract.productId,
      serviceId: _contract.serviceId,
      status: status,
    };

    if (status === 'Canceled') {
      const result = await bookingService.updateRodinhas(
        data,
        _contract.reserveId
      );
      setIsLoading(false);

      alert(
        'Informações',
        result.message ?? 'Reserva cancelada com sucesso',
        true,
        ''
      );
    } else if (status === 'Subscribed') {
      if (validateChildrenList()) {
        data.childrenRodinhasDTO = data.childrenRodinhasDTO.map((item) => {
            item.birthDate = item.birthDate.split("-").reverse().join("-")
            return item;
        });
        const result = await bookingService.updateRodinhas(
          data,
          _contract.reserveId
        );
        alert(
          'Informações',
          result.message ?? 'Reserva realizada com sucesso',
          true,
          ''
        );
        setIsLoading(false);
      } else {
        alert(
          'Informações',
          'Algum dos campos não está preenchido correctamente',
          false,
          ''
        );
        setIsLoading(false);
      }
    }

    setIsLoading(false);
  };

  const validateChildrenList = () => {
    if (childrenList.length >= 1) {
      let status = false;
      childrenList.forEach((el) => {
        if (
          el.birthDate !== undefined &&
          el.birthDate !== '' &&
          el.height !== undefined &&
          el.height !== '' &&
          el.name !== undefined &&
          el.name !== '' &&
          el.itineraryList.length > 0
        ) {
          status = true;
        } else {
          status = false;
        }
      });
      return status;
    } else {
      return false;
    }
  };

  const formatdate = (date) => {
    try {
      let newDate = date.split("-").reverse().join("-")
      return dayjs(newDate).toDate()
    } catch (e) {
      return new Date()
    }
  }

  const handleSelectItinerary = (index, id) => {
    let _childrenList = childrenList;

    if (_childrenList[index]) {
      if (_childrenList[index].itineraryList !== undefined) {
        if (_childrenList[index].itineraryList?.includes(id)) {
          let _index = _childrenList[index].itineraryList?.findIndex(
            (el) => el === id
          );
          if (_index != -1 && _index !== undefined) {
            _childrenList[index].itineraryList?.splice(_index, 1);
            setChildrenList(_childrenList);
          }
        } else {
          _childrenList[index].itineraryList?.push(id);
          setChildrenList(_childrenList);
        }
      } else {
        _childrenList[index].itineraryList = [id];
        setChildrenList(_childrenList);
      }
    }
  };

  const component = () => {
    return (
      <div className='reserve_modal reserve_modal_rodinhas'>
        {isLoading && (
          <Loading
            loading
            background='rgba(255, 255, 255, .4)'
            loaderColor='#3498db'
          />
        )}
        {step === 1 && (
          <div className='card'>
            <Row className='d-flex justify-content-center mt-2 mb-2'>
              <h5>Aceitar contrato</h5>
            </Row>
            <Row className='mt-1'>
              <Col>Desconto</Col>
              <Col>
                <Row className='d-flex justify-content-end mr-2'>
                  {toFixed(_contract.rodinhasOfferDTO?.discount, 2)}€
                </Row>
              </Col>
            </Row>
            <Row className='mt-1'>
              <Col>Taxa de registo</Col>
              <Col>
                <Row className='d-flex justify-content-end mr-2'>
                  {toFixed(_contract.rodinhasOfferDTO?.registrationFee, 2)}€
                </Row>
              </Col>
            </Row>
            <Row className='mt-1'>
              <Col>Valor Mensal</Col>
              <Col>
                <Row className='d-flex justify-content-end mr-2'>
                  {toFixed(_contract.rodinhasOfferDTO?.monthlyFee, 2)}€
                </Row>
              </Col>
            </Row>

            <Row className='d-flex justify-content-center mt-5 mb-5'>
              <Button
                variant='outline-secondary'
                onClick={() => setAlertDialogCancel(true)}
              >
                Recusar
              </Button>

              <Button
                variant='outline-primary'
                className='ml-3'
                onClick={() => setStep(2)}
              >
                Aceitar
              </Button>
            </Row>
          </div>
        )}
        {step === 2 && (
          <div className='card'>
            <Row className='d-flex justify-content-center mt-2 mb-2'>
              <h5>Adicionar dados</h5>
            </Row>

            {childrenList.map((el, index) => {
              return (
                <div key={index + 'child'}>
                  <Row key={index + 'row_iti'}>
                    <Col className='mb-2 mt-3 title_col' md={4}>
                      <p className='mb-0 title'>Criança {index + 1}</p>
                    </Col>
                    <Col className='mb-2 align-content-center mt-3' md={8}>
                      <Row>
                        <Col>
                          <hr />
                        </Col>
                      </Row>
                    </Col>
                    <Col className='mb-2' md={4}>
                      <p>Nome</p>
                    </Col>
                    <Col md={8}>
                      <Form.Control
                        defaultValue={childrenList[index].name}
                        onBlur={(e) => {
                          updateValue('name', e.target.value, index);
                        }}
                      />
                    </Col>
                    <Col className='mb-2' md={4}>
                      <p>Altura (cm)</p>
                    </Col>
                    <Col md={8}>
                      <Form.Control
                        defaultValue={childrenList[index].height}
                        onBlur={(e) => {
                          updateValue('height', e.target.value, index);
                        }}
                      />
                    </Col>
                    <Col className='mb-2' md={4}>
                      <p>Telefone</p>
                    </Col>
                    <Col md={8}>
                      <Form.Control
                        defaultValue={childrenList[index].phone}
                        onBlur={(e) => {
                          updateValue('phone', e.target.value, index);
                        }}
                      />
                    </Col>
                    <Col className='mb-2' md={4}>
                      <p>Data de nascimento</p>
                    </Col>
                    <Col md={8}>
                      <DatePicker
                        selected={
                          (childrenList[index] && childrenList[index].birthDate != '' && childrenList[index].birthDate != null && childrenList[index].birthDate != undefined)
                            ? formatdate(childrenList[index].birthDate)
                            : null
                        }
                        dateFormat={'dd-MM-yyyy'}
                        maxDate={new Date()}
                        onChange={(date) => {
                          updateValue(
                            'birthDate',
                            dayjs(date?.toString()).format('DD-MM-YYYY'),
                            index
                          );
                        }}
                        id='documentExpiryDate'
                        renderCustomHeader={({
                          date,
                          changeYear,
                          changeMonth,
                          decreaseMonth,
                          increaseMonth,
                          prevMonthButtonDisabled,
                          nextMonthButtonDisabled,
                        }) => (
                          <div
                            style={{
                              margin: 10,
                              display: 'flex',
                              justifyContent: 'center',
                            }}
                          >
                            <button
                              onClick={decreaseMonth}
                              disabled={prevMonthButtonDisabled}
                            >
                              {'<'}
                            </button>
                            <select
                              value={dayjs(date).year()}
                              onChange={({ target: { value } }) =>
                                changeYear(Number(value))
                              }
                            >
                              {years.map((option) => (
                                <option
                                  key={option.toString()}
                                  value={option.toString()}
                                >
                                  {option}
                                </option>
                              ))}
                            </select>
                            <select
                              value={months[dayjs(date).month()]}
                              onChange={({ target: { value } }) =>
                                changeMonth(months.indexOf(value))
                              }
                            >
                              {months.map((option) => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>
                            <button
                              onClick={increaseMonth}
                              disabled={nextMonthButtonDisabled}
                            >
                              {'>'}
                            </button>
                          </div>
                        )}
                      />
                    </Col>
                    <Col className='mb-2' md={4}>
                      <p>Itinerário</p>
                    </Col>
                    <Col md={8}>
                      <Row>
                        <Col md={2}></Col>
                        <Col md={5}>
                          <p>Levantamento</p>
                        </Col>
                        <Col md={5}>
                          <p>Entrega</p>
                        </Col>
                      </Row>
                      {_contract.packItineraryDTO &&
                        _contract.packItineraryDTO.map((elIti, indexIti) => {
                          return (
                            <Row
                              key={'itinerary' + indexIti}
                              className='card_itinerary'
                            >
                              <Col md={2} className='pt-2'>
                                <Form.Check
                                  type={'checkbox'}
                                  id={`defaultcheckbox-${elIti.id}`}
                                  onChange={() =>
                                    handleSelectItinerary(index, elIti.id)
                                  }
                                />
                              </Col>
                              <Col md={5}>
                                <p>{elIti.pickUp}</p>
                                <p>{elIti.hourPickUp}</p>
                              </Col>
                              <Col md={5}>
                                <p>{elIti.dropOff}</p>
                                <p>{elIti.hourDropOff}</p>
                              </Col>
                            </Row>
                          );
                        })}
                    </Col>
                  </Row>
                </div>
              );
            })}
            <Row className='d-flex justify-content-end'>
              <Button
                variant='outline-primary'
                className='mr-3 mt-2'
                onClick={() => submit('Subscribed')}
              >
                Aceitar
              </Button>
            </Row>
          </div>
        )}
      </div>
    );
  };
  return (
    <>
      <ModalBase
        serviceId={4}
        Component={component}
        closeButton={closeButton}
        open={open}
        onClose={onClose}
      ></ModalBase>
      <Dialog
        open={alertDialog}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>{alertConfig.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            {alertConfig.message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setAlertDialog(false);
              if (alertConfig.redirect) {
                closeButton();
                setStep(1);
              }
            }}
            color='primary'
            autoFocus
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={alertDialogCancel}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>Recusar</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Tens a certeza que deseja cancelar o pedido de subscrição de
            Rodinhas?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAlertDialogCancel(false)} color='primary'>
            Cancelar
          </Button>
          <Button
            onClick={() => {
              setAlertDialogCancel(false);
              submit('Canceled');
            }}
            color='primary'
            autoFocus
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ModalRodinhas;

import React, { useState, useEffect } from 'react';
import { Form, Container, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import DatePicker from 'react-datepicker';
import { companyService } from '../../services/companyService';
import { CLIENT_PAGE } from '../../constants/clientConstants';
import { Title, Content, Styles } from '../../style/client';
import { pricingService } from 'src/services/pricingService';
import { SweetAlertType } from 'react-bootstrap-sweetalert/dist/types';
import { ModalClient } from './Components/ModalClient';
import { useHistory } from 'react-router-dom';
import { handleErrors } from 'src/utils/handleErrors';

const PricingCreate: React.FC = () => {
  const [services, setServices] = useState([] as Service[]);
  const [promoCode, setPromoCode] = useState({} as Promocode);
  const [alertDialog, setAlertDialog] = useState(false);
  const [alertDialogConfig, setAlertDialogConfig] = useState({} as AlertConfig);
  const [clientModal, setClientModal] = useState(false);
  const history = useHistory();

  useEffect(() => {
    getServices();
  }, []);

  const createPromoCode = async () => {
    if (promoCode.fareType === 'B2B2C') {
      const result = await pricingService.create([promoCode]);
      if (result.status === 200) {
        alert('Sucesso', result.message, 'success', '/pricing');
      } else {
        alert('Erro', handleErrors.getMessage(result), 'error');
      }
    } else {
      if (promoCode.clientId) {
        let pc = { ...promoCode, clientId: promoCode.clientId.id };
        const result = await pricingService.create([pc]);
        if (result.status === 200) {
          alert('Sucesso', result.message, 'success', '/pricing');
        } else {
          alert('Erro', handleErrors.getMessage(result), 'error');
        }
      } else {
        alert('Erro', 'Preencha o cliente', 'error');
      }
    }
  };

  const checkFields = () => {
    if (
      promoCode.code !== '' &&
      promoCode.discountType !== undefined &&
      promoCode.clientType !== '' &&
      promoCode.fareType !== undefined &&
      promoCode.serviceId !== undefined &&
      promoCode.beginDate !== undefined &&
      promoCode.expirationDate !== undefined &&
      promoCode.expirationDate > promoCode.beginDate
    ) {
      if (promoCode.serviceId.includes(4)) {
        if (promoCode.discountFare !== undefined) {
          return true;
        } else {
          return false;
        }
      }
      return true;
    }
    return false;
  };

  const getServices = async () => {
    const result = await companyService.getAllActive();
    if (result.data) {
      let services = result.data.filter((el) => el.id !== 6);
      setServices(services);
    }
  };

  const handleClose = (e) => {
    setPromoCode({
      ...promoCode,
      clientId: { name: e.name, id: Number(e.id) },
    });
  };

  const closeModal = (e) => {
    setClientModal(false);
  };

  const updateServices = (service) => {
    let code = { ...promoCode };
    if (code.serviceId !== undefined) {
      if (code.serviceId.includes(service)) {
        const index = code.serviceId.indexOf(service);
        code.serviceId.splice(index,1);
      } else {
        code.serviceId.push(service);
      }
    } else {
      code.serviceId = [];
      code.serviceId.push(service);
    }
    setPromoCode(code);
  };

  const alert = (
    title: String,
    message: String,
    type: SweetAlertType,
    redirect: string = ''
  ) => {
    setAlertDialog(true);
    setAlertDialogConfig({
      title: title,
      message: message,
      type: type,
      redirect: redirect,
    });
  };

  return (
    <>
      <Title>Criar um novo cupão</Title>

      <Content>
        <div className='content'>
          <Container fluid className='card-no-tabs pricing_create'>
            <Row
              style={{
                textAlign: 'center',
                font: 'normal normal normal 18px/22px Roboto',
                letterSpacing: '0px',
                color: '#878787',
                opacity: 1,
                marginLeft: '82.5px',
                marginTop: '51px',
              }}
            >
              <Link className='color-back' to={'/pricing'}>
                <p>
                  <u>&#60; {CLIENT_PAGE.BACK}</u>
                </p>
              </Link>
            </Row>

            <Row>
              <Col
                style={{
                  textAlign: 'left',
                  font: 'normal normal bold 26px/34px Roboto',
                  letterSpacing: '0px',
                  color: '#2292CC',
                  opacity: 1,
                  marginLeft: '82.5px',
                }}
              >
                <h3>Novo Cupão</h3>
              </Col>
            </Row>

            <Row
              style={{
                textAlign: 'left',
                font: 'normal normal normal 16px/19px Roboto',
                letterSpacing: '0px',
                color: '#1E1E1E',
                opacity: 1,
                margin: '16px 0 0px 65px',
              }}
            >
              <Col lg={6} className='mt-4'>
                <h6 className='light'>Código</h6>
                <Form onSubmit={(e) => e.preventDefault()}>
                  <Row>
                    <Col>
                      <Form.Control
                        onChange={(e) =>
                          setPromoCode({ ...promoCode, code: e.target.value })
                        }
                        defaultValue={promoCode.code}
                      />
                    </Col>
                  </Row>
                </Form>
              </Col>
              <Col lg={6} className='mt-4'>
                <h6 className='light'>Tipo</h6>
                <Form.Control
                  as='select'
                  custom
                  value={promoCode.discountType}
                  onChange={(e) =>
                    setPromoCode({ ...promoCode, discountType: e.target.value })
                  }
                >
                  <option value=''>Escolha o tipo</option>
                  <option value='PONTUAL'>Pontual</option>
                  <option value='REGULAR'>Regular</option>
                </Form.Control>
              </Col>
              <Col lg={6} className='mt-4'>
                <h6 className='light'>Tipo de cliente</h6>
                <Form.Control
                  as='select'
                  custom
                  value={promoCode.clientType}
                  onChange={(e) =>
                    setPromoCode({ ...promoCode, clientType: e.target.value })
                  }
                >
                  <option value=''>Escolha o tipo de cliente</option>
                  <option value='B2B'>B2B</option>
                  <option value='B2B2C'>B2B2C</option>
                  <option value='BOTH'>B2B e B2B2C</option>
                </Form.Control>
              </Col>
              <Col lg={6} className='mt-4'>
                <h6 className='light'>Tarifa</h6>
                <Form.Control
                  as='select'
                  custom
                  value={promoCode.fareType}
                  onChange={(e) =>
                    setPromoCode({ ...promoCode, fareType: e.target.value })
                  }
                >
                  <option value=''>Escolha o tipo de tarifa</option>
                  <option value='Tarifa'>Tarifa</option>
                  <option value='Promocode'>Promocode</option>
                </Form.Control>
              </Col>
              <Col lg={6} className='mt-4'>
                <h6 className='light'>Desconto</h6>
                <Form onSubmit={(e) => e.preventDefault()}>
                  <Row>
                    <Col>
                      <Form.Control
                        onChange={(e) =>
                          setPromoCode({
                            ...promoCode,
                            discountFare: e.target.value,
                          })
                        }
                        defaultValue={promoCode.discountFare}
                      />
                    </Col>
                  </Row>
                </Form>
                <h6 className='light mt-3'>Cliente</h6>
                <Form onSubmit={(e) => e.preventDefault()}>
                  <Row>
                    <Col>
                      <Form.Control
                        defaultValue={promoCode.clientId?.name}
                        onClick={() => setClientModal(true)}
                      />
                    </Col>
                  </Row>
                </Form>
              </Col>
              <Col lg={6} className='mt-4'>
                <h6 className='light'>Serviço</h6>
                <div className='services_div'>
                  {services.map((item) => {
                    return (
                      <Row key={item.id.toString()}>
                        <Form.Check
                          type='checkbox'
                          label={item.name}
                          id={`disabled-default-${item.name}`}
                          onChange={(e) => updateServices(item.id)}
                        />
                      </Row>
                    );
                  })}
                </div>
              </Col>
              <Col lg={6} className='mt-4'>
                <h6 className='light'>Data de Início</h6>
                <DatePicker
                  showTimeSelect
                  timeIntervals={30}
                  selected={promoCode.beginDate}
                  dateFormat='dd/MM/yyyy p'
                  onChange={(date) =>
                    date &&
                    setPromoCode({
                      ...promoCode,
                      beginDate: new Date(date.toString()),
                    })
                  }
                />
              </Col>
              <Col lg={6} className='mt-4'>
                <h6 className='light'>Data de Fim</h6>
                <DatePicker
                  showTimeSelect
                  timeIntervals={30}
                  selected={promoCode.expirationDate}
                  dateFormat='dd/MM/yyyy p'
                  onChange={(date) =>
                    date &&
                    setPromoCode({
                      ...promoCode,
                      expirationDate: new Date(date.toString()),
                    })
                  }
                />
              </Col>
            </Row>
            <Row className='mt-4'>
              <Col className='center'>
                <Button
                  style={Styles.okButton}
                  variant='outline-info'
                  size='lg'
                  onClick={() => createPromoCode()}
                  disabled={!checkFields()}
                >
                  Registar Cupão
                </Button>
              </Col>
            </Row>

            {alertDialog && (
              <SweetAlert
                type={alertDialogConfig?.type}
                title={alertDialogConfig?.title ?? ''}
                onConfirm={() => {
                  setAlertDialog(false);
                  if (
                    alertDialogConfig.redirect !== '' &&
                    alertDialogConfig.redirect !== undefined
                  ) {
                    history.push(alertDialogConfig.redirect);
                  }
                }}
              >
                {alertDialogConfig?.message ?? ''}.
              </SweetAlert>
            )}
          </Container>
        </div>
        <ModalClient
          open={clientModal}
          onClose={(e) => handleClose(e)}
          closeButton={closeModal}
        ></ModalClient>
      </Content>
    </>
  );
};

export default PricingCreate;

export interface Company {
  name: string;
  id: number;
}

export interface Promocode {
  serviceId: number[];
  code?: string;
  clientType?: string;
  discountType?: string;
  fareType: string;
  discountFare: string;
  clientId: Client;
  beginDate?: Date;
  expirationDate?: Date;
}

export interface Client {
  id: number;
  name: string;
}

export interface AlertConfig {
  title: String;
  message: String;
  type: SweetAlertType;
  redirect?: string;
}

export interface Service {
  active: Boolean;
  dateCreated: Date;
  description: String;
  id: Number;
  name: String;
  pathLocationFiles?: String;
}

import React, { useState, useEffect } from 'react';
import { Form, Container, Row, Col, Image, Button } from 'react-bootstrap';
import { User, AlertConfig, Country } from '../../Profile/profile.interface';
import { profileService } from '../../../services/User/profileService';
import { AuthService } from '../../../services/auth.service';
import DatePicker from 'react-datepicker';
import dayjs from 'dayjs';
import { SweetAlertType } from 'react-bootstrap-sweetalert/dist/types';
import SweetAlert from 'react-bootstrap-sweetalert';

export const ProfileData = ({ data, onSave, back }) => {
  const [user, setUser] = useState({} as User);
  const [countries, setCountries] = useState([] as Country[]);
  const [alertDialog, setAlertDialog] = useState(false);
  const [alertDialogConfig, setAlertDialogConfig] = useState({} as AlertConfig);
  const [years, setYears] = useState([] as Number[]);
  const [yearsFuture, setYearsFuture] = useState([] as Number[]);

  const months = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ];

  useEffect(() => {
    if (data) {
      getData();
    }
    getCountries();
    let _years = generateArrayOfYears('past');
    setYears(_years);
    let _yearsFuture = generateArrayOfYears('future');
    setYearsFuture(_yearsFuture);
  }, []);

  const getData = async () => {
    const result = await AuthService.getUserFromSubscription(true);
    if (result) {
      let user = result;
      if (result.dateBirth) {
        user = { ...user, dateBirth: new Date(result.dateBirth) };
      }
      if (result.documentExpiryDate) {
        user = {
          ...user,
          documentExpiryDate: new Date(result.documentExpiryDate),
        };
      }
      if (result.driverLicenseExpiryDate) {
        user = {
          ...user,
          driverLicenseExpiryDate: new Date(result.driverLicenseExpiryDate),
        };
      }
      setUser(user);
    } else {
      console.log(result.message);
    }
  };

  const getCountries = async () => {
    const result = await profileService.listCountries();
    if (result) {
      setCountries(result);
    } else {
      console.log(result.message);
    }
  };

  const alert = (
    title: String,
    message: String,
    type: SweetAlertType,
    redirect: Boolean = false
  ) => {
    setAlertDialog(true);
    setAlertDialogConfig({
      title: title,
      message: message,
      type: type,
      redirect: redirect,
    });
  };

  function generateArrayOfYears(type) {
    let max;
    let min;
    let years = [] as Number[];

    if (type === 'past') {
      max = new Date().getFullYear() - 18;
      min = new Date().getFullYear() - 100;
    } else if (type === 'future') {
      max = new Date().getFullYear() + 50;
      min = new Date().getFullYear();
    }

    for (let i = max; i >= min; i--) {
      years.push(i);
    }
    return years;
  }

  const save = async () => {
    let newUser = { ...user };
    newUser = {
      ...newUser,
      dateBirth: newUser.dateBirth
        ? dayjs(newUser.dateBirth).format('YYYY-MM-DD')
        : undefined,
      documentExpiryDate: newUser.documentExpiryDate
        ? dayjs(newUser.documentExpiryDate).format('YYYY-MM-DD')
        : undefined,
      driverLicenseExpiryDate: newUser.driverLicenseExpiryDate
        ? dayjs(newUser.driverLicenseExpiryDate).format('YYYY-MM-DD')
        : undefined,
    };
    if (
      newUser.name &&
      newUser.phoneNumber &&
      newUser.countryCode &&
      newUser.dateBirth &&
      newUser.address &&
      newUser.region &&
      newUser.postalCode &&
      newUser.district &&
      newUser.country &&
      newUser.nif &&
      newUser.driverLicense &&
      newUser.driverLicenseExpiryDate &&
      newUser.driverLicenseIssuingCountry &&
      newUser.documentExpiryDate &&
      newUser.identificationCard
    ) {
      const vatValidation = await profileService.vatValidation({
        nif: newUser.nif,
      });

      if (vatValidation.status === 200) {
        if (data) {
          const result = await profileService.updateUser(newUser);

          if (!result) {
            alert('Erro', result.message, 'error');
          } else {
            onSave(newUser);
          }
        } else {
          onSave(newUser);
        }
      } else {
        alert('Erro', vatValidation.errors[0].defaultMessage, 'error');
      }
    } else {
      alert('Erro', 'Preencha campos obrigatórios', 'error');
    }
  };

  return (
    <div className=''>
      <Row>
        <Col md={12}>
          <label htmlFor='name'>Nome completo</label>
          <Form.Control
            onChange={(e) => setUser({ ...user, name: e.target.value })}
            defaultValue={user.name}
            id='name'
          />
        </Col>
        <Col md={12} className='mt-2'>
          <label htmlFor='contact' className='mb-0'>
            Contacto
          </label>
        </Col>
        <Col md={12}>
          <p className='small'>
            Para aumento da segurança da conta, insira o número de telemóvel.
          </p>
        </Col>
        <Col md={3}>
          <Form.Control
            as='select'
            onChange={(e) =>
              setUser({ ...user, countryCode: Number(e.target.value) })
            }
            value={user.countryCode}
          >
            {countries.map((country: Country, index) => {
              return (
                <option
                  value={country.countryPhoneCode}
                  key={country.name + index + 'xx'}
                >
                  {country.name} (+{country.countryPhoneCode})
                </option>
              );
            })}
          </Form.Control>
        </Col>
        <Col md={4}>
          <Form.Control
            onChange={(e) =>
              setUser({ ...user, phoneNumber: Number(e.target.value) })
            }
            type='number'
            defaultValue={user.phoneNumber}
            id='contact'
          />
        </Col>
        <Col md={6}>
          <label htmlFor='birthday' className='mt-3'>
            Data de nascimento
          </label>
        </Col>
        <Col md={6}>
          <p className='mt-3'>Género <span className='small'>(Opcional)</span></p>
        </Col>
        <Col md={6}>
          <DatePicker
            selected={user.dateBirth}
            dateFormat='dd/MM/yyyy'
            onChange={(date) =>
              date &&
              setUser({
                ...user,
                dateBirth: new Date(date.toString()),
              })
            }
            id='birthday'
            renderCustomHeader={({
              date,
              changeYear,
              changeMonth,
              decreaseMonth,
              increaseMonth,
              prevMonthButtonDisabled,
              nextMonthButtonDisabled,
            }) => (
              <div
                style={{
                  margin: 10,
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <button
                  onClick={decreaseMonth}
                  disabled={prevMonthButtonDisabled}
                >
                  {'<'}
                </button>
                <select
                  value={dayjs(date).year()}
                  onChange={({ target: { value } }) =>
                    changeYear(Number(value))
                  }
                >
                  {years.map((option) => (
                    <option key={option.toString()} value={option.toString()}>
                      {option}
                    </option>
                  ))}
                </select>
                <select
                  value={months[dayjs(date).month()]}
                  onChange={({ target: { value } }) =>
                    changeMonth(months.indexOf(value))
                  }
                >
                  {months.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
                <button
                  onClick={increaseMonth}
                  disabled={nextMonthButtonDisabled}
                >
                  {'>'}
                </button>
              </div>
            )}
          />
        </Col>

        <Col md={6}>
          <Row>
            <Form.Check
              custom
              inline
              name='checkbox-female'
              type={'radio'}
              id={`checkbox-female`}
              style={{ marginLeft: '15px' }}
              onChange={(e) => setUser({ ...user, userGender: 'FEMALE' })}
              checked={user.userGender === 'FEMALE'}
              className='mr-0'
            />
            <label htmlFor='checkbox-female'>Feminino</label>
            <Form.Check
              custom
              inline
              name='checkbox-male'
              type={'radio'}
              id={`checkbox-male`}
              style={{ marginLeft: '15px' }}
              onChange={(e) => setUser({ ...user, userGender: 'MALE' })}
              checked={user.userGender === 'MALE'}
              className='mr-0'
            />
            <label htmlFor='checkbox-male'>Masculino</label>
          </Row>
        </Col>
        <Col md={12}>
          <label htmlFor='nif' className='mt-3'>
            NIF
          </label>
        </Col>
        <Col md={6}>
          <Form.Control
            onChange={(e) => setUser({ ...user, nif: e.target.value })}
            type='number'
            defaultValue={user.nif}
            id='nif'
          />
        </Col>
      </Row>
      <Row className='form_content'>
        <Col md={12}>
          <label htmlFor='address'>Morada</label>
        </Col>
        <Col md={12}>
          <Form.Control
            onChange={(e) => setUser({ ...user, address: e.target.value })}
            defaultValue={user.address}
            id='address'
          />
        </Col>
        <Col md={6}>
          <label htmlFor='country' className='mt-3'>
            País
          </label>
        </Col>
        <Col md={6}>
          <label htmlFor='district' className='mt-3'>
            Distrito
          </label>
        </Col>
        <Col md={6}>
          <Form.Control
            as='select'
            onChange={(e) => setUser({ ...user, country: e.target.value })}
            value={user.country ? user.country : ''}
            id='country'
          >
            <option value={''} key={''}>
              {'Selecione'}
            </option>
            {countries.map((country: Country, index) => {
              return (
                <option
                  value={country.alpha2Code}
                  key={country.alpha2Code + index + 'al'}
                >
                  {country.name}
                </option>
              );
            })}
          </Form.Control>
        </Col>
        <Col md={6}>
          <Form.Control
            onChange={(e) => setUser({ ...user, district: e.target.value })}
            defaultValue={user.district}
            id='district'
          />
        </Col>
        <Col md={6}>
          <label htmlFor='postalcode' className='mt-3'>
            Código Postal
          </label>
        </Col>
        <Col md={6}>
          <label htmlFor='region' className='mt-3'>
            Localidade
          </label>
        </Col>
        <Col md={6}>
          <Form.Control
            onChange={(e) => setUser({ ...user, postalCode: e.target.value })}
            defaultValue={user.postalCode}
            id='postalcode'
          />
        </Col>
        <Col md={6}>
          <Form.Control
            onChange={(e) => setUser({ ...user, region: e.target.value })}
            defaultValue={user.region}
            id='region'
          />
        </Col>
      </Row>
      <Row className='form_content'>
        <Col md={6}>
          <label htmlFor='driverLicense' className='mt-3'>
            Carta de condução
          </label>
        </Col>
        <Col md={6}>
          <label htmlFor='driverLicenseExpiryDate' className='mt-3'>
            Data de validade
          </label>
        </Col>
        <Col md={6}>
          <Form.Control
            onChange={(e) =>
              setUser({ ...user, driverLicense: e.target.value })
            }
            defaultValue={user.driverLicense}
            id='driverLicense'
          />
        </Col>
        <Col md={6}>
          <DatePicker
            selected={user.driverLicenseExpiryDate}
            dateFormat='dd/MM/yyyy'
            minDate={new Date()}
            onChange={(date) =>
              date &&
              setUser({
                ...user,
                driverLicenseExpiryDate: new Date(date.toString()),
              })
            }
            id='driverLicenseExpiryDate'
            renderCustomHeader={({
              date,
              changeYear,
              changeMonth,
              decreaseMonth,
              increaseMonth,
              prevMonthButtonDisabled,
              nextMonthButtonDisabled,
            }) => (
              <div
                style={{
                  margin: 10,
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <button
                  onClick={decreaseMonth}
                  disabled={prevMonthButtonDisabled}
                >
                  {'<'}
                </button>
                <select
                  value={dayjs(date).year()}
                  onChange={({ target: { value } }) =>
                    changeYear(Number(value))
                  }
                >
                  {yearsFuture.map((option) => (
                    <option key={option.toString()} value={option.toString()}>
                      {option}
                    </option>
                  ))}
                </select>
                <select
                  value={months[dayjs(date).month()]}
                  onChange={({ target: { value } }) =>
                    changeMonth(months.indexOf(value))
                  }
                >
                  {months.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
                <button
                  onClick={increaseMonth}
                  disabled={nextMonthButtonDisabled}
                >
                  {'>'}
                </button>
              </div>
            )}
          />
        </Col>
        <Col md={12}>
          <label htmlFor='driverLicenseIssuingCountry' className='mt-3'>
            País
          </label>
        </Col>
        <Col md={6}>
          <Form.Control
            as='select'
            onChange={(e) =>
              setUser({
                ...user,
                driverLicenseIssuingCountry: e.target.value,
              })
            }
            value={
              user.driverLicenseIssuingCountry
                ? user.driverLicenseIssuingCountry
                : ''
            }
            id='driverLicenseIssuingCountry'
          >
            <option value={''} key={''}>
              {'Selecione'}
            </option>
            {countries.map((country: Country) => {
              return (
                <option value={country.alpha2Code} key={country.alpha2Code}>
                  {country.name}
                </option>
              );
            })}
          </Form.Control>
        </Col>
      </Row>
      <Row className='form_content'>
        <Col md={6}>
          <label htmlFor='identificationCard' className='mt-3'>
            Documento de identidade
          </label>
        </Col>
        <Col md={6}>
          <label htmlFor='documentExpiryDate' className='mt-3'>
            Data de validade
          </label>
        </Col>
        <Col md={6}>
          <Form.Control
            onChange={(e) =>
              setUser({ ...user, identificationCard: e.target.value })
            }
            defaultValue={user.identificationCard}
            id='identificationCard'
          />
        </Col>
        <Col md={6}>
          <DatePicker
            selected={user.documentExpiryDate}
            dateFormat='dd/MM/yyyy'
            minDate={new Date()}
            onChange={(date) =>
              date &&
              setUser({
                ...user,
                documentExpiryDate: new Date(date.toString()),
              })
            }
            id='documentExpiryDate'
            renderCustomHeader={({
              date,
              changeYear,
              changeMonth,
              decreaseMonth,
              increaseMonth,
              prevMonthButtonDisabled,
              nextMonthButtonDisabled,
            }) => (
              <div
                style={{
                  margin: 10,
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <button
                  onClick={decreaseMonth}
                  disabled={prevMonthButtonDisabled}
                >
                  {'<'}
                </button>
                <select
                  value={dayjs(date).year()}
                  onChange={({ target: { value } }) =>
                    changeYear(Number(value))
                  }
                >
                  {yearsFuture.map((option) => (
                    <option key={option.toString()} value={option.toString()}>
                      {option}
                    </option>
                  ))}
                </select>
                <select
                  value={months[dayjs(date).month()]}
                  onChange={({ target: { value } }) =>
                    changeMonth(months.indexOf(value))
                  }
                >
                  {months.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
                <button
                  onClick={increaseMonth}
                  disabled={nextMonthButtonDisabled}
                >
                  {'>'}
                </button>
              </div>
            )}
          />
        </Col>
      </Row>
      <Row className='d-flex justify-content-center mt-5'>
        <a onClick={back} className='mr-3 mt-2'>
          Voltar
        </a>
        <Button variant='outline-secondary' onClick={() => save()}>
          Continuar
        </Button>
      </Row>
      {alertDialog && (
        <SweetAlert
          type={alertDialogConfig?.type}
          title={alertDialogConfig?.title ?? ''}
          onConfirm={() => {
            setAlertDialog(false);
          }}
        >
          {alertDialogConfig?.message ?? ''}.
        </SweetAlert>
      )}
    </div>
  );
};

export default ProfileData;

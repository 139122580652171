import api from "../utils/api";

export const companyService = {
  getAll,
  getAllActive,
  getClientServices,
  getUserServices
};

async function getAll() {
  try {
    const response = await api.apiPrivate.get("/api/product/service-product/list-services");
    return response
    // TODO: Mudar redirecionamento da página
  } catch (error) {
    // TODO: Criar o tratamento de erro
    throw error;
  }
}

async function getAllActive() {
  try {
    const response = await api.apiPrivate.get("/api/product/service-product/list-active-services");
    return response
    // TODO: Mudar redirecionamento da página
  } catch (error) {
    // TODO: Criar o tratamento de erro
    throw error;
  }
}

async function getClientServices(id) {
  try {
    const response = await api.apiPrivate
      .get(`api/comercial-profile/clients/client-services/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
}

async function getUserServices(id) {
  try {
    const response = await api.apiPrivate
      .get(`api/comercial-profile/user-system/user-services/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
}